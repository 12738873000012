import { useGate, useStore } from 'effector-react'
import { FC, Fragment } from 'react'

import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'
import {
  SelectLocations,
  selectLocationsModel,
} from '~/features/onboarding/select-locations'

import { Heading, RadioGroup } from '~/shared/ui'
import { locationsPageModel } from '../'

import { destinationsTypeChanged } from '../model'
import { SelectedItem } from './selected-item'
import { styles } from './styles'

export const LocationsPage: FC = () => {
  useGate(locationsPageModel.Gate)

  const selectedLocations = useStore(selectLocationsModel.$selectedLocations)
  const isStepSkippable = useStore(locationsPageModel.$isStepSkippable)
  const isMultipleDestinations = useStore(locationsPageModel.$isMultipleDestinations)

  return (
    <OnboardingLayout
      onNext={() => locationsPageModel.nextStepNavigated()}
      onReset={() => locationsPageModel.stateReset()}
      isStepSkippable={isStepSkippable}
      extendStyles={{ content: styles.wrapper }}
      text={`${selectedLocations.length} Selected`}
    >
      <Fragment>
        <Heading
          title="Already have somewhere in mind?"
          subtitle="Discover and book hand-picked hotels, cultural experiences and foodie options – curated by leading travel journalists for Condé Nast Traveller, National Geographic Traveller, Sunday Times Travel Magazine & more."
          css={styles.headingWrapper}
          extendStyles={{ title: styles.headingTitle }}
        ></Heading>

        <div css={styles.toggle.wrapper}>
          <RadioGroup
            value={isMultipleDestinations ? '2' : '1'}
            onChange={(value) => destinationsTypeChanged(value === '1' ? false : true)}
            items={[
              { value: '1', label: 'Single destination' },
              { value: '2', label: 'Multi-destination trip' },
            ]}
          />
        </div>

        <div css={styles.selectBlock}>
          <SelectLocations />

          <div css={styles.selectedItems}>
            {selectedLocations.map(({ name }, i) => (
              <SelectedItem
                key={name + i}
                label={name}
                onRemove={() => selectLocationsModel.selectedLocationRemoved(name)}
              />
            ))}
          </div>
        </div>
      </Fragment>
    </OnboardingLayout>
  )
}
