import { FC } from 'react'
import { styles } from './styles'

interface Props {
  onSelect: () => void

  isActive?: boolean
}

export const Tab: FC<Props> = ({ children, onSelect, isActive }) => {
  return (
    <div onClick={onSelect} css={styles.container(isActive)}>
      {children}
    </div>
  )
}
