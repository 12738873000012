import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    width: 100%;

    ${Breakpoints.SM} {
      position: fixed;
      z-index: 100;
      background-color: ${Colors.LIGHT_1};
      padding: 24px 20px 16px;
    }
  `,

  iframeWrapper: css`
    position: fixed;
    z-index: 100;
    background-color: ${Colors.LIGHT_1};
    padding: 0 20px 16px;
  `,

  container: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ${Breakpoints.LG} {
      flex-direction: column;
      gap: 24px;
    }
  `,

  backButton: css`
    display: none;
    position: absolute;
    left: -6px;
    top: -10px;
    padding: 6px;

    ${Breakpoints.SM} {
      display: block;
    }
  `,

  logo: css`
    /* margin: 40px 0; */
  `,
  actionsBlock: css`
    display: flex;
    align-items: center;
    gap: 5px;

    ${Breakpoints.LG} {
      flex-direction: column;
      gap: 12px;
    }
  `,
  alreadyRegistered: css`
    display: flex;

    color: ${Colors.NEUTRAL_DARK};
    letter-spacing: 2%;
  `,
}
