import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css``,

  offers: css`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 8px;
  `,

  subtitle: css`
    margin-top: 8px;

    color: ${Colors.NEUTRAL_GREY};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;

    display: flex;
    align-items: center;
    gap: 12px;

    margin-bottom: 20px;
  `,

  divider: css`
    width: 1px;
    height: 16px;
    background-color: ${Colors.NEUTRAL_STONE};
  `,

  loader: {
    container: css`
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,

    loader: css`
      width: 90px;
      height: 90px;
    `,
  },

  flightsOptions: {
    wrapper: css`
      margin-top: 24px;
    `,

    desc: css`
      font-size: 16px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    container: css`
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    `,

    button: css`
      font-size: 18px;
      color: ${Colors.NEUTRAL_GREY};
      border-color: ${Colors.NEUTRAL_GREY};
      padding: 13px 20px;

      ${Breakpoints.MD} {
        font-size: 15px;
        padding: 8px 8px;
        letter-spacing: 0.2px;
      }
    `,
  },

  priceLabel: css`
    display: flex;
    align-items: center;
    max-width: max-content;
    gap: 10px;
    background-color: #ef89431a;
    padding: 6px 8px;
    border-radius: 4px;

    font-size: 16px;
    color: #ef8943;
    img {
      width: 20px;
      height: 20px;
    }
  `,

  notFound: {
    wrapper: css`
      max-width: 1224px;
      width: 100%;
      padding-top: 100px;
      padding-bottom: 140px;
      align-self: center;

      ${Breakpoints.MD} {
        padding-top: 40px;
        padding-bottom: 100px;
      }
    `,

    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `,

    icon: css``,

    title: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-top: 20px;
    `,

    description: css`
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_GREY};
      max-width: 290px;
      margin-top: 8px;
    `,
  },
}
