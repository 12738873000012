import { TripBooking } from '@softcery/awayaway-apiclient'
import { apiErrorHandler, apiService } from '~/shared/api'

export const cancelBooking = async ({
  id,
}: {
  id: number
}): Promise<TripBooking | undefined> => {
  try {
    const { booking } = await apiService().cancelTrip({ id })

    return booking
  } catch (res: any) {
    return apiErrorHandler(res)
  }
}
