import { css } from '@emotion/react'
import { Colors, Fonts, Breakpoints } from '~/shared/styles'

export const styles = {
  base: css`
    margin-right: 10px;
    cursor: pointer;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};
    font-family: ${Fonts.Calibre};
    font-size: 20px;
    font-weight: normal;

    ${Breakpoints.SM} {
      font-size: 16px;
    }
  `,

  page: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border: solid 1px ${Colors.DARK_GREY_4};
    background-color: ${Colors.LIGHT_1};

    ${Breakpoints.SM} {
      width: 45px;
      height: 45px;
    }
  `,

  currentPage: css`
    pointer-events: none;
    opacity: 0.19;
  `,

  jump: css`
    display: flex;
    align-items: flex-end;
    height: 65px;
    ${Breakpoints.SM} {
      height: 45px;
    }
  `,
}
