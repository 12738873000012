import { useCallback, useEffect, useRef } from 'react'
import {
  checkIfSeatsIsSelected,
  getElementByDataTestId,
} from '~/features/select-services/lib/change-ui'
import { ServiceOptionType } from '~/features/select-services/types'
import { UiElementsDataAtributeNameDuffelServiceOption } from '../const'

export const useGetTypeFromButtonService = (
  callBack: (type: ServiceOptionType | null) => void,
  onResetClicked: () => void,
): void => {
  const baggageButton = getElementByDataTestId(
    UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
  )
  const seatButton = getElementByDataTestId(
    UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
  )

  if (baggageButton) {
    baggageButton.addEventListener('click', () => callBack(ServiceOptionType.BAGGAGE))
  }
  if (seatButton) {
    seatButton.addEventListener('click', () => {
      callBack(ServiceOptionType.SEAT)
      onResetClicked()
    })
  }
}

export const useClickedConfirmForSeats = (
  onClickConfirmButton: (clicked: boolean) => void,
  onClickSelectSeat: (select: boolean) => void,
) => {
  const observerRef = useRef<MutationObserver | null>(null)

  const attachClickListener = useCallback((button: HTMLElement) => {
    if (button.textContent === 'Confirm') {
      button.addEventListener('click', () => {
        onClickConfirmButton(true)
      })
    }
  }, [])

  useEffect(() => {
    const targetNode = window.document.body
    const config = { childList: true, subtree: true }

    observerRef.current = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const existElement = checkIfSeatsIsSelected()

          if (existElement) {
            onClickSelectSeat(true)
          }

          const confirmButton = getElementByDataTestId(
            UiElementsDataAtributeNameDuffelServiceOption.CONFIRM_SELECTION_FOR_SEATS,
          )
          if (confirmButton && confirmButton instanceof HTMLElement) {
            attachClickListener(confirmButton)
            break
          }
        }
      }
    })

    if (observerRef.current && targetNode) {
      observerRef.current.observe(targetNode, config)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [])
}
