import { createEffect, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'

import { SelectedHotelRoom } from '~/entities/room'
import { LocalStorageKeys } from '~/shared/config'

import { createGuestGroupsForms, generateGuestsWithDetails } from '../lib'
import { DocumentType, GuestGroupForms, GuestGroups } from '../types'

type GuestDetailsFormInitPayload = {
  selectedRooms: SelectedHotelRoom[]
  availableDocumentTypes: DocumentType[]
}

const checkIfAllFormsValidFx = createEffect((guestGroupForms: GuestGroupForms) => {
  return Object.values(guestGroupForms).every((forms) =>
    // eslint-disable-next-line effector/no-getState
    forms.every((form) => form.$isValid.getState()),
  )
})

const validateFormsFx = createEffect((guestGroupForms: GuestGroupForms) => {
  return Object.values(guestGroupForms).map((forms) =>
    forms.map((form) => form.validate()),
  )
})

export const stateReset = createEvent()
export const guestDetailsFormInit = createEvent<GuestDetailsFormInitPayload>()
export const validationInitiated = createEvent()
export const validationCompleted = createEvent<boolean>()

export const $availableDocumentTypes = createStore<DocumentType[]>([])
export const $guestGroups = createStore<GuestGroups | null>(null)
export const $guestGroupsForms = createStore<GuestGroupForms>({})
export const $isAllGuestFormsValid = createStore(false)
export const $hasError = createStore(false)


// Synchronize guest group forms with guest groups store
sample({
  clock: validationInitiated,
  source: $guestGroupsForms,
  fn: (guestGroupsForms) => {
    const guestGroups: GuestGroups = {}

    Object.keys(guestGroupsForms).forEach((roomId) => {
      const forms = guestGroupsForms[roomId]

      // eslint-disable-next-line effector/no-getState
      guestGroups[roomId] = forms.map((form: any) => form.$values.getState())
    })

    return guestGroups
  },
  target: $guestGroups,
})

sample({
  clock: guestDetailsFormInit,
  fn: ({ availableDocumentTypes }) => availableDocumentTypes,
  target: $availableDocumentTypes,
})

// Generate initial guest groups if not set on form initialization
sample({
  clock: guestDetailsFormInit,
  source: $guestGroups,
  filter: (guestGroups) => guestGroups === null,
  fn: (_, { selectedRooms }) => {
    const guestGroups: GuestGroups = {}

    selectedRooms.forEach((room) => {
      guestGroups[room.roomId] = generateGuestsWithDetails(room.guests!)
    })

    return guestGroups
  },
  target: $guestGroups,
})

// Create guest group forms based on guest groups and document types
sample({
  clock: $guestGroups,
  source: $availableDocumentTypes,
  filter: (_, guestsGroups) => guestsGroups !== null,
  fn: (availableDocumentTypes, guestGroups) =>
    createGuestGroupsForms(guestGroups!, availableDocumentTypes),
  target: $guestGroupsForms,
})

sample({
  clock: validationInitiated,
  source: $guestGroupsForms,
  target: validateFormsFx,
})

sample({
  clock: validateFormsFx.done,
  source: $guestGroupsForms,
  target: checkIfAllFormsValidFx,
})

sample({
  clock: checkIfAllFormsValidFx.doneData,
  target: [$isAllGuestFormsValid, validationCompleted],
})

sample({
  clock: validationCompleted,
  fn: (isValid) => !isValid,
  target: $hasError,
})

reset({
  clock: stateReset,
  target: [$guestGroups, $guestGroupsForms, $isAllGuestFormsValid, $hasError],
})

persist({
  store: $guestGroups,
  key: LocalStorageKeys.GuestGroups,
})
