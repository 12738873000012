import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    justify-content: center;
  `,

  wrapper: css`
    padding-bottom: 130px;

    ${Breakpoints.SM} {
      width: 100%;
    }
  `,

  headingWrapper: css`
    margin: 36px 0 60px;
    text-align: center;

    p {
      ${Breakpoints.SM} {
        font-size: 16px;
        line-height: 19px;
      }
    }

    ${Breakpoints.SM} {
      margin: 24px 0 32px;
    }
  `,

  headingTitle: css`
    text-align: center;
    font-weight: 300;

    ${Breakpoints.SM} {
      font-size: 24px;
      font-weight: 400;
    }
  `,
}
