import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    background-color: ${Colors.LIGHT_1};
    padding: 40px 0;
    display: flex;
    align-items: flex-end;
    gap: 20px;
  `,

  airports: css`
    display: flex;
    gap: 20px;

    ${Breakpoints.SM} {
      flex-direction: column;
      width: 100%;
    }
  `,

  toAirportInput: {
    container: css`
      border-left: none;
    `,
  },

  journeyType: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,

  button: css`
    width: 155px;
    height: 48px;
    font-size: 18px;
  `,

  select: {
    control: css`
      padding: 11px;
      outline: none;
    `,

    container: css`
      width: 241px;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,
  },
}
