import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const RadioButtonIcon: FC<Props> = ({
  width = 20,
  height = 20,
  color = '#555555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke={color} />
    </svg>
  )
}
