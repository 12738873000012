import { useStore } from 'effector-react'
import { Label } from '~/shared/ui'

import { addAdditionalDetailsModel } from '..'
import { styles } from './styles'

export const AdditionalDetailsForm = () => {
  const additionalDetails = useStore(addAdditionalDetailsModel.$additionalDetails)

  return (
    <div css={styles.wrapper}>
      <div css={[styles.title, styles.title]}>Anything else?</div>
      <div css={styles.subtitle}>
        Please let us know if there is anything else that we can do to make your stay
        extra special. <br />
        Our Travel Experts review every booking, so we'll take note of your requests!
      </div>

      <Label>Your request</Label>

      <textarea
        placeholder="Enter your request here..."
        css={styles.largeInput}
        value={additionalDetails}
        onChange={(e) =>
          addAdditionalDetailsModel.additionalDetailsUpdated(e.target.value)
        }
      ></textarea>
    </div>
  )
}
