import { SerializedStyles } from '@emotion/react'

import { FC } from 'react'
import { Tooltip } from '~/shared/ui/atoms'
import { CancellationFeesDetails } from './cancellation-fees-details'
import { styles } from './styles'

interface Props {
  policies: any[]
  currency: string
  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }
}

export const StrictPolicyDetails: FC<Props> = ({ policies, currency, extendStyles }) => {
  if (policies.length === 0) return null
  return (
    <>
      You may not be entitled to a refund.{' '}
      <Tooltip
        content={<CancellationFeesDetails policies={policies} currency={currency} />}
        extendStyle={extendStyles?.tooltip || styles.tooltip.container}
        tooltipPosition={{
          side: 'bottom',
          align: 'start',
          alignOffset: 40,
        }}
      >
        <span css={[styles.info.dashedText, extendStyles?.focusedText]}>
          View cancellation fee’s
        </span>
      </Tooltip>
    </>
  )
}
