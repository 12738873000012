import { Analytics, Properties } from './types'

const identify = (id: string, properties?: Properties) => {
  try {
    if (process.env.AA_GTAG_ID && typeof window.gtag === 'function')
      gtag('config', process.env.AA_GTAG_ID, {
        user_id: id,
        ...properties,
      })
  } catch (e) {
    console.error('Failed to identify - ', id)
  }
}

const trackEvent = (name: string, properties?: Properties) => {
  try {
    if (typeof window.gtag === 'function') {
      gtag('event', name, properties)
    }
  } catch (e) {
    console.log('Google analytics track event error: ', e)
  }
}

const trackConversion = (properties?: Properties) => {
  try {
    if (typeof window.gtag === 'function') {
      // Sign-up [test]
      gtag('event', 'conversion_event_signup', properties)
      // Sign-up [code]
      gtag('event', 'conversion_event_signup_3', properties)
      // [test] Sign-up
      gtag('event', 'conversion', {
        send_to: 'AW-11282720541/7OYRCNe3q54ZEJ3OgoQq',
        ...properties,
      })
    }
  } catch (e) {
    console.log('Google analytics trackConversion event error: ', e)
  }
}

export const googleAnalytics: Analytics = {
  name: 'google',
  identify,
  trackEvent,
  trackConversion,
}
