import { css, keyframes } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

const loadingAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`

export const styles = {
  container: css`
    width: 100%;
    min-width: 255px;
    height: 100%;
    min-height: 270px;
    cursor: pointer;
    border-radius: 8px;
    aspect-ratio: 67 / 76;
    background-color: ${Colors.LIGHT_GREY_2};
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 2s ${loadingAnimation} ease-in-out infinite;
    position: relative;

    ${Breakpoints.MD} {
      min-height: 200px;
    }

    ${Breakpoints.SM} {
      min-width: 140px;
    }
  `,

  image: css`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  `,

  selected: css`
    position: absolute;
    bottom: 16px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    user-select: none;
    color: ${Colors.DARK_1};
    border-radius: 999px;
    background-color: ${Colors.LIGHT_1};
    font-size: 14px;
    font-weight: 400;
  `,

  overlay: css`
    width: 100%;
    height: 100%;
    opacity: 0.56;
    background-color: ${Colors.PRIMARY_GREEN};
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 4%);
  `,

  border: css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 4px solid ${Colors.PRIMARY_GREEN};
    border-radius: 6px;
  `,
}
