import { SerializedStyles } from '@emotion/react'
import { FC, InputHTMLAttributes, ReactNode } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { TextInput, Label, Error } from '../../atoms'
import { styles } from './styles'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  error?: string | undefined
  register?: UseFormRegister<any>
  label?: string
  extendStyle?: {
    container?: SerializedStyles
    wrapper?: SerializedStyles
    input?: SerializedStyles
    label?: SerializedStyles
  }
  additionalIcon: ReactNode
}

export const InputWithIcon: FC<Props> = ({
  name,
  label,
  error,
  register,
  extendStyle,
  additionalIcon,

  ...props
}) => (
  <div css={extendStyle?.wrapper}>
    {label && <Label extendStyles={styles.label}>{label}</Label>}
    <div css={[styles.container, extendStyle?.container]}>
      <TextInput
        name={name}
        error={!!error}
        register={register}
        extendStyle={extendStyle?.input}
        {...props}
      />
      {additionalIcon}
    </div>
    {error && <Error>{error}</Error>}
  </div>
)
