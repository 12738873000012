import { sample } from 'effector'

import { confirmBookingModel } from '~/features/trip-booking/confirm-booking'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import {
  RoomsStepGate,
  CheckoutStepGate,
  TripInfoStepGate,
  FlightsStepGate,
  BookingSummaryGate,
} from './'

sample({
  clock: confirmBookingModel.confirmTripBookingFx.doneData,
  fn: (res) => ({
    name: eventNames.bookingPaymentCompleted,
    properties: {
      user_id: res?.userId || 0,
      trip_id: res?.tripId || 0,
      trip_name: res?.name || '',
      full_price:
        res?.paymentTransactions?.reduce((acc, { amount }) => acc + (amount || 0), 0) ||
        0,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: TripInfoStepGate.open,
  fn: () => ({
    name: eventNames.visitedDatesAndGuestsStep,
  }),
  target: analyticsModel.track,
})

sample({
  clock: RoomsStepGate.open,
  fn: () => ({
    name: eventNames.visitedRoomsStep,
  }),
  target: analyticsModel.track,
})

sample({
  clock: FlightsStepGate.open,
  fn: () => ({
    name: eventNames.flightDetailsVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: CheckoutStepGate.open,
  fn: () => ({
    name: eventNames.visitedPaymentStep,
  }),
  target: analyticsModel.track,
})

sample({
  clock: CheckoutStepGate.open,
  fn: () => ({
    name: eventNames.bookingPaymentVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: BookingSummaryGate.open,
  fn: () => ({
    name: eventNames.visitedConfirmationStep,
  }),
  target: analyticsModel.track,
})
