import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    gap: 16px;
  `,

  content: css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    max-width: 544px;
    width: 100%;

    ${Breakpoints.MD} {
      max-width: auto;
      width: 100%;
    }
  `,

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 20px;
    font-weight: 400;
    margin-top: 6px;

    ${Breakpoints.MD} {
      font-size: 18px;
    }
  `,

  text: css`
    color: ${Colors.NEUTRAL_DARK};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;
    margin-bottom: 18.5px;

    ${Breakpoints.MD} {
      font-size: 16px;
    }
  `,

  timeline: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  icon: (isActive?: boolean, pulseAnimation?: boolean) => css`
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${isActive ? 'rgba(0, 159, 157, 0.1)' : Colors.NEUTRAL_LIGHT};

    box-shadow: 0 0 0 0 rgba(0, 159, 157, 0.1);
    transform: scale(1);
    animation: ${pulseAnimation ? 'pulse 1s' : 'none'};

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 159, 157, 0.7);
      }

      70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  `,

  line: (hidden?: boolean) => css`
    position: relative;
    width: 1px;
    height: 100%;
    background: ${Colors.NEUTRAL_STONE};

    ${hidden &&
    css`
      display: none;

      div {
        display: none;
      }
    `}
  `,

  progress: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: ${Colors.PRIMARY_GREEN};
    transform-origin: top;
  `,
}
