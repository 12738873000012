import { css } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
    margin-left: 10px;
  `,

  pagination: css`
    display: flex;
  `,
}
