import { sample } from 'effector'
import { selectBudgetModel } from '~/features/onboarding/select-budget'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { Gate, nextStepNavigated } from './'

sample({
  clock: nextStepNavigated,
  source: selectBudgetModel.$selectedBudget,
  fn: (selectedBudget) => ({
    name: eventNames.completedBudgetStep,
    properties: { budget: selectedBudget?.category || 'not selected' },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  fn: () => ({
    name: eventNames.quizBudgetVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: selectBudgetModel.$selectedBudget,
  fn: (selectedBudget) => ({
    name: eventNames.quizBudgetCompleted,
    properties: { budget: selectedBudget?.category || 'not selected' },
  }),
  target: analyticsModel.track,
})
