import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const RefreshIcon: FC<Props> = ({
  width = 17,
  height = 16,
  color = '#FDFDFD',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10164_5389)">
        <path
          d="M1.1665 2.66663V6.66662H5.1665"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8335 13.3334V9.33337H11.8335"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1598 6.00001C13.8217 5.04453 13.2471 4.19028 12.4895 3.51696C11.732 2.84363 10.8162 2.37319 9.82764 2.14952C8.8391 1.92584 7.81 1.95624 6.83638 2.23786C5.86275 2.51948 4.97633 3.04315 4.25984 3.76001L1.1665 6.66668M15.8332 9.33334L12.7398 12.24C12.0233 12.9569 11.1369 13.4805 10.1633 13.7622C9.18967 14.0438 8.16057 14.0742 7.17203 13.8505C6.18348 13.6268 5.2677 13.1564 4.51015 12.4831C3.75259 11.8097 3.17795 10.9555 2.83984 10"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10164_5389">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
