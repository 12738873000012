import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  extendStyle?: SerializedStyles
}

export const Loader: FC<Props> = ({ extendStyle }) => (
  <svg
    css={[styles.base, extendStyle]}
    width="20"
    height="23"
    viewBox="0 0 26 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26 13.5C26 11.7928 25.6637 10.1023 25.0104 8.52512C24.3571 6.94788 23.3995 5.51477 22.1924 4.30761C20.9852 3.10045 19.5521 2.14288 17.9749 1.48957C16.3977 0.836255 14.7072 0.5 13 0.5L13 3.1C14.3657 3.1 15.7181 3.369 16.9799 3.89165C18.2417 4.4143 19.3882 5.18036 20.3539 6.14609C21.3196 7.11182 22.0857 8.25831 22.6083 9.52009C23.131 10.7819 23.4 12.1343 23.4 13.5H26Z" />
  </svg>
)
