import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  extendStyles?: SerializedStyles
}

export const Error: FC<Props> = ({ children, extendStyles }) => (
  <p css={[styles.text, extendStyles]}>{children}</p>
)
