import { useForm } from 'effector-forms'
import { useGate, useStore, useUnit } from 'effector-react'
import { viewerModel } from '~/entities/viewer'
import {
  AvatarWithPlaceholder,
  Button,
  ButtonWithLoader,
  SelectWithLabel,
} from '~/shared/ui'
import { InputWithLabel } from '~/shared/ui/molecules/input-with-label'
import { FileUploader } from '~/shared/ui/organisms/file-uploader'
import { personalInfoModel } from '..'
import { styles } from './styles'

export const UpdatePersonalInfo = () => {
  useGate(personalInfoModel.Gate)
  const { fields, errorText } = useForm(personalInfoModel.$$personalInfoForm)
  const isFormChanged = useStore(personalInfoModel.$isFormContainsChanges)
  const viewer = useStore(viewerModel.$viewer)
  const avatar = useStore(personalInfoModel.$avatar)

  const { error, loading, success } = useUnit({
    error: personalInfoModel.$error,
    success: personalInfoModel.$successMessage,
    loading: personalInfoModel.updateUserInfoFx.pending,
  })

  return (
    <div css={styles.layout.wrapper}>
      <div css={styles.layout.container}>
        {error && <div css={styles.statusBlock(true)}>{error}</div>}
        {success && <div css={styles.statusBlock(false)}>{success}</div>}
        <div css={styles.layout.tile}>
          <h6 css={styles.title}>Personal Information</h6>

          <div css={styles.layout.rowContainer}>
            <AvatarWithPlaceholder userName={viewer.fullName!} url={avatar} />
            <FileUploader model={personalInfoModel.$$fileUploadModel} />
          </div>

          <div css={styles.layout.rowContainer}>
            <InputWithLabel
              type="text"
              name="fullName"
              label="Name"
              placeholder="John Doe"
              value={fields.fullName.value}
              error={errorText('fullName')}
              onChange={(e) => fields.fullName.onChange(e.target.value)}
              extendStyle={{ wrapper: styles.input.wrapper, input: styles.input.text }}
            />
          </div>
        </div>

        <div css={styles.divider} />

        <div css={styles.layout.tile}>
          <h6 css={styles.title}>Language & Currency</h6>
          <div css={styles.layout.rowContainer}>
            <SelectWithLabel
              onSelect={() => console.log('select')}
              label={'Default Language'}
              options={[{ label: 'English', value: 'English' }]}
              extendStyles={{ container: styles.input.wrapper }}
              isDisabled={true}
            />

            <SelectWithLabel
              onSelect={() => console.log('select')}
              label={'Currency'}
              options={[{ label: 'Great British Pound (£)', value: 'GBR' }]}
              extendStyles={{ container: styles.input.wrapper }}
              isDisabled={true}
            />
          </div>
        </div>

        <div css={styles.divider} />

        <div css={styles.layout.tile}>
          <h6 css={styles.title}>Notifications</h6>
          <div css={styles.input.container}>
            <input
              type="checkbox"
              id="subscribe"
              name="scales"
              checked={fields.notifications.value}
              css={styles.input.checkbox}
              onChange={(e) => fields.notifications.onChange(e.target.checked)}
            />
            <label htmlFor="subscribe">Subscribe to marketing emails</label>
          </div>
        </div>

        <div css={styles.layout.actionsContainer}>
          <ButtonWithLoader
            variant="default"
            onClick={() => personalInfoModel.submitInitiated()}
            disabled={!isFormChanged}
            loading={loading}
          >
            Save Changes
          </ButtonWithLoader>

          <Button variant="link" disabled={true}>
            Delete Account
          </Button>
        </div>
      </div>
    </div>
  )
}
