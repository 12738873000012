import { useEffect, useState } from 'react'
import { styles } from './styles'

export const TripDetailsNavigation = () => {
  const [isSticky, setSticky] = useState(false)
  const [activeSection, setActiveSection] = useState('')

  const smoothScroll = (targetId: string) => {
    const targetElement = document.getElementById(targetId)
    targetElement?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setSticky(scrollPosition > 85)

      const overviewSection = document.getElementById('overview')!
      const hotelsSection = document.getElementById('hotels')!
      const roomsSection = document.getElementById('rooms')!
      const itinerarySection = document.getElementById('itinerary')!
      const flightsSection = document.getElementById('flights')!

      if (
        scrollPosition >= overviewSection.offsetTop - 35 &&
        scrollPosition < hotelsSection.offsetTop - 35
      ) {
        setActiveSection('overview')
      } else if (
        scrollPosition >= hotelsSection.offsetTop - 35 &&
        scrollPosition < roomsSection.offsetTop - 35
      ) {
        setActiveSection('hotels')
      } else if (
        scrollPosition >= roomsSection.offsetTop - 35 &&
        scrollPosition < flightsSection.offsetTop - 35
      ) {
        setActiveSection('rooms')
      } else if (
        scrollPosition >= flightsSection.offsetTop - 35 &&
        scrollPosition < itinerarySection.offsetTop - 35
      ) {
        setActiveSection('flights')
      } else if (scrollPosition >= itinerarySection.offsetTop - 35) {
        setActiveSection('itinerary')
      } else {
        setActiveSection('')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav css={styles.nav.wrapper(isSticky)}>
      <div css={styles.nav.container}>
        <div
          css={[styles.nav.item, activeSection === 'overview' && styles.nav.activeItem]}
          onClick={() => smoothScroll('overview')}
        >
          Overview
        </div>
        <div
          css={[styles.nav.item, activeSection === 'hotels' && styles.nav.activeItem]}
          onClick={() => smoothScroll('hotels')}
        >
          Hotel
        </div>
        <div
          css={[styles.nav.item, activeSection === 'rooms' && styles.nav.activeItem]}
          onClick={() => smoothScroll('rooms')}
        >
          Rooms
        </div>
        <div
          css={[styles.nav.item, activeSection === 'flights' && styles.nav.activeItem]}
          onClick={() => smoothScroll('flights')}
        >
          Flights
        </div>
        <div
          css={[styles.nav.item, activeSection === 'itinerary' && styles.nav.activeItem]}
          onClick={() => smoothScroll('itinerary')}
        >
          Itinerary
        </div>
      </div>
    </nav>
  )
}
