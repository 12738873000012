import { FC } from 'react'
import { Colors } from '~/shared/styles'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const SunIcon: FC<Props> = ({
  width = 17,
  height = 16,
  color = Colors.NEUTRAL_STONE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="sun" clipPath="url(#clip0_10750_14086)">
        <path
          id="Vector"
          d="M8.49984 11.3332C10.3408 11.3332 11.8332 9.84079 11.8332 7.99984C11.8332 6.15889 10.3408 4.6665 8.49984 4.6665C6.65889 4.6665 5.1665 6.15889 5.1665 7.99984C5.1665 9.84079 6.65889 11.3332 8.49984 11.3332Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.5 0.666504V1.99984"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.5 14V15.3333"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M3.31348 2.81348L4.26014 3.76014"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M12.7397 12.2402L13.6864 13.1869"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M1.1665 8H2.49984"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M14.5 8H15.8333"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M3.31348 13.1869L4.26014 12.2402"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_9"
          d="M12.7397 3.76014L13.6864 2.81348"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10750_14086">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
