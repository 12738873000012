import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { styles } from './styles'

export const NavigationSkeleton: FC = () => {
  return (
    <div css={styles.container}>
      <Skeleton css={styles.skeleton} />
      <Skeleton css={styles.skeleton} />
      <Skeleton css={styles.skeleton} />
    </div>
  )
}
