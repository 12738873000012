import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    padding: 40px 0;

    ${Breakpoints.SM} {
      padding: 30px 0 0;
    }
  `,

  container: css`
    display: grid;
    grid-template-columns: 197px 1fr;
    grid-column-gap: 26px;
    align-items: center;

    ${Breakpoints.SM} {
      display: flex;
      flex-direction: column;
      align-self: start;
      justify-content: flex-start;
      width: 100%;
      gap: 20px;
    }
  `,

  number: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.NEUTRAL_GREY};
    margin-bottom: 15px;
  `,

  image: {
    container: (placeholder: boolean, dimmed: boolean) => css`
      width: 197px;
      height: 126px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      opacity: ${dimmed ? '0.5' : '1'};

      ${Breakpoints.SM} {
        width: 100%;
      }

      ${placeholder &&
      css`
        border: 1px solid ${Colors.LIGHT_GREY_1};
      `}
    `,

    base: css`
      width: 197px;
      height: 126px;
      object-position: center;
      object-fit: cover;
      border-radius: 8px;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,

    placeholder: css`
      object-position: center;
    `,
  },

  info: {
    wrapper: css`
      display: grid;
      grid-row-gap: 12px;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,

    title: (dimmed: boolean) => css`
      font-family: ${Fonts.Calibre};
      font-weight: 400;
      font-size: 20px;
      color: ${dimmed ? Colors.NEUTRAL_GREY : Colors.NEUTRAL_BLACK};
    `,

    container: css`
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-column-gap: 20px;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      ${Breakpoints.SM} {
        grid-template-columns: repeat(1, auto);
        gap: 10px;
      }
    `,

    itemWrapper: css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `,

    item: (dimmed: boolean) => css`
      font-family: ${Fonts.Calibre};
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 2%;
      color: ${dimmed ? Colors.NEUTRAL_GREY : Colors.NEUTRAL_GREY};

      &:not(:last-child)::after {
        content: '';
        vertical-align: center;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: ${Colors.NEUTRAL_SILVER};
        margin-left: 20px;
      }
    `,
  },

  link: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${Colors.PRIMARY_GREEN};
    margin-right: auto;
  `,

  status: css`
    width: fit-content;

    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${Colors.NEUTRAL_SILVER};

    font-size: 18px;
    font-weight: 400;
    color: ${Colors.NEUTRAL_DARK};
    letter-spacing: 0.02em;
  `,
}
