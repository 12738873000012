import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 8px;
  `,
}
