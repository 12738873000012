import * as yup from 'yup'
import { normalizeStringCase } from '~/shared/lib/string'

export const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export const getLoginErrorMessage = (error: Error) =>
  normalizeStringCase(
    'Failed to login user: ' +
      (normalizeStringCase(error.message) === 'Invalid request body'
        ? 'invalid email or password'
        : error.message),
  )
