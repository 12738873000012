import { createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { LocalStorageKeys } from '~/shared/config'

export const Gate = createGate<void>()
export const skippedLocationsStage = createEvent()
export const nextStepNavigated = createEvent()
export const destinationsTypeChanged = createEvent<boolean>()
export const stateReset = createEvent()
export const $isStepSkippable = createStore(true)
export const $isMultipleDestinations = createStore(false)
persist({ store: $isMultipleDestinations, key: LocalStorageKeys.IsMultipleDestinations })

sample({
  clock: destinationsTypeChanged,
  target: $isMultipleDestinations,
})

sample({
  clock: [
    Gate.open,
    selectLocationsModel.getCountryCodeByAddressFx.done,
    selectLocationsModel.selectedLocationRemoved,
  ],
  source: selectLocationsModel.$selectedLocations,
  fn: (selectedLocations) => !Boolean(selectedLocations.length),
  target: $isStepSkippable,
})

sample({
  clock: Gate.open,
  target: onboardingSessionModel.scrollToTopFx,
})

sample({
  clock: skippedLocationsStage,
  target: [nextStepNavigated, selectLocationsModel.selectedLocationsReset],
})

reset({
  clock: stateReset,
  target: [
    $isStepSkippable,
    selectLocationsModel.$selectedLocations,
    $isMultipleDestinations,
  ],
})
