import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  hotels: {
    title: css`
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      margin-bottom: 12px;
    `,
    container: css`
      max-width: 1200px;
      ${Breakpoints.XL} {
        margin-left: 0px;
      }
    `,
    multipleHotels: css`
      margin-right: 30px;
    `,
    list: css`
      display: flex;
    `,
    arrows: css`
      width: 44px;
      height: 44px;
    `,
  },

  pagination: css`
    display: flex;
    justify-content: center;
    color: ${Colors.NEUTRAL_DARK};
    font-size: 14px;
    margin-top: 5px;
  `,
}
