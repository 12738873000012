import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  label: css`
    color: ${Colors.NEUTRAL_DARK};
    font-size: 16px;
  `,

  container: css`
    display: flex;
    width: 241px;
    height: 48px;
    border: 1px solid ${Colors.NEUTRAL_STONE};
    margin-top: 8px;

    ${Breakpoints.SM} {
      width: 100%;
    }
  `,

  combobox: css`
    padding: 0;
  `,

  input: {
    wrapper: css`
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 18px;
    `,

    container: css`
      width: 100%;
    `,

    field: css`
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.NEUTRAL_BLACK};
      border: 0;
      outline: none;
      height: 100%;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: transparent;
        &::placeholder {
          color: ${Colors.LIGHT_GREY_1};
        }
      }
    `,
  },

  dropdown: {
    wrapper: css`
      position: relative;
      width: 100%;
      max-width: 540px;
    `,

    container: css`
      position: absolute;

      overflow: hidden;
      width: 100%;
      padding: 10px 0;
      z-index: 999;
      border: 1px solid ${Colors.NEUTRAL_STONE};
      border-top: none;
      background-color: ${Colors.LIGHT_1};
      & [data-reach-combobox-option][data-highlighted] {
        background-color: ${Colors.LIGHT_GREY_2};
      }
    `,

    loading: css`
      padding: 10px 30px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    item: css`
      padding: 10px 30px;
      cursor: pointer;
      color: ${Colors.NEUTRAL_BLACK};
      background-color: ${Colors.LIGHT_1};
      &:hover {
        background-color: ${Colors.LIGHT_GREY_2};
      }
    `,

    active: css`
      background-color: ${Colors.LIGHT_GREY_2};
    `,
  },
}
