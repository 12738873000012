import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 16px;

    border: 1px solid ${Colors.NEUTRAL_LIGHT};
    border-radius: 20px;
    width: max-content;

    ${Breakpoints.MD} {
      width: auto;
      border: 0;
    }
  `,
}
