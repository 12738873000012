import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  base: css`
    display: block;
    cursor: pointer;
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 2%;
    color: ${Colors.PRIMARY_GREEN};
  `,
}
