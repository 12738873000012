import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: (isSingleHotel?: boolean) => css`
    position: relative;
    height: ${isSingleHotel ? '270px' : '200px'};
    min-height: ${isSingleHotel ? '270px' : '200px'};
    width: ${isSingleHotel && '100%'};
    max-width: ${isSingleHotel && '470px'};
    border-radius: ${isSingleHotel && '16px'};
    overflow: hidden;

    ${Breakpoints.LG} {
      max-width: ${isSingleHotel && '400px'};
    }

    ${Breakpoints.MD} {
      border-radius: 0;
      width: 100%;
      max-width: ${isSingleHotel && 'unset'};
    }

    ${Breakpoints.SM} {
      height: 200px;
      min-height: 200px;
      border-radius: 0;
    }
  `,

  image: css`
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  `,

  gradient: css`
    position: absolute;
    /* might be needed in scope of redesign*/
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) -29.57%, rgba(0, 0, 0, 0.7) 100%),
      rgba(0, 0, 0, 0.2); */
    background: linear-gradient(360deg, #1d1d1d 0%, rgba(217, 217, 217, 0) 35.1%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  hotelName: css`
    position: absolute;
    bottom: 25px;
    left: 15px;
    color: ${Colors.LIGHT_1};
    font-size: 24px;
    font-weight: normal;
  `,
}
