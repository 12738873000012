import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    width: 273px;
    height: 93px;

    border-radius: 4px;
    border: 1px solid ${Colors.NEUTRAL_SILVER};

    padding: 24px;

    ${Breakpoints.SM} {
      width: 100%;
    }
  `,

  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  label: css`
    display: block;
    font-weight: 400px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000000;
  `,

  description: css`
    font-weight: 400px;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_GREY};

    margin-top: 4px;
  `,

  counter: css`
    border: 0;
  `,
}
