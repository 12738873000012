export const isStaging = process.env.AA_API_URL === 'https://api.stag.awayaway.com'
export const isProduction = process.env.AA_API_URL === 'https://api.awayaway.com'

export const LANDING_PAGE_URL = isStaging
  ? 'https://awayaway-landing-page.webflow.io'
  : 'https://awayaway.com'

export enum AppRoute {
  Home = '/',
  DestinationsList = '/destinations-list',
  // onboarding
  Inspiration = '/inspiration',
  Destination = '/destination',
  Guests = '/guests',
  Budget = '/budget',
  Dates = '/dates',
  Summary = '/summary',

  // destinations
  Destinations = '/recommendations',
  DestinationsDetails = '/details',

  // reset password
  ResetPassword = '/reset-password',

  // user
  UserVerify = '/users/verify',

  // account
  Account = '/account/*',
  AccountBookings = '/account/bookings',
  AccountSecurity = '/account/security',
  AccountPersonalInfo = '/account/personal-info',

  Verification = '/verification',

  SentryDebug = '/sentry-debug',
}
