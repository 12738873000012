import { wrap } from 'module'
import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    position: relative;

    overflow-x: hidden;
  `,

  drawer: css`
    border-radius: 12px 12px 0px 0px !important;
    box-shadow: 0px -4px 40px 0px rgba(43, 43, 43, 0.1) !important;
    background-color: red !important;
  `,

  headerContainer: css`
    z-index: 4;
    background-color: ${Colors.LIGHT_2};
  `,

  title: css`
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: normal;
  `,

  hotels: (isSingle: boolean | undefined) => css`
    padding: 30px 0 80px 0px;
    background-color: #fff;
    max-width: 1200px;

    ${Breakpoints.XL} {
      padding-left: 20px;
      padding-right: 20px;
    }

    ${Breakpoints.SM} {
      padding-top: 20px;
      padding-right: ${isSingle ? '20px' : '5px'};
      padding-bottom: 40px;
    }
  `,

  hotelsContainer: css`
    display: flex;
  `,

  hotelsCarouselArrows: css`
    top: 139px;
  `,

  bookingModalWrapper: css`
    max-width: 1140px;
  `,

  trip: {
    container: css`
      display: flex;
      flex-direction: column;
    `,

    itineraries: css`
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      margin-bottom: 20vh;

      ${Breakpoints.MD} {
        margin-bottom: 160px;
      }
    `,

    wrapper: css`
      display: block;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

      ${Breakpoints.XL} {
        margin: 0;
        width: unset;
      }
    `,
  },

  flights: {
    wrapper: css`
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      margin-bottom: 10vh;

      ${Breakpoints.XL} {
        padding: 0 20px;
      }
    `,

    grid: css`
      display: grid;
    `,
  },

  floating: {
    wrapper: css`
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 100;
    `,

    container: css`
      box-shadow: 0px -4px 76px 0px rgba(52, 52, 52, 0.1);
      padding: 24px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${Colors.LIGHT_1};

      ${Breakpoints.MD} {
        border: 1px solid #e7e7e7;
        padding: 12px;
        border-radius: 8px;
      }
    `,

    row: css`
      max-width: 1224px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      ${Breakpoints.LG} {
        display: none;
      }
    `,

    feedback: {
      wrapper: css``,

      title: css`
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
        color: ${Colors.NEUTRAL_DARK};
        margin-bottom: 12px;

        ${Breakpoints.SM} {
          display: none;
        }
      `,
    },

    button: css`
      ${Breakpoints.MD} {
        width: 100%;
        max-width: 180px;
        font-size: 18px;
        padding: 11px 20px;
        border-radius: 4px;
      }
    `,
  },

  floatingMobile: {
    wrapper: css`
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 100;
      display: none;

      ${Breakpoints.LG} {
        display: block;
      }
    `,

    container: css`
      box-shadow: 0px -4px 76px 0px rgba(52, 52, 52, 0.1);
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 12px 12px 0px 0px;
      background-color: ${Colors.LIGHT_1};
    `,

    info: css`
      margin-bottom: 24px;
    `,

    item: {
      wrapper: css``,

      title: css`
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        color: ${Colors.NEUTRAL_GREY};
        margin-bottom: 5px;
      `,

      border: css`
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid ${Colors.LIGHT_GREY_2};
      `,

      row: css`
        display: flex;
        justify-content: space-between;
      `,

      edit: css`
        color: ${Colors.PRIMARY_GREEN};
        text-decoration: underline;
        cursor: pointer;
      `,
    },

    bottom: {
      wrapper: css``,

      container: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `,
    },

    viewMore: {
      wrapper: css`
        margin-top: 8px;
      `,

      button: css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.PRIMARY_GREEN};
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;

        svg {
          margin-left: 4px;
        }
      `,
    },
  },

  hotelInfo: {
    wrapper: css`
      display: flex;

      ${Breakpoints.LG} {
        display: none;
      }
    `,

    row: css`
      display: flex;
      align-items: center;
      gap: 16px;
    `,

    item: css`
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      border-right: 1px solid ${Colors.NEUTRAL_LIGHT};

      &:last-child {
        border-right: none;
      }
    `,

    title: css`
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      color: ${Colors.NEUTRAL_GREY};
      margin-bottom: 9px;
    `,

    type: css`
      font-size: 14px;
      color: ${Colors.NEUTRAL_BLACK};
      padding: 2px 4px;
      background-color: ${Colors.NEUTRAL_LIGHT};
      border-radius: 4px;
    `,

    value: css`
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.LG} {
        font-size: 16px;
        letter-spacing: 0.32px;
      }
    `,

    name: css`
      display: flex;
      flex-grow: 2.5;
      position: relative;
      p {
        display: block;
        width: 100%;
        max-width: 258px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `,

    nameHover: css`
      &:hover {
        span {
          opacity: 0.6;
        }
      }
    `,

    tooltip: css`
      z-index: 2;
      padding: 8px 12px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      transition: all 0.3s;
      opacity: 0;
      border-radius: 8px;
      position: absolute;
      color: ${Colors.LIGHT_1};
      top: -20px;
      left: 0;
      pointer-events: none;
      font-size: 14px;
    `,

    flightTooltip: css`
      display: flex;
      align-items: center;
      z-index: 2;
      padding: 8px 12px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      transition: all 0.3s;
      opacity: 0;
      border-radius: 8px;
      position: absolute;
      color: ${Colors.LIGHT_1};
      top: -20px;
      left: 0;
      pointer-events: none;
      font-size: 14px;
      min-width: max-content;
      svg {
        margin: 0 4px;
      }
    `,

    dates: css`
      display: flex;
      flex-grow: 2;
    `,

    price: css`
      display: flex;
      flex-grow: 1;
    `,

    flighs: css`
      display: flex;
      flex-grow: 1;
      position: relative;
    `,
  },

  toggle: {
    container: css`
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    `,

    checkbox: css`
      position: absolute;
      margin: 10px 0 0 120px;

      & + label {
        position: relative;
        cursor: pointer;

        font-size: 18px;
        font-weight: 500;
      }

      & + label:before {
        content: '';
        position: absolute;
        display: block;
        left: 115px;
        top: 0;
        width: 40px;
        height: 24px;
        border-radius: 16px;
        background: ${Colors.NEUTRAL_STONE};
        border: 1px solid #d9d9d9;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      & + label:after {
        content: '';
        position: absolute;
        display: block;
        left: 116px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 16px;
        background: #fff;
        border: 1px solid #d9d9d9;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      & + label:hover:after {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }

      &:checked + label:after {
        margin-left: 16px;
      }

      &:checked + label:before {
        background: ${Colors.PRIMARY_GREEN};
      }
    `,
  },
}
