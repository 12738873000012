import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const CalendarIcon: FC<Props> = ({
  width = 16,
  height = 17,
  color = '#333333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 3.16663H3.33333C2.59695 3.16663 2 3.76358 2 4.49996V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V4.49996C14 3.76358 13.403 3.16663 12.6667 3.16663Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 1.83337V4.50004"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 1.83337V4.50004"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.16663H14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
