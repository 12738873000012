import { sample } from 'effector'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { getLoggedInUserFx, loginProfileFx } from './'

sample({
  clock: getLoggedInUserFx.doneData,
  fn: ({ email, fullName, settings }) => ({
    id: email!,
    properties: {
      $name: fullName,
      $email: email,
      email: email,
      full_name: fullName,
      ...(settings?.avatarImageURI && {
        avatar_image_uri: settings.avatarImageURI,
      }),
    },
  }),
  target: analyticsModel.identify,
})

sample({
  clock: loginProfileFx.doneData,
  fn: () => ({
    name: eventNames.logIn,
  }),
  target: analyticsModel.track,
})

sample({
  clock: onboardingSessionModel.authBackClicked,
  fn: () => ({
    name: eventNames.quizAuthBackClicked,
  }),
  target: analyticsModel.track,
})

sample({
  clock: loginProfileFx.doneData,
  fn: () => ({
    name: eventNames.quizAuthCompleted,
    properties: {
      type: 'email',
    },
  }),
  target: analyticsModel.track,
})
