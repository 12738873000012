import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  button: {
    wrapper: css`
      background-color: ${Colors.LIGHT_1};
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 20px;
      box-shadow: 0px -4px 40px 0px rgba(43, 43, 43, 0.1);
      height: 58px;

      &:hover,
      &:focus {
        background-color: ${Colors.LIGHT_1};
      }
    `,

    icon: css`
      margin-right: 16px;
    `,
  },
}
