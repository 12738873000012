import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Dimensions } from '~/shared/styles'

const KEY_POINTS_GAP = 8
const KEY_POINTS_LAST_ELEMENT_WIDTH = 80
export const KEY_POINTS_COUNT_ELEMENT_CLASS = 'keypoints-count'

export const useMaxKeyPoints = () => {
  const [maxKeyPoints, setMaxKeyPoints] = useState<number | null>(null)

  const keyPointsWrapperEl = useRef<HTMLDivElement>(null)
  const keyPointsEl = useRef<HTMLDivElement>(null)

  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Lg}px)` })

  const maxRows = isDesktop ? 2 : 1

  const calculateMaxKeyPoints = () => {
    if (keyPointsEl.current && keyPointsWrapperEl.current && !maxKeyPoints) {
      const children = keyPointsEl.current.children
      const maxWidth =
        keyPointsWrapperEl.current.clientWidth * maxRows - KEY_POINTS_LAST_ELEMENT_WIDTH

      let accWidth = 0
      let maxChildrenCount = 0

      for (const child of children) {
        //ignore count element
        if (child.classList.contains(KEY_POINTS_COUNT_ELEMENT_CLASS)) {
          continue
        }

        //include empty space of row to accWidth
        if (
          accWidth < keyPointsWrapperEl.current.clientWidth &&
          accWidth + child.getBoundingClientRect().width >
            keyPointsWrapperEl.current.clientWidth
        ) {
          accWidth += keyPointsWrapperEl.current.clientWidth - accWidth - KEY_POINTS_GAP
        }

        accWidth += child.getBoundingClientRect().width + KEY_POINTS_GAP

        if (accWidth >= maxWidth) {
          break
        }

        maxChildrenCount += 1
      }

      setMaxKeyPoints(maxChildrenCount)
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      calculateMaxKeyPoints()
    })

    // Observe the first child only
    const firstChild = keyPointsEl.current?.children[0]
    if (firstChild) {
      resizeObserver.observe(firstChild)
    }

    // Initial calculation
    calculateMaxKeyPoints()

    return () => {
      // Disconnect observer
      resizeObserver.disconnect()
    }
  }, [keyPointsEl.current, keyPointsWrapperEl.current, maxRows])
  return { maxKeyPoints, keyPointsEl, keyPointsWrapperEl }
}
