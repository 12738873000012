import { CALENDAR_PREV_BUTTON_CLASS } from './config'

export const disablePrevButton = (prevButton: Element) => {
  prevButton?.setAttribute('disabled', 'true')
}

export const enablePrevButton = (prevButton: Element) => {
  prevButton?.removeAttribute('disabled')
}

export const getPrevButton = () => {
  return document.querySelector(CALENDAR_PREV_BUTTON_CLASS)
}

// disable calendar prev button
export const disablePrevButtonOnShownDateChange = (date: Date, minViewedDate: Date) => {
  const prevButton = getPrevButton()

  // disable calendar prev button
  if (date.getMonth() === minViewedDate.getMonth()) {
    disablePrevButton(prevButton!)
  } else {
    enablePrevButton(prevButton!)
  }
}
