import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
  `,

  icon: css`
    width: 20px;
    height: 20px;
    margin-right: 10px;
  `,

  container: css`
    display: grid;
    grid-row-gap: 8px;
  `,

  text: (faded?: boolean) => css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${faded ? Colors.NEUTRAL_GREY : Colors.NEUTRAL_BLACK};
  `,

  title: css`
    color: ${Colors.NEUTRAL_GREY};
  `,
}
