import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  root: css`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    height: 100%;
  `,
  track: css`
    width: 100%;
    height: 1px;
    background-color: ${Colors.LIGHT_GREY_2};
  `,
  thumb: css`
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${Colors.PRIMARY_GREEN};
  `,
}
