import { SerializedStyles } from '@emotion/react'
import { forwardRef, ReactNode } from 'react'
import Select, { GroupBase } from 'react-select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { default as SelectType } from 'react-select/dist/declarations/src/Select'
import { Colors } from '~/shared/styles'
import { DropdownIndicator, Label } from '../../atoms'
import { styles } from './styles'

type Value = string | number | boolean

type OptionType = {
  value: Value
  label: string | ReactNode
  icon?: ReactNode | string
}

export type FilterOption<T> =
  | ((
      option: FilterOptionOption<OptionType | OptionType[] | T | undefined>,
      inputValue: string,
    ) => boolean)
  | null

interface Props {
  options: OptionType[]
  value?: Value
  values?: Value[]
  validationError?: boolean

  onChange?: (option: any) => void
  filterOption?: FilterOption<any>
  dropdownIndicator?: boolean
  dropdownIndicatorAbove?: boolean
  defaultDisplayOption?: OptionType
  disabled?: boolean
  label?: string | ReactNode
  required?: boolean
  tabIndex?: number
  isMulti?: boolean
  placeholder?: ReactNode
  isSearchable?: boolean
  extendStyles?: {
    container?: SerializedStyles
    label?: SerializedStyles
    control?: SerializedStyles
    menu?: SerializedStyles
    option?: SerializedStyles
    optionIcon?: SerializedStyles
    singleValue?: SerializedStyles
    color?: string
    fontSize?: number
    fontWeight?: number
  }
  contentEditable?: boolean
  components?: Partial<
    SelectComponents<
      OptionType | OptionType[] | undefined,
      boolean,
      GroupBase<OptionType>
    >
  >
}

export type SelectRef = SelectType<
  OptionType | OptionType[] | undefined,
  boolean,
  GroupBase<OptionType>
>

export const SelectInput = forwardRef<SelectRef, Props>(
  (
    {
      options,
      onChange = (v: any) => null,
      validationError,
      value,
      values,
      filterOption,
      defaultDisplayOption,
      dropdownIndicator = true,
      dropdownIndicatorAbove = false,
      disabled,
      label,
      required,
      isMulti,
      tabIndex,
      extendStyles,
      isSearchable = true,
      contentEditable,
      components,
      placeholder = 'Select',
    },
    ref,
  ) => {
    const showHeader = label || dropdownIndicator

    return (
      <div css={extendStyles?.container}>
        {showHeader && (
          <div css={styles.header.container}>
            {label && <Label css={extendStyles?.label}>{label}</Label>}

            {dropdownIndicatorAbove && <DropdownIndicator />}
          </div>
        )}

        <div contentEditable={contentEditable}>
          <Select
            ref={ref}
            styles={{
              menuList: (provided) => ({
                ...provided,
                ...(extendStyles?.menu || {}),
              }),
              control: (provided) => ({
                ...provided,
                ...(extendStyles?.control || {}),
              }),
              singleValue: (provided, { isDisabled }) => ({
                ...provided,
                color: extendStyles?.color || Colors.NEUTRAL_BLACK,
                fontSize: extendStyles?.fontSize || 16,
                fontWeight: extendStyles?.fontWeight || 400,
                opacity: isDisabled ? 0.5 : 1,
                ...(extendStyles?.singleValue || {}),
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            options={options}
            value={
              value
                ? options.find((option) => option.value === value) || defaultDisplayOption
                : values
                ? values.map((value) => options.find((option) => option.value === value))
                : null
            }
            filterOption={filterOption}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            components={{
              DropdownIndicator: () => {
                if (dropdownIndicatorAbove) {
                  return null
                }

                return <DropdownIndicator />
              },
              IndicatorSeparator: () => null,
              Control: ({ innerRef, innerProps, children, getValue, menuIsOpen }) => {
                const options = getValue() as OptionType[] | undefined
                const icon = options?.[0]?.icon

                return (
                  <div
                    ref={innerRef}
                    {...innerProps}
                    css={[
                      styles.control(menuIsOpen, validationError),
                      extendStyles?.control,
                    ]}
                  >
                    {!!icon && <span css={styles.icon}>{icon}</span>}

                    {children}
                  </div>
                )
              },
              Option: ({ innerRef, innerProps, label, getValue }) => {
                const options = getValue() as OptionType[] | undefined
                const icon = options?.[0]?.icon

                return (
                  <div
                    ref={innerRef}
                    {...innerProps}
                    css={[styles.option.container, extendStyles?.option]}
                  >
                    {!!icon && (
                      <span css={[styles.icon, extendStyles?.optionIcon]}>{icon}</span>
                    )}

                    {label}
                  </div>
                )
              },
              ...components,
            }}
            isMulti={isMulti}
            isDisabled={disabled}
            tabIndex={tabIndex}
            isSearchable={isSearchable}
          />
        </div>
      </div>
    )
  },
)
