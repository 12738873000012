import { FC, useState, useEffect, useCallback } from 'react'

import { ClockIcon } from '~/shared/assets/icons/components'
import { formatSecondsToMinutes } from '~/shared/lib/number'

import { styles } from './styles'

interface Props {
  expiresAt: string
  onEndTime?: () => void
}

export const BookingTimer: FC<Props> = ({ expiresAt, onEndTime }) => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0)

  const calculateRemainingTime = useCallback(() => {
    const expiresAtDate = new Date(expiresAt)
    const now = new Date()
    const timeDiff = expiresAtDate.getTime() - now.getTime()

    if (timeDiff <= 0) {
      setRemainingSeconds(0)
      onEndTime && onEndTime()
    }

    setRemainingSeconds(Math.floor(timeDiff / 1000))
  }, [expiresAt, onEndTime])

  useEffect(() => {
    calculateRemainingTime()

    const interval = setInterval(calculateRemainingTime, 1000)

    return () => clearInterval(interval)
  }, [calculateRemainingTime])

  const time = formatSecondsToMinutes(remainingSeconds)

  return (
    <div css={styles.wrapper}>
      <ClockIcon />

      <span css={styles.text}>Remaining time: {time}</span>
    </div>
  )
}
