import { SVGProps } from 'react'
import { icons } from './const'
import { IconName } from './types'

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName | any
  width?: number
  height?: number
  color?: string
  strokeWidth?: string | number
  onClick?: (e: any) => void
}

export const IconComponent = ({ name, ...params }: IconProps): JSX.Element | null => {
  const Icon = icons[name as IconName]
  return <Icon {...params} />
}
