import Hotjar from '@hotjar/browser'
import { Analytics } from './types'

const init = (hotjarId: string) => {
  try {
    Hotjar.init(Number(hotjarId), 6)
  } catch (e) {
    console.log('Hotjar initialization error: ', e)
  }
}

export const hotjarAnalytics: Analytics = {
  name: 'hotjar',
  init,
}
