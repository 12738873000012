import { useStore } from 'effector-react'
import { crossIcon } from '~/shared/assets/icons'
import {
  BorderedCircleCheckIcon,
  CrossIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '~/shared/assets/icons/components'
import { Button } from '~/shared/ui'
import {
  $isFeedbackFormSubmmited,
  $selectedTripFeedback,
  closeFeedbackModal,
  feedbackTextChanged,
  tripDislikeProcessInitiated,
  tripDisliked,
  tripLiked,
} from '../model'
import { styles } from './styles'

export const TripFeedback = () => {
  const selectedTripFeedback = useStore($selectedTripFeedback)

  return (
    <div css={styles.feedback.wrapper}>
      <div css={styles.feedback.container}>
        <button onClick={() => tripLiked()} css={styles.feedback.button}>
          <ThumbsUpIcon color={selectedTripFeedback === 1 ? '#009F9D' : '#555555'} />
        </button>
        <button
          onClick={() => tripDislikeProcessInitiated()}
          css={styles.feedback.button}
        >
          <ThumbsDownIcon color={selectedTripFeedback === 0 ? '#009F9D' : '#555555'} />
        </button>
      </div>
    </div>
  )
}

export const TripFeedbackModal = () => {
  const isFormSubmitted = useStore($isFeedbackFormSubmmited)

  return (
    <div css={styles.modal.wrapper}>
      <div onClick={() => closeFeedbackModal()} css={styles.modal.closeBtn}>
        <CrossIcon />
      </div>
      {!isFormSubmitted ? (
        <div css={styles.modal.container}>
          <h3 css={styles.modal.title}>Can you tell us more?</h3>
          <p css={styles.modal.text}>This will help us to improve our recommendations</p>
          <textarea
            onChange={(e) => feedbackTextChanged(e.target.value)}
            css={styles.modal.textarea}
          />
          <Button onClick={() => tripDisliked()} css={styles.modal.button}>
            Submit
          </Button>
        </div>
      ) : (
        <div css={styles.modal.container}>
          <BorderedCircleCheckIcon />
          <h3 css={styles.modal.submittedTitle}>Thank you!</h3>
          <p css={styles.modal.submittedText}>Your feedback is submitted</p>
        </div>
      )}
    </div>
  )
}
