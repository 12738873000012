import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { styles } from './styles'

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  extendStyles?: SerializedStyles
}

export const Link: FC<Props> = ({ children, to, extendStyles, ...rest }) => {
  return (
    <RouterLink to={to} css={[styles.base, extendStyles]} {...rest}>
      {children}
    </RouterLink>
  )
}
