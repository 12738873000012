import { css } from '@emotion/react'
import { mapPinIcon } from '~/shared/assets/icons/primary'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  label: (isDisabled?: boolean) => css`
    letter-spacing: 0.01em;
    color: ${isDisabled ? Colors.LIGHT_GREY_1 : Colors.NEUTRAL_GREY};
    font-size: 20px;
  `,

  container: (isDisabled: boolean) => css`
    display: flex;
    width: 100%;
    height: 70px;
    margin-top: 15px;
    border: 1px solid ${isDisabled ? Colors.LIGHT_GREY_1 : Colors.PRIMARY_GREEN};
  `,

  icon: (isDisabled: boolean) => css`
    width: 75px;
    height: 100%;
    border-right: 1px solid ${isDisabled ? Colors.LIGHT_GREY_1 : Colors.PRIMARY_GREEN};
    background-color: ${Colors.ACCENT_4};
    background-image: url(${mapPinIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px 22px;
    filter: ${isDisabled ? 'grayscale(100%)' : undefined};
    opacity: ${isDisabled ? '0.6' : undefined};
  `,

  input: {
    wrapper: css`
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 18px;
    `,

    container: css`
      width: 100%;
    `,

    field: css`
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      color: ${Colors.NEUTRAL_BLACK};
      border: 0;
      outline: none;
      height: 100%;
      ${Breakpoints.SM} {
        font-size: 18px;
      }
      &:disabled {
        background-color: transparent;
        &::placeholder {
          color: ${Colors.LIGHT_GREY_1};
        }
      }
    `,
  },

  dropdown: {
    wrapper: css`
      position: relative;
      width: 100%;
    `,

    container: css`
      position: absolute;
      z-index: 101;
      width: 100%;
      padding: 10px 0;
      border: 1px solid ${Colors.PRIMARY_GREEN};
      border-top: none;
      background-color: ${Colors.LIGHT_1};
      & [data-reach-combobox-option][data-highlighted] {
        background-color: ${Colors.LIGHT_GREY_2};
      }
    `,

    loading: css`
      padding: 10px 30px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    item: css`
      padding: 10px 30px;
      cursor: pointer;
      color: ${Colors.NEUTRAL_BLACK};
      background-color: ${Colors.LIGHT_1};
      &:hover {
        background-color: ${Colors.LIGHT_GREY_2};
      }
    `,

    active: css`
      background-color: ${Colors.LIGHT_GREY_2};
    `,
  },
}
