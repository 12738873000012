import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  uploadContainer: (isHoverable?: boolean) => css`
    padding: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border: 1px solid ${Colors.LIGHT_GREY_4};
    border-radius: 10px;
    min-height: 116px;
    &:hover {
      cursor: ${isHoverable ? 'pointer' : 'default'};
    }
  `,

  filesContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    text-align: center;
  `,
  fileWrapper: css``,
  textContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  text: {
    main: css`
      font-family: ${Fonts.Calibre};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${Colors.NEUTRAL_DARK};
      text-align: center;
    `,
    accent: css`
      font-weight: 500;
      color: ${Colors.PRIMARY_GREEN};
    `,
  },
  uploadIconWrapper: css`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f2f4f7;
    border: 6px solid #f9fafb;
    border-radius: 28px;
  `,

  uploadIcon: css``,

  deleteIcon: css`
    padding: 2px;
    width: 14px;
    height: 14px;
    border: 1px solid ${Colors.DARK_1};
    border-radius: 100%;
    margin-bottom: 2px;
    &:hover {
      cursor: pointer;
    }
  `,
}
