import { apiServiceNew, errorHandler } from '~/shared/api'
import { SearchHotelsResponse, SearchHotels } from '../..'

export const getRooms = async ({
  codes,
  stay,
}: SearchHotels): Promise<SearchHotelsResponse> => {
  try {
    return await apiServiceNew.appClient.hotels.searchHotelsByCodes({
      codes,
      stay,
    })
  } catch (res) {
    return errorHandler(res)
  }
}
