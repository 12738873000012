const iOSDevices = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
]

const appleDevices = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']

export const isiOS =
  iOSDevices.includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

export const isAppleDevices =
  iOSDevices.includes(navigator.platform) || appleDevices.includes(navigator.platform)

export const isSafari =
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
  !navigator.userAgent.match(/CriOS/i)
