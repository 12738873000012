import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const FacilitiesIcon: FC<Props> = ({
  width = 16,
  height = 17,
  color = '#374151',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6673 7.88668V8.50001C14.6665 9.93763 14.201 11.3365 13.3402 12.4879C12.4794 13.6393 11.2695 14.4817 9.89089 14.8893C8.51227 15.2969 7.03882 15.2479 5.6903 14.7497C4.34177 14.2515 3.19042 13.3307 2.40796 12.1247C1.6255 10.9187 1.25385 9.49205 1.34844 8.05755C1.44303 6.62306 1.99879 5.25756 2.93284 4.16473C3.86689 3.07189 5.12917 2.31027 6.53144 1.99344C7.93371 1.67662 9.40083 1.82157 10.714 2.40668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 3.16663L8 9.83996L6 7.83996"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
