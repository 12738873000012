import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const Dates: FC<Props> = ({ width = 16, height = 16, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M8.0315 7.54C7.4435 7.54 6.9995 7.84 6.7235 8.176H5.9795V4.132H10.0115V4.972H6.7715V7.192C7.0235 6.964 7.5155 6.724 8.1155 6.724C9.4715 6.724 10.4315 7.756 10.4315 9.172C10.4315 10.564 9.4355 11.608 8.0435 11.608C6.6395 11.608 5.6795 10.768 5.5115 9.4H6.4355C6.5915 10.264 7.1195 10.792 8.0435 10.792C8.9315 10.792 9.5435 10.096 9.5435 9.172C9.5435 8.248 8.9315 7.54 8.0315 7.54Z"
        fill="white"
      />
    </svg>
  )
}
