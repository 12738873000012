import { trackPageVisibility } from '@withease/web-api'
import { sample, createEffect, merge } from 'effector'
import { interval, spread } from 'patronum'

import { logoutModel } from '~/features/user/logout'
import { viewerModel } from '~/entities/viewer'

import { apiService, errorHandler, updateApiServiceToken } from '~/shared/api'
import { appStarted, appStopped } from '~/shared/config/init'

const ACCESS_TOKEN_LIFE_MS = 900000
const ESTIMATED_TOKEN_REFRESHING_MS = 10000
const REFRESH_TOKEN_INTERVAL_MS = ACCESS_TOKEN_LIFE_MS - ESTIMATED_TOKEN_REFRESHING_MS

const { visible } = trackPageVisibility({
  setup: appStarted,
})

const refreshIntervalStarted = merge([appStarted, visible])

const { tick: tokenUpdateInitiated } = interval({
  timeout: REFRESH_TOKEN_INTERVAL_MS,
  start: refreshIntervalStarted,
  stop: appStopped,
})

export const refreshTokenFx = createEffect(async (refreshToken: string) => {
  try {
    return await apiService().refreshToken({
      fields: { refreshToken },
    })
  } catch (res: any) {
    return errorHandler(res, 'Failed to refresh token')
  }
})

export const updateApiServiceTokenFx = createEffect((token: string) => {
  try {
    updateApiServiceToken(token)
  } catch (error) {
    throw Error('Failed to pass token')
  }
})

sample({
  clock: [refreshIntervalStarted, tokenUpdateInitiated],
  source: viewerModel.$refreshToken,
  filter: Boolean,
  target: refreshTokenFx,
})

spread({
  source: refreshTokenFx.doneData,
  targets: {
    accessToken: viewerModel.accessTokenUpdated,
    refreshToken: viewerModel.refreshTokenUpdated,
  },
})

sample({
  clock: refreshTokenFx.fail,
  target: logoutModel.logOutInitialized,
})

sample({
  clock: [appStarted, viewerModel.accessTokenUpdated],
  source: viewerModel.$accessToken,
  target: updateApiServiceTokenFx,
})
