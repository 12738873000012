import { sample } from 'effector'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { tripRated } from './'

sample({
  clock: tripRated,
  fn: (fields) => ({
    name: eventNames.ratedRecommendedTrip,
    properties: fields,
  }),
  target: analyticsModel.track,
})

sample({
  clock: tripRated,
  fn: (fields) => ({
    name: eventNames.tripRated,
    properties: {
      rating: fields.rate === 0 ? 'down' : 'up',
    },
  }),
  target: analyticsModel.track,
})
