import { DEFAULT_ADULT, DEFAULT_CHILD } from './config'

export enum GUEST_TYPE {
  ADULT = 'AD',
  CHILD = 'CH',
}

export interface Room {
  adults: (typeof DEFAULT_ADULT)[]
  children: (typeof DEFAULT_CHILD)[]
}

export interface GuestsOption {
  value: number
  label: string
}
