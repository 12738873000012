import { DefaultApi, Configuration } from '@softcery/awayaway-apiclient'
import { AppClient } from '@softcery/awayaway-nodejs-api-client'

const config = {
  basePath: `${process.env.AA_API_URL}/api/v1`,
  baseNodeApiPath: `${process.env.AA_NODE_API_URL}`,
}

let token: string | undefined
export const updateApiServiceToken = (newToken: string) => {
  token = newToken
  const apiService = ApiService.getInstance()
  apiService.updateToken(token)
  apiServiceNew.updateToken(token)
}

class ApiService {
  private static instance: ApiService | null = null
  public appClient: AppClient

  private constructor() {
    this.appClient = new AppClient({
      BASE: config.baseNodeApiPath,
      TOKEN: token,
    })
  }

  public static getInstance(): ApiService {
    if (this.instance === null) {
      this.instance = new ApiService()
    }
    return this.instance
  }

  public updateToken(token: string) {
    this.appClient = new AppClient({
      BASE: config.baseNodeApiPath,
      TOKEN: token,
    })
  }

  public async requestWithoutToken<T>(
    requestFunc: (client: AppClient) => Promise<T>,
  ): Promise<T> {
    const clientWithoutToken = new AppClient({
      BASE: config.baseNodeApiPath,
      TOKEN: undefined,
    })
    return await requestFunc(clientWithoutToken)
  }
}

const apiServiceNew = ApiService.getInstance()

const apiService = () => {
  return new DefaultApi(
    new Configuration({
      ...config,
      ...(token && {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),
  )
}

export { apiService, config, apiServiceNew }
