import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ArrowRightIcon: FC<Props> = ({
  width = 17,
  height = 16,
  color = '#009F9D',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M3.8335 8H13.1668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3.33337L13.1667 8.00004L8.5 12.6667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
