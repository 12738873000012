import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const Destination: FC<Props> = ({ width = 16, height = 16, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M9.07339 4.12V11.5H8.14939V5.26L6.32539 6.196V5.236L8.50939 4.12H9.07339Z"
        fill="white"
      />
    </svg>
  )
}
