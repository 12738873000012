import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const appearance = {
  rules: {
    '.Input': {
      border: '1px solid #D5D4DC',
      borderRadius: '0px',
      padding: '12px',
      lineHeight: '22px',
      marginBottom: '4px',
      outline: 'none',
      boxShadow: 'none',

      fontFamily: Fonts.Calibre,
      color: Colors.DARK_1,
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.2px',
    },

    '.Input::placeholder': {
      fontFamily: Fonts.Calibre,
      color: Colors.NEUTRAL_GREY,
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.2px',
    },

    '.Label': {
      fontFamily: Fonts.Calibre,
      color: Colors.NEUTRAL_DARK,
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.4px',
      marginBottom: '8px',
    },

    '.Input:focus': {
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #D5D4DC',
    },
  },
}

export const styles = {
  wrapper: css`
    max-width: 460px;

    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    border-radius: 10px;

    ${Breakpoints.MD} {
      max-width: 100%;
    }
  `,

  inputs: {
    container: css`
      display: flex;
      flex-direction: column;
      gap: 15px;
    `,
  },
}
