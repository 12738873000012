import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ErrorCircleIcon: FC<Props> = ({
  width = 20,
  height = 20,
  color = '#F10000',
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width={width} height={height} rx="10" fill={color} />
      <path
        d="M13 7.5L7 13.5"
        stroke="#FDFDFD"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.5L13 13.5"
        stroke="#FDFDFD"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
