import { STEPS_ORDER } from './config'

export const getNavbarData = (activeStep: number, lastCompletedStep: number) => {
  const stepsData = Object.entries(STEPS_ORDER).map(([name, order]) => ({
    name,
    order,
    isActive: order === activeStep,
    isDisabled: order > lastCompletedStep,
    isStepCompleted: order <= lastCompletedStep,
  }))
  return stepsData
}
