import { sample } from 'effector'
import { createGate } from 'effector-react'
import { searchBookingModel } from '~/entities/booking'
import { atom } from '~/shared/lib/factory'

export const bookingDetailsPageModel = atom(() => {
  const BookingDetailsGate = createGate<number>()

  sample({
    clock: BookingDetailsGate.open,
    target: searchBookingModel.$$getBookingFactory.initiatedGetBooking,
  })
  return {
    BookingDetailsGate,
  }
})
