import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    min-height: 200px;
    padding: 40px;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};

    &:not(:last-child)::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: ${Colors.NEUTRAL_SILVER};
    }
  `,

  title: css`
    font-family: ${Fonts.Calibre};
    font-weight: 500;
    font-size: 20px;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  states: {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    `,

    loader: css`
      fill: ${Colors.NEUTRAL_BLACK};
    `,
  },
}
