import { DocumentType, GuestGender, GuestTitle } from './types'

export const guestTitleOptions = [
  { label: 'Mr', value: GuestTitle.Mr },
  { label: 'Mrs', value: GuestTitle.Mrs },
  { label: 'Ms', value: GuestTitle.Ms },
]

export const guestGenderOptions = [
  { label: 'Male', value: GuestGender.Male },
  { label: 'Female', value: GuestGender.Female },
]

export const documentTypeOptions = [
  { label: 'Passport', value: DocumentType.Passport },
  { label: 'Tax ID', value: DocumentType.TaxID },
  { label: 'Known Traveler Number', value: DocumentType.KnownTravelerNumber },
  { label: 'Redress Number', value: DocumentType.RedressNumber },
]

export const documentTypeConfigByDocumentType = {
  [DocumentType.Passport]: {
    label: 'Passport/ID number',
    placeholder: '123456789',
  },
  [DocumentType.TaxID]: {
    label: 'Tax ID',
    placeholder: 'AB123456C',
  },
  [DocumentType.KnownTravelerNumber]: {
    label: 'Known Traveler Number',
    placeholder: '123456789',
  },
  [DocumentType.RedressNumber]: {
    label: 'Redress Number',
    placeholder: '87654321',
  },
}
