import { BookedTripsResponse } from '~/entities/booking'
import { apiService, errorHandler } from '~/shared/api'

export const getBookings = async (): Promise<BookedTripsResponse> => {
  try {
    return await apiService().listTripBookings()
  } catch (e) {
    return errorHandler(e)
  }
}
