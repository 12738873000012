import { createEvent, sample } from 'effector'
import { condition } from 'patronum'

import { availabilityPageModel } from '~/pages/onboarding/availability'
import { budgetPageModel } from '~/pages/onboarding/budget'
import { guestsPageModel } from '~/pages/onboarding/guests'
import { holidaysPageModel } from '~/pages/onboarding/holidays'
import { locationsPageModel } from '~/pages/onboarding/locations'
import { summaryPageModel } from '~/pages/onboarding/summary'
import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { selectBudgetModel } from '~/features/onboarding/select-budget'
import { selectImagesModel } from '~/features/onboarding/select-images'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { logoutModel } from '~/features/user/logout'
import { cancelBookingModel } from '~/entities/booking'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { navigationModel } from '~/entities/navigation'
import {
  getOnboardingStepUrlPath,
  onboardingSessionModel,
} from '~/entities/onboarding-session'
import {
  $onboardingFinished,
  newRecommendationsInitiated,
} from '~/entities/onboarding-session/model'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { AppRoute } from '~/shared/config'
import { ONBOARDING_STEPS_NUMBER } from './config'

export const stepNavigated = createEvent<number>()

export const previousStepNavigated = createEvent()
export const startNavigated = createEvent()
export const homeNavigated = createEvent()
export const backToOnboardingNavigated = createEvent<string>()

sample({
  clock: [stepNavigated, onboardingLayoutModel.stepNavigated],
  fn: (step) => getOnboardingStepUrlPath(step),
  target: navigationModel.pathChanged,
})

condition({
  source: sample({
    clock: [
      locationsPageModel.nextStepNavigated,
      holidaysPageModel.nextStepNavigated,
      availabilityPageModel.nextStepNavigated,
      budgetPageModel.nextStepNavigated,
      guestsPageModel.nextStepNavigated,
      summaryPageModel.nextStepNavigated,
    ],
    source: onboardingSessionModel.$step,
  }),
  if: (step) => step === ONBOARDING_STEPS_NUMBER,
  then: newRecommendationsInitiated,
  else: stepNavigated.prepend((step: number) => step + 1),
})

sample({
  clock: [
    locationsPageModel.nextStepNavigated,
    holidaysPageModel.nextStepNavigated,
    availabilityPageModel.nextStepNavigated,
    budgetPageModel.nextStepNavigated,
    guestsPageModel.nextStepNavigated,
    summaryPageModel.nextStepNavigated,
  ],

  target: onboardingLayoutModel.stepCompleted,
})

//External navigation
sample({
  clock: onboardingSessionModel.onboardingFinished,
  target: navigationModel.pathChanged.prepend(() => AppRoute.Destinations),
})

sample({
  clock: onboardingLayoutModel.startNavigated,
  target: startNavigated,
})

condition({
  source: startNavigated,
  if: viewerModel.$isAuthorized,
  then: stepNavigated.prepend(() => 1),
  else: homeNavigated,
})

sample({
  clock: homeNavigated,
  target: navigationModel.pathChanged.prepend(() => AppRoute.Home),
})

sample({
  clock: [
    onboardingLayoutModel.previousStepNavigated,
    holidaysPageModel.previousStepNavigated,
  ],
  source: onboardingSessionModel.$step,
  fn: (step) => step - 1 || 1,
  target: stepNavigated,
})

//Reset states
//Reset onboardingFinished state if user navigates to onboarding page
sample({
  clock: backToOnboardingNavigated,
  source: $onboardingFinished,
  filter: (onboardingFinished, pathname) =>
    onboardingFinished && (pathname === AppRoute.Summary || pathname === AppRoute.Dates),
  target: onboardingSessionModel.statusReset,
})

sample({
  clock: onboardingSessionModel.statusReset,
  source: onboardingSessionModel.$step,
  target: [stepNavigated, destinationModel.getTripsStatusReset],
})

sample({
  clock: [
    logoutModel.logOutInitialized,
    startNavigated,
    onboardingSessionModel.reOnboardingInitiated,
  ],
  target: [
    onboardingSessionModel.stateReset,
    destinationModel.stateReset,
    selectImagesModel.stateReset,
    selectLocationsModel.stateReset,
    locationsPageModel.stateReset,
    tripInfoModel.stateReset,
    selectBudgetModel.budgetReset,
    listImageRecommendationsModel.stateReset,
    summaryPageModel.stateReset,
    onboardingLayoutModel.stateReset,
    holidaysPageModel.stateReset,
    hotelRoomModel.$$getRoomsFactory.stateReset,
  ],
})

sample({
  clock: destinationModel.$selectedDestination,
  target: cancelBookingModel.resetState,
})
