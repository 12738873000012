import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 1100px;

    ${Breakpoints.XL} {
      padding: 0 24px;
    }
  `,
  wrapper: css`
    background-color: #fafafa;
    min-height: 100vh;
  `,
  topBar: css`
    margin-top: 100px;
    background-color: #ffffff;
    width: 100%;
  `,
  content: css`
    padding-block: 50px;
  `,
}
