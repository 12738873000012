import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  extendStyles?: SerializedStyles
  wrap?: boolean
}

export const Row: FC<Props> = ({ children, extendStyles, wrap }) => {
  return <div css={[styles.row(!!wrap), extendStyles]}>{children}</div>
}
