import valid from 'card-validator'
export const getCardVendorCode = (cardNumber: string): 'VI' | 'CA' | 'AE' | null => {
  const numberValidation = valid.number(cardNumber)
  switch (numberValidation.card?.type) {
    case 'visa':
      return 'VI'
    case 'mastercard':
      return 'CA'
    case 'american-express':
      return 'AE'
    default:
      return null
  }
}
