import { TripIdea } from '@softcery/awayaway-apiclient'
import {
  TripIdeaResponseDto,
  TripIdeaHotelResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { motion } from 'framer-motion'
import { Fragment, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { FreeMode, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MapPinIcon,
} from '~/shared/assets/icons/components'
import { changeImageResolution } from '~/shared/lib/image'
import { Dimensions } from '~/shared/styles'
import { Title } from '~/shared/ui'
import { hotelBedsUrlProvider } from '~/shared/ui/atoms/image/hotelbeds-provider'
import { ImageUrlProvider } from '~/shared/ui/atoms/image/provider'
import { keyPointsIcons } from '../../lib'
import { KeyPoint } from '../trip/key-point'
import { styles } from './styles'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import './styles.css'

interface Props {
  destination?: TripIdeaResponseDto
  onReturnClick: () => void
  imageUrlProvider?: ImageUrlProvider
}

export const DestinationDetails = ({
  destination,
  onReturnClick,
  imageUrlProvider = hotelBedsUrlProvider,
}: Props) => {
  const keyPoints = Object.entries(destination?.keypoints || {}) || []
  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Lg}px)` })
  const [images, setImages] = useState<string[]>(destination!.images || [])
  const [containerKey, setContainerKey] = useState<number>(0)

  const nextClickHandler = () => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages]
      const image = updatedImages.shift()
      updatedImages.push(image!)
      return updatedImages
    })

    setContainerKey((prevKey) => prevKey + 1)
  }

  const prevClickHandler = () => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages]
      const image = updatedImages.pop()
      updatedImages.unshift(image!)
      return updatedImages
    })

    setContainerKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    // No need to manually trigger a re-render, setting state will do that
  }, [images, containerKey])

  return (
    <Fragment>
      <div css={styles.preview.container}>
        {isDesktop ? (
          <motion.div
            key={containerKey}
            css={styles.images.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.6 } }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <div
              key={images[0]}
              css={styles.images.firstImage(changeImageResolution(images[0], 1920, 1080))}
            />
            <div
              key={images[1]}
              css={styles.images.secondImage(
                changeImageResolution(images[1], 1920, 1080),
              )}
            />
            <div
              key={images[2]}
              css={styles.images.thirdImage(changeImageResolution(images[2], 1920, 1080))}
            />
            <div
              key={images[3]}
              css={styles.images.fourthImage(
                changeImageResolution(images[3], 1920, 1080),
              )}
            />

            <div css={styles.images.navigation}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  prevClickHandler()
                }}
                css={styles.images.button}
              >
                <ChevronLeftIcon />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  nextClickHandler()
                }}
                css={styles.images.button}
              >
                <ChevronRightIcon />
              </button>
            </div>
          </motion.div>
        ) : (
          <Swiper
            loop={true}
            spaceBetween={10}
            modules={[FreeMode, Pagination]}
            className="trip-details-slider"
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
          >
            {images!.map((url, i) => (
              <SwiperSlide key={url + i}>
                <img src={changeImageResolution(url, 1920, 1080)} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {!isDesktop && !!destination?.countryName && (
          <div css={styles.destination.country}>
            <MapPinIcon />
            {destination?.countryName}
          </div>
        )}
      </div>

      <div css={styles.destination.wrapper}>
        <div css={styles.destination.container}>
          <div css={styles.preview.crumbs}>
            <span onClick={() => onReturnClick()}>Results</span> /{' '}
            <span>{destination?.name}</span>
          </div>
          <Title css={styles.destination.name}>{destination?.name}</Title>
          <div css={styles.destination.inner}>
            <div css={styles.destination.colLeft}>
              {isDesktop && !!destination?.countryName && (
                <div css={styles.destination.country}>
                  <MapPinIcon />
                  {destination?.countryName}
                </div>
              )}
              {!isDesktop && (
                <div css={styles.keyPoints.wrapper}>
                  <div css={styles.keyPoints.container}>
                    {keyPoints.map(([key, value], i) => (
                      <KeyPoint
                        icon={keyPointsIcons[key as keyof typeof keyPointsIcons]}
                        key={i}
                      >
                        {value}
                      </KeyPoint>
                    ))}
                  </div>
                </div>
              )}
              <p css={styles.destination.description}>{destination?.description}</p>
            </div>

            {isDesktop && !!keyPoints.length && (
              <div css={styles.destination.colRight}>
                <p css={styles.keyPoints.title}>Inside this trip</p>
                <div css={styles.keyPoints.wrapper}>
                  <div css={styles.keyPoints.container}>
                    {keyPoints.map(([key, value], i) => (
                      <KeyPoint
                        icon={keyPointsIcons[key as keyof typeof keyPointsIcons]}
                        key={i}
                      >
                        {value}
                      </KeyPoint>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
