import {
  GenerateItineraryDto,
  RecommendTripsRequestDto,
  RegenerateItineraryDto,
  SaveRecommendedTripsRequestDto,
  TripIdeaResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import { pending, reset, spread } from 'patronum'
import { apiServiceNew, errorHandler, getErrorMessage } from '~/shared/api'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { LocalStorageKeys } from '~/shared/config/localstorage'
import { createAbortableEffect } from '~/shared/lib/abort-effect'
import { formatToDate } from '~/shared/lib/date'
import { bridge } from '~/shared/lib/factory'
import { Itinerary } from './types'
// todo todo
export const selectedChanged = createEvent<TripIdeaResponseDto | null>()
export const stateReset = createEvent()
export const resetItineraryFromAllItineraries = createEvent<string>()

export const selectedDestinationReset = createEvent()
export const getTripsStatusReset = createEvent()

export const getUserSavedTripsFx = createEffect(async () => {
  try {
    const res = await apiServiceNew.appClient.savedRecommendations.getSavedTrips()

    return {
      tripId: res.data?.id,
      trips: res.data?.ideas || [],
      summary: res.data?.summary || '',
    }
  } catch (res: any) {
    return errorHandler(res, 'Failed to get user saved trips')
  }
})

export const saveUserTripsFx = createEffect(
  async (fields: SaveRecommendedTripsRequestDto) => {
    try {
      const res = await apiServiceNew.appClient.savedRecommendations.saveTrips(fields)

      return res.data
    } catch (res: any) {
      return errorHandler(res, 'Failed to save user trips')
    }
  },
)

// NodeApi version
export const [getTripsFx, getTripsAborted] = createAbortableEffect((abortSignal) =>
  createEffect(async (fields: RecommendTripsRequestDto) => {
    try {
      const promiseRes = apiServiceNew.appClient.recommender.recommendTrips(fields)
      abortSignal.onabort = () => {
        if (abortSignal.aborted) {
          if (!promiseRes.isCancelled) {
            promiseRes.cancel()
          }
        }
      }
      const res = await promiseRes
      return {
        tripId: res.data?.id,
        trips: res.data?.ideas || [],
        summary: res.data?.summary || '',
      }
    } catch (res: any) {
      return errorHandler(res, res.body.code)
    }
  }),
)

export const getTripItinerariesFx = createEffect(
  async ({
    // destination code using for caching itinerary
    destinationCode,
    fields,
  }: {
    destinationCode: string
    fields: GenerateItineraryDto
  }) => {
    try {
      const res = await apiServiceNew.appClient.itinerary.generateItinerary(fields)
      return res
    } catch (res: any) {
      return errorHandler(res, 'Failed to get trip itinerary')
    }
  },
)

export const regenerateTripItinerariesFx = createEffect(
  async ({
    // destination code using for caching itinerary
    destinationCode,
    fields,
  }: {
    destinationCode: string
    fields: RegenerateItineraryDto
  }) => {
    try {
      const res = await apiServiceNew.appClient.itinerary.regenerateItinerary(fields)
      return res
    } catch (res: any) {
      return errorHandler(res, 'Failed to regenerate trip itinerary')
    }
  },
)

export const $isTripsLoading = pending({
  effects: [getTripsFx],
  of: 'some',
})

export const $tripsReceivingFailed = createStore(false)

export const $selectedDestination = createStore<TripIdeaResponseDto | null>(null).on(
  selectedChanged,
  (_, destination) => destination,
)

export const $trips = createStore<TripIdeaResponseDto[]>([])
export const $tripId = createStore('')
export const $greetingMessage = createStore<string | undefined>('')

export const updateAllItineraries = createEvent<{ [key: string]: Itinerary[] }>()
// itinerary for every trip saved by destination code
export const $allItineraries = restore<{ [key: string]: Itinerary[] }>(
  updateAllItineraries,
  {},
)

export const $itineraryRegenerateStarted = createStore(false)

// itinerary for current trip
export const $itineraries = combine(
  $allItineraries,
  $selectedDestination,
  (allItineraries, trip) => allItineraries[trip?.destinationCode || ''] || [],
)

export const $pendingItinerariesTripsCodes = createStore<string[]>([])

export const regenerateTripItinerariesWhenDateChanged = createEvent()

bridge(() => {
  sample({
    clock: resetItineraryFromAllItineraries,
    source: $allItineraries,
    fn: (allItineraries, destinationCode) => {
      const copyOfItineraries = { ...allItineraries }
      delete copyOfItineraries[destinationCode]
      return copyOfItineraries
    },
    target: updateAllItineraries,
  })
})

bridge(() => {
  sample({
    clock: [getTripItinerariesFx, regenerateTripItinerariesFx],
    source: $pendingItinerariesTripsCodes,
    fn: (codes, { destinationCode }) => [...codes, destinationCode],
    target: $pendingItinerariesTripsCodes,
  })

  sample({
    clock: [getTripItinerariesFx.done, regenerateTripItinerariesFx.done],
    source: $pendingItinerariesTripsCodes,
    fn: (codes, { params: { destinationCode } }) =>
      codes.filter((code) => code !== destinationCode),
    target: $pendingItinerariesTripsCodes,
  })
})

sample({
  clock: [getTripItinerariesFx.done, regenerateTripItinerariesFx.done],
  source: $allItineraries,
  fn: (allItineraries, { params: { destinationCode }, result }) => {
    const dayFormat = 'YYYY-MM-DD'

    // sort itineraries by day
    const sortedItineraries =
      (result?.length || 0) >= 2
        ? result?.sort(
            (a, b) =>
              formatToDate(a.day!, dayFormat).getTime() -
              formatToDate(b.day!, dayFormat).getTime(),
          )
        : result

    return { ...allItineraries, [destinationCode]: sortedItineraries || [] }
  },
  target: updateAllItineraries,
})

sample({
  clock: regenerateTripItinerariesFx,
  fn: () => true,
  target: $itineraryRegenerateStarted,
})

reset({
  clock: regenerateTripItinerariesFx.finally,
  target: $itineraryRegenerateStarted,
})

export const $getItinerariesStatus = combine({
  loading: combine($pendingItinerariesTripsCodes, $selectedDestination, (codes, trip) =>
    codes.includes(trip?.destinationCode || ''),
  ),
  error: combine(
    restore(getTripItinerariesFx.finally, null)
      .map(getErrorMessage)
      .reset(getTripItinerariesFx),
    restore(regenerateTripItinerariesFx.finally, null)
      .map(getErrorMessage)
      .reset(regenerateTripItinerariesFx),
    (a, b) => a || b,
  ),
})

sample({
  clock: getTripsFx.doneData,
  fn: ({ trips, summary, tripId }) => ({ trips, summary, tripId }),
  target: spread({
    targets: {
      trips: $trips,
      summary: $greetingMessage,
      tripId: $tripId,
    },
  }),
})

sample({
  source: getTripsFx.failData,
  fn: () => true,
  target: $tripsReceivingFailed,
})

sample({
  source: getTripsFx.doneData,
  fn: () => false,
  target: $tripsReceivingFailed,
})

sample({
  clock: getUserSavedTripsFx.doneData,
  filter: ({ trips }) => Boolean(trips),
  fn: ({ trips, summary, tripId }) => ({ trips, summary, tripId }),
  target: spread({
    targets: {
      trips: $trips,
      summary: $greetingMessage,
      tripId: $tripId,
    },
  }),
})

export const $getTripsStatus = combine({
  loading: combine(getTripsFx.pending, getUserSavedTripsFx.pending, (a, b) => a || b),
  error: combine(
    restore(getTripsFx.finally, null).map(getErrorMessage).reset(getTripsFx),
    restore(getUserSavedTripsFx.finally, null).map(getErrorMessage).reset(getTripsFx),
    (a, b) => a || b,
  ),
})

persist({ store: $trips, key: LocalStorageKeys.Trips })
persist({ store: $tripId, key: LocalStorageKeys.TripId })
persist({ store: $greetingMessage, key: LocalStorageKeys.GreetingMessa })
persist({ store: $selectedDestination, key: LocalStorageKeys.SelectedDestination })
persist({ store: $tripsReceivingFailed, key: LocalStorageKeys.TripsReceivingFailed })
persist({ store: $allItineraries, key: LocalStorageKeys.TripsItineraries })
persist({ store: $getTripsStatus, key: LocalStorageKeys.TripsStatus })

reset({
  clock: stateReset,
  target: [
    $selectedDestination,
    $getTripsStatus,
    $trips,
    $tripId,
    $greetingMessage,
    $tripsReceivingFailed,
    $allItineraries,
  ],
})

reset({
  clock: selectedDestinationReset,
  target: $selectedDestination,
})

reset({
  clock: getTripsStatusReset,
  target: $getTripsStatus,
})

export const openTripDetailsFromEmail = createEvent()

sample({
  clock: openTripDetailsFromEmail,
  fn: () => ({
    name: eventNames.bookingConfirmationVisited,
  }),
  target: analyticsModel.track,
})

//Analytics
let startTime = 0

sample({
  clock: getTripsFx,
  fn: () => {
    startTime = performance.now()

    return {
      name: eventNames.quizLoadingVisited,
    }
  },
  target: analyticsModel.track,
})

sample({
  clock: getTripsFx.doneData,
  fn: () => {
    const endTime = performance.now()
    const responseTimeInSeconds = (endTime - startTime) / 1000
    const responseTimeFormatted = responseTimeInSeconds.toFixed(2)

    return {
      name: eventNames.quizLoadingCompleted,
      properties: {
        timespent: `${responseTimeFormatted} seconds`,
      },
    }
  },
  target: analyticsModel.track,
})
