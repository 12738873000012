import { createEffect, createStore, createEvent, sample } from 'effector'
import { reset } from 'patronum'
import { apiErrorHandler, apiService } from '~/shared/api'

export const confirmTripBookingFx = createEffect(async (tripId: number) => {
  try {
    const { trip } = await apiService().confirmTrip({ id: tripId })
    return trip!
  } catch (res: any) {
    return apiErrorHandler(res)
  }
})

export const stateReset = createEvent()
export const tripBookingConfirmed = createEvent()
export const $isBookingConfirmed = createStore(false)

sample({
  clock: confirmTripBookingFx.done,
  fn: () => true,
  target: $isBookingConfirmed,
})

sample({
  clock: confirmTripBookingFx.done,
  target: tripBookingConfirmed,
})

reset({
  clock: stateReset,
  target: $isBookingConfirmed,
})