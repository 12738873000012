import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    padding: 6px 8px;
    margin-bottom: 16px;

    background-color: #def0ef;
    border: 1px solid ${Colors.PRIMARY_GREEN};
    border-radius: 4px;
  `,

  text: css`
    font-weight: 400;
    margin-left: 8px;
    color: ${Colors.PRIMARY_GREEN};
  `,
}
