import { FC } from 'react'

export interface Props {
  color?: string
}

export const PlusMinusVerticalIcon: FC<Props> = ({ color }) => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33317 0.5L3.33317 5.83333M0.666504 3.16667H5.99984M0.666504 8.5H5.99984"
        stroke={color || 'black'}
      />
    </svg>
  )
}
