import { yupResolver } from '@hookform/resolvers/yup'
import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { popupModel, PopupType } from '~/entities/popup'
import { crossIcon } from '~/shared/assets/icons'
import { arrowLeft } from '~/shared/assets/icons/primary'
import { ButtonWithLoader, InputWithLabel, Error, Title, Link } from '~/shared/ui'
import { sendEmailSchema } from '../../lib'
import { $sentEmail, $sendEmailStatus, emailSent, ForgotPasswordGate } from '../../model'
import { styles } from './styles'

const initialValues = {
  email: '',
}

export const ForgotPassword: FC = () => {
  const isOnboardingFinished = useStore(onboardingSessionModel.$onboardingFinished)

  useGate(ForgotPasswordGate)

  const sentEmail = useStore($sentEmail)
  const { loading, error } = useStore($sendEmailStatus)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendEmailSchema),
    defaultValues: {
      ...initialValues,
    },
  })

  const onLogin = () => popupModel.visiblePopupChanged(PopupType.Login)

  return (
    <div css={styles.container}>
      {isOnboardingFinished ? (
        <div css={styles.return.container}>
          <button
            onClick={() => {
              onboardingSessionModel.statusReset()
            }}
            css={styles.return.button}
          >
            <img src={arrowLeft} />
          </button>
        </div>
      ) : (
        <img
          src={crossIcon}
          alt="cross"
          css={styles.closeIcon}
          onClick={() => popupModel.visiblePopupChanged(PopupType.None)}
        />
      )}

      <Title css={styles.title}>
        {sentEmail ? 'Check your inbox' : 'Forgot your password?'}
      </Title>

      {sentEmail ? (
        <p css={styles.subtitle}>
          We've just emailed instructions and a reset password link to
          <span css={[styles.subtitle, styles.bold]}> {sentEmail}</span>. It might take a
          few minutes to arrive.
        </p>
      ) : (
        <p css={styles.subtitle}>
          No problem! We'll send you a link to reset it. Please enter the email address
          you use to sign in to Awayaway.
        </p>
      )}

      {!sentEmail && (
        <InputWithLabel
          type="text"
          name="email"
          label="Email address"
          placeholder="Enter your email address"
          extendStyle={{ wrapper: styles.input }}
          error={errors.email?.message}
          register={register}
        />
      )}

      {error && !sentEmail && (
        <div css={styles.errorContainer}>
          <Error>{error}</Error>
        </div>
      )}

      <ButtonWithLoader
        css={styles.button}
        variant="default"
        onClick={
          sentEmail
            ? onLogin
            : handleSubmit((data) => {
                emailSent(data.email)
              })
        }
        loading={loading}
      >
        {sentEmail ? 'Back to log in' : 'Send reset link'}
      </ButtonWithLoader>

      {!sentEmail && (
        <Link to="#" onClick={onLogin}>
          Back to log in
        </Link>
      )}
    </div>
  )
}
