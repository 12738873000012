import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  base: (isActive?: boolean) => css`
    min-width: max-content;
    padding: 12px 19.5px;
    border: 1px solid ${Colors.NEUTRAL_SILVER};
    border-radius: 100px;

    color: ${Colors.NEUTRAL_DARK};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;

    transition: all 0.2s linear;

    ${Breakpoints.MD} {
      padding: 10px 15px;
      font-size: 16px;
    }
    ${Breakpoints.SM} {
      padding: 6.5px 16px;
      font-size: 16px;
    }

    &:hover {
      border-color: ${Colors.NEUTRAL_DARK};
      color: ${Colors.NEUTRAL_DARK};
    }

    ${isActive &&
    css`
      background-color: ${Colors.PRIMARY_GREEN};
      border-color: ${Colors.PRIMARY_GREEN};
      color: ${Colors.NEUTRAL_WHITE};

      &:hover {
        border-color: ${Colors.PRIMARY_GREEN};
        color: ${Colors.NEUTRAL_WHITE};
      }
    `}
  `,
}
