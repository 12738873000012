import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ChevronUpIcon: FC<Props> = ({
  width = 16,
  height = 17,
  color = '#009F9D',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.5L8 6.5L4 10.5"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
