import getSymbolFromCurrency from 'currency-symbol-map'
import { FC } from 'react'
import { LowestDailyRates } from '~/entities/room'
import { crossIcon } from '~/shared/assets/icons'
import { getFormattedPrice, getPriceWithCurrency } from '~/shared/lib/currency'
import {
  formatToHyphenDate,
  isDateBetween,
  isDatesSame,
  formatToDate,
} from '~/shared/lib/date'
import { Button, Error } from '~/shared/ui/atoms'
import { Calendar } from '~/shared/ui/organisms/calendar'
import { chooseTripDatesModel } from '../..'
import { getRangeSummaryTitle } from '../../lib'
import { CalendarRange } from '../../types'
import { styles } from './styles'

interface Props {
  checkInDate?: Date
  checkOutDate?: Date
  range: CalendarRange
  totalPrice: number
  currency: string
  lowestDailyRates: LowestDailyRates
  unavailableDates: Date[]
  checkOutOnlyStringDates: string[]
  isConfirmDatesDisabled: boolean
  onClose: () => void
  error?: string
  lastCheckOutDate: Date | null
  isGetLastCheckoutLoading: boolean
  isRoomsListEmpty: boolean
  loading: boolean
}

export const SelectDates: FC<Props> = ({
  range,
  checkInDate,
  checkOutDate,
  totalPrice,
  currency,
  lowestDailyRates,
  unavailableDates,
  checkOutOnlyStringDates,
  isConfirmDatesDisabled,
  onClose,
  error,
  lastCheckOutDate,
  isGetLastCheckoutLoading,
  isRoomsListEmpty,
  loading,
}) => {
  const isCheckOutOnlyEnabled =
    checkInDate && checkOutDate ? isDatesSame(checkInDate, checkOutDate) : false

  const checkOutOnlyDates = isCheckOutOnlyEnabled
    ? []
    : checkOutOnlyStringDates.map((date) => formatToDate(date))

  const disabledDates = lastCheckOutDate
    ? []
    : [...unavailableDates, ...checkOutOnlyDates]

  const checkDate = (date: Date) => {
    const price = lowestDailyRates[formatToHyphenDate(date)]
    const isCheckIn = checkInDate ? isDatesSame(date, checkInDate) : false
    const isCheckOut = checkOutDate ? isDatesSame(date, checkOutDate) : false

    const isInRange =
      checkInDate && checkOutDate ? isDateBetween(date, checkInDate, checkOutDate) : false

    const isSelected = isCheckIn || isCheckOut

    const isCheckOutOnly =
      lastCheckOutDate || isCheckOut || isInRange || isCheckIn || isGetLastCheckoutLoading
        ? false
        : checkOutOnlyStringDates.indexOf(formatToHyphenDate(date)) !== -1

    const isBeforeCheckIn = checkInDate ? date < checkInDate : false

    const isInCheckoutDatesRange =
      lastCheckOutDate && checkInDate
        ? !isDateBetween(date, checkInDate, lastCheckOutDate)
        : false

    const isPriceHidden =
      (!isCheckIn && !price) ||
      isInRange ||
      isInCheckoutDatesRange ||
      (isCheckIn && !totalPrice) ||
      (isCheckIn && isGetLastCheckoutLoading) ||
      isCheckOut ||
      (isGetLastCheckoutLoading && isBeforeCheckIn)

    const today = new Date()
    const isDatePast = date < today

    const dateContainerStyle = isCheckOutOnly
      ? styles.date.tooltip.container
      : isDatePast
      ? styles.date.pastDate
      : isInRange
      ? styles.date.inRange
      : null
    return {
      dateContainerStyle,
      isSelected,
      isCheckOutOnly,
      isPriceHidden,
      isCheckIn,
      price,
      isCheckOut,
    }
  }

  return (
    <div css={styles.container}>
      <header css={styles.header}>
        <img src={crossIcon} onClick={onClose} />
      </header>

      <main css={styles.main}>
        <span css={styles.label}>Select dates</span>

        <Calendar
          range={range}
          onChange={(rangesByKey) =>
            chooseTripDatesModel.bookingDatesChanged(rangesByKey['selection'])
          }
          onShownDateChange={chooseTripDatesModel.calendarViewChanged}
          dayContentRenderer={(date) => {
            const {
              dateContainerStyle,
              isSelected,
              isCheckOutOnly,
              isPriceHidden,
              isCheckIn,
              price,
              isCheckOut,
            } = checkDate(date)

            return (
              <div css={dateContainerStyle}>
                <div css={styles.date.date(isSelected, isCheckOutOnly)}>
                  {date.getDate()}
                </div>
                <div css={styles.date.price(isSelected, isPriceHidden)}>
                  {isCheckIn ? getFormattedPrice(totalPrice) : getFormattedPrice(price)}
                </div>
                {isCheckOutOnly && !isCheckOut && (
                  <span css={styles.date.tooltip.content}>Checkout only</span>
                )}
              </div>
            )
          }}
          maxDate={lastCheckOutDate ? lastCheckOutDate : undefined}
          minDate={
            lastCheckOutDate || isGetLastCheckoutLoading ? range.startDate : undefined
          }
          disabledDates={[...disabledDates]}
        />
        {isGetLastCheckoutLoading && <div css={styles.loadingLayer}></div>}
      </main>

      <footer css={styles.footer.container}>
        <div css={styles.footer.wrapper}>
          {loading ? (
            'Loading...'
          ) : (
            <div>
              <h1 css={styles.footer.info}>
                {checkInDate && checkOutDate
                  ? getRangeSummaryTitle(checkInDate, checkOutDate)
                  : 'Check In Date - Check Out Date: '}

                {isRoomsListEmpty ? (
                  <span css={styles.footer.noAvailable}>No rooms available</span>
                ) : (
                  getPriceWithCurrency(totalPrice, currency)
                )}
              </h1>
              {!isRoomsListEmpty && (
                <p css={styles.footer.subtitle}>
                  All prices are shown in {currency} ({getSymbolFromCurrency(currency)})
                </p>
              )}
            </div>
          )}

          <Button
            css={styles.footer.button}
            onClick={onClose}
            disabled={isConfirmDatesDisabled}
          >
            Confirm Dates
          </Button>
        </div>

        {error && <Error extendStyles={styles.error}>{error}</Error>}
      </footer>
    </div>
  )
}
