import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ChevronLeftIcon: FC<Props> = ({
  width = 23,
  height = 22,
  color = '#FDFDFD',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2275 16.4545L8.77295 11L14.2275 5.54544"
        stroke={color}
        strokeWidth="1.13636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
