export enum BOOKING_STATUS {
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
}

export const DEFAULT_BOOKING = {
  amount: 0,
  leadName: '',
  leadSurname: '',
  hotelEmail: '',
  hotelPhone: '',
  destinationName: '',
  hotelAddress: '',
  hotelCity: '',
  hotelUrl: '',
  totalAdults: 0,
  totalChildren: 0,
  checkIn: '',
  checkOut: '',
  reference: '',
  totalPaidAmount: 0,
  currency: '',
}

export const DEFAULT_PAYMENT_TRANSACTION = {
  id: 0,
  bookingId: 0,
  paymentClientToken: '',
  status: '',
  amount: 0,
  currency: '',
}

export const REDIRECT_BOOKING_ERRORS = {
  OFFER_NOT_FOUND: 'Offer for the selected order not found.',
  THE_OFFER_NO_LONGER_AVAILABLE:
    'The offer no longer available. Please select another offer.',
  THE_OFFER_HAS_EXPIRED: 'The offer has expired. Please select another offer.',
  THE_OFFER_IS_BOOKED: 'The offer is booked. Please select another offer.',
  THE_OFFER_IS_NOT_AVAILABLE: 'The offer is not available. Please select another offer.',
}
