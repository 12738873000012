import { useStore } from 'effector-react'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { Calendar } from '~/shared/ui/organisms'
import { MAX_FLEXIBLE_DATES_AMOUNT } from '../config'
import {
  daysNearbyOptions,
  stayDurationOptions,
  monthAndYearOptions,
  generateDaysSelectOptions,
  formatToCustomOrdinalDate,
} from '../lib'
import {
  $activeTab,
  $displayingFlexibleDuration,
  $flexibleDates,
  $selectedDaysNearby,
  $stayDuration,
  $isExactDatesDurationLimitReached,
  customStayDurationChanged,
  daysNearbyChanged,
  flexibleDateSelected,
  stayDurationChanged,
  tabSelected,
  $exactDatesRange,
  exactDatesChanged,
} from '../model'
import { Notification } from './notification'
import { OptionBar } from './option-bar'
import { ScheduledDates } from './scheduled-dates'
import { Select } from './select'
import { styles } from './styles'
import { TabBar } from './tab-bar'

export const ScheduleTripDates = () => {
  const activeTab = useStore($activeTab)
  const selectedDaysNearby = useStore($selectedDaysNearby)
  const stayDuration = useStore($stayDuration)
  const flexibleDates = useStore($flexibleDates)
  const datesRange = useStore($exactDatesRange)
  const displayingFlexibleDuration = useStore($displayingFlexibleDuration)
  const { startDate, endDate } = datesRange
  const isDurationLimitReached = useStore($isExactDatesDurationLimitReached)

  const controls = useAnimation()
  const shakeAnimation = {
    x: [0, -10, 10, -10, 10, 0],
    transition: { duration: 0.5 },
  }

  useEffect(() => {
    if (isDurationLimitReached) {
      controls.start(shakeAnimation)
    }
  }, [isDurationLimitReached, controls])

  return (
    <div css={styles.container}>
      {activeTab === 1 && isDurationLimitReached ? (
        <Notification
          variant={isDurationLimitReached ? 'warning' : 'default'}
          title={isDurationLimitReached ? 'Duration limit reached' : undefined}
        >
          <p>
            Maximum planning limit is <span>30 nights</span> due to hotels booking
            restrictions
          </p>
        </Notification>
      ) : null}

      <motion.div animate={controls}>
        <ScheduledDates
          activeTab={activeTab}
          checkInDate={startDate ? formatToCustomOrdinalDate(startDate) : ''}
          checkOutDate={endDate ? formatToCustomOrdinalDate(endDate) : ''}
          dateRange={displayingFlexibleDuration}
        />

        <main css={styles.contentWrapper}>
          <TabBar onTabSelect={tabSelected} activeTab={activeTab} />

          {activeTab === 1 ? (
            <div css={styles.specificCalendarWrapper}>
              <Calendar
                range={datesRange}
                onChange={(rangesByKey) => {
                  exactDatesChanged(rangesByKey['selection'])
                }}
                dayContentRenderer={(date) => {
                  const today = new Date()
                  const isDatePast = date < today

                  return <div css={styles.date(isDatePast)}>{date.getDate()}</div>
                }}
              />

              <OptionBar
                options={daysNearbyOptions}
                selectedOptions={[selectedDaysNearby.label]}
                onOptionSelect={daysNearbyChanged}
              />
            </div>
          ) : (
            <div css={styles.flexibleCalendarWrapper}>
              <p css={styles.label}>How long would you like to go for?</p>
              <OptionBar
                options={stayDurationOptions}
                selectedOptions={[stayDuration.label]}
                onOptionSelect={stayDurationChanged}
              />

              {stayDuration.label === 'Custom' && (
                <Select
                  label="Select length of stay"
                  options={generateDaysSelectOptions(14)}
                  onSelect={customStayDurationChanged}
                  extendStyles={{ container: styles.select }}
                  value={stayDuration.option}
                />
              )}

              <p css={styles.label}>When do you want to go?</p>
              <div css={styles.carousel}>
                <OptionBar
                  options={monthAndYearOptions}
                  selectedOptions={flexibleDates?.map(({ label }) => label)}
                  onOptionSelect={flexibleDateSelected}
                  optionsDisabled={flexibleDates.length >= MAX_FLEXIBLE_DATES_AMOUNT}
                />
              </div>
            </div>
          )}
        </main>
      </motion.div>
    </div>
  )
}
