import { TripIdeaResponseDto } from '@softcery/awayaway-nodejs-api-client'
import { format } from 'date-fns'
import { createEvent, sample, combine, restore, createStore } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { tripFeedbackModel } from '~/features/feedback'
import { chooseTripDatesModel } from '~/features/trip-booking/choose-trip-dates'
import {
  $availableDatesWithRates,
  $checkOutOnlyDates,
  $unavailableDates,
  bookingDatesChangeInitiated,
  calendarUpdated,
  checkedMonthReset,
} from '~/features/trip-booking/choose-trip-dates/model'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { hotelsForTripModel } from '~/entities/hotel'

import { navigationModel } from '~/entities/navigation'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel, SelectedDatesForAllDestinations } from '~/entities/trip'

import { tripInfoModel } from '~/entities/trip-info'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'

export const DestinationDetailsGate = createGate<string>()
export const bookingInitiated = createEvent()
export const destinationViewed = createEvent<TripIdeaResponseDto | null>()

const itinerariesRequested = createEvent()
export const changeForbidDoReqForItineraries = createEvent<boolean>()
export const $isForbidDoReqForItineraries = restore(
  changeForbidDoReqForItineraries,
  false,
)

export const resetItinerariesWithRequestsDate = createEvent()
export const updateItinerariesWithRequestsDate = createEvent<
  SelectedDatesForAllDestinations[] | null
>()
export const $itinerariesWithRequestsDate = restore(
  updateItinerariesWithRequestsDate,
  null,
)

const $isDestinationDetailsGateOpened = createStore(false)

sample({
  clock: DestinationDetailsGate.open,
  source: destinationModel.$trips,
  fn: (trips, destinationCode) =>
    trips.find((t) => t.destinationCode === destinationCode) || null,
  target: destinationViewed,
})

// update selected hotel code in new hotel room model
sample({
  clock: [
    DestinationDetailsGate.open,
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  ],
  source: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  filter: (hotelCode) => !!hotelCode,
  fn: (hotelCode) => hotelCode!.code!,
  target: hotelRoomModel.$$getRoomsFactory.updateSelectedHotelCode,
})

// update hotels prices
sample({
  clock: hotelRoomModel.$$manageRoomsDataFactory.$lowestTotalRateAmount,
  source: {
    hotelsFromSelectedTripWithPrices:
      hotelsForTripModel.$$manageHotelFactory.$hotelsFromSelectedTripWithPrices,
    selectedHotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  filter: ({ selectedHotel }, lowestTotalRateAmount) =>
    !!selectedHotel?.code && !!lowestTotalRateAmount,
  fn: ({ hotelsFromSelectedTripWithPrices, selectedHotel }, lowestTotalRateAmount) => {
    const selectedHotelCode = selectedHotel?.code
    if (!hotelsFromSelectedTripWithPrices)
      return { [selectedHotelCode!]: lowestTotalRateAmount }

    const copyOfHotels = {
      ...hotelsFromSelectedTripWithPrices,
      [selectedHotelCode!]: lowestTotalRateAmount,
    }
    return copyOfHotels
  },
  target: hotelsForTripModel.$$manageHotelFactory.updateHotelsFromSelectedTrioWithPrices,
})

sample({
  clock: DestinationDetailsGate.open,
  source: {
    trips: destinationModel.$trips,
    selectedTrip: destinationModel.$selectedDestination,
  },
  filter: ({ selectedTrip }, destinationCode) =>
    selectedTrip?.destinationCode !== destinationCode,
  fn: ({ trips }, destinationCode) =>
    trips.find((t) => t.destinationCode === destinationCode) || null,
  target: destinationModel.selectedChanged,
})

sample({
  clock: destinationModel.regenerateTripItinerariesWhenDateChanged,
  source: {
    isRoomsListEmpty: hotelRoomModel.$$manageRoomsDataFactory.$isRoomsListEmpty,
    selectedDestination: destinationModel.$selectedDestination,
  },
  filter: ({ isRoomsListEmpty }) => isRoomsListEmpty,
  fn: ({ selectedDestination }) => selectedDestination?.destinationCode || '',
  target: destinationModel.resetItineraryFromAllItineraries,
})

sample({
  clock: bookingInitiated,
  source: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  filter: (selectedHotel) => !!selectedHotel?.code,
  fn: (hotel) => `${hotel!.code!}/contacts`,
  target: navigationModel.pathPushed,
})

reset({
  clock: DestinationDetailsGate.close,
  target: tripFeedbackModel.$selectedTripFeedback,
})

bridge(() => {
  // open/close destination details gate
  sample({
    clock: DestinationDetailsGate.open,
    fn: () => true,
    target: $isDestinationDetailsGateOpened,
  })

  sample({
    clock: DestinationDetailsGate.close,
    fn: () => false,
    target: $isDestinationDetailsGateOpened,
  })

  sample({
    clock: [
      chooseTripDatesModel.$selectedDateForDestinations,
      destinationModel.regenerateTripItinerariesWhenDateChanged,
    ],
    source: {
      itinerariesWithRequestsDate: $itinerariesWithRequestsDate,
      selectedDestination: destinationModel.$selectedDestination,
      isCalendarVisible: $isForbidDoReqForItineraries,
      selectedDate: chooseTripDatesModel.$selectedDateForDestinations,
    },
    filter: ({
      itinerariesWithRequestsDate,
      selectedDestination,
      isCalendarVisible,
      selectedDate,
    }) => {
      if (isCalendarVisible) return false
      if (!selectedDate?.length) return false
      if (!itinerariesWithRequestsDate) return true

      const getDestinationSelectedDate = selectedDate.find(
        (dest) => dest.destinationCode === selectedDestination?.destinationCode,
      )
      if (!getDestinationSelectedDate) return false

      const ifDestinationCodeExist = itinerariesWithRequestsDate.find(
        (dest) => dest.destinationCode === selectedDestination?.destinationCode,
      )
      if (!ifDestinationCodeExist) return true

      const isDateMatch =
        ifDestinationCodeExist.startDate === getDestinationSelectedDate.startDate &&
        ifDestinationCodeExist.endDate === getDestinationSelectedDate.endDate
      return !isDateMatch
    },

    target: itinerariesRequested,
  })

  sample({
    clock: itinerariesRequested,
    source: chooseTripDatesModel.$selectedDateForDestinations,
    target: updateItinerariesWithRequestsDate,
  })

  // get itineraries when destination is selected
  sample({
    clock: itinerariesRequested,
    source: {
      categories: listImageRecommendationsModel.$imagesLabels,
      trip: destinationModel.$selectedDestination,
      dates: tripInfoModel.$selectedTripDates,
      travellers: combine(
        tripInfoModel.$adultsAmount,
        tripInfoModel.$childrenAmount,
        tripInfoModel.$childrenAges,
        (adults, children, childrenAges) => ({
          adults,
          children,
          childrenAges,
        }),
      ),
    },
    fn: ({ categories, trip, travellers, dates }) => {
      return {
        destinationCode: trip?.destinationCode || '',
        fields: {
          categories,
          trip: {
            country: trip?.countryName || '',
            date: {
              startDate: format(new Date(dates.startDate), 'yyyy-MM-dd') || '',
              endDate: format(new Date(dates.endDate), 'yyyy-MM-dd') || '',
            },
            description: trip?.description || '',
            keypoints: trip?.keypoints || '',
            name: trip?.name || '',
            travellers,
          },
        },
      }
    },
    target: destinationModel.getTripItinerariesFx,
  })

  // reset selected dates when destination is changed
  sample({
    clock: destinationModel.$selectedDestination,
    filter: (selectedDestination) => !selectedDestination,
    target: tripInfoModel.selectedDatesReset,
  })
})

sample({
  clock: DestinationDetailsGate.open,
  source: {
    onboardingDates: onboardingSessionModel.$savedDates,
    currentBookingDates: tripInfoModel.$selectedTripDates,
  },
  fn: ({ onboardingDates, currentBookingDates }) =>
    currentBookingDates.startDate !== '' && currentBookingDates.endDate !== ''
      ? currentBookingDates
      : onboardingDates,
  target: bookingDatesChangeInitiated,
})

reset({
  clock: [DestinationDetailsGate.close, calendarUpdated],
  target: [
    hotelRoomModel.$$manageRoomsDataFactory.$getRoomsStatus,
    $availableDatesWithRates,
    $checkOutOnlyDates,
    $unavailableDates,
  ],
})

sample({
  clock: DestinationDetailsGate.close,
  target: checkedMonthReset,
})

persist({
  store: $itinerariesWithRequestsDate,
  key: LocalStorageKeys.SelectedDateWhenRequestDoForItinerary,
})

reset({
  clock: destinationModel.stateReset,
  target: $itinerariesWithRequestsDate,
})
