import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ReturnIcon: FC<Props> = ({ width = 14, height = 15, color = '#ffffff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33268 8.08333L11.0827 9.83333M11.0827 9.83333L9.33268 11.5833M11.0827 9.83333H2.91602M4.66602 6.91666L2.91602 5.16666M2.91602 5.16666L4.66602 3.41666M2.91602 5.16666H11.0827"
        stroke={color}
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
