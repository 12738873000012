import { SerializedStyles } from '@emotion/react'
import { useState } from 'react'
import { useWindowSize } from 'react-use'
import { Hotel } from '~/entities/hotel'
import { Dimensions } from '~/shared/styles'
import { ImageCarousel } from '~/shared/ui'
import { MutatedTripIdea } from '../../types'
import { styles } from './styles'

import './styles.css'

//carousel config values
//check with designs
const MIN_SLIDES_FOR_MOBILE_CAROUSEL = 1
const MIN_SLIDES_FOR_TABLET_CAROUSEL = 2
const MIN_SLIDES_FOR_DESKTOP_CAROUSEL = 3
const CAROUSEL_TABLET_BREAKPOINT = 920
const CAROUSEL_DESKTOP_BREAKPOINT = 1680
const BASE_SLIDE_WIDTH = 475

interface Props {
  hotels: MutatedTripIdea[]
  isRoomLoading: boolean
  onSelect?: (hotel: MutatedTripIdea) => void
  onDetailsClick?: (hotel: MutatedTripIdea) => void
  extendStyles?: {
    container: SerializedStyles
  }
  withSlider?: boolean
  hotelCode?: number | null
  loading: boolean
  selectedDate?: {
    destinationCode: string
    startDate: string
    endDate: string
  }
  hotelsFromSelectedTripWithPrices?: Record<string, number>
}

export const HotelsList = ({
  hotels,
  onDetailsClick,
  extendStyles,
  withSlider,
  hotelCode,
  onSelect,
  selectedDate,
  loading,
  hotelsFromSelectedTripWithPrices,
  isRoomLoading,
}: Props) => {
  const { width } = useWindowSize()
  const [activeSlideIndex, setActiveSlideIndex] = useState(1)
  const isMobile = width <= Dimensions.Sm

  const isStaticHotelsView =
    hotels.length <
    (width > CAROUSEL_TABLET_BREAKPOINT
      ? MIN_SLIDES_FOR_DESKTOP_CAROUSEL
      : MIN_SLIDES_FOR_TABLET_CAROUSEL)

  const carouselItems = [
    MIN_SLIDES_FOR_MOBILE_CAROUSEL,
    MIN_SLIDES_FOR_TABLET_CAROUSEL,
    width > CAROUSEL_DESKTOP_BREAKPOINT
      ? MIN_SLIDES_FOR_DESKTOP_CAROUSEL
      : MIN_SLIDES_FOR_TABLET_CAROUSEL,
  ] as [number, number, number]

  const isSingleHotel = hotels.length === 1

  return (
    <div css={[styles.hotels.container, extendStyles?.container]}>
      {isStaticHotelsView ? (
        <div>
          {' '}
          <div css={styles.hotels.list}>
            {hotels.map((hotel, i) => (
              <Hotel
                key={i}
                hotel={hotel}
                onDetailsClick={onDetailsClick}
                extendStyles={
                  hotels.length > 1
                    ? { container: styles.hotels.multipleHotels }
                    : undefined
                }
                selected={hotel.isSelected}
                onSelect={onSelect ? () => onSelect(hotel) : undefined}
                isSingleHotel={isSingleHotel}
                withTitle
                selectedDate={selectedDate}
                loading={loading}
                isRoomLoading={hotel.code === hotelCode ? isRoomLoading : false}
                lowestTotalRate={
                  hotelsFromSelectedTripWithPrices
                    ? hotelsFromSelectedTripWithPrices[hotel.code]
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      ) : (
        <ImageCarousel
          extendStyles={{ arrows: styles.hotels.arrows }}
          customItemClass="hotel-carousel-item"
          withSlider={withSlider}
          responsiveItemsCount={carouselItems}
          slideWidth={BASE_SLIDE_WIDTH}
          usePartialVisibility
          showDots={true}
          arrows={isMobile ? false : true}
          draggable={true}
          dotListClass="custom-dot-list-style"
          transitionDuration={500}
          afterChange={(_, { currentSlide }) => setActiveSlideIndex(currentSlide + 1)}
        >
          {hotels.map((hotel, i) => (
            <Hotel
              key={i}
              hotel={hotel}
              onDetailsClick={onDetailsClick}
              withTitle
              selected={hotel.isSelected}
              onSelect={onSelect ? () => onSelect(hotel) : undefined}
              isSingleHotel={isSingleHotel}
              selectedDate={selectedDate}
              loading={loading}
              isRoomLoading={hotel.code === hotelCode ? isRoomLoading : false}
              lowestTotalRate={
                hotelsFromSelectedTripWithPrices
                  ? hotelsFromSelectedTripWithPrices[hotel.code]
                  : undefined
              }
            />
          ))}
        </ImageCarousel>
      )}
      {!isSingleHotel && isMobile && (
        <span css={styles.pagination}>
          {activeSlideIndex}/{hotels.length}
        </span>
      )}
    </div>
  )
}
