import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { resetPasswordModel } from '~/features/user/reset-password'
import { viewerModel } from '~/entities/viewer'
import { ButtonWithLoader, Error } from '~/shared/ui'
import { SecurityPageGate } from '../model'
import { styles } from './styles'

export const SecurityPage: FC = () => {
  useGate(SecurityPageGate)

  const { email } = useStore(viewerModel.$viewer)
  const { loading, error } = useStore(resetPasswordModel.$sendEmailStatus)
  const sentEmail = useStore(resetPasswordModel.$sentEmail)

  const sendEmail = () => resetPasswordModel.emailSent(email!)

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Reset Password</h1>
      {sentEmail ? (
        <p css={styles.subtitle}>
          We've just emailed instructions and a reset password link to{' '}
          <span css={styles.bold}>{sentEmail}</span>. It might take a few minutes to
          arrive.
        </p>
      ) : (
        <p css={styles.subtitle}>We'll send reset link on your email</p>
      )}

      {!sentEmail && (
        <ButtonWithLoader loading={loading} onClick={sendEmail}>
          Send reset link
        </ButtonWithLoader>
      )}

      {error && <Error extendStyles={styles.error}>{error}</Error>}
    </div>
  )
}
