import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  base: css`
    font-size: 20px;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_GREY};
    line-height: 1.5;
  `,
}
