import { css } from '@emotion/react'
import { Colors, Breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    width: 89px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #d5d4dc;
    border-radius: 4px;
    padding: 11.67px;
  `,

  circle: css`
    width: 16.67px;
    height: 16.67px;

    ${Breakpoints.MD} {
      width: 20px;
      height: 20px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 0px;

    border: 1.25px solid ${Colors.PRIMARY_GREEN};
    border-radius: 100%;
    background-color: transparent;

    font-weight: 500;
    color: ${Colors.PRIMARY_GREEN};

    cursor: pointer;

    :disabled {
      border: 1.25px solid ${Colors.NEUTRAL_STONE};
      color: ${Colors.NEUTRAL_STONE};
      cursor: default;
    }
  `,
}
