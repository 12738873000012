import { FC } from 'react'
import { Link } from 'react-router-dom'
import { normalizeStringCase } from '~/shared/lib/string'
import { Image } from '../../atoms'
import { styles } from './styles'

interface Props {
  image?: string
  title: string
  link?: {
    title: string
    path: string
    action?: () => void
  }

  items?: string[]
  number?: string
  status?: string
}

export const ListItemCard: FC<Props> = ({
  number,
  image,
  title,
  items,
  link,
  status,
}) => {
  return (
    <div css={styles.wrapper}>
      {!!number && <h1 css={styles.number}>{number}</h1>}

      <div css={styles.container}>
        <div css={styles.image.container(!image, !!status)}>
          <Image image={image} extendStyle={styles.image.base} alt={title} />
        </div>

        <div css={styles.info.wrapper}>
          <h1 css={styles.info.title(!!status)}>{title}</h1>

          {!!items && (
            <div css={styles.info.container}>
              {items.map((item, index) => (
                <div css={styles.info.itemWrapper} key={index}>
                  <span css={styles.info.item(!!status)}>{item}</span>
                </div>
              ))}
            </div>
          )}

          {link && (
            <Link to={link.path} onClick={link.action} css={styles.link}>
              {link.title}
            </Link>
          )}

          {status && <div css={styles.status}>{normalizeStringCase(status)}</div>}
        </div>
      </div>
    </div>
  )
}
