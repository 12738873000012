import { FC } from 'react'
import { styles } from './styles'

interface Props {
  day: number
  isActive?: boolean
}

const smoothScroll = (targetId: string) => {
  const targetElement = document.getElementById(targetId)
  targetElement?.scrollIntoView({
    behavior: 'smooth',
  })
}

export const NavigationButton: FC<Props> = ({ day, isActive }) => {
  return (
    <a css={styles.base(isActive)} onClick={() => smoothScroll(`itinerary/day${day}`)}>
      Day {day}
    </a>
  )
}
