import { yupResolver } from '@hookform/resolvers/yup'
import { LoginUserRequestBody } from '@softcery/awayaway-apiclient'
import { useGate, useStore, useUnit } from 'effector-react'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { bookingModel } from '~/pages/booking'
import { summaryPageModel } from '~/pages/onboarding/summary'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { popupModel, PopupType } from '~/entities/popup'
import { destinationModel } from '~/entities/trip'
import { ArrowLeftIcon } from '~/shared/assets/icons/components'
import { Colors } from '~/shared/styles'
import {
  ButtonWithLoader,
  Error,
  InputWithLabel,
  Link,
  PageLoader,
  PasswordInput,
} from '~/shared/ui'
import { loginProfileModel } from '..'
import { OAuthButton } from '../../oauth'

import { schema } from '../lib'
import { styles } from './styles'

const initialValues: LoginUserRequestBody = {
  email: '',
  password: '',
}

export const LoginProfile: FC = () => {
  useGate(loginProfileModel.Gate)
  const { error, loading } = useUnit({
    error: loginProfileModel.$loginError,
    loading: loginProfileModel.loginProfileFx.pending,
  })

  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isTripsLoading = useStore(destinationModel.$isTripsLoading)
  const isLoadingMessagesPending = useStore(summaryPageModel.$isLoadingMessagesPending)
  const loadingMessages = useStore(summaryPageModel.$loadingMessages)
  const isBookingCreated = useStore(createBookingModel.$isBookingCreated)
  const isLoaderVisible = isTripsLoading || isNewSummaryPending

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues,
    },
  })

  const backClickHandler = () => {
    !isBookingCreated
      ? (onboardingSessionModel.statusReset(), onboardingSessionModel.authBackClicked())
      : (bookingModel.contactsStepOpened(), popupModel.popupHidden())
  }

  const onSubmitHandler = (data: typeof initialValues) =>
    loginProfileModel.loginProfileFx(data)

  return (
    <div css={styles.container.main(isLoaderVisible)}>
      <div css={styles.container.content(isLoaderVisible)}>
        <div css={styles.return.container}>
          <button onClick={backClickHandler} css={styles.return.button}>
            <ArrowLeftIcon color={Colors.NEUTRAL_BLACK} width={24} height={24} />
            Back to quiz
          </button>
        </div>

        <h2 css={styles.title}>Log in to your account</h2>
        {!isBookingCreated && (
          <Link
            extendStyles={styles.link}
            onClick={() => popupModel.visiblePopupChanged(PopupType.Register)}
            to={'#'}
          >
            Don't have an account? Register
          </Link>
        )}
        <div css={styles.container.inputs}>
          <InputWithLabel
            type="text"
            name="email"
            label="Email address"
            placeholder="Enter your email address"
            extendStyle={{ input: styles.input }}
            error={errors.email?.message}
            register={register}
          />

          <PasswordInput
            name="password"
            label="Password"
            placeholder="Enter your password"
            extendStyle={{ input: styles.input }}
            error={errors.password?.message}
            register={register}
          />
        </div>

        <Link
          extendStyles={styles.link}
          onClick={() => popupModel.visiblePopupChanged(PopupType.ForgotPassword)}
          to={'#'}
        >
          Forgot your password?
        </Link>

        <div css={styles.buttonWrapper}>
          <ButtonWithLoader
            css={styles.button}
            variant="default"
            onClick={handleSubmit(onSubmitHandler)}
            loading={loading}
          >
            Log in
          </ButtonWithLoader>

          {!isBookingCreated && (
            <div css={styles.divider}>
              <span>or</span>
            </div>
          )}

          {!isBookingCreated && <OAuthButton />}
        </div>
        {error && (
          <div css={styles.errorContainer}>
            <Error>{error}</Error>
          </div>
        )}
      </div>

      {isLoaderVisible && (
        <div css={styles.loader.wrapper}>
          <PageLoader
            loadingMessages={loadingMessages}
            isMessagesLoading={isLoadingMessagesPending}
            isNewSummaryPending={isNewSummaryPending}
          />
        </div>
      )}
    </div>
  )
}
