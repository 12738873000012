import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 120px;

    ${Breakpoints.MD} {
      gap: 48px;
    }

    ${Breakpoints.XL} {
      gap: 80px;
    }
  `,

  container: css`
    position: relative;
    display: flex;
    gap: 48px;
    max-width: 1224px;

    margin-bottom: 120px;

    ${Breakpoints.MD} {
      flex-direction: column;
      gap: 16px;
      margin-bottom: 80px;
    }

    overflow-x: hidden;
  `,

  images: {
    container: css`
      position: relative;
      display: grid;
      grid-template:
        'first second' 1fr
        'first third' 1fr
        / 2fr 1fr;
      gap: 4px;

      ${Breakpoints.XL} {
        grid-template:
          'first' 1fr
          / 1fr;
      }
    `,

    firstImage: css`
      grid-area: first;

      width: 477px;
      height: 468px;

      border-radius: 16px 0 0 16px;

      ${Breakpoints.MD} {
        width: 100%;
        height: 263px;
        border-radius: 16px;
      }
    `,

    secondImage: css`
      grid-area: second;

      width: 215px;
      height: 232px;

      border-radius: 0 16px 0 0;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    thirdImage: css`
      grid-area: third;

      width: 215px;
      height: 232px;

      border-radius: 0 0 16px 0;

      ${Breakpoints.MD} {
        display: none;
      }
    `,
  },

  content: css`
    display: flex;
    flex-direction: column;
    max-width: 480px;
    width: 100%;

    ${Breakpoints.MD} {
      max-width: 100%;
    }
  `,

  dates: css`
    margin-top: 24px;

    width: 100%;
    display: flex;
    gap: 8px;

    ${Breakpoints.MD} {
      margin-top: 24px;
    }
  `,

  date: css`
    width: 33.3%;
  `,

  flexContainer: css`
    margin-top: 16px;

    display: flex;
    align-items: center;
    gap: 16px;
  `,

  description: css`
    margin-top: 16px;
  `,

  keyPoints: css`
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;
  `,

  button: css`
    margin-top: auto;

    ${Breakpoints.SM} {
      margin-top: 0;
    }
  `,
}
