import { FC } from 'react'
import { Colors } from '~/shared/styles'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const MoonIcon: FC<Props> = ({
  width = 17,
  height = 16,
  color = Colors.NEUTRAL_STONE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="moon">
        <path
          id="Vector"
          d="M14.4999 8.52667C14.3951 9.66147 13.9692 10.7429 13.2721 11.6445C12.575 12.5461 11.6356 13.2305 10.5637 13.6177C9.49188 14.0049 8.33192 14.0787 7.2196 13.8307C6.10728 13.5827 5.0886 13.023 4.28275 12.2172C3.47691 11.4113 2.91723 10.3927 2.66921 9.28033C2.42118 8.16801 2.49508 7.00806 2.88224 5.9362C3.2694 4.86434 3.95382 3.92491 4.85541 3.22784C5.757 2.53076 6.83847 2.10487 7.97327 2C7.30888 2.89884 6.98917 4.0063 7.07229 5.12094C7.15541 6.23559 7.63584 7.28337 8.4262 8.07373C9.21656 8.86409 10.2643 9.34452 11.379 9.42765C12.4936 9.51077 13.6011 9.19106 14.4999 8.52667Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
