import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'
import { markerIcon } from '../../../assets/icons'

export const styles = {
  location: css`
    display: flex;
    align-items: flex-start;
    color: ${Colors.DARK_GREY_2};
    font-size: 16px;
    line-height: 19px;

    &::before {
      width: 10px;
      height: 14px;
      margin-right: 8px;
      padding-right: 8px;
      content: '';
      background-image: url('${markerIcon}');
      background-repeat: no-repeat;
    }
  `,
}
