import { FC, HTMLAttributes } from 'react'
import { Button, NavigationControls, NavigationControlsProps } from '~/shared/ui'
import { styles } from './styles'

interface Props extends NavigationControlsProps, HTMLAttributes<HTMLDivElement> {
  text?: string
  widthLimit?: boolean
  onReset?: () => void
}

export const NavigationBar: FC<Props> = (props) => {
  const {
    text,
    disabled,
    onPrev,
    onNext,
    widthLimit,
    onReset,
    isStepSkippable,
    isLoading,
  } = props

  return (
    <div css={styles.container} data-testid="navigation-bar">
      <div css={styles.wrapper}>
        <div css={[styles.widthLimiter.base, widthLimit && styles.widthLimiter.limit]}>
          <div>
            <p css={styles.text} data-testid="navigation-bar-text">
              {text}
            </p>
            <Button
              variant="link"
              css={styles.linkButton}
              onClick={onReset}
              disabled={disabled}
            >
              Clear Selection
            </Button>
          </div>

          <NavigationControls
            disabled={disabled || isLoading}
            onPrev={onPrev}
            onNext={onNext}
            isStepSkippable={isStepSkippable}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
