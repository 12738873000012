import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  summaryModal: css`
    backdrop-filter: blur(5px);
  `,

  fullWidth: css`
    width: 100%;
  `,

  states: {
    loader: css`
      fill: ${Colors.NEUTRAL_BLACK};
    `,
  },
}
