import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  payCard: css`
    position: absolute;
    top: 42px;
    right: 12px;
    margin: auto;
    fill: ${Colors.LIGHT_GREY_1};
  `,

  invalid: css`
    fill: ${Colors.ERROR};
  `,
}
