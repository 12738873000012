import { FC } from 'react'
import { CloudIcon, MoonIcon, SunIcon } from '~/entities/trip/assets'
import { ItineraryFragmentSkeleton } from '../../fragment/skeleton/skeleton'
import { styles } from './styles'

interface Props {
  isLast?: boolean
}

export const ItinerarySkeletonLoader: FC<Props> = ({ isLast }) => {
  return (
    <div css={styles.container}>
      <ItineraryFragmentSkeleton Icon={SunIcon} />
      <ItineraryFragmentSkeleton Icon={CloudIcon} />
      <ItineraryFragmentSkeleton Icon={MoonIcon} isLast={isLast} />
    </div>
  )
}
