import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  preview: {
    container: css`
      width: 100%;
      height: 402px;
      position: relative;
      margin-top: 84px;
      ${Breakpoints.XL} {
        padding-left: 0;
      }

      ${Breakpoints.SM} {
        height: 220px;
      }
    `,

    background: {
      image: css`
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 612px;
        object-fit: cover;

        ${Breakpoints.MD} {
          height: 420px;
        }
        ${Breakpoints.SM} {
          height: 220px;
        }
      `,

      filter: css`
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 612px;
        opacity: 0.82;
        background-image: linear-gradient(
          35deg,
          ${Colors.DARK_1} 5%,
          rgb(58 58 58 / 62%) 114%,
          rgb(84 84 84 / 45%) 163%
        );

        ${Breakpoints.MD} {
          height: 420px;
        }

        ${Breakpoints.SM} {
          height: 220px;
        }
      `,
    },

    info: css`
      position: absolute;
      z-index: 3;
    `,

    crumbs: css`
      user-select: none;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_GREY};
      font-family: ${Fonts.Calibre};
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 24px;

      & > span:first-of-type {
        cursor: pointer;
        color: ${Colors.NEUTRAL_BLACK};
      }

      ${Breakpoints.MD} {
        margin-bottom: 16px;
      }
    `,

    title: css`
      margin: 120px 0 50px;
      color: ${Colors.LIGHT_1};
      font-size: 42px;
      font-weight: normal;
    `,

    tags: css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 310px;
    `,

    tag: css`
      display: block;
      margin: 0 10px 10px 0;
      padding: 15px 23px;
      letter-spacing: 0.16px;
      color: ${Colors.LIGHT_1};
      border-radius: 999px;
      background-color: rgb(253 253 253 / 18%);
      font-family: ${Fonts.Calibre};
      font-size: 16px;
      font-weight: normal;
    `,
  },

  images: {
    container: css`
      position: relative;
      display: grid;
      grid-template:
        'first first second fourth' 1fr
        'first first third fourth' 1fr
        / 1fr 38% 1fr 1fr;
      gap: 4px;

      ${Breakpoints.XL} {
        grid-template:
          'first first second fourth' 1fr
          'first first third fourth' 1fr
          / 1fr 1fr 1fr 1fr;
      }

      ${Breakpoints.LG} {
        grid-template:
          'first' 1fr
          / 1fr;
      }
      cursor: pointer;
    `,

    firstImage: (url?: string) => css`
      grid-area: first;
      max-width: 100%;

      height: 402px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      ${Breakpoints.SM} {
        height: 220px;
      }
    `,

    secondImage: (url?: string) => css`
      grid-area: second;

      height: 198.5px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    thirdImage: (url?: string) => css`
      grid-area: third;

      height: 198.5px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    fourthImage: (url?: string) => css`
      grid-area: fourth;

      height: 402px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    navigation: css`
      position: absolute;
      bottom: 20px;
      display: flex;
      width: 100%;
      gap: 10px;
      justify-content: center;
      align-items: center;
    `,

    button: css`
      width: 35px;
      height: 35px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 90.909px;
      background: rgba(0, 0, 0, 1);
      border: none;
      cursor: pointer;

      /* Small shadow */
      box-shadow: 0px -4px 40px 0px rgba(43, 43, 43, 0.1);
    `,
  },

  destination: {
    container: css`
      padding: 40px 0 40px 0px;
      max-width: 1200px;

      ${Breakpoints.XL} {
        padding-left: 20px;
      }

      ${Breakpoints.MD} {
        flex-direction: column;
        padding-top: 24px;
        margin-bottom: 20px;
      }
    `,

    wrapper: css`
      display: flex;
      justify-content: center;
    `,

    inner: css`
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      ${Breakpoints.MD} {
        margin-top: 16px;
      }
    `,

    colLeft: css`
      width: 55%;
      padding-right: 40px;

      ${Breakpoints.LG} {
        width: 100%;
        padding-left: 0;
      }
    `,

    colRight: css`
      width: 45%;
      padding-left: 24px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: ${Colors.LIGHT_GREY_2};
      }

      ${Breakpoints.LG} {
        width: 100%;
      }
    `,

    name: css`
      color: ${Colors.DARK_1};
    `,

    country: css`
      display: flex;
      align-items: center;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};

      svg {
        margin-right: 8px;
      }

      ${Breakpoints.LG} {
        position: absolute;
        background-color: ${Colors.LIGHT_1};
        z-index: 10;
        bottom: 12px;
        left: 20px;
        padding: 6px 10px;
        border-radius: 8px;

        box-shadow: 0px -4px 40px 0px rgba(43, 43, 43, 0.1);
      }

      ${Breakpoints.MD} {
        font-size: 14px;
      }
    `,

    description: css`
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};
      margin-top: 24px;

      ${Breakpoints.MD} {
        width: auto;
      }

      ${Breakpoints.SM} {
        margin-top: 16px;
      }
    `,

    images: css`
      width: 60%;

      ${Breakpoints.LG} {
        width: 40%;
      }

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,
  },

  destinationImages: css`
    display: flex;
    justify-content: center;
  `,

  destinationImage: css`
    width: 100%;
    max-width: 360px;
    height: 400px;
    margin-right: 20px;
    object-position: center;
    object-fit: cover;

    ${Breakpoints.MD} {
      max-width: 100%;
    }
  `,

  trip: {
    inner: {
      wrapper: css`
        display: flex;
      `,

      content: css``,
    },
  },

  keyPoints: {
    wrapper: css`
      position: relative;
    `,

    title: css`
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      margin-bottom: 24px;
    `,

    container: css`
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      ${Breakpoints.MD} {
        /* position: absolute; */
        /* width: 1000%; */
        /* flex-wrap: nowrap; */
      }
    `,

    item: css`
      font-size: 13px !important;
    `,
  },
}
