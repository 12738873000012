import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
  `,

  selectBlock: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 130px;
    max-width: 850px;
  `,

  selectedItems: css`
    margin-top: 18px;
  `,

  skip: {
    wrapper: css`
      max-width: 380px;
      margin-top: 86px;
    `,

    title: css`
      margin-bottom: 8px;
      letter-spacing: 0.2px;
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    `,

    description: css`
      margin-bottom: 19px;
      letter-spacing: 0.2px;
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 20px;
      line-height: 30px;
    `,
  },

  headingWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 36px 0 60px;
    text-align: center;

    p {
      max-width: 850px;
      ${Breakpoints.SM} {
        font-size: 16px;
        line-height: 19px;
      }
    }

    ${Breakpoints.SM} {
      margin: 24px 0 32px;
    }
  `,

  headingTitle: css`
    text-align: center;
    font-weight: 300;

    ${Breakpoints.SM} {
      font-size: 24px;
      font-weight: 400;
    }
  `,

  toggle: {
    wrapper: css`
      display: flex;
      margin-bottom: 32px;
      width: 100%;
      max-width: 850px;

      ${Breakpoints.SM} {
        margin-bottom: 24px;
      }
    `,
  },
}
