import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    gap: 8px;
  `,

  wrapper: (isActive?: boolean) => css`
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${isActive ? Colors.LIGHT_1 : 'transparent'};
    border: 1px solid ${!isActive ? Colors.LIGHT_GREY_1 : 'transparent'};
    box-shadow: ${isActive
      ? '0px 10px 20px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1)'
      : undefined};

    ${Breakpoints.SM} {
      min-width: 32px;
      min-height: 32px;
      padding: 6px;
    }
  `,

  completed: css`
    border: 1px solid ${Colors.PRIMARY_GREEN};
    cursor: pointer;
  `,

  disabled: css`
    cursor: not-allowed !important;
    filter: grayscale(80%);
  `,

  numberLabel: css`
    max-width: 16px;
    max-height: 16px;
  `,

  text: css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  `,

  step: {
    icon: css`
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    `,

    title: (isActive: boolean) => css`
      margin-left: ${isActive ? '6px' : '0px'};
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 16px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.36px;
    `,
  },
}
