import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ArrowLeftIcon: FC<Props> = ({
  width = 32,
  height = 32,
  color = '#009F9D',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6663 8H3.33301"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99968 12.6667L3.33301 8.00004L7.99968 3.33337"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
