import { invoke } from '@withease/factories'
import { createEvent, sample } from 'effector'
import { getHotelsFactory } from '~/entities/hotel/model/get-hotels'
import { manageHotelFactory } from '~/entities/hotel/model/manage-hotel'
import { manageHotelDetailsFactory } from '~/entities/hotel/model/manage-hotel-details'
import { atom } from '~/shared/lib/factory'

export const hotelsForTripModel = atom(() => {
  const resetAllState = createEvent()
  const $$getHotelsFactory = invoke(getHotelsFactory)
  const $$manageHotelFactory = invoke(manageHotelFactory, {
    $$getHotelsFactory,
  })
  const $$manageHotelDetailsFactory = invoke(manageHotelDetailsFactory)

  sample({
    clock: resetAllState,
    target: [
      $$getHotelsFactory.resetState,
      $$manageHotelFactory.resetState,
      $$manageHotelDetailsFactory.resetState,
    ],
  })

  return {
    resetAllState,

    $$getHotelsFactory,
    $$manageHotelFactory,
    $$manageHotelDetailsFactory,
  }
})
