import { sample } from 'effector'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { nextStepNavigated, $isUpdated, $initialSummary, $currentSummary, Gate } from './'

sample({
  clock: nextStepNavigated,
  source: {
    initialSummary: $initialSummary,
    summary: $currentSummary,
    isUpdated: $isUpdated,
  },
  fn: ({ initialSummary, summary, isUpdated }) => ({
    name: eventNames.completedSummaryStep,
    properties: {
      skipped: false,
      changed: isUpdated,
      initialSummary: initialSummary,
      summary: summary,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  fn: () => ({
    name: eventNames.quizSuggestionVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    isUpdated: $isUpdated,
  },
  filter: ({ isUpdated }) => isUpdated,
  fn: () => ({
    name: eventNames.quizSuggestionEdited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    initialSummary: $initialSummary,
    summary: $currentSummary,
    isUpdated: $isUpdated,
  },
  fn: ({ initialSummary, summary, isUpdated }) => ({
    name: eventNames.quizSuggestionCompleted,
    properties: {
      skipped: false,
      changed: isUpdated,
      initialSummary: initialSummary,
      summary: summary,
    },
  }),
  target: analyticsModel.track,
})
