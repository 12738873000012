import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  image?: string
  extendStyles?: SerializedStyles
}

export const Avatar: FC<Props> = ({ image, children, extendStyles, ...rest }) => (
  <div css={[styles.base(image), extendStyles]} {...rest}>
    {children}
  </div>
)
