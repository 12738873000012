import { css } from '@emotion/react'
import { dropdownIndicatorIcon } from '~/shared/assets/icons'

export const styles = {
  dropdownIndicator: css`
    width: 24px;
    height: 24px;
    background-image: url(${dropdownIndicatorIcon});
    background-size: 14px 8px;
    background-repeat: no-repeat;
    background-position: center;
  `,
}
