import { createStore, createEvent, sample, createEffect } from 'effector'
import { recommendationsLoader } from '~/shared/assets/loaders'

export const animationDataRequested = createEvent()

const getAnimationDataFx = createEffect(async () => {
  const res = await fetch(recommendationsLoader).then((response) => response.json())
  return res
})

export const $animationData = createStore<any>({})

sample({
  clock: animationDataRequested,
  target: getAnimationDataFx,
})

sample({
  clock: getAnimationDataFx.doneData,
  fn: (data) => data,
  target: $animationData,
})
