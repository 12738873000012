import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    ${Breakpoints.XL} {
      padding: 0 20px;
    }
  `,

  container: css`
    display: grid;
    grid-gap: 30px;
    position: relative;
    overflow: hidden;
    padding-bottom: 80px;
  `,

  title: css`
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 28px;

    span {
      font-weight: 500;
    }
  `,

  rooms: {
    heading: {
      wrapper: css`
        color: ${Colors.NEUTRAL_BLACK};
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.32px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
      `,

      num: css`
        margin-right: 12px;
      `,

      quests: css`
        display: flex;
        align-items: center;
        padding: 0 4px;
        svg {
          margin-right: 8px;
        }
      `,
    },

    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    notRooms: css`
      font-size: 25px;
      color: ${Colors.ERROR};
    `,
  },
}
