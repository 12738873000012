import { combine, createEvent, createStore, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'

import { LocalStorageKeys } from '~/shared/config'

import { DEFAULT_ROOM, DEFAULT_ADULT, DEFAULT_CHILD } from './config'
import { GUEST_TYPE, Room } from './types'

export const selectedDatesReset = createEvent()
export const stateReset = createEvent()

export const checkInChanged = createEvent<Date | string>()
export const checkOutChanged = createEvent<Date | string>()

export const roomAdded = createEvent()
export const roomRemoved = createEvent<number>()
export const adultAdded = createEvent<number>()
export const adultRemoved = createEvent<number>()
export const childAdded = createEvent<number>()
export const childRemoved = createEvent<number>()
export const ageAdded = createEvent<{
  age: number
  roomIndex: number
  childIndex: number
}>()

export const priceCategoryChoosed = createEvent<string>()

export const $checkIn = restore(checkInChanged, '')
export const $checkOut = restore(checkOutChanged, '')
export const $selectedTripDates = combine({
  startDate: $checkIn,
  endDate: $checkOut,
})

//TODO: think how to destructure tripInfo store
// initialize rooms with 1 room and 1 adult guest
export const $tripInfo = createStore<Room[]>([
  {
    adults: [
      {
        type: GUEST_TYPE.ADULT,
        name: '',
        surname: '',
        age: 0,
      },
    ],
    children: [],
  },
])

export const $roomsAmount = $tripInfo.map((rooms) => rooms.length)

export const $childrenAmount = $tripInfo.map((rooms) => {
  return rooms.reduce(
    (previous, current) =>
      current?.children?.length ? previous + current?.children?.length : previous,
    0,
  )
})

export const $childrenAges = $tripInfo.map((rooms) =>
  rooms.map(({ children }) => children.map((child) => child.age)).flat(),
)

export const $adultsAmount = $tripInfo.map((rooms) => {
  return rooms.reduce(
    (previous, current) =>
      current?.adults?.length ? previous + current?.adults?.length : previous,
    0,
  )
})

export const $choosedPriceCategory = restore(priceCategoryChoosed, 'any')

export const $selectedGuests = combine(
  $roomsAmount,
  $adultsAmount,
  $childrenAmount,
  (roomsAmount, adultsAmount, childrenAmount) =>
    `${roomsAmount} ${roomsAmount === 1 ? 'room' : 'rooms'},
     ${adultsAmount} ${adultsAmount === 1 ? 'adult' : 'adults'}, 
     ${childrenAmount} ${childrenAmount === 1 ? 'child' : 'children'}`,
)

// TODO: simplify these actions
$tripInfo
  .on(roomAdded, (rooms) => [...rooms, DEFAULT_ROOM])
  .on(roomRemoved, (rooms, indexForRemove) =>
    rooms.filter((_, index) => index !== indexForRemove),
  )
  .on(adultAdded, (rooms, targetIndex) =>
    rooms.map((room, index) =>
      index === targetIndex ? { ...room, adults: [...room.adults, DEFAULT_ADULT] } : room,
    ),
  )
  .on(childAdded, (rooms, targetIndex) =>
    rooms.map((room, index) =>
      index === targetIndex
        ? { ...room, children: [...room.children, DEFAULT_CHILD] }
        : room,
    ),
  )
  .on(adultRemoved, (rooms, index) => {
    rooms[index].adults.pop()
    return [...rooms]
  })
  .on(childRemoved, (rooms, index) => {
    rooms[index].children.pop()
    return [...rooms]
  })
  .on(ageAdded, (rooms, { age, roomIndex, childIndex }) =>
    rooms.map((room, index) =>
      index === roomIndex
        ? {
            ...room,
            children: room.children.map((child, index) =>
              index === childIndex ? { ...child, age } : child,
            ),
          }
        : room,
    ),
  )

persist({ store: $tripInfo, key: LocalStorageKeys.RoomsForBooking })
persist({ store: $checkIn, key: LocalStorageKeys.SelectedCheckInDate })
persist({ store: $checkOut, key: LocalStorageKeys.SelectedCheckOutDate })
persist({ store: $choosedPriceCategory, key: LocalStorageKeys.SelectedPriceCategory })

reset({
  clock: selectedDatesReset,
  target: [$checkIn, $checkOut],
})

reset({
  clock: stateReset,
  target: [$tripInfo, $checkIn, $checkOut, $choosedPriceCategory],
})
