import { OccupancyOptions } from '@softcery/awayaway-nodejs-api-client'
import { GuestType, Guest, GuestGender, GuestTitle } from '../types'

export function generateGuestsWithDetails(occupancyOptions: OccupancyOptions): Guest[] {
  const createGuest = (type: GuestType, age = 0): Guest => ({
    type,
    title: GuestTitle.Mr,
    gender: GuestGender.Male,
    dateOfBirth: '',
    age,
    firstName: '',
    lastName: '',
  })

  const adultGuests = Array.from({ length: occupancyOptions.adults }).map(() =>
    createGuest(GuestType.Adult),
  )
  const childGuests = Array.from({ length: occupancyOptions.children || 0 }).map((_, i) =>
    createGuest(GuestType.Child, occupancyOptions.childrenAges?.[i] || 0),
  )

  return [...adultGuests, ...childGuests]
}
