import { FC } from 'react'
import { CalendarIcon } from '~/shared/assets/icons/components'
import { formatToCustom, formatToDate } from '~/shared/lib/date'
import { styles } from './styles'

// input date format YYYY-MM-DD
interface Props {
  start: string
  end: string
}

const formatDate = (stringDate: string) => {
  const date = formatToDate(stringDate, 'YYYY-MM-DD')
  return formatToCustom(date, 'MMM DD')
}

export const DatesItem: FC<Props> = ({ start, end }) => {
  return (
    <div css={styles.container}>
      <CalendarIcon /> {formatDate(start)} - {formatDate(end)}
    </div>
  )
}
