import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  input: css`
    width: 100%;
    padding: 14px 60px 14px 20px;
    color: ${Colors.NEUTRAL_BLACK};
    border: 1px solid ${Colors.LIGHT_GREY_2};
    font-size: 18px;
    line-height: 20px;

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${Colors.NEUTRAL_GREY};
    }
  `,

  invalid: css`
    color: ${Colors.ERROR};
    border: 1px solid ${Colors.ERROR} !important;
  `,

  wrapper: css`
    position: relative;
  `,

  label: css`
    margin-bottom: 8px;
  `,
}
