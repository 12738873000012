import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    margin-top: 84px;
    min-height: calc(100vh - 84px);
    background-color: ${Colors.LIGHT_2};
  `,

  backToBookings: {
    wrapper: css`
      max-width: 1110px;
      margin: 0 auto;
    `,

    link: css`
      margin-bottom: 24px;
    `,
  },

  fullWidth: css`
    width: 100%;
  `,
}
