import { SerializedStyles } from '@emotion/react'
import { FC, Fragment, ReactNode } from 'react'
import { styles } from './styles'

interface Props {
  header: ReactNode
  extendStyles?: SerializedStyles
}

export const WithHeader: FC<Props> = ({ header, extendStyles, children }) => (
  <Fragment>
    {header}
    <div css={[styles.container, extendStyles]}>{children}</div>
  </Fragment>
)
