import { createEvent, restore, sample, createStore } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { destinationsListModel } from '~/features/get-destinations-list'
import { TripIdea } from '~/entities/trip'

export const DestinationDetailsGate = createGate<string>()

export const selectedDestinationsUpdated = createEvent<TripIdea | null>()

export const $currentDestinationCode = createStore<string>('')
export const $selectedDestination = restore(selectedDestinationsUpdated, null)

export const $loading = destinationsListModel.getDestinationByCodeFx.pending
export const $errorMessage = createStore('')

sample({
  clock: DestinationDetailsGate.open,
  target: $currentDestinationCode,
})

sample({
  clock: DestinationDetailsGate.open,
  source: destinationsListModel.$results,
  fn: (trips, destinationCode) =>
    trips.find((t) => t.destinationCode === destinationCode) || null,
  target: selectedDestinationsUpdated,
})

sample({
  clock: selectedDestinationsUpdated,
  source: $currentDestinationCode,
  filter: (_, value) => !value,
  fn: (code) => code,
  target: destinationsListModel.getDestinationInitiated,
})

sample({
  clock: destinationsListModel.destinationReceived,
  target: selectedDestinationsUpdated,
})

sample({
  clock: destinationsListModel.destinationReceivingFailed,
  target: $errorMessage,
})

reset({
  clock: [DestinationDetailsGate.open, selectedDestinationsUpdated],
  target: $errorMessage,
})
