import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  popupContent: {
    wrapper: css`
      padding: 24px;
      border-radius: 8px;
      background-color: ${Colors.LIGHT_1};
      max-width: 340px;
      text-align: center;
      position: relative;
    `,

    title: css`
      margin-bottom: 12px;
      font-size: 24px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    desc: css`
      margin-bottom: 24px;
      color: ${Colors.NEUTRAL_DARK};
      font-size: 18px;
    `,

    button: css`
      width: 100%;
    `,
  },
}
