import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: relative;
    max-width: 700px;
    display: flex;
    flex-direction: column;
  `,

  contentWrapper: css`
    position: relative;
    width: 100%;
    border: 1px solid ${Colors.PRIMARY_GREEN};
    border-top: none;

    ${Breakpoints.SM} {
      border: none;
    }
  `,

  specificCalendarWrapper: css`
    max-width: 628px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 28px;
  `,

  flexibleCalendarWrapper: css`
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 10px;
    margin-top: 32px;
    margin-bottom: 28px;
  `,

  label: css`
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_BLACK};

    margin-bottom: 12px;

    :not(:first-of-type) {
      margin-top: 28px;
    }
  `,

  select: css`
    margin-top: 28px;
  `,

  carousel: css`
    position: relative;
    max-width: calc(100% - 75px);
    margin: 0 auto;
  `,

  pastDate: css`
    height: 100%;
    width: 100%;
    background-color: white;
    color: #d1d1d1 !important;
  `,

  date: (isGrayedOut: boolean) => css`
    height: 100%;
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    justify-content: center;
    background-color: ${isGrayedOut ? 'white' : 'transparent'};
    color: ${isGrayedOut ? Colors.LIGHT_GREY_1 + `!important` : Colors.NEUTRAL_BLACK};
  `,
}
