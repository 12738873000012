import { sample } from 'effector'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { $isMultipleDestinations, Gate, nextStepNavigated } from './'

sample({
  clock: nextStepNavigated,
  source: {
    selectedLocations: selectLocationsModel.$selectedLocations,
    isMultipleDestinations: $isMultipleDestinations,
  },
  fn: ({ selectedLocations, isMultipleDestinations }) => ({
    name: eventNames.completedDestinationStep,
    properties: {
      selected_locations: selectedLocations.length
        ? selectedLocations.map(({ name }) => name)
        : 'not selected',
      destinations_type: isMultipleDestinations ? 'multiple' : 'single',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    selectedLocations: selectLocationsModel.$selectedLocations,
    isMultipleDestinations: $isMultipleDestinations,
  },
  fn: ({ selectedLocations, isMultipleDestinations }) => ({
    name: eventNames.quizDestinationsCompleted,
    properties: {
      selected_locations: selectedLocations.length
        ? selectedLocations.map(({ name }) => name)
        : 'not selected',
      destinations_type: isMultipleDestinations ? 'multiple' : 'single',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  source: selectLocationsModel.$selectedLocations,
  fn: (selectedLocations) => ({
    name: eventNames.quizDestinationsVisited,
    properties: {
      selected_locations: selectedLocations.length
        ? selectedLocations.map(({ name }) => name)
        : 'not selected',
    },
  }),
  target: analyticsModel.track,
})
