import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    background-color: #ffffff;
    border-radius: 6px;
    position: relative;
  `,

  header: css`
    padding: 16px;
    padding-bottom: 0;

    text-align: right;

    img {
      cursor: pointer;
    }
  `,

  main: css`
    position: relative;
    padding: 0 40px;
    width: 100%;

    ${Breakpoints.MD} {
      padding: 0 14px;
    }
  `,

  label: css`
    display: block;

    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};

    margin-bottom: 10px;

    margin-bottom: 30px;
  `,

  footer: {
    container: css`
      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
      padding: 25px 40px 32px 40px;

      ${Breakpoints.MD} {
        padding: 15px;
      }
    `,

    button: css`
      padding: 12px 18px;
      font-size: 16px;
    `,

    wrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: 10px;
    `,

    info: css`
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.MD} {
        font-size: 16px;
        line-height: 20px;
      }
    `,

    subtitle: css`
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: ${Colors.NEUTRAL_GREY};

      ${Breakpoints.MD} {
        font-size: 14px;
        line-height: 18px;
      }
    `,
    noAvailable: css`
      color: ${Colors.ERROR};
    `,
  },

  date: {
    date: (isSelected: boolean, isGrayedOut: boolean) => css`
      height: 22px;
      padding-top: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: ${isSelected
        ? Colors.NEUTRAL_WHITE
        : isGrayedOut
        ? Colors.NEUTRAL_GREY + `!important`
        : Colors.NEUTRAL_BLACK};
    `,
    inRange: css`
      height: 100%;
      width: 100%;
      padding-top: 3px;
      background-color: white;
      & div {
        color: #333 !important;
      }
    `,

    pastDate: css`
      height: 100%;
      width: 100%;
      padding-top: 3px;
      background-color: white;
      & div {
        color: #d1d1d1 !important;
      }
    `,

    price: (isSelected: boolean, isHide: boolean) => css`
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: ${isSelected ? Colors.NEUTRAL_WHITE : Colors.NEUTRAL_GREY};
      opacity: ${isHide ? 0 : 1};
      z-index: 3;
    `,

    tooltip: {
      container: css`
        position: relative;
        background-color: white;
        width: 100%;
        height: 100%;
        padding-top: 3px;
        &:hover > span {
          color: #333 !important;
          opacity: 1;
          z-index: 3;
          transform: translate(-25%, -100%);
        }
      `,

      content: css`
        font-size: 14px !important;
        position: absolute;
        opacity: 0;
        padding: 4px 4px;
        white-space: nowrap;
        top: 0;
        color: #333;
        border-radius: 4px;
        border: 1px solid ${Colors.NEUTRAL_SILVER};
        background: white;
        line-height: 100%;
        left: 0;

        /* tooltip arrow */
        &:after {
          content: '';
          position: absolute;
          left: 44%;
          bottom: -5px;
          height: 9px;
          width: 9px;
          background-color: #fff;
          transform: rotate(45deg);
          border-bottom: inherit;
          border-right: inherit;
          box-shadow: inherit;
        }
      `,
    },
  },

  error: css`
    margin-top: 12px;
  `,

  loadingLayer: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
  `,
}
