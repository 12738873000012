import { css } from '@emotion/react'

export const styles = {
  wrapper: css`
    position: relative;
  `,

  label: css`
    margin-bottom: 8px;
  `,
}
