import { FC } from 'react'
import { styles } from './styles'

interface Props {
  label: string
  onRemove: () => void
}

export const SelectedItem: FC<Props> = ({ label, onRemove }) => {
  return (
    <div css={styles.container}>
      <span css={styles.label}>{label}</span>

      <div css={styles.cross} onClick={onRemove} />
    </div>
  )
}
