import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'
import moonIcon from './moon.svg'

export const styles = {
  wrapper: (isSelected: boolean) => css`
    border-radius: 16px;
    border: 1.5px solid ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_SILVER};
    background: #fff;
    border-radius: 10px;
    padding: 20px;

    ${Breakpoints.MD} {
      padding: 12px;
    }
  `,

  tooltip: {
    container: css`
      z-index: 2;
      padding: 12px 16px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      opacity: 0.8;
      border-radius: 8px;
    `,

    title: css`
      margin-bottom: 12px;
      font-weight: 500;
    `,

    text: css`
      font-size: 16px;
      font-weight: 400;
      color: ${Colors.LIGHT_1};
    `,

    boldText: css`
      font-weight: 500;
    `,

    helper: css`
      margin-top: 16px;
      opacity: 0.75;
    `,
  },

  container: css``,

  imageWrapper: css`
    ${Breakpoints.MD} {
      width: 100%;
    }
  `,

  image: css`
    width: 240px;
    min-width: 240px;
    height: 160px;
    object-fit: cover;
    object-position: bottom center;
    margin-right: 42px;
    border-radius: 8px;
    background-color: ${Colors.NEUTRAL_SILVER};
    cursor: pointer;

    ${Breakpoints.MD} {
      width: 100%;
      max-height: 240px;
      min-width: unset;
      height: auto;
    }
  `,

  content: css`
    width: 100%;
    display: flex;
    align-items: flex-start;

    ${Breakpoints.MD} {
      flex-direction: column;
      position: relative;
    }
  `,

  title: css`
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.36px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 12px;
    cursor: pointer;

    ${Breakpoints.MD} {
      margin-top: 16px;
    }
  `,

  info: {
    wrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    `,
    container: css`
      width: 100%;
    `,

    beds: {
      wrapper: css`
        display: flex;
        gap: 12px;
        margin-bottom: 15px;
        flex-wrap: wrap;
      `,
      item: css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_GREY};

        svg {
          margin-right: 8px;
        }
      `,
    },

    facilities: {
      wrapper: css`
        display: flex;
        gap: 12px;
      `,

      item: css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};
        svg {
          margin-right: 8px;
        }
      `,
    },
  },

  meta: {
    sleeps: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: ${Colors.NEUTRAL_GREY};

      &:before {
        content: url(${moonIcon});
        margin: 2px 6px 0px 0px;
      }
    `,
  },

  detailsButton: css`
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    font-size: 16px;

    letter-spacing: 0.02em;
    color: ${Colors.NEUTRAL_GREY};
    cursor: pointer;
    text-decoration-line: underline;
    margin-top: 24px;
    margin-bottom: 24px;
  `,

  price: {
    wrapper: css`
      display: flex;
      align-items: center;
      gap: 45px;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    title: css`
      font-size: 20px;
      color: ${Colors.NEUTRAL_DARK};
      letter-spacing: 0.02em;
    `,

    value: css`
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;

      span {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};
      }
    `,

    total: css`
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_DARK};
    `,
  },

  rates: {
    row: css`
      margin-left: 282px;
      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
      padding-top: 16px;
      display: flex;
      gap: 20px;

      ${Breakpoints.MD} {
        flex-direction: column;
        margin-left: 0;
      }
    `,

    title: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};
      margin-bottom: 12px;
    `,

    col: css`
      width: 50%;

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,

    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    item: css`
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      svg {
        margin-right: 8px;
      }
    `,

    icon: css`
      margin-left: 8px;
    `,

    withoutChoice: {
      wrapper: css`
        margin-left: 282px;
        border-top: 1px solid ${Colors.NEUTRAL_SILVER};
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        ${Breakpoints.MD} {
          margin-left: 0;
        }
      `,

      board: css`
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};
      `,

      policy: css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};

        span {
          color: ${Colors.PRIMARY_GREEN};
          margin-right: 6px;
        }
      `,
    },

    mobilePrice: {
      wrapper: css`
        display: none;
        border-top: 1px solid ${Colors.NEUTRAL_SILVER};
        padding-top: 16px;
        margin-top: 16px;

        ${Breakpoints.MD} {
          display: flex;
          flex-direction: column;
        }
      `,
    },

    refundCost: css`
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_BLACK};
      margin: 0 5px;
    `,
    mobileItem: css`
      display: flex;
    `,

    mobileTooltip: {
      wrapper: css`
        display: none;

        ${Breakpoints.MD} {
          display: flex;
          align-items: center;
          height: 100%;
          max-width: 340px;
          gap: 5px;
        }
      `,

      top: css`
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        color: ${Colors.NEUTRAL_BLACK};
      `,

      cross: css`
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 5px;
      `,

      icon: css`
        margin-left: 6px;
      `,

      container: css`
        position: relative;
        background-color: ${Colors.LIGHT_1};
        max-width: 340px;
        padding: 16px;
        border-radius: 8px;
      `,

      button: (isPolicyDetailsVisible: boolean) => css`
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: ${Colors.NEUTRAL_DARK};

        span {
          display: flex;
          align-items: center;
          svg {
            transform: rotate(${isPolicyDetailsVisible ? '180deg' : '0deg'});
            transition: transform 0.3s;
            margin-left: 4px;
          }
        }
      `,

      content: css`
        margin-top: 10px;
        font-size: 16px;
        color: ${Colors.NEUTRAL_DARK};
      `,
    },

    desktopTooltip: {
      wrapper: css`
        display: flex;

        ${Breakpoints.MD} {
          display: none;
        }
      `,
    },
  },

  selectButton: (isSelected: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    border-radius: 100px;
    border: 1px solid ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_DARK};
    backdrop-filter: blur(1px);
    cursor: pointer;
    font-size: 16px;
    font-weight: ${isSelected ? '500' : '400'};
    letter-spacing: 0.32px;
    color: ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_DARK};
    background-color: ${Colors.LIGHT_1};
    span {
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }
  `,

  selectButtonMobile: (isSelected: boolean) => css`
    display: none;

    ${Breakpoints.MD} {
      display: flex;
      position: absolute;
      right: 12px;
      top: 12px;
      background: ${isSelected ? Colors.LIGHT_1 : 'rgba(52, 53, 55, 0.6)'};
      color: ${isSelected ? Colors.PRIMARY_GREEN : Colors.LIGHT_1};
      border: 1px solid ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_GREY};
      backdrop-filter: blur(1px);
    }
  `,
}
