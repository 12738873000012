import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ThumbsDownIcon: FC<Props> = ({
  width = 24,
  height = 24,
  color = '#555555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 1.66681H16.3917C16.8633 1.65846 17.3216 1.82358 17.6795 2.13082C18.0375 2.43806 18.2701 2.86602 18.3334 3.33347V9.16681C18.2701 9.63426 18.0375 10.0622 17.6795 10.3695C17.3216 10.6767 16.8633 10.8418 16.3917 10.8335H14.1667M8.33336 12.5001V15.8335C8.33336 16.4965 8.59675 17.1324 9.06559 17.6012C9.53443 18.0701 10.1703 18.3335 10.8334 18.3335L14.1667 10.8335V1.66681H4.76669C4.36475 1.66226 3.97471 1.80313 3.66844 2.06347C3.36216 2.3238 3.16029 2.68605 3.10003 3.08347L1.95003 10.5835C1.91377 10.8223 1.92988 11.0662 1.99724 11.2983C2.0646 11.5303 2.18161 11.7449 2.34014 11.9272C2.49868 12.1095 2.69496 12.2552 2.91538 12.3541C3.13581 12.4531 3.3751 12.5029 3.61669 12.5001H8.33336Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
