import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 50px;
  `,

  header: css`
    max-width: min(51vw, calc(100vw - 545px));
    ${Breakpoints.MD} {
      max-width: 100%;
    }
  `,

  headingWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 36px 0 60px;
    text-align: center;

    p {
      max-width: 640px;
      ${Breakpoints.SM} {
        font-size: 16px;
        line-height: 19px;
      }
    }

    ${Breakpoints.SM} {
      margin: 24px 0 32px;
    }
  `,

  headingTitle: css`
    text-align: center;
    font-weight: 300;

    ${Breakpoints.SM} {
      font-size: 24px;
      font-weight: 400;
    }
  `,

  modal: css`
    width: 1140px;
  `,

  iframeOverlay: {
    wrapper: css`
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    `,
    content: css`
      background-color: ${Colors.LIGHT_1};
      padding: 40px 28px;
      max-width: 700px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
    `,

    title: css`
      font-family: ${Fonts.Joane};
      font-size: 32px;
      line-height: 36px;

      ${Breakpoints.SM} {
        font-size: 24px;
        line-height: 30px;
      }
    `,

    desc: css`
      font-size: 18px;
      margin-top: 16px;
    `,

    button: css`
      border-radius: 40px;
      padding: 12px 20px;
      margin-top: 24px;

      svg {
        margin-left: 10px;
      }
    `,
  },
}
