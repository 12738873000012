import { FC } from 'react'
import { styles } from './styles'

interface Props {
  onSelect: () => void
  isActive?: boolean
  isDisabled?: boolean
}

export const Option: FC<Props> = ({ children, onSelect, isActive, isDisabled }) => {
  return (
    <div
      onClick={isDisabled ? undefined : onSelect}
      css={styles.container(isActive, isDisabled)}
    >
      {children}
    </div>
  )
}
