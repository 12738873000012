import { Props as PlusMinusVerticalIconProps } from '~/shared/assets/icons/components/plus-minus-vertical'

export type Tab = 1 | 2

export enum DaysNearbyOption {
  EXACT_DATES = 0,
  ONE_DAY = 1,
  THREE_DAYS = 3,
  FIVE_DAYS = 5,
}

export enum StayDurationOption {
  ONLY_WEEKEND = 0,
  WEEK = 7,
  TWO_WEEKS = 14,
  CUSTOM = 1,
}

export interface MonthAndYearOption {
  month: number
  year: number
}

export interface TabBarOption<OptionType> {
  label: string
  option: OptionType
  Icon?: React.FC<PlusMinusVerticalIconProps>
}

export interface SelectOption {
  label: string
  value: number
}

export interface Range {
  startDate?: Date
  endDate?: Date
  key?: string
}
