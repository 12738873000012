import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  title: css`
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 30px;
  `,
  subtitle: css`
    width: 380px;
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 32px;
  `,

  content: css`
    margin-top: 40px;
  `,
  input: css`
    margin-bottom: 20px;
  `,

  button: css`
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  `,

  errorContainer: css`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  `,
}
