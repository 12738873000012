import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    cursor: pointer;
    border-radius: 12px;
    outline: 1px solid ${Colors.LIGHT_GREY_2};
    background-color: ${Colors.NEUTRAL_WHITE};

    ${Breakpoints.SM} {
      padding: 12px;
    }
  `,

  selected: css`
    transition: all ease-in 0.1s;
    outline: 2px solid ${Colors.PRIMARY_GREEN};
  `,

  symbol: css`
    display: block;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    font-weight: 400;
  `,

  segment: css`
    margin-top: 12px;
    margin-bottom: 5px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    ${Breakpoints.SM} {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  `,

  price: css`
    letter-spacing: 0.2px;
    color: #7b7b7b;
    font-size: 18px;
  `,

  greenColor: css`
    color: ${Colors.PRIMARY_GREEN};
  `,
}
