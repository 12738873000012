import { OnPayloadReadyMetadata } from '@duffel/components/types/DuffelAncillariesProps'
import { CalculateService, ServiceOptionType } from '../types'

export const convertToServiceAddOptionObj = (
  data: OnPayloadReadyMetadata,
): CalculateService => {
  const baggage = data.baggage_services.reduce(
    (total, service) => {
      const totalBaggagePrice =
        total.totalBaggagePrice + parseFloat(service.serviceInformation.total_amount)
      const totalCount = total.totalCount + service.quantity
      return { totalBaggagePrice, totalCount }
    },
    { totalBaggagePrice: 0, totalCount: 0 },
  )

  const seat = data.seat_services.reduce(
    (total, service) => {
      const totalSeatPrice =
        total.totalSeatPrice + parseFloat(service.serviceInformation.total_amount)
      const totalCount = total.totalCount + service.quantity
      return { totalSeatPrice, totalCount }
    },
    { totalSeatPrice: 0, totalCount: 0 },
  )

  return {
    baggage,
    seat,
    totalCount: baggage.totalBaggagePrice + seat.totalSeatPrice,
  }
}

export const changeSelectedServiceOption = ({
  newSelected,
  oldSelected,
  clickedButtonTypeOption,
}: {
  newSelected: CalculateService
  oldSelected: CalculateService
  clickedButtonTypeOption: ServiceOptionType | null
}): CalculateService => {
  const changedSelectedOption: Partial<CalculateService> = {}

  if (clickedButtonTypeOption === ServiceOptionType.BAGGAGE) {
    changedSelectedOption.baggage = newSelected.baggage
  } else if (oldSelected.baggage) {
    changedSelectedOption.baggage = oldSelected.baggage
  }

  if (clickedButtonTypeOption === ServiceOptionType.SEAT) {
    changedSelectedOption.seat = newSelected.seat
  } else if (oldSelected.seat) {
    changedSelectedOption.seat = oldSelected.seat
  }

  const totalCount =
    (changedSelectedOption.baggage?.totalBaggagePrice || 0) +
    (changedSelectedOption.seat?.totalSeatPrice || 0)
  return { ...changedSelectedOption, totalCount } as CalculateService
}
