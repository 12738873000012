import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    ${Breakpoints.XL} {
      padding: 0 20px;
    }
  `,

  container: css`
    display: grid;
    grid-gap: 30px;
    position: relative;
    overflow: hidden;
    padding-bottom: 80px;
  `,

  title: css`
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 28px;

    span {
      font-weight: 500;
    }
  `,

  rooms: {
    heading: {
      wrapper: css`
        color: ${Colors.NEUTRAL_BLACK};
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.32px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
      `,

      num: css`
        margin-right: 16px;
        font-size: 16px;
        background-color: ${Colors.PRIMARY_GREEN};
        color: ${Colors.LIGHT_1};
        padding: 6px 10px;
        border-radius: 6px;
      `,

      quests: css`
        display: flex;
        align-items: center;
        padding: 0 4px;
        svg {
          margin-right: 8px;
        }
      `,
    },

    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,
  },

  viewButton: (viewMore: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;
    color: ${Colors.NEUTRAL_BLACK};
    cursor: pointer;

    svg {
      margin-left: 4px;
      transition: all 0.3s;
      transform: rotate(${viewMore ? 180 : 0}deg);
    }
  `,
}
