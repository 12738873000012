import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { searchFlightsModel } from '~/features/search-flights/model'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { CancellationPolicy, HotelBookingSummary } from '~/entities/booking'
import { BookingSummaryGate } from '../../model'

export const SummaryPage: FC = () => {
  useGate(BookingSummaryGate)

  const tripBooking = useStore(createBookingModel.$tripBooking)
  const flightsType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )

  if (!tripBooking) return null

  const cancellationPolicyDetails = (
    <CancellationPolicy
      policies={[]}
      currency={'GBP'}
      isStrict={tripBooking.hotel?.cancellationType === 'strict'}
    />
  )

  return (
    <HotelBookingSummary
      flightsType={flightsType}
      cancellationPolicyDetails={cancellationPolicyDetails}
    />
  )
}
