import { createStore, createEvent, sample, attach } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { HolidayImage, listImageRecommendationsModel } from '~/entities/holiday'

import { onboardingSessionModel } from '~/entities/onboarding-session'
import { LocalStorageKeys } from '~/shared/config'
import { DISPLAYED_IMAGES_LIMIT, SIMILAR_IMAGES_NUMBER } from './config'
import { filterDuplicates, isCountryCodesChanged } from './lib'

export const Gate = createGate<{ countryCodes: string[] }>()

export const imageIdSelected = createEvent<number>()
export const imageIdRemoved = createEvent<number>()
export const imagesAdded = createEvent<HolidayImage[]>()

export const getImageCategoriesInitiated = createEvent()
export const imageCategoriesReceived = createEvent<string[]>()
export const selectionReset = createEvent()
export const stateReset = createEvent()

const getInitialImagesFx = attach({
  effect: listImageRecommendationsModel.getHolidaysRecommendationsFx,
})

const getImagesCategoriesFx = attach({
  effect: listImageRecommendationsModel.getHolidaysRecommendationsFx,
})

const getSimilarImagesFx = attach({
  effect: listImageRecommendationsModel.getHolidaysRecommendationsFx,
})

export const $defaultImages = createStore<HolidayImage[]>([])
persist({ store: $defaultImages, key: LocalStorageKeys.DefaultImages })

export const $imagesLimit = createStore(DISPLAYED_IMAGES_LIMIT)
export const $similarImages = createStore<HolidayImage[]>([])
export const $isLimitReached = createStore(false)

export const $displayedImages = createStore<HolidayImage[]>([])
persist({ store: $displayedImages, key: LocalStorageKeys.HolidaysList })

export const $selectedImageIds = createStore<number[]>([])
persist({ store: $selectedImageIds, key: LocalStorageKeys.SelectedImages })

export const $imageLocationCodes = createStore<string[]>([])
persist({ store: $imageLocationCodes, key: LocalStorageKeys.ImageLocationCodes })

// we use this store to keep track of images that were selected at least once
const $selectedImageIdsHistory = createStore<number[]>([])
persist({ store: $selectedImageIdsHistory, key: LocalStorageKeys.SelectedImagesHistory })

export const $loading = getInitialImagesFx.pending
export const $error = createStore('')

sample({
  clock: Gate.open,
  source: { imageLocationCodes: $imageLocationCodes, defaultImages: $defaultImages },
  filter: ({ imageLocationCodes: oldCodes, defaultImages }, { countryCodes: newCodes }) =>
    isCountryCodesChanged(oldCodes, newCodes) || !defaultImages.length,
  fn: (_, { countryCodes }) => ({ selectedCountryCodes: countryCodes }),
  target: getInitialImagesFx,
})

sample({
  clock: Gate.open,
  source: $imageLocationCodes,
  filter: (oldCodes, { countryCodes: newCodes }) =>
    isCountryCodesChanged(oldCodes, newCodes),
  target: selectionReset,
})

sample({
  clock: Gate.open,
  fn: ({ countryCodes }) => countryCodes,
  target: $imageLocationCodes,
})

sample({
  clock: getInitialImagesFx.doneData,
  fn: (res) => res.images || [],
  target: [$defaultImages, $displayedImages],
})

// get similar images for those that weren't selected before at least once
sample({
  clock: imageIdSelected,
  source: $selectedImageIdsHistory,
  filter: (selectedImageIdsHistory, selectedId) =>
    !selectedImageIdsHistory.includes(selectedId),
  fn: (_, selectedImage) => ({ selectedImages: [selectedImage] }),
  target: getSimilarImagesFx,
})

//Select images
sample({
  clock: imageIdSelected,
  source: $selectedImageIds,
  fn: (ids, selectedId) => [...ids, selectedId],
  target: [$selectedImageIds, $selectedImageIdsHistory],
})

sample({
  clock: imageIdRemoved,
  source: $selectedImageIds,
  fn: (ids, removedId) => ids.filter((id) => id !== removedId),
  target: $selectedImageIds,
})

//Update when categories are assigned to all of them
sample({
  clock: [$displayedImages, $imagesLimit],
  source: { displayedImages: $displayedImages, limitNumber: $imagesLimit },
  fn: ({ displayedImages, limitNumber }) => !(displayedImages.length < limitNumber),
  target: $isLimitReached,
})

sample({
  clock: getSimilarImagesFx.doneData,
  source: $displayedImages,
  fn: (displayedImages, res) =>
    res?.images
      ? filterDuplicates(displayedImages, res?.images).slice(0, SIMILAR_IMAGES_NUMBER)
      : [],
  target: $similarImages,
})

//Update images list
sample({
  clock: imagesAdded,
  target: $displayedImages,
})

//Get Selected Images Categories
sample({
  clock: getImageCategoriesInitiated,
  source: $selectedImageIds,
  fn: (selectedImages) => ({ selectedImages }),
  target: getImagesCategoriesFx,
})

sample({
  clock: getImagesCategoriesFx.doneData,
  filter: ({ categories }) => Boolean(categories),
  fn: ({ categories }) => categories!,
  target: imageCategoriesReceived,
})

// Error handling
sample({
  clock: getInitialImagesFx.failData,
  fn: (res) => res.message,
  target: $error,
})

//Reset
reset({
  clock: getInitialImagesFx.done,
  target: $error,
})

sample({
  clock: selectionReset,
  target: onboardingSessionModel.scrollToTopFx,
})

sample({
  clock: selectionReset,
  source: $defaultImages,
  target: $displayedImages,
})

reset({
  clock: selectionReset,
  target: [$selectedImageIdsHistory, $selectedImageIds],
})

reset({
  clock: stateReset,
  target: [
    $selectedImageIds,
    $selectedImageIdsHistory,
    $imageLocationCodes,
    $displayedImages,
    $defaultImages,
    $error,
  ],
})
