import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 32px;

    background-color: ${Colors.NEUTRAL_WHITE};
    padding: 32px;
    border-radius: 10px;
    width: 604px;
  `,

  crossIcon: css`
    width: 16px;
    height: 16px;
    display: block;
    float: right;
    cursor: pointer;
  `,

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
  `,

  offerHeader: css`
    display: flex;
    justify-content: space-between;
  `,

  editButton: css`
    width: 61px;
    height: 29px;

    border-radius: 6px;
    border: 1px solid ${Colors.NEUTRAL_STONE};
    background: ${Colors.NEUTRAL_WHITE};

    color: ${Colors.NEUTRAL_DARK};
    font-size: 18px;
    font-weight: 400;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    &:focus {
      background-color: transparent;
      opacity: 0.7;
    }
  `,

  offer: css`
    margin-top: 12px;
    box-shadow: 0px 0.2963px 3.42361px 0px rgba(0, 0, 0, 0.01),
      0px 1.3037px 7.08889px 0px rgba(0, 0, 0, 0.01),
      0px 3.2px 14.1375px 0px rgba(0, 0, 0, 0.01),
      0px 6.16296px 27.71111px 0px rgba(0, 0, 0, 0.02),
      0px 10.37037px 50.95139px 0px rgba(0, 0, 0, 0.02),
      0px 16px 87px 0px rgba(0, 0, 0, 0.03);
  `,

  controls: css`
    display: flex;
    gap: 24px;
  `,

  totalCost: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    align-self: flex-end;

    span {
      font-weight: 500;
    }
  `,

  backButton: css`
    width: 50%;
    height: 58px;
    background-color: transparent;
    border: 1px solid ${Colors.NEUTRAL_STONE};
    color: ${Colors.NEUTRAL_BLACK};

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    &:focus {
      background-color: transparent;
      opacity: 0.7;
    }
  `,

  confirmButton: css`
    width: 50%;
    height: 58px;
  `,
}
