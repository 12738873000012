import { useStore } from 'effector-react'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { searchFlightsModel } from '~/features/search-flights/model'
import { navigationModel } from '~/entities/navigation'
import { STEPS, STEP_NUMBERS } from '../../config'
import { $currentStep, tripInfoStepOpened } from '../../model'
import { CompletedStep } from './completed-step'
import { Step } from './step'
import { styles } from './styles'

export const Steps: FC = () => {
  const { pathname } = useLocation()

  const currentStep = useStore($currentStep)

  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )

  const to = (path: string) => {
    const currentPath = pathname.split('/')
    currentPath.pop()

    return navigationModel.pathChanged(`${currentPath.join('/')}/${path}`)
  }

  const isActive = (path: string) => currentStep === path
  const isCompleted = (step: STEPS) => STEP_NUMBERS[currentStep] > STEP_NUMBERS[step]
  const isDisabled = (step: STEPS) => !isCompleted(step)

  return (
    <div css={styles.container}>
      <button
        css={[
          styles.step(isActive(STEPS.TRIP_INFO)),
          isCompleted(STEPS.TRIP_INFO) && styles.stepDone,
        ]}
        onClick={() => tripInfoStepOpened()}
      >
        <CompletedStep />
        Trip selection
      </button>

      <div css={[styles.line, styles.lineDone]}></div>
      <button
        css={[
          styles.step(isActive(STEPS.CONTACTS)),
          isCompleted(STEPS.CONTACTS) && styles.stepDone,
        ]}
        onClick={() => to(STEPS.CONTACTS)}
        disabled={isDisabled(STEPS.CONTACTS)}
      >
        <div css={styles.item.num}>
          {isCompleted(STEPS.CONTACTS) ? (
            <CompletedStep />
          ) : (
            <Step number={2} isActive={isActive(STEPS.CONTACTS)} />
          )}
        </div>
        Contact details
      </button>

      <div css={[styles.line, isCompleted(STEPS.CONTACTS) && styles.lineDone]}></div>

      {isFlightsIncluded && (
        <>
          <button
            css={[
              styles.step(isActive(STEPS.FLIGHTS)),
              isCompleted(STEPS.FLIGHTS) && styles.stepDone,
            ]}
            onClick={() => to(STEPS.FLIGHTS)}
            disabled={isDisabled(STEPS.FLIGHTS)}
          >
            <div css={styles.item.num}>
              {isCompleted(STEPS.FLIGHTS) ? (
                <CompletedStep />
              ) : (
                <Step number={3} isActive={isActive(STEPS.FLIGHTS)} />
              )}
            </div>
            Flight Details
          </button>

          <div css={[styles.line, isCompleted(STEPS.FLIGHTS) && styles.lineDone]}></div>
        </>
      )}

      <button
        css={styles.step(isActive(STEPS.PAYMENT))}
        disabled={isDisabled(STEPS.PAYMENT)}
      >
        <div css={styles.item.num}>
          <Step number={isFlightsIncluded ? 4 : 3} isActive={isActive(STEPS.PAYMENT)} />
        </div>
        Payment
      </button>
    </div>
  )
}
