import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { Dimensions } from '~/shared/styles'
import { styles } from './styles'

interface Props {
  Icon: React.ComponentType
  isLast?: boolean
}

export const ItineraryFragmentSkeleton: FC<Props> = ({ Icon, isLast }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Dimensions.Md}px)` })

  return (
    <div css={styles.wrapper}>
      <div css={styles.timeline}>
        <div css={styles.icon}>
          <Icon />
        </div>

        {!isLast && <div css={styles.line} />}
      </div>

      {!isMobile && (
        <div css={styles.container}>
          <Skeleton css={styles.title} />

          <Skeleton width={399} css={styles.row} />
          <Skeleton width={501} css={styles.row} />
          <Skeleton width={538} css={styles.row} />
        </div>
      )}

      {isMobile && (
        <div css={styles.container}>
          <Skeleton css={styles.title} />

          <Skeleton width={198} css={styles.row} />
          <Skeleton width={239} css={styles.row} />
          <Skeleton width={260} css={styles.row} />
          <Skeleton width={142} css={styles.row} />
          <Skeleton width={195} css={styles.row} />
          <Skeleton width={165} css={styles.row} />
        </div>
      )}
    </div>
  )
}
