export interface CalculateService {
  baggage: {
    totalBaggagePrice: number
    totalCount: number
  }
  seat: {
    totalSeatPrice: number
    totalCount: number
  }
  totalCount: number
}

export enum ServiceOptionType {
  BAGGAGE = 'baggage',
  SEAT = 'seat',
}
