import mixpanel from 'mixpanel-browser'
import { setWindowProperty } from '~/shared/lib/set-window-property'
import { Analytics, Properties } from './types'

const init = (token: string) => {
  try {
    mixpanel.init(token, { api_host: process.env.AA_MIXPANEL_PROXY_URL })
    setWindowProperty('mixpanel', mixpanel)
  } catch (e) {
    console.log('MixPanel initialization error: ', e)
  }
}

const identify = (id: string, properties?: Properties) => {
  try {
    mixpanel.identify(id)

    if (properties) {
      mixpanel.people.set(properties)
    }
  } catch (e) {
    console.error('Failed to identify - ', id)
  }
}

const trackEvent = (name: string, properties?: Properties) => {
  try {
    mixpanel.track(name, properties)
  } catch (e) {
    console.log('MixPanel track event error: ', e)
  }
}

const trackPage = (properties?: Properties) => trackEvent('Page Viewed', properties)

const reset = () => {
  try {
    mixpanel.reset()
  } catch (e) {
    console.log('MixPanel reset error: ', e)
  }
}

export const mixpanelAnalytics: Analytics = {
  name: 'mixpanel',
  init,
  identify,
  trackEvent,
  trackPage,
  reset,
}
