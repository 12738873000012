import { createEvent, sample } from 'effector'
import { not, or } from 'patronum'

import { searchFlightsModel } from '~/features/search-flights/model'
import { addContactInfoModel } from '~/features/trip-booking/add-contact-info'
import { addGuestsInfoModel, DocumentType } from '~/features/trip-booking/add-guests-info'

import { hotelRoomModel } from '~/entities/room'
import { analyticsModel, eventNames } from '~/shared/api/analytics'

export const nextPageInitiated = createEvent()

export const pageOpened = createEvent()
export const pageCompleted = createEvent()

sample({
  clock: pageOpened,
  target: addContactInfoModel.contactDetailsFormInit,
})
export const $contactDetailsHasValidationError = or(
  not(addContactInfoModel.$isContactFormValid),
  addGuestsInfoModel.$hasError,
)
sample({
  clock: pageOpened,
  source: {
    flightOffer: searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
    selectedRooms: hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms,
  },
  fn: ({ flightOffer, selectedRooms }) => {
    return {
      selectedRooms,
      availableDocumentTypes: flightOffer?.passengerIdentityDocumentsRequired
        ? (flightOffer?.supportedPassengerIdentityDocuments as DocumentType[])
        : [],
    }
  },
  target: addGuestsInfoModel.guestDetailsFormInit,
})

sample({
  clock: nextPageInitiated,
  target: [
    addContactInfoModel.validationInitiated,
    addGuestsInfoModel.validationInitiated,
  ],
})

sample({
  clock: addGuestsInfoModel.validationCompleted,
  source: {
    isContactFormValid: addContactInfoModel.$isContactFormValid,
    isGuestFormValid: addGuestsInfoModel.$isAllGuestFormsValid,
  },
  filter: ({ isContactFormValid, isGuestFormValid }) =>
    isContactFormValid && isGuestFormValid,
  target: pageCompleted,
})

// Analytics
sample({
  clock: pageOpened,
  fn: () => ({
    name: eventNames.bookingContactsVisited,
  }),
  target: analyticsModel.track,
})
