import { FC, useMemo, useRef } from 'react'
import { DateRange, DateRangeProps, Range } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import './DateRangePicker.css' // custom styles
import { isMobile } from 'react-device-detect'

import { addDaysToDate } from '~/shared/lib/date'

import { disablePrevButtonOnShownDateChange } from './lib'
import { usePrevButtonDisable } from './usePrevButtonDisable'

interface Props extends DateRangeProps {
  range: Range
  minDate?: Date
}

export const Calendar: FC<Props> = ({ range, minDate, onShownDateChange, ...props }) => {
  const memomizedRanges = useMemo(() => [range], [range])
  const minViewedDate = minDate || addDaysToDate(new Date(), 1)

  const ref = useRef<DateRange>(null)

  usePrevButtonDisable(range.startDate, minViewedDate)

  return (
    <DateRange
      ref={ref}
      preventSnapRefocus={true}
      ranges={memomizedRanges}
      months={isMobile ? 1 : 2}
      minDate={minViewedDate}
      direction="horizontal"
      className={'calendarWrapper'}
      weekdayDisplayFormat={'EEEEEE'}
      monthDisplayFormat={'MMMM yyyy'}
      showMonthAndYearPickers={false}
      moveRangeOnFirstSelection={false}
      showDateDisplay={false}
      onShownDateChange={(date) => {
        disablePrevButtonOnShownDateChange(date, minViewedDate)

        onShownDateChange?.(date)
      }}
      {...props}
    />
  )
}
