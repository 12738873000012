import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { CancelBookingSidebar, searchBookingModel } from '~/entities/booking'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'
import { bookingCancelPageModel } from '../model'
import { BookingCancellationRequest } from './booking-cancellation-request'

export const BookingCancelPage: FC = () => {
  const { bookingId } = useParams()

  useGate(bookingCancelPageModel.BookingCancelGate, Number(bookingId))

  const booking = useStore(searchBookingModel.$$getBookingFactory.$bookedTripDetails)

  const paidTransaction = booking?.paymentTransactions?.find((t) => t?.status === 'PAID')

  const totalPrice = paidTransaction?.amount || 0
  const currency = paidTransaction?.paymentCurrency || ''

  const rooms: { name: string }[] = booking?.hotel?.packages
    ? booking?.hotel?.packages?.map((p): { name: string } => ({ name: p.name || '' }))
    : []

  const flightDetails =
    booking?.flight && booking?.flight.segments
      ? {
          originName: booking.flight.segments[0]?.origin?.iataCode || '',
          destinationName: booking.flight.segments[0]?.destination?.iataCode || '',
          type: booking.flight.segments[0]?.fareClass || '',
        }
      : undefined

  return (
    <WithSidebar
      sidebarContent={
        <CancelBookingSidebar
          selectedRooms={[]}
          hotelImage={booking?.hotel?.hotelImages?.[0] || ''}
          hotelName={booking?.hotel?.hotelName}
          destinationName={booking?.hotel?.hotelDestinationName}
          checkIn={booking?.hotel?.checkIn}
          checkOut={booking?.hotel?.checkOut}
          rooms={rooms}
          totalPrice={totalPrice}
          currency={currency}
          flights={flightDetails}
        />
      }
      main={<BookingCancellationRequest />}
      position="right"
    />
  )
}
