import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    width: max-content;

    display: flex;
    align-items: center;
    gap: 10px;

    padding: 6px 10px;
    border-radius: 6px;

    background-color: ${Colors.NEUTRAL_LIGHT};

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
  `,
}
