import { Root, Item, Indicator } from '@radix-ui/react-radio-group'
import { FC } from 'react'
import { Label } from '../../atoms'
import { styles } from './styles'

type Value = string

interface RadioItem {
  value: Value
  label: string
}

interface Props {
  value: Value
  onChange: (value: Value) => void
  items: RadioItem[]
  defaultValue?: Value
  label?: string
  disabled?: boolean
}

export const RadioGroup: FC<Props> = ({
  value,
  onChange,
  items,
  defaultValue,
  disabled,
}) => {
  const handleItemClick = (value: Value) => {
    if (!disabled) {
      onChange(value)
    }
  }

  return (
    <Root
      defaultValue={defaultValue}
      value={value}
      onValueChange={onChange}
      disabled={disabled}
      css={styles.wrapper}
    >
      <div css={styles.container}>
        {items.map(({ value, label }) => (
          <div
            key={value}
            css={styles.itemWrapper(disabled)}
            onClick={() => handleItemClick(value)}
          >
            <Item css={styles.item(disabled)} value={value}>
              <Indicator css={styles.indicator} />
            </Item>
            <Label>{label}</Label>
          </div>
        ))}
      </div>
    </Root>
  )
}
