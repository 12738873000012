import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  nav: {
    wrapper: (isSticky: boolean) => css`
      display: ${isSticky ? 'flex' : 'none'};
      width: 100%;
      height: 70px;
      background-color: ${Colors.LIGHT_1};
      justify-content: center;
      align-items: flex-end;
      z-index: 10;
      position: ${isSticky && 'fixed'};
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    `,

    container: css`
      max-width: 1200px;
      width: 100%;
      display: flex;
      border-bottom: 1px solid ${Colors.LIGHT_GREY_2};
    `,

    item: css`
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};
      padding: 12px 20px;
      cursor: pointer;

      ${Breakpoints.SM} {
        font-size: 16px;
      }
    `,

    activeItem: css`
      font-weight: 500;
      color: ${Colors.PRIMARY_GREEN};
      border-bottom: 2px solid ${Colors.PRIMARY_GREEN};
    `,
  },
}
