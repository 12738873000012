import { sample } from 'effector'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { saveProfileModel } from './core'

sample({
  clock: saveProfileModel.saveProfileFx.doneData,
  fn: () => ({
    name: eventNames.signUp,
  }),
  target: analyticsModel.track,
})

sample({
  clock: saveProfileModel.saveProfileFx.doneData,
  fn: () => ({
    name: eventNames.quizAuthCompleted,
    properties: {
      type: 'email',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: saveProfileModel.saveProfileFx.done,
  fn: ({ params }) => ({
    properties: {
      email: params.email,
      full_name: params.fullName,
    },
  }),
  target: analyticsModel.trackConversion,
})
