import { SerializedStyles } from '@emotion/react'
import { useStore } from 'effector-react'
import { FC } from 'react'
import { STEPS } from '~/pages/booking/config'
import { $currentStep } from '~/pages/booking/model'
import { SelectedHotelRoom } from '~/entities/room'
import { calendarIcon as fadedCalendarIcon } from '~/shared/assets/icons/grey'
import { calendarIcon, usersIcon } from '~/shared/assets/icons/primary'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { normalizeStringCase } from '~/shared/lib/string'
import { Image } from '~/shared/ui'
import { formatBookingDates, getRoomsLabelForCancelBookingSidebar } from '../../lib'
import { SidebarInfoTile } from '../sidebar-info-tile'
import { styles } from './styles'

interface Props {
  hotelImage?: string
  hotelName?: string
  destinationName?: string
  checkIn?: string | Date
  checkOut?: string | Date

  rooms: { name: string }[]
  guests?: {
    adults: number
    children: number
    rooms: number
  }
  isFlightIncluded?: boolean

  totalPrice: number
  currency?: string

  selectedRooms: SelectedHotelRoom[]

  newDetails?: {
    newCheckIn?: Date
    newCheckOut?: Date
    newTotalPrice?: number
  }

  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }

  flights?: {
    destinationName: string
    originName: string
    type: string
  }
}

export const CancelBookingSidebar: FC<Props> = ({
  hotelImage,
  hotelName,
  destinationName,
  checkIn,
  checkOut,
  rooms,
  currency = '',
  guests,
  selectedRooms,
  extendStyles,
  isFlightIncluded,
  totalPrice,
  flights,
  newDetails,
}) => {
  const { newCheckIn, newCheckOut } = newDetails || {}
  const currentStep = useStore($currentStep)

  const isPaymentStep = currentStep === STEPS.PAYMENT

  return (
    <div>
      {!isPaymentStep && (
        <div css={styles.container}>
          <Image
            image={hotelImage}
            desiredSize={'bigger'}
            extendStyle={styles.image}
            key={hotelImage}
          />

          <h1 css={styles.title}>{destinationName || '-'}</h1>

          {rooms && (
            <p css={styles.subtitle}>{getRoomsLabelForCancelBookingSidebar(rooms)}</p>
          )}

          <div css={styles.infoTile}>
            <SidebarInfoTile
              icon={newDetails ? fadedCalendarIcon : calendarIcon}
              title={newDetails ? 'Original dates' : 'Dates'}
              value={formatBookingDates(checkIn, checkOut)}
              faded={!!newDetails}
            />

            {newDetails && (
              <SidebarInfoTile
                icon={calendarIcon}
                title="New dates"
                value={formatBookingDates(newCheckIn, newCheckOut)}
              />
            )}
          </div>

          {guests && (
            <div css={styles.infoTile}>
              <SidebarInfoTile
                icon={usersIcon}
                title={'Guests'}
                value={`${guests.adults} Adults${
                  guests.children > 0 ? `, ${guests.children} Children ` : ''
                } `}
              />
            </div>
          )}

          <div css={styles.infoTile}>
            <SidebarInfoTile
              editable={false}
              icon={usersIcon}
              title={'Hotel'}
              value={`${hotelName} `}
            />
          </div>

          {/* {selectedRooms && (
            <div css={styles.infoTile}>
              <div>
                <SidebarInfoTile editable={false} icon={moonIcon} title={'Rooms'} />
                {selectedRooms.map((room, index) => (
                  <div key={index} css={styles.item}>
                    <p css={styles.room.title}>
                      {normalizeStringCase(room.name)}{' '}
                      <span css={styles.room.board}>
                        {normalizeStringCase(room.rate?.boardName)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )} */}

          {flights?.destinationName && flights?.originName && (
            <div css={styles.infoTile}>
              <div>
                <SidebarInfoTile editable={false} title={'Flights'} />
                <p css={styles.room.title}>
                  {`${flights.originName} - ${flights.destinationName}`}
                  <span css={styles.room.board}>
                    {normalizeStringCase(flights.type || '')}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      <div css={styles.price.container(isPaymentStep)}>
        <div css={styles.price.total.row}>
          <div css={styles.price.total.title}>Total price</div>
          <p css={styles.price.total.value}>
            {getPriceWithCurrency(totalPrice, currency)}
          </p>
        </div>
      </div>
    </div>
  )
}
