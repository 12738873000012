import { useState } from 'react'
import { eyeIcon, eyeOffIcon } from '~/shared/assets/icons'
import { InputWithIcon, InputWithIconProps } from '../../molecules'

import { styles } from './styles'

interface Props extends Partial<InputWithIconProps> {
  label?: string
  name?: string
}

export const PasswordInput = ({
  label = 'Password',
  name = 'password',
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputWithIcon
      label={label}
      name={name}
      type={showPassword ? 'text' : 'password'}
      additionalIcon={
        <img
          css={[styles.additionalIcon]}
          src={showPassword ? eyeOffIcon : eyeIcon}
          onClick={() => setShowPassword(!showPassword)}
        />
      }
      extendStyle={{ input: styles.input }}
      {...props}
    />
  )
}
