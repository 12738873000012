import { useStore } from 'effector-react'
import { useState, useEffect } from 'react'
import { Holiday, HolidayImage } from '~/entities/holiday'
import { selectImagesModel } from '../..'
import { GENERATED_IMAGES_MAX_NUMBER } from '../../config'
import { ImagesSkeleton } from '../images-skeleton'
import { styles } from './styles'

interface Props {
  images: HolidayImage[]
  selectedImages: number[]
  isLoading?: boolean
}

export const ImagesGrid = ({ images, selectedImages, isLoading }: Props) => {
  const similarImages = useStore(selectImagesModel.$similarImages)

  //TODO: Move this logic to model
  const [selectedImageId, setSelectedImageId] = useState()

  useEffect(() => {
    const foundSelectedImageIndex = images.findIndex(
      (item) => item.id === selectedImageId,
    )
    let newArr = images
    if (foundSelectedImageIndex !== -1) {
      newArr = [
        ...images.slice(0, foundSelectedImageIndex + 1),
        ...similarImages,
        ...images.slice(foundSelectedImageIndex + 1),
      ]
    }
    selectImagesModel.imagesAdded(newArr)
  }, [similarImages])

  const handleClick = (id: any, selected: any) => {
    setSelectedImageId(id)

    if (selected) {
      selectImagesModel.imageIdRemoved(id || 0)
      return
    }
    selectImagesModel.imageIdSelected(id || 0)
  }

  return (
    <div css={styles.container}>
      {' '}
      {images.map(({ id, link }) => {
        const selected = selectedImages.includes(id || 0)
        return (
          <Holiday
            key={id}
            id={id || Math.random()}
            url={link || ''}
            selected={selected}
            onSelect={() => handleClick(id, selected)}
          />
        )
      })}
      {isLoading && <ImagesSkeleton cardCount={GENERATED_IMAGES_MAX_NUMBER} />}
    </div>
  )
}
