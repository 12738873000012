import { getUserInitials } from '~/shared/lib/string'
import { Avatar } from '../../atoms'
import { styles } from './styles'

interface Props {
  url?: string
  userName: string
}

export const AvatarWithPlaceholder = ({ url, userName }: Props) => (
  <Avatar extendStyles={styles.avatar} image={url}>
    {!url && getUserInitials(userName)}
  </Avatar>
)
