import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 16px;

    border: 1px solid ${Colors.NEUTRAL_LIGHT};
    border-radius: 20px;

    width: max-content;

    ${Breakpoints.MD} {
      border: none;

      min-width: 100%;
      max-width: 100%;
    }
  `,

  header: css`
    display: flex;
    align-items: center;
    gap: 24px;
  `,

  container: css``,

  title: css`
    color: #000;
    font-size: 24px;
    font-weight: 400;

    ${Breakpoints.MD} {
      font-size: 20px;
    }
  `,

  checkIn: css`
    border-radius: 100px;
    border: 1px solid ${Colors.NEUTRAL_SILVER};

    padding: 8px 10px 8px 8px;

    display: flex;
    align-items: center;
    gap: 8px;

    color: ${Colors.NEUTRAL_BLACK};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
  `,

  content: css``,

  href: css`
    position: absolute;
    // for target element vertical centering
    top: calc(-100vh / 3 + 25px);

    ${Breakpoints.MD} {
      width: 100%;
      top: -100px;
      right: 0;
    }
  `,
}
