import { TripBooking } from '@softcery/awayaway-apiclient'
import { createFactory } from '@withease/factories'
import { createEvent, restore, sample, createEffect } from 'effector'
import { reset } from 'patronum'
import { bridge } from '~/shared/lib/factory'
import { GetBookingsFactory } from '../get-bookings'
import { getBooking } from './api'

export const getBookingFactory = createFactory(
  ({ $$getBookingsFactory }: { $$getBookingsFactory: GetBookingsFactory }) => {
    const resetState = createEvent()

    const updateError = createEvent<string | null>()
    const $error = restore(updateError, null)

    const initiatedGetBooking = createEvent<number>()
    const getBookingFx = createEffect(getBooking)

    const $loading = getBookingFx.pending

    const updateBookedTrip = createEvent<TripBooking | null>()
    const $bookedTripDetails = restore<TripBooking | null>(updateBookedTrip, null)

    // initiate get bookings
    bridge(() => {
      sample({
        clock: initiatedGetBooking,
        source: $$getBookingsFactory.$bookedTrips,
        filter: (bookedTrips) => !!bookedTrips?.length,
        fn: (bookedTrips, bookingId) =>
          bookedTrips!.find((booking) => booking.id === bookingId) || null,
        target: updateBookedTrip,
      })

      sample({
        clock: initiatedGetBooking,
        source: $$getBookingsFactory.$bookedTrips,
        filter: (bookedTrips) => !bookedTrips,
        fn: (_, bookingId) => bookingId,
        target: getBookingFx,
      })
    })

    // handle success
    bridge(() => {
      sample({
        clock: getBookingFx.doneData,
        fn: (bookedTrip) => bookedTrip?.booking ?? null,
        target: updateBookedTrip,
      })
    })

    reset({
      clock: resetState,
      target: [$bookedTripDetails],
    })

    return {
      $loading,
      $bookedTripDetails,
      $error,

      resetState,
      updateError,
      updateBookedTrip,
      initiatedGetBooking,
    }
  },
)
