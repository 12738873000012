import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useGate, useStore } from 'effector-react'
import { FC, useEffect, useState } from 'react'

import { publishableKey } from '../config'
import { makePaymentModel } from '../model'
import { Form } from './form'
import { appearance, styles } from './styles'

interface Props {
  model: ReturnType<typeof makePaymentModel>
  buttonCustomText: string
  buttonIcon: string
}

export const PaymentForm: FC<Props> = ({ model, buttonCustomText, buttonIcon }) => {
  useGate(model.PaymentGate, { buttonCustomText, buttonIcon })

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  const clientSecret = useStore(model.$paymentToken)
  const isBookingCompleting = useStore(model.$loading)

  useEffect(() => {
    setStripePromise(loadStripe(publishableKey))
  }, [])

  return (
    <div css={styles.wrapper}>
      <div css={styles.inputs.container}>
        <div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
              <Form
                onPaymentCompleted={model.paymentCompleted}
                onErrorOccurred={model.paymentErrorOccured}
                isBookingCompleting={isBookingCompleting}
                buttonCustomText={buttonCustomText}
                buttonIcon={buttonIcon}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  )
}
