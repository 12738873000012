import { css } from '@emotion/react'
import { crossIcon } from '~/shared/assets/icons'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    display: inline-grid;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid ${Colors.LIGHT_GREY_2};
    border-radius: 999px;
    grid-column-gap: 25px;
    grid-template-columns: auto auto;
  `,

  label: css`
    letter-spacing: 0.01em;
    color: ${Colors.NEUTRAL_BLACK};
    font-family: ${Fonts.Montserrat};
    font-size: 16px;
    font-weight: 400;
  `,

  cross: css`
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url(${crossIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `,
}
