import { FC } from 'react'
import { ArrowProps } from 'react-multi-carousel'
import { styles } from './styles'

interface Props extends ArrowProps {
  arrowRight?: boolean
}

export const Arrow: FC<Props> = ({ onClick, carouselState, arrowRight }) => {
  const isDisabledLeft = carouselState?.currentSlide === 0 && !arrowRight
  const isDisabledRight =
    (carouselState?.currentSlide || 0) + 1 === carouselState?.slidesToShow && arrowRight

  return (
    <div
      onClick={onClick}
      css={styles.container(arrowRight, isDisabledLeft || isDisabledRight)}
    />
  )
}
