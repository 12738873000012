import { useStore } from 'effector-react'
import { FC } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { crossIcon } from '~/shared/assets/icons'
import { Button, Modal } from '~/shared/ui'
import { regenerateItineraryModel } from '../model'
import { styles } from './styles'

export const RegenerateItineraryPopup: FC = () => {
  const isOpen = useStore(regenerateItineraryModel.$popupIsOpen)
  const inputValue = useStore(regenerateItineraryModel.$inputValue)

  return (
    <Modal
      isOpen={isOpen}
      onClose={regenerateItineraryModel.closePopup}
      extendStyles={styles.modal}
    >
      <div css={styles.container}>
        <img
          src={crossIcon}
          onClick={() => regenerateItineraryModel.closePopup()}
          css={styles.crossIcon}
        />

        <h1 css={styles.title}>
          We're generating a new itinerary, do you have any specific requests?
        </h1>

        <TextareaAutosize
          minRows={5}
          css={styles.textarea}
          value={inputValue}
          onChange={(e) => regenerateItineraryModel.inputValueChanged(e.target.value)}
        />

        <Button
          onClick={() => regenerateItineraryModel.initiate()}
          extendStyle={styles.button}
        >
          Submit
        </Button>
      </div>
    </Modal>
  )
}
