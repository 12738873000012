import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { MutatedTripIdea } from '~/entities/hotel/types'
import { AddressIcon, CalendarIcon, CheckIcon } from '~/shared/assets/icons/components'
import { getPriceWithCurrency } from '~/shared/lib/currency'

import { formatToCustom, formatToDate } from '~/shared/lib/date'
import { normalizeStringCase } from '~/shared/lib/string'
import { Title } from '~/shared/ui'
import { HotelImage } from '~/shared/ui/organisms/hotel-image'
import { styles } from './styles'

interface Props {
  hotel: MutatedTripIdea
  className?: string
  withTitle?: boolean
  selected?: boolean
  isSingleHotel?: boolean
  loading: boolean
  isRoomLoading: boolean
  onSelect?: () => void
  onDetailsClick?: (hotel: MutatedTripIdea) => void
  selectedDate?: {
    destinationCode: string
    startDate: string
    endDate: string
  }
  extendStyles?: {
    container?: SerializedStyles
  }
  lowestTotalRate?: number
}

const formatDate = (stringDate: string) => {
  const date = formatToDate(stringDate, 'YYYY-MM-DD')
  return formatToCustom(date, 'MMM DD')
}

export const Hotel: FC<Props> = ({
  hotel,
  className,
  onSelect,
  withTitle,
  extendStyles,
  selected,
  isSingleHotel,
  onDetailsClick,
  selectedDate,
  loading,
  lowestTotalRate,
  isRoomLoading,
}) => {
  const checkIn = selectedDate?.startDate ? selectedDate.startDate : hotel.checkIn
  const checkOut = selectedDate?.endDate ? selectedDate.endDate : hotel.checkOut

  return (
    <div
      css={[styles.container(selected, isSingleHotel), extendStyles?.container]}
      className={className}
    >
      <div
        css={styles.image(isSingleHotel)}
        onClick={(e) => {
          e.stopPropagation()
          onDetailsClick && onDetailsClick(hotel)
        }}
      >
        <HotelImage
          hotelName={!withTitle ? hotel.name : undefined}
          images={hotel.images || []}
          isSingleHotel={isSingleHotel}
        />
      </div>

      {!isSingleHotel && (
        <button
          disabled={loading}
          onClick={onSelect}
          css={styles.chooseButton(selected, isSingleHotel)}
        >
          {selected ? (
            <span>
              Selected <CheckIcon />
            </span>
          ) : (
            <span>Choose this hotel</span>
          )}
        </button>
      )}

      <div css={styles.info}>
        <div css={styles.descriptionBlock}>
          {withTitle && (
            <Title
              css={[styles.title]}
              onClick={(e) => {
                e.stopPropagation()
                onDetailsClick && onDetailsClick(hotel)
              }}
            >
              {hotel.name}
            </Title>
          )}

          <div css={styles.dates.wrapper}>
            <div css={styles.dates.item}>
              <CalendarIcon /> {formatDate(checkIn)} - {formatDate(checkOut)}
            </div>
            {/* //TODO: add lowestTotalAmount to the hotel object */}
            {(!!hotel?.lowestTotalAmount || lowestTotalRate) && (
              <div css={styles.averagePrice}>
                {isRoomLoading ? (
                  <Skeleton width={120} />
                ) : (
                  <>
                    from{' '}
                    {getPriceWithCurrency(
                      lowestTotalRate ?? hotel.lowestPerNightAmount!,
                      'GBP',
                      false,
                    )}
                    <span>/night</span>
                  </>
                )}
              </div>
            )}
          </div>

          <div css={styles.facilities.wrapper}>
            <div css={styles.facilities.item}>
              <AddressIcon /> {normalizeStringCase(hotel.city!)}
            </div>

            {/* {!!hotelTypes.length && (
              <div css={styles.facilities.item}>
                <HotelTypesIcon /> {normalizeStringCase(hotelTypes[0].description)}
              </div>
            )}

            {!!distances.length && !!distances[0].distance && (
              <div css={styles.facilities.item}>
                <DistancesIcon /> {distances[0].description}{' '}
                {convertDistance(distances[0].distance)}
              </div>
            )} */}
          </div>

          {hotel.description ? (
            <div css={styles.description}>
              {' '}
              <p css={styles.descriptionCut}>{hotel.description}</p>
              {
                <button
                  css={styles.readMoreButton}
                  onClick={(e) => {
                    e.stopPropagation()
                    onDetailsClick && onDetailsClick(hotel)
                  }}
                >
                  View details
                </button>
              }
            </div>
          ) : (
            <div css={styles.description} />
          )}
        </div>
      </div>
    </div>
  )
}
