export const Step = ({ number, isActive }: { number: number; isActive: boolean }) => {
  if (number === 2) {
    return (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          width="20"
          height="20"
          rx="10"
          fill={isActive ? '#009F9D' : '#D1D1D1'}
        />
        <path
          d="M12.1873 7.39C12.1873 6.445 11.5423 5.83 10.5223 5.83C9.41227 5.83 8.81227 6.61 8.72227 7.675H7.50727C7.68727 5.965 8.82727 4.78 10.5223 4.78C12.2773 4.78 13.3273 5.8 13.3273 7.375C13.3273 8.545 12.3973 9.46 11.4673 10.51L9.12727 13.075H13.5223V14.125H7.50727V13.345L10.4773 10.075C11.4373 9.025 12.1873 8.26 12.1873 7.39Z"
          fill="white"
        />
      </svg>
    )
  }
  if (number === 3) {
    return (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.666016"
          width="20"
          height="20"
          rx="10"
          fill={isActive ? '#009F9D' : '#D1D1D1'}
        />
        <path
          d="M9.97631 9.91V8.905H10.5913C11.5663 8.905 12.3163 8.35 12.3163 7.36C12.3163 6.475 11.6713 5.8 10.6363 5.8C9.60131 5.8 8.94131 6.475 8.80631 7.435H7.65131C7.87631 5.875 9.00131 4.78 10.6363 4.78C12.3913 4.78 13.4413 5.83 13.4413 7.3C13.4413 8.32 12.8413 9.145 12.0013 9.4C12.9913 9.685 13.6213 10.51 13.6213 11.545C13.6213 13.06 12.5113 14.26 10.6663 14.26C8.86631 14.26 7.62131 13.165 7.47131 11.5H8.62631C8.79131 12.565 9.49631 13.24 10.6513 13.24C11.7463 13.24 12.4963 12.58 12.4963 11.56C12.4963 10.525 11.6713 9.91 10.6213 9.91H9.97631Z"
          fill="white"
        />
      </svg>
    )
  }
  if (number === 4) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="10" fill={isActive ? '#009F9D' : '#D1D1D1'} />
        <path
          d="M11.2541 4.915L7.81908 11.05H10.7591V8.83H11.8541V11.05H13.4741V12.04H11.8541V14.125H10.7591V12.04H6.51408V11.2L10.0541 4.915H11.2541Z"
          fill="white"
        />
      </svg>
    )
  } else throw new Error('Invalid step number')
}
