import {
  TripIdeaHotelResponseDto,
  TripIdeaResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { FC, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { FreeMode, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  ArrowRightIcon,
  CheckIcon,
  MapPinIcon,
  TagIcon,
} from '~/shared/assets/icons/components'
import { changeImageResolution } from '~/shared/lib/image'
import { useWindowSize } from '~/shared/lib/use-window-size'
import { Dimensions } from '~/shared/styles'
import { Button } from '~/shared/ui'
import { destinationModel, keyPointsIcons } from '../..'
import { DatesItem } from './dates-item'
import { KeyPoint } from './key-point'
import { styles } from './styles'
import { KEY_POINTS_COUNT_ELEMENT_CLASS, useMaxKeyPoints } from './use-max-key-points'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import './styles.css'

interface Props {
  trip: TripIdeaResponseDto
  onGetHotels: (hotels: TripIdeaHotelResponseDto[]) => void
}

export const Trip: FC<Props> = ({ trip, onGetHotels }) => {
  const imagesAmount = trip.images?.length || 0
  const leftImagesAmount = imagesAmount - 3
  const { width } = useWindowSize()
  const [isHeadingGreaterThanThreshold, setHeadingGreaterThanThreshold] = useState(false)
  const ref = useRef<HTMLHeadingElement>(null)

  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Lg}px)` })

  const keyPoints = Object.entries(trip.keypoints || {}) || []

  const { keyPointsEl, keyPointsWrapperEl, maxKeyPoints } = useMaxKeyPoints()

  const shownKeyPoints = keyPoints.slice(0, maxKeyPoints || 0)
  const restKeyPoints = maxKeyPoints ? keyPoints.length - maxKeyPoints : 0

  const [firstImageUrl, secondImageUrl, thirdImageUrl] = trip.images || []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const price = trip.lowestTotalAmount ? Math.round(trip.lowestTotalAmount as number) : 0

  const openTrip = () => {
    destinationModel.selectedChanged(trip)
    onGetHotels(trip.hotels)
  }

  useEffect(() => {
    const checkHeadingHeight = () => {
      if (ref.current) {
        const threshold = 75 // px
        setHeadingGreaterThanThreshold(ref.current.offsetHeight > threshold)
      }
    }

    checkHeadingHeight()
  }, [width])

  return (
    <div css={styles.container}>
      {isDesktop && trip.images && (
        <div onClick={openTrip} css={styles.images.container}>
          {trip.explanation && (
            <div css={styles.explanation.wrapper}>
              <span css={styles.explanation.icon}>
                <CheckIcon width={10} height={10} />
              </span>
              <p css={styles.explanation.text}>{trip.explanation}</p>
            </div>
          )}
          <div
            css={styles.images.firstImage(
              changeImageResolution(firstImageUrl, 1366, 768),
            )}
          />
          <div
            css={styles.images.secondImage(
              changeImageResolution(secondImageUrl, 854, 480),
            )}
          />
          <div
            css={styles.images.thirdImage(changeImageResolution(thirdImageUrl, 854, 480))}
          />

          {leftImagesAmount && (
            <div css={styles.images.count}>+{leftImagesAmount} photo</div>
          )}
        </div>
      )}

      {!isDesktop && (
        <div css={styles.images.container} onClick={openTrip}>
          <Swiper
            loop={true}
            spaceBetween={10}
            modules={[FreeMode, Pagination]}
            className="trip-slider"
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 4,
            }}
          >
            {trip.images!.map((url, i) => (
              <SwiperSlide key={url + i}>
                <img src={changeImageResolution(url, 1920, 1080)} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div css={styles.content}>
        <h1 onClick={openTrip} css={styles.title} ref={ref}>
          {trip.name}
        </h1>

        {!isDesktop && trip.explanation && (
          <div css={styles.explanation.wrapper}>
            <span css={styles.explanation.icon}>
              <CheckIcon width={10} height={10} />
            </span>
            <p css={styles.explanation.text}>{trip.explanation}</p>
          </div>
        )}

        <div css={styles.dates}>
          {trip.dates?.map(({ start = '', end = '' }, i) => (
            <DatesItem key={i} start={start} end={end} />
          ))}
        </div>

        <div css={styles.flexContainer}>
          <div css={styles.location}>
            <MapPinIcon />
            {trip.countryName}
          </div>

          <div css={styles.price}>
            <TagIcon />
            From £{price}
          </div>
        </div>

        <p css={styles.description(isHeadingGreaterThanThreshold)}>{trip.description}</p>

        <div ref={keyPointsWrapperEl} css={styles.keyPoints.wrapper}>
          <div ref={keyPointsEl} css={styles.keyPoints.container}>
            {(maxKeyPoints != null ? shownKeyPoints : keyPoints)?.map(
              ([key, value], i) => (
                <KeyPoint
                  icon={keyPointsIcons[key as keyof typeof keyPointsIcons]}
                  key={i}
                >
                  {value}
                </KeyPoint>
              ),
            )}

            {Boolean(restKeyPoints) && (
              <div
                onClick={openTrip}
                css={styles.keyPoints.count}
                className={KEY_POINTS_COUNT_ELEMENT_CLASS}
              >
                +{restKeyPoints}
              </div>
            )}
          </div>
        </div>

        <Button onClick={openTrip} extendStyle={styles.button.base}>
          <div css={styles.button.background} /> Discover more{' '}
          <span css={styles.button.icon}>
            <ArrowRightIcon color="#fff" />
          </span>
        </Button>
      </div>
    </div>
  )
}
