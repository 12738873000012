import { FC } from 'react'
import { Link } from 'react-router-dom'
import { styles } from './styles'

interface Props {
  icon: any
  name: string
  path: string
  active?: boolean
  action?: () => void
  disabled?: boolean
}

export const MenuItem: FC<Props> = ({ icon, name, path, active, action, disabled }) => {
  return (
    <Link to={path} onClick={action} css={styles.container(!!active, !!disabled)}>
      <img css={styles.icon} src={icon} alt={name} />
      <div css={styles.name(active)}>{name}</div>
    </Link>
  )
}
