import { GetNearestAirportRes } from '~/entities/flights/types'
import { apiErrorHandler, apiService } from '~/shared/api'

export const getNearestAirport = async (): Promise<GetNearestAirportRes> => {
  try {
    return await apiService().getNearestAirport()
  } catch (e: any) {
    return apiErrorHandler(e)
  }
}
