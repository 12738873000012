import { FC } from 'react'
import { styles } from './styles'

export const FlightsSkeleton: FC = () => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.item}>
        <div css={styles.block}>
          <div css={styles.title}></div>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
        <div css={styles.block}>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
      </div>

      <div css={styles.item}>
        <div css={styles.block}>
          <div css={styles.title}></div>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
        <div css={styles.block}>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
      </div>

      <div css={styles.item}>
        <div css={styles.block}>
          <div css={styles.title}></div>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
        <div css={styles.block}>
          <div css={styles.subtitle}></div>
          <div css={styles.content}></div>
        </div>
      </div>
    </div>
  )
}
