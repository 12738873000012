import React, { FC, Fragment } from 'react'
import { getCancellationDeadlineDate } from '~/entities/booking/lib'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatToCustom } from '~/shared/lib/date'
import { styles } from './styles'

interface Props {
  policies: { from: string; amount: number }[]
  currency: string
}

export const CancellationFeesDetails: FC<Props> = ({ policies, currency }) => (
  <div>
    <p css={[styles.tooltip.text, styles.tooltip.title]}>Cancellation charges</p>

    <div>
      {policies.map((policy) => {
        const deadlineDate = getCancellationDeadlineDate(policy.from!)
        const formattedDate = formatToCustom(deadlineDate, 'DD/MM/YYYY hh:mm a')

        return (
          <p css={styles.tooltip.text} key={`${policy.from}`}>
            After: {formattedDate} -{' '}
            <span css={styles.tooltip.boldText}>
              {getPriceWithCurrency(policy.amount!, currency)}
            </span>
          </p>
        )
      })}
    </div>

    <p css={[styles.tooltip.text, styles.tooltip.helper]}>
      Date and time is calculated based on local time of destination.
    </p>
  </div>
)
