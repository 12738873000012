import { FC } from 'react'
import { CloudIcon, MoonIcon, SunIcon } from '~/entities/trip/assets'
import { Itinerary as ItineraryType } from '../../../types'
import { ItineraryFragment } from '../fragment'
import { styles } from './styles'

interface Props {
  itinerary: ItineraryType
  dayNumber: number
  isLast?: boolean
}

export const Itinerary: FC<Props> = ({ itinerary, dayNumber, isLast }) => {
  const { timeline } = itinerary

  return (
    <div css={styles.wrapper}>
      <div css={styles.href} id={`itinerary/day${dayNumber}`} />

      <div css={styles.header}>
        <h1 css={styles.title}>Day {dayNumber}</h1>

        {/* {dayNumber === 1 && (
          <div css={styles.checkIn}>
            <MapPinIcon width={16} height={16} color={Colors.NEUTRAL_BLACK} /> Check in
          </div>
        )} */}
      </div>

      <div css={styles.container}>
        <div css={styles.content}>
          <ItineraryFragment
            Icon={SunIcon}
            title="Morning"
            description={timeline?.morning || ''}
          />

          <ItineraryFragment
            Icon={CloudIcon}
            title="Afternoon"
            description={timeline?.afternoon || ''}
          />

          <ItineraryFragment
            Icon={MoonIcon}
            title="Evening"
            description={timeline?.evening || ''}
            isLast={isLast}
          />
        </div>
      </div>
    </div>
  )
}
