import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    padding-top: 32px;
    margin-top: 24px;
    border-top: 1px solid ${Colors.NEUTRAL_SILVER};
  `,

  guests: {
    container: css`
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    `,

    formsContainer: css`
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 12px;
    `,

    title: css`
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 24px;
      font-weight: 400;
    `,
  },

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    margin: 32px 0;
  `,

  subtitle: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 18px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 40px;
  `,

  room: {
    num: css`
      display: inline-block;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    guests: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-bottom: 24px;
    `,
  },

  error: css`
    margin-top: 10px;
  `,
}
