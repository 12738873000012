import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};
    padding: 36px 40px;

    ${Breakpoints.SM} {
      padding: 25px;
    }
  `,

  header: {
    container: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      ${Breakpoints.SM} {
        flex-direction: column;
        gap: 5px;
      }
    `,

    title: css`
      font-family: Calibre;
      font-weight: 500;
      font-size: 20px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    reference: css`
      font-family: Calibre;
      font-weight: 400;
      font-size: 20px;
      color: ${Colors.NEUTRAL_GREY};
    `,
  },

  dates: {
    container: css`
      grid-column-gap: 0;
    `,

    icon: css`
      margin: 0 32px;

      ${Breakpoints.SM} {
        margin: 0 16px;
      }
    `,
  },

  link: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.PRIMARY_GREEN};
  `,
}
