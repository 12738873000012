import { isImgUrlValid } from '~/shared/lib/images'
import { ImageUrlProvider, IMAGE_SIZES } from './provider'

const DEFAULT_URL = 'http://photos.hotelbeds.com/giata'

export const hotelBedsUrlProvider: ImageUrlProvider = {
  getImageUrl: (image, desiredSize) => {
    if (desiredSize) {
      return `${DEFAULT_URL}/${desiredSize}/${image || ''}`
    }

    return `${DEFAULT_URL}/${image || ''}`
  },
  getValidImageUrl: async (imageName, size) => {
    if (size && size !== 'small') {
      const desiredSizeIndex = IMAGE_SIZES.indexOf(size)
      for (let index = desiredSizeIndex; index >= 0; index--) {
        const size = IMAGE_SIZES[index]
        const imageUrl = `${DEFAULT_URL}/${size}/${imageName || ''}`
        const isImageValid = await isImgUrlValid(imageUrl)

        if (isImageValid) {
          return imageUrl
        }
      }
    }

    return ''
  },
}
