import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'
import { NotificationType } from '.'

export const styles = {
  container: (type: NotificationType) => css`
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 20px;
    width: 80%;
    color: white;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background: ${type === 'error' ? Colors.ERROR : Colors.PRIMARY_GREEN};
  `,

  icon: css`
    width: 16px;
    margin-right: 12px;
  `,
}
