import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'
import { SelectBudget, selectBudgetModel } from '~/features/onboarding/select-budget'
import { Heading } from '~/shared/ui'
import { budgetPageModel } from '..'
import { styles } from './styles'

export const BudgetPage: FC = () => {
  useGate(budgetPageModel.Gate)
  const selectedBudget = useStore(selectBudgetModel.$selectedBudget)
  const isStepSkippable = useStore(budgetPageModel.$isStepSkippable)

  return (
    <OnboardingLayout
      onNext={budgetPageModel.nextStepNavigated}
      onReset={budgetPageModel.stateReset}
      text={selectedBudget ? `${selectedBudget.price}` : 'Nothing selected'}
      isStepSkippable={isStepSkippable}
    >
      <div css={styles.wrapper}>
        <div css={styles.container}>
          <Heading
            title="What's your hotel budget per night?"
            subtitle="Our hotel is of beautiful quality across categories."
            css={styles.headingWrapper}
            extendStyles={{ title: styles.headingTitle }}
          />

          <SelectBudget />
        </div>
      </div>
    </OnboardingLayout>
  )
}
