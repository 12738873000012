import { Airport, FlightPlaceRes, UserAirport } from '~/entities/flights/types'

export const convertAirportsWithMainCity = (place: FlightPlaceRes): Airport[] => {
  const childrenAirports: Airport[] =
    place.airports?.map((airport) => {
      return {
        id: (airport.name ?? '' + airport.name) || '',
        city_name: place.name,
        iata_city_code: place.iataCityCode,
        iata_code: airport.iataCode || '',
        longitude: airport.longitude,
        latitude: airport.latitude,
        name: airport.name,
      }
    }) || []

  return [
    {
      id: (place.iataCityCode ?? '' + place.name) || '',
      city_name: place.name,
      iata_city_code: place.iataCityCode,
      iata_code: '',
      name: place.name,
      longitude: undefined,
      latitude: undefined,
    },
    ...childrenAirports,
  ]
}

export const convertAirportsWithOutMainCity = (airports: UserAirport[]): Airport[] => {
  return airports?.map((airport) => {
    return {
      id: `${airport?.iataCode ?? '' + airport?.name}` || '',
      city_name: '',
      iata_city_code: '',
      iata_code: airport.iataCode || '',
      longitude: airport.longitude,
      latitude: airport.latitude,
      name: airport.name,
    }
  })
}

export const airportsMapper = (places?: FlightPlaceRes[]) => {
  if (!places?.length) return null

  const citiesWithAirports = places.filter((city) => city.name)
  const citiesWithOutAirports = places.filter((city) => city.name === undefined)
  const airportsWithMainCity = citiesWithAirports.flatMap((city) =>
    convertAirportsWithMainCity(city),
  )
  const citiesWithoutAirports = citiesWithOutAirports.flatMap((city) => {
    return convertAirportsWithOutMainCity(city.airports || [])
  })

  const allAirports = Array.from(
    new Set([...airportsWithMainCity, ...citiesWithoutAirports]),
  )
  return allAirports
}
