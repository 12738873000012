import React, { Fragment } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '~/shared/lib/notification/styles.css'

export const withNotifications = (Component: () => React.ReactElement) => () =>
  (
    <Fragment>
      <ToastContainer style={{ zIndex: 999 }} autoClose={7000} hideProgressBar />
      <Component />
    </Fragment>
  )
