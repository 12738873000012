// example STRING -> String
export const normalizeStringCase = (string?: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''

export const normalizeStringEnding = (string?: string) =>
  string
    ? (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).replace(
        /\s\w+$/,
        '',
      )
    : ''

// example Lorem ipsum blablabla -> Lorem ipsum bl...
export const cutString = (description: string | undefined, length: number) => {
  if (!description) {
    return ''
  }

  if (description?.length > length) {
    return description?.substring(0, length) + '... '
  }

  return description
}

export const getUserInitials = (name: string) => {
  const splittedName = name.toUpperCase().split(' ')
  const initials = splittedName.reduce(
    (string, name) => (name ? string + name[0] : string),
    '',
  )
  return initials
}
