import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  label: css`
    display: block;
    color: ${Colors.NEUTRAL_GREY};
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 10px;
  `,

  dates: css`
    display: flex;

    ${Breakpoints.MD} {
      flex-direction: column;
      gap: 15px;
    }
  `,

  firstInput: css`
    border-right: 0;
  `,

  input: (isFirst: boolean, isError?: boolean) => css`
    border: 1px solid ${isError ? Colors.ERROR : Colors.LIGHT_GREY_4};
    border-right: ${isFirst ? 0 : 1};
  `,
}
