import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  divider: css`
    height: 13px;
  `,

  title: css`
    font-weight: 300;
    text-align: center;
  `,

  description: css`
    font-size: 18px;
    font-weight: 500;
    font-family: ${Fonts.Calibre};
    color: ${Colors.NEUTRAL_DARK};
    letter-spacing: 0.36px;
    margin-top: 16px;
  `,
}
