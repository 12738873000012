import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,

  titleWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  title: css`
    color: #000;
    font-family: ${Fonts.Joane};
    font-size: 32px;
    font-weight: 400;
  `,

  subtitle: css`
    color: ${Colors.NEUTRAL_DARK};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;

    margin-top: 14px;
  `,

  semiBold: css`
    font-weight: 500;
  `,

  hero: css`
    ${Breakpoints.MD} {
      padding: 0 20px;
    }
  `,

  header: {
    placeholder: css`
      width: 100%;
      height: 95px;

      ${Breakpoints.MD} {
        height: 70px;
      }
    `,

    wrapper: (isSticky?: boolean) => css`
      ${isSticky &&
      css`
        position: fixed;
        top: 70px;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: #fff;
      `}
    `,

    header: (isSticky?: boolean) => css`
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 24px 0;

      margin: 0 auto;

      ${isSticky &&
      css`
        max-width: 1200px;
        width: 100%;
      `}

      ${Breakpoints.MD} {
        padding: 15px;

        box-shadow: 0px 2px 10px 0px rgba(43, 43, 43, 0.03);
      }
    `,
  },

  content: css`
    display: flex;
    flex-direction: column;
    gap: 24px;

    scroll-behavior: smooth;

    ${Breakpoints.MD} {
    }
  `,

  navigation: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    ${Breakpoints.MD} {
      flex-wrap: nowrap;
    }
  `,

  regenerateButton: {
    base: css`
      min-width: max-content;
      height: 44px;
      padding: 0 40px;
      font-size: 18px;
      font-weight: 400;

      display: flex;
      align-items: center;

      background: transparent;
      border: 1px solid ${Colors.PRIMARY_GREEN};
      color: ${Colors.PRIMARY_GREEN};

      transition: all 0.3s;
      position: relative;
      z-index: 1;
      overflow: hidden;

      margin-left: auto;

      &:disabled {
        color: ${Colors.NEUTRAL_GREY};
        border: 1px solid ${Colors.NEUTRAL_GREY};
        background: #fff;
        padding: 0 10px 0 20px;
      }

      &:not(:disabled) {
        :hover,
        :focus {
          background: transparent;
          color: #fff;

          div {
            margin-bottom: 0;
          }

          svg {
            opacity: 1;
          }
        }
      }
    `,

    background: css`
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: -100%;
      width: 100%;
      height: 100%;

      background: ${Colors.PRIMARY_GREEN};
      transition: 0.3s;
      z-index: -1;
    `,

    mobile: css`
      color: ${Colors.PRIMARY_GREEN};
      font-size: 18px;
      font-weight: 400;
    `,
  },
}
