import { FC } from 'react'

export const CompletedStep: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="#009F9D" />
      <path
        d="M12.8572 7.85718L8.9286 11.7857L7.14288 10"
        stroke="#FDFDFD"
        strokeWidth="0.892857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
