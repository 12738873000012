import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  wrapper: css`
    width: 100%;
    max-width: 700px;
    padding-bottom: 130px;
  `,

  headingWrapper: css`
    margin: 36px 0 60px;
    text-align: center;

    ${Breakpoints.SM} {
      margin: 24px 0 32px;
    }

    p {
      ${Breakpoints.SM} {
        font-size: 16px;
        line-height: 19px;
      }
    }
  `,

  headingTitle: css`
    text-align: center;
    font-weight: 300;

    ${Breakpoints.SM} {
      font-size: 24px;
      font-weight: 400;
    }
  `,

  controlsWrapper: css`
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
  `,
}
