import { useCallback, useLayoutEffect, useRef } from 'react'
import { isiOS } from './is-ios'

export const useScrollLock = () => {
  const scrollOffset = useRef(0)

  const lockScroll = useCallback(() => {
    document.body.classList.add('lock')

    if (isiOS) {
      scrollOffset.current = window.pageYOffset

      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollOffset.current}px`
    }
  }, [])

  const unlockScroll = useCallback(() => {
    document.body.classList.remove('lock')

    if (isiOS) {
      document.body.style.position = ''
      document.body.style.top = ''
    }
  }, [])

  useLayoutEffect(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
    document.body.style.setProperty(
      '--scrollbar-compensation',
      `${scrollBarCompensation}px`,
    )
  }, [])

  return [lockScroll, unlockScroll]
}
