import { FC, useEffect, useRef } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Colors } from '~/shared/styles'
import { TabBarOption } from '../../types'
import { Arrow } from '../arrow'
import { Option as OptionFC } from '../option'
import './styles.css'

interface Props {
  options: TabBarOption<any>[]
  selectedOptions: string[]
  onOptionSelect: (option: TabBarOption<any>) => void
  optionsDisabled?: boolean
}

export const OptionBar: FC<Props> = ({
  options,
  selectedOptions,
  onOptionSelect,
  optionsDisabled,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const removeScroll = (e: TouchEvent) => {
      e.preventDefault()
    }

    if (ref.current) {
      ref.current.addEventListener('touchmove', removeScroll)
    }

    return () => {
      ref.current?.removeEventListener('touchmove', removeScroll)
    }
  }, [ref.current])

  // Fix rendering issue with react-multi-carousel
  return (
    <div ref={ref}>
      <div css={{ visibility: 'hidden', height: '0px' }}>days shift</div>
      <Carousel
        partialVisible
        className="ob-carousel"
        itemClass="ob-carousel-item"
        autoPlay={false}
        renderArrowsWhenDisabled={true}
        shouldResetAutoplay={false}
        customLeftArrow={<Arrow />}
        customRightArrow={<Arrow arrowRight />}
        slidesToSlide={4}
        responsive={{
          desktop: {
            breakpoint: { max: 9999, min: 0 },
            items: 6,
            partialVisibilityGutter: 1,
          },
        }}
      >
        {options.map(({ label, option, Icon = () => null }) => {
          const isActive = selectedOptions.includes(label)
          const isDisabled = optionsDisabled && !isActive

          return (
            <OptionFC
              key={label}
              onSelect={() => onOptionSelect({ label, option })}
              isActive={isActive}
              isDisabled={isDisabled}
            >
              <Icon color={isActive ? Colors.PRIMARY_GREEN : undefined} /> {label}
            </OptionFC>
          )
        })}
      </Carousel>
    </div>
  )
}
