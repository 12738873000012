import { ServiceOptionType } from '~/features/select-services/types'
import { iconsForService, UiElementsDataAtributeNameDuffelServiceOption } from '../const'

export const getElementByDataTestId = (
  dataTestId: UiElementsDataAtributeNameDuffelServiceOption,
): Element | null => {
  return window.document.querySelector(dataTestId)
}

export const changeIconToCheck = (
  nameAttribute: UiElementsDataAtributeNameDuffelServiceOption,
  covertToIcon: UiElementsDataAtributeNameDuffelServiceOption,
  parentElement: UiElementsDataAtributeNameDuffelServiceOption,
) => {
  const mainWrapper = getElementByDataTestId(parentElement)

  if (!mainWrapper) return

  const iconHtml = mainWrapper.querySelector(nameAttribute)
  if (!iconHtml) return

  const parent = iconHtml.parentElement
  if (!parent) return

  const icon = iconsForService.get(covertToIcon)
  if (!icon) return
  parent.innerHTML = icon
}

export const changeTextForSelected = (
  type: ServiceOptionType,
  count: number,
  price: number,
  parentElement: UiElementsDataAtributeNameDuffelServiceOption,
  mainText?: string,
) => {
  const baggageWrapperHtml = getElementByDataTestId(parentElement)
  if (!baggageWrapperHtml) return

  const pHtml = baggageWrapperHtml.children[1]

  if (!pHtml) return

  const isMoreThenOne = count > 1
  let text = ''
  if (type === ServiceOptionType.BAGGAGE) {
    text = `${count} ${isMoreThenOne ? 'bags' : 'bag'} added for £${price}`
  }
  if (type === ServiceOptionType.SEAT) {
    text = `${count} ${isMoreThenOne ? 'seats' : 'seat'} selected for £${price}`
  }

  pHtml.innerHTML = mainText ?? text
}

export const checkIfSeatsIsSelected = () => {
  const element = document.querySelector('.map-element--selected')
  return element
}
