import { css } from '@emotion/react'
import { Fonts, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    border: 0;
  `,

  header: {
    container: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,

    icon: css`
      margin-bottom: 8px;
    `,
  },

  dropdownIndicatorIcon: css`
    margin-right: 8px;
  `,

  control: (isActive: boolean, validationError?: boolean) => css`
    border: 1px solid #d5d4dc;
    font-family: ${Fonts.Calibre};
    font-size: 14px;
    color: ${Colors.DARK_1};

    display: flex;
    align-items: center;
    padding: 12px;

    ${validationError &&
    css`
      border-color: ${Colors.ERROR};
    `}

    ${isActive &&
    css`
      outline: 1px solid ${Colors.ACCENT_1};
      border-color: ${Colors.ACCENT_1};
    `}
  `,

  icon: css`
    margin-left: 8px;
  `,

  option: {
    container: css`
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      cursor: pointer;

      font-family: ${Fonts.Calibre};
      font-size: 16px;
      color: ${Colors.NEUTRAL_BLACK};

      &:hover {
        background-color: ${Colors.ACCENT_1};
        color: white;

        div {
          background-color: ${Colors.ACCENT_1};
        }
      }
    `,

    avatar: css`
      width: 16px;
      height: 16px;
      font-size: 8px;
    `,
  },
}
