import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: {
    base: css`
      display: flex;
      flex-direction: column;
      background-color: ${Colors.NEUTRAL_WHITE};
      border: 0;
      text-align: left;
      margin: 0;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    `,
  },

  rate: {
    container: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,

    meta: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,

    name: css`
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_BLACK};
      margin: 0 8px;
    `,
  },

  description: {
    text: css`
      margin-top: 20px;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 2%;
      color: ${Colors.NEUTRAL_GREY};
    `,

    button: css`
      position: relative;
      z-index: 3;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 2%;
      color: ${Colors.NEUTRAL_GREY};
      text-decoration: underline;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
    `,
  },

  select: css`
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.18px;
    color: ${Colors.PRIMARY_GREEN};
  `,
}
