import { FC } from 'react'
import { Tab } from '../../types'
import { Tab as TabFC } from '../tab'
import { styles } from './styles'

interface Props {
  onTabSelect: (tab: Tab) => void
  activeTab: Tab
}

export const TabBar: FC<Props> = ({ activeTab, onTabSelect }) => {
  return (
    <div css={styles.container}>
      <TabFC onSelect={() => onTabSelect(2)} isActive={activeTab === 2}>
        I’m Flexible
      </TabFC>

      <TabFC onSelect={() => onTabSelect(1)} isActive={activeTab === 1}>
        Choose Dates
      </TabFC>
    </div>
  )
}
