// This object maps event names to their respective naming conventions in different analytics libraries.
// Adjust the names based on the naming conventions of the libraries you are integrating.
// Note: If naming conventions need to be consistent across all libraries, update the names accordingly or remove names mapping.

export const eventNames = {
  //onboarding
  completedDestinationStep: {
    mixpanel: 'Destination Step Completed',
    google: 'select_destination',
  },
  completedInspirationStep: {
    mixpanel: 'Inspiration Step Completed',
    google: 'select_inspiration',
  },
  completedGuestsStep: {
    mixpanel: 'Guests Step Completed',
    google: 'select_guests',
  },
  completedBudgetStep: {
    mixpanel: 'Budget Step Completed',
    google: 'select_budget',
  },
  completedDatesStep: {
    mixpanel: 'Dates Step Completed',
    google: 'select_dates',
  },
  completedSummaryStep: {
    mixpanel: 'Summary Step Completed',
    google: 'complete_summary',
  },
  visitedSignUp: {
    mixpanel: 'Sign Up Visited',
    google: 'visit_sign_up',
  },
  signUp: {
    mixpanel: 'Sign Up Completed',
    google: 'sign_up',
  },
  verified: {
    mixpanel: 'Email verified',
    google: 'verify_email',
  },
  visitedLogIn: {
    mixpanel: 'Log In Visited',
    google: 'visit_log_in',
  },
  logIn: {
    mixpanel: 'Log In Completed',
    google: 'log_in',
  },
  visitedForgotPassword: {
    mixpanel: 'Forgot Password Visited',
    google: 'visit_forgot_password',
  },

  //recommendations
  visitedTripsPage: {
    mixpanel: 'Recommendations Page Visited',
    google: 'visit_trips_page',
  },
  initiatedRegenerateTrips: {
    mixpanel: 'Regenerate Trips Initiated',
    google: 'regenerate_trips',
  },
  clickedMessageTravelConsultant: {
    mixpanel: 'Message Travel Consultant Clicked',
    google: 'click_message_travel_consultant',
  },
  navigatedBackToQuiz: {
    mixpanel: 'Back To Quiz Navigated',
    google: 'navigate_back_to_quiz',
  },
  visitedTripPage: {
    mixpanel: 'Recommended Trip Page Visited',
    google: 'visit_trip_page',
  },
  initiatedItineraryRegenerate: {
    mixpanel: 'Regenerate Itinerary Initiated',
    google: 'regenerate_itinerary',
  },
  ratedRecommendedTrip: {
    mixpanel: 'Recommended Trip Rated',
    google: 'regenerate_itinerary',
  },

  //booking
  visitedDatesAndGuestsStep: {
    mixpanel: 'Dates & Guests Step Visited',
    google: 'visit_dates_guests_step',
  },
  completedDatesAndGuestsStep: {
    mixpanel: 'Dates & Guests Step Completed',
    google: 'complete_dates_guests_step',
  },
  visitedRoomsStep: {
    mixpanel: 'Rooms Step Visited',
    google: 'visit_rooms_step',
  },
  completedRoomsStep: {
    mixpanel: 'Rooms Step Completed',
    google: 'complete_rooms_step',
  },
  visitedContactsStep: {
    mixpanel: 'Contacts Step Visited',
    google: 'visit_contacts_step',
  },
  completedContactsStep: {
    mixpanel: 'Contacts Step Completed',
    google: 'complete_contacts_step',
  },
  visitedPaymentStep: {
    mixpanel: 'Payment Step Visited',
    google: 'visit_payment_step',
  },
  completedPaymentStep: {
    mixpanel: 'Payment Step Completed',
    google: 'complete_payment_step',
  },
  visitedConfirmationStep: {
    mixpanel: 'Confirmation Step Visited',
    google: 'visit_confirmation_step',
  },

  //new mixpanel events

  //Quiz
  quizDestinationsVisited: {
    mixpanel: 'Quiz Destinations Visited',
  },
  quizDestinationsCompleted: {
    mixpanel: 'Quiz Destinations Completed',
  },
  quizDestinationSelected: {
    mixpanel: 'Quiz Destination Selected',
  },
  quizImagesVisited: {
    mixpanel: 'Quiz Images Visited',
  },
  quizImagesCompleted: {
    mixpanel: 'Quiz Images Completed',
  },
  quizGuestsVisited: {
    mixpanel: 'Quiz Guests Visited',
  },
  quizGuestsCompleted: {
    mixpanel: 'Quiz Guests Completed',
  },
  quizBudgetVisited: {
    mixpanel: 'Quiz Budget Visited',
  },
  quizBudgetCompleted: {
    mixpanel: 'Quiz Budget Completed',
  },
  quizDatesVisited: {
    mixpanel: 'Quiz Dates Visited',
  },
  quizDatesCompleted: {
    mixpanel: 'Quiz Dates Completed',
  },

  quizSuggestionVisited: {
    mixpanel: 'Quiz Suggestion Visited',
  },

  quizSuggestionCompleted: {
    mixpanel: 'Quiz Suggestion Completed',
  },

  quizSuggestionEdited: {
    mixpanel: 'Quiz Suggestion Edited',
  },

  quizLoadingVisited: {
    mixpanel: 'Quiz Loading Visited',
  },
  quizLoadingCompleted: {
    mixpanel: 'Quiz Loading Completed',
  },
  quizAuthVisited: {
    mixpanel: 'Quiz Auth Visited',
  },
  quizAuthCompleted: {
    mixpanel: 'Quiz Auth Completed',
  },
  quizAuthBackClicked: {
    mixpanel: 'Quiz Auth Back Clicked',
  },
  referralCodeSaved: {
    mixpanel: 'Referral Code Saved',
  },

  //Trips
  tripsListVisited: {
    mixpanel: 'Trips List Visited',
  },
  tripsTripClicked: {
    mixpanel: 'Trips Trip Clicked',
  },
  tripsListRegenerated: {
    mixpanel: 'Trips List Regenerated',
  },
  tripsListChatOpened: {
    mixpanel: 'Trips List Chat Opened',
  },

  //Trip
  tripVisited: {
    mixpanel: 'Trip Visited',
  },
  tripHotelVisited: {
    mixpanel: 'Trip Hotel Visited',
  },
  tripRoomVisited: {
    mixpanel: 'Trip Room Visited',
  },
  tripDatesOpened: {
    mixpanel: 'Trip Dates Opened',
  },
  tripDatesSaved: {
    mixpanel: 'Trip Dates Saved',
  },
  tripDatesClosed: {
    mixpanel: 'Trip Dates Closed',
  },
  tripBookingClicked: {
    mixpanel: 'Trip Booking Clicked',
  },
  tripRated: {
    mixpanel: 'Trip Rated',
  },

  //Flights
  userLocationSelected: {
    mixpanel: 'User Location Selected',
  },
  flightsTypeSelected: {
    mixpanel: 'Flights Type Selected',
  },
  flightsOptionSelected: {
    mixpanel: 'Flights Option Selected',
  },
  flightOfferSelected: {
    mixpanel: 'Flight Offer Selected',
  },
  flightOfferForBookingSelected: {
    mixpanel: 'Flight Offer For Booking Selected',
  },
  flightDetailsVisited: {
    mixpanel: 'Flight Details Visited',
  },
  flightDetailsCompleted: {
    mixpanel: 'Flight Details Completed',
  },
  emptyFlightsReceived: {
    mixpanel: 'Empty Flights Received',
  },
  flightsRequestFailed: {
    mixpanel: 'Flights Request Failed',
  },
  flightsIncluded: {
    mixpanel: 'Flights Included',
  },

  //Booking
  bookingContactsVisited: {
    mixpanel: 'Booking Contacts Visited',
  },
  bookingContactsCompleted: {
    mixpanel: 'Booking Contacts Completed',
  },
  bookingPaymentVisited: {
    mixpanel: 'Booking Payment Visited',
  },
  bookingPaymentCompleted: {
    mixpanel: 'Booking Payment Completed',
  },
  bookingConfirmationVisited: {
    mixpanel: 'Booking Confirmation Visited From Email',
  },
  logoClicked: {
    mixpanel: 'AA Logo Clicked',
  },
} as const
