import { useStore } from 'effector-react'
import { Error } from '~/shared/ui'
import { selectImagesModel } from '..'
import { ImagesGrid } from './images-grid'

export const SelectImages = () => {
  const holidayImages = useStore(selectImagesModel.$displayedImages)
  const selectedImageIds = useStore(selectImagesModel.$selectedImageIds)
  const isLoading = useStore(selectImagesModel.$loading)
  const error = useStore(selectImagesModel.$error)

  return (
    <div>
      <ImagesGrid
        selectedImages={selectedImageIds}
        images={holidayImages}
        isLoading={isLoading}
      />

      {error && <Error>{error}</Error>}
    </div>
  )
}
