import { combine, createEffect, createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset, spread } from 'patronum'
import { LocalStorageKeys, ManageUrlQueryKeys, SessionStorageKeys } from '~/shared/config'
import { isDateInFuture } from '~/shared/lib/date'

import { ONBOARDING_STEPS, defaultRange, DateString } from './config'

export const Gate = createGate<void>()

export const stateReset = createEvent()
export const statusReset = createEvent()
export const authBackClicked = createEvent()
export const exactDatesReset = createEvent()

export const pageChanged = createEvent()
export const stepChanged = createEvent<string>()
export const onboardingFinished = createEvent()
export const onboardingSkipped = createEvent()
export const reOnboardingProcessFinished = createEvent()
export const reOnboardingInitiated = createEvent()
export const exactDatesSaved = createEvent<{ startDate: string; endDate: string }>()
export const exactDatesRemoved = createEvent()

export const iframeInitiated = createEvent()

export const newRecommendationsInitiated = createEvent()

export const $step = createStore<number>(1).on(
  stepChanged,
  (_, stepPathname) => ONBOARDING_STEPS[stepPathname as keyof typeof ONBOARDING_STEPS],
)

export const scrollToTopFx = createEffect(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

export const exitIframeModeFx = createEffect((redirectUrl: string) => {
  window.parent.postMessage({ redirect_url: redirectUrl }, '*')
})

export const updateBookingConfirmation = createEvent<string>()
export const $bookingConfirmation = createStore<string>('')

export const $onboardingFinished = createStore(false).on(onboardingFinished, () => true)
export const $isOnboardingSkipped = createStore(false).on(onboardingSkipped, () => true)
export const $isReOnboardingProcess = createStore(false)
  .on([reOnboardingInitiated, statusReset], () => true)
  .reset([reOnboardingProcessFinished, onboardingFinished])

export const $exactDateStart = createStore<DateString>(defaultRange.startDate)
export const $exactDateEnd = createStore<DateString>(defaultRange.endDate)
export const $savedDates = combine({
  startDate: $exactDateStart,
  endDate: $exactDateEnd,
})
export const $isAppOpenedInIframe = createStore(false).on(iframeInitiated, () => true)

persist({ store: $bookingConfirmation, key: ManageUrlQueryKeys.BOOKING_CONFIRMATION })
persist({ store: $exactDateStart, key: LocalStorageKeys.ExactDatesStart })
persist({ store: $exactDateEnd, key: LocalStorageKeys.ExactDatesEnd })
persist({ store: $onboardingFinished, key: SessionStorageKeys.OnboardingFinished })
persist({
  store: $isOnboardingSkipped,
  key: SessionStorageKeys.IsOnboardingSkipped,
})

sample({
  clock: updateBookingConfirmation,
  filter: (bookingConfirmation) => !!bookingConfirmation,
  target: $bookingConfirmation,
})

sample({
  clock: $bookingConfirmation,
  filter: (bookingConfirmation) => !!bookingConfirmation,
  target: onboardingSkipped,
})

sample({
  clock: Gate.open,
  source: $exactDateStart,
  filter: (date) => !isDateInFuture(new Date(date)),
  target: exactDatesReset,
})

sample({
  clock: onboardingSkipped,
  target: onboardingFinished,
})

sample({
  clock: exactDatesSaved,
  filter: (range) => Boolean(range.startDate && range.endDate),
  target: spread({
    targets: {
      startDate: $exactDateStart,
      endDate: $exactDateEnd,
    },
  }),
})

sample({
  clock: exactDatesRemoved,
  fn: () => ({ startDate: '', endDate: '' }),
  target: spread({
    targets: {
      startDate: $exactDateStart,
      endDate: $exactDateEnd,
    },
  }),
})

reset({
  clock: exactDatesReset,
  target: [$exactDateStart, $exactDateEnd],
})

reset({
  clock: statusReset,
  target: [$onboardingFinished, $isOnboardingSkipped],
})

sample({
  clock: reOnboardingInitiated,
  target: stateReset,
})

reset({
  clock: exitIframeModeFx.doneData,
  target: $isAppOpenedInIframe,
})

reset({
  clock: stateReset,
  target: [
    $step,
    $onboardingFinished,
    $isOnboardingSkipped,
    $exactDateEnd,
    $exactDateStart,
  ],
})
