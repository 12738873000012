import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const SelectedRadioButtonIcon: FC<Props> = ({
  width = 20,
  height = 20,
  color = '#009F9D',
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="10" fill={color} />
      <circle cx="10" cy="10" r="4" fill="#FDFDFD" />
    </svg>
  )
}
