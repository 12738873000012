import { createEvent, sample } from 'effector'
import { selectFlightsServicesModel } from '~/features/select-services'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { atom } from '~/shared/lib/factory'

export const flightDetailsPageModel = atom(() => {
  const pageCompleted = createEvent()
  const nextPageInitiated = createEvent()

  sample({
    clock: nextPageInitiated,
    target:
      selectFlightsServicesModel.$$manageUiForServicesOptionsFactory
        .checkIfEverythingIsOk,
  })

  sample({
    clock:
      selectFlightsServicesModel.$$manageUiForServicesOptionsFactory.updateEverythingIsOk,
    target: pageCompleted,
  })

  sample({
    clock: selectFlightsServicesModel.$$manageUiForServicesOptionsFactory.$error,
    target: createBookingModel.errorChanged,
  })

  sample({
    clock: pageCompleted,
    fn: () => ({
      name: eventNames.flightDetailsCompleted,
    }),
    target: analyticsModel.track,
  })

  sample({
    clock: pageCompleted,
    fn: () => ({
      name: eventNames.flightDetailsCompleted,
    }),
    target: analyticsModel.track,
  })

  return { pageCompleted, nextPageInitiated }
})
