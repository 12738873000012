import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    position: relative;
    display: flex;
    gap: 48px;
    max-width: 1224px;

    ${Breakpoints.LG} {
      flex-direction: column;
    }

    ${Breakpoints.SM} {
      gap: 16px;
    }

    overflow-x: hidden;
  `,

  images: {
    container: css`
      position: relative;
      display: grid;
      grid-template:
        'first second' 1fr
        'first third' 1fr
        / 2fr 1fr;
      gap: 4px;

      ${Breakpoints.LG} {
        grid-template:
          'first' 1fr
          / 1fr;
      }

      cursor: pointer;
    `,

    count: css`
      position: absolute;
      right: 12px;
      bottom: 12px;

      padding: 8px 12px;
      border-radius: 100px;
      background: #fff;

      color: #000;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    firstImage: (url?: string) => css`
      grid-area: first;

      width: 477px;
      height: 468px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      border-radius: 16px 0 0 16px;

      ${Breakpoints.MD} {
        width: 100%;
        height: 263px;
        border-radius: 16px;
      }
    `,

    secondImage: (url?: string) => css`
      grid-area: second;

      width: 215px;
      height: 232px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      border-radius: 0 16px 0 0;

      ${Breakpoints.MD} {
        display: none;
      }
    `,

    thirdImage: (url?: string) => css`
      grid-area: third;

      width: 215px;
      height: 232px;

      background: url(${url}) no-repeat center;
      background-size: cover;

      border-radius: 0 0 16px 0;

      ${Breakpoints.MD} {
        display: none;
      }
    `,
  },

  content: css`
    display: flex;
    flex-direction: column;
    max-width: 480px;
    min-width: 356px;
    ${Breakpoints.XL} {
      max-width: 100%;
      min-width: unset;
    }
  `,

  title: css`
    color: #000;
    font-size: 32px;
    font-weight: 400;
    font-family: ${Fonts.Joane};

    ${Breakpoints.MD} {
      font-size: 24px;
    }

    cursor: pointer;
  `,

  dates: css`
    margin-top: 24px;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    ${Breakpoints.SM} {
      margin-top: 16px;
    }
  `,

  flexContainer: css`
    margin-top: 16px;

    display: flex;
    align-items: center;
    gap: 16px;

    ${Breakpoints.MD} {
      position: absolute;
      top: 0px;
      margin-top: 0;
      z-index: 8;
    }

    ${Breakpoints.SM} {
      margin-top: 0;
    }
  `,

  location: css`
    width: max-content;

    display: flex;
    align-items: center;
    gap: 10px;

    color: ${Colors.NEUTRAL_BLACK};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;

    text-transform: capitalize;

    ${Breakpoints.MD} {
      position: absolute;
      top: 12px;
      left: 12px;
      padding: 6px 10px;
      border-radius: 6px;
      background: #fff;
    }
  `,

  price: css`
    width: max-content;

    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 10px;
    border-radius: 6px;
    background: #ecfaf4;

    color: ${Colors.PRIMARY_GREEN};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;

    ${Breakpoints.MD} {
      position: absolute;
      top: 53px;
      left: 12px;
    }
  `,

  description: css`
    margin-top: 16px;

    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;

    max-height: 84px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  keyPoints: {
    wrapper: css`
      position: relative;
      margin-top: 24px;

      height: 41px;

      margin-bottom: 24px;

      ${Breakpoints.SM} {
        margin-top: 16px;
      }
    `,

    container: css`
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      ${Breakpoints.MD} {
        position: absolute;
        /* width: 1000%; */
        flex-wrap: nowrap;
      }
    `,

    count: css`
      width: max-content;

      border-radius: 100px;
      border: 1px solid ${Colors.NEUTRAL_SILVER};
      padding: 10px 16px;

      color: #000;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;

      cursor: pointer;
    `,
  },

  button: {
    base: css`
      margin-top: 24px;

      width: 100%;
      height: 58px;

      border: 1px solid ${Colors.PRIMARY_GREEN};
      background: transparent;

      color: ${Colors.PRIMARY_GREEN};
      font-size: 20px;
      font-weight: 400;

      display: flex;
      align-items: center;
      gap: 10px;

      transition: color 0.6s;
      position: relative;
      z-index: 0;
      overflow: hidden;

      &:hover,
      &:focus {
        background: transparent;
        color: #fff;

        div {
          margin-left: 0;
        }

        svg {
          opacity: 1;
        }
      }
    `,

    background: css`
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: -100%;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(${Colors.PRIMARY_GREEN}, ${Colors.PRIMARY_GREEN});
      transition: 0.4s;
      z-index: -1;
    `,

    icon: css`
      width: 17px;
      height: 18px;

      svg {
        opacity: 0;
        transition: all 0.6s;
      }
    `,
  },
}
