import { TripBooking } from '@softcery/awayaway-apiclient'

export const getAllBags = (tripBooking: TripBooking) => {
  if (!tripBooking?.flight?.passengers) return []

  return tripBooking.flight.passengers.flatMap(
    (passenger) =>
      passenger.services?.filter((service) => service.type === 'baggage') || [],
  )
}

export const hasServiceType = (tripBooking: TripBooking, serviceType: string) => {
  return (
    tripBooking?.flight?.passengers?.some((passenger) =>
      passenger.services?.some((service) => service.type === serviceType),
    ) || false
  )
}
