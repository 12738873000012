import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 18px;
  `,

  info: {
    boldText: css`
      font-weight: 500;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    dashedText: css`
      position: relative;
      cursor: default;
      padding-bottom: 2px;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        border-bottom: 1px dashed ${Colors.NEUTRAL_BLACK}; /* Add the border-bottom property and set the style to dashed */
      }
    `,
  },

  tooltip: {
    container: css`
      z-index: 2;
      padding: 12px 16px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      opacity: 0.8;
      border-radius: 8px;
    `,

    title: css`
      margin-bottom: 12px;
      font-weight: 500;
    `,

    text: css`
      font-size: 16px;
      font-weight: 400;
      color: ${Colors.LIGHT_1};
    `,

    boldText: css`
      font-weight: 500;
    `,

    helper: css`
      margin-top: 16px;
      opacity: 0.75;
    `,
  },
}
