import { useStore } from 'effector-react'
import React from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { crossIcon, uploadIcon } from '~/shared/assets/icons'
import { fileUploadFactory } from '../'
import { onDrop } from '../lib'
import { styles } from './styles'

interface Props {
  model: ReturnType<typeof fileUploadFactory>
  accept?: Accept
  multiple?: boolean
  disabled?: boolean
  additionalText?: string
  maxFiles?: number
}

export const FileUploader = ({
  model,
  accept = { 'image/*': ['.png', '.jpeg', '.svg', '.gif'] },
  multiple = false,
  disabled,
  additionalText = 'SVG, PNG, JPG or GIF (max. 800x400px)',
  maxFiles = 1,
}: Props) => {
  const files = useStore(model.$files)

  const maxFileCount = multiple ? maxFiles : 1
  const isMaxFileCountReached = maxFileCount === files.length

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: onDrop(model),
    accept,
    multiple,
    disabled: disabled || isMaxFileCountReached,
    maxFiles: maxFileCount,
  })

  return (
    <div {...getRootProps()} css={styles.uploadContainer(!isMaxFileCountReached)}>
      <input {...getInputProps()} />

      {isMaxFileCountReached && (
        <div css={styles.filesContainer}>
          {files.map((file) => (
            <div key={file.name} css={styles.fileWrapper}>
              {file.name}{' '}
              <img
                css={styles.deleteIcon}
                src={crossIcon}
                onClick={() => model.fileRemoved(file)}
              />
            </div>
          ))}
        </div>
      )}

      {!isMaxFileCountReached && (
        <>
          <div css={styles.uploadIconWrapper}>
            <img css={styles.uploadIcon} src={uploadIcon} />
          </div>
          <div css={styles.textContainer}>
            {isDragActive ? (
              <span css={styles.text.main}>Drop your file here</span>
            ) : (
              <>
                <div>
                  <span css={styles.text.accent}>Click to upload </span>
                  <span css={styles.text.main}>or drag and drop</span>
                </div>
                <div>
                  <span css={styles.text.main}>{additionalText}</span>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}
