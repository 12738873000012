import { type TypeOptions } from 'react-toastify'
import { ErrorCircleIcon } from '~/shared/assets/icons/components'
import { CircleCheckIcon } from '~/shared/assets/icons/components/circle-check'

export const getNotificationIcon = (type: TypeOptions | undefined) => {
  switch (type) {
    case 'error':
      return <ErrorCircleIcon />
    default:
      return <CircleCheckIcon />
  }
}
