import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const CheckIcon: FC<Props> = ({ width = 17, height = 16, color = '#009F9D' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8332 4L6.49984 11.3333L3.1665 8"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
