import { createGate } from 'effector-react'
import { atom } from '~/shared/lib/factory'

export const bookingCancellationDetailsPageModel = atom(() => {
  const BookingCancellationDetailsGate = createGate<number>()

  return {
    BookingCancellationDetailsGate,
  }
})
