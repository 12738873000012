import React, { FC, PropsWithChildren } from 'react'
import { Button, NotificationPopup } from '~/shared/ui'
import { styles } from './styles'

interface InfoPopupProps {
  error: string
  onClose: () => void
  isOpen: boolean
  title: string
  buttonText?: string
}
export const InfoBookingPopup: FC<PropsWithChildren<InfoPopupProps>> = ({
  error,
  onClose,
  isOpen,
  title,
  buttonText = 'Proceed to payment',
}) => {
  return (
    <NotificationPopup isOpen={isOpen} onClose={onClose}>
      <div css={styles.popupContent.wrapper}>
        <h2 css={styles.popupContent.title}>{title}</h2>
        <p css={styles.popupContent.desc}>{error}</p>
        <Button css={styles.popupContent.button} onClick={onClose}>
          {buttonText}
        </Button>
      </div>
    </NotificationPopup>
  )
}
