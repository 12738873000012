import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: grid;
    grid-row-gap: 24px;
  `,

  container: css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};
    padding-bottom: 14px;
  `,

  title: css`
    margin: 26px 0 14.5px 22px;
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 24px;
    color: ${Colors.NEUTRAL_BLACK};
  `,
}
