import { SerializedStyles } from '@emotion/react'
import { useStore } from 'effector-react'
import { FC } from 'react'
import { navigationModel } from '~/entities/navigation'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { arrowLeftIcon } from '~/shared/assets/icons'
import { AppRoute } from '~/shared/config'
import { Button, Link, Logo } from '~/shared/ui'
import { onboardingLayoutModel } from '../..'
import { styles } from './styles'

interface Props {
  isShowSkipLink: boolean
  onLogoClick: () => void
  onPrev?: () => void
  extendStyles?: {
    container: SerializedStyles
  }
  navbar?: JSX.Element
}

export const OnboardingHeader: FC<Props> = ({
  isShowSkipLink,
  extendStyles,
  onLogoClick,
  onPrev,
  navbar,
}) => {
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)

  const logoClickHandler = () => {
    onLogoClick && !isIframe && onLogoClick()
    isIframe && navigationModel.pathChanged(AppRoute.Inspiration)
    onboardingLayoutModel.logoClickedTracked()
  }

  return (
    <div css={[styles.wrapper, isIframe && styles.iframeWrapper]}>
      <div css={[styles.container, extendStyles?.container]}>
        {onPrev && (
          <Button css={styles.backButton} variant="link" onClick={onPrev}>
            <img src={arrowLeftIcon} alt="back-arrow-icon" />
          </Button>
        )}
        <Logo css={styles.logo} type="dark" onClick={logoClickHandler} />

        <div css={styles.actionsBlock}>
          {isShowSkipLink && !isIframe && (
            <h1 css={styles.alreadyRegistered}>
              Already registered?&nbsp;
              <Link
                to={AppRoute.Destinations}
                onClick={() => onboardingSessionModel.onboardingSkipped()}
              >
                Sign in{' '}
              </Link>
            </h1>
          )}
          {navbar}
        </div>
      </div>
    </div>
  )
}
