import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'
import { SelectGuests } from '~/features/onboarding/select-guests'

import { tripInfoModel } from '~/entities/trip-info'
import { Heading } from '~/shared/ui'

import { guestsPageModel } from '..'
import { styles } from './styles'

export const GuestsPage: FC = () => {
  useGate(guestsPageModel.Gate)
  const roomsAmount = useStore(tripInfoModel.$roomsAmount)
  const childrenAmount = useStore(tripInfoModel.$childrenAmount)
  const adultsAmount = useStore(tripInfoModel.$adultsAmount)

  const guestsAmout = childrenAmount + adultsAmount

  return (
    <OnboardingLayout
      onNext={guestsPageModel.nextStepNavigated}
      onReset={guestsPageModel.stateReset}
      text={`${roomsAmount} Rooms, ${guestsAmout} Guests`}
    >
      <div css={styles.wrapper}>
        <Heading
          title="Who's travelling?"
          subtitle="Let us know the number of guests and the rooms you'll require."
          css={styles.headingWrapper}
          extendStyles={{ title: styles.headingTitle }}
        />
        <SelectGuests />
      </div>
    </OnboardingLayout>
  )
}
