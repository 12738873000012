import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  base: css`
    color: ${Colors.DARK_1};
    font-family: ${Fonts.Joane};
    font-size: 40px;
    font-weight: 600;
    line-height: 1.25;

    ${Breakpoints.SM} {
      font-size: 28px;
    }
  `,
}
