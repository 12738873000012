import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { oauthModel } from '~/features/user/oauth/model'
import { navigationModel } from '~/entities/navigation'
import { AppRoute } from '~/shared/config'

export const VerificationPage = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const accessToken = searchParams.get('accessToken')!
    const refreshToken = searchParams.get('refreshToken')!
    const userId = searchParams.get('userId')!
    const error = searchParams.get('error')!

    if (accessToken && refreshToken && userId) {
      oauthModel.oAuthLoginInitiated({ accessToken, refreshToken, userId })
    } else {
      navigationModel.pathChanged(AppRoute.Destinations)
      oauthModel.oAuthFailed(error)
    }
  }, [location.search])

  return <div></div>
}
