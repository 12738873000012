import { css } from '@emotion/react'

export const styles = {
  base: css`
    width: 136px;
    height: 16px;
    cursor: pointer;
  `,

  light: css`
    filter: invert(1);
  `,
}
