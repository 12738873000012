import { invoke } from '@withease/factories'
import { atom } from '~/shared/lib/factory'
import { getBookingFactory } from './get-booking'
import { getBookingsFactory } from './get-bookings'

export const searchBookingModel = atom(() => {
  const $$getBookingsFactory = invoke(getBookingsFactory)
  const $$getBookingFactory = invoke(getBookingFactory, { $$getBookingsFactory })

  return {
    $$getBookingFactory,
    $$getBookingsFactory,
  }
})
