import { FC } from 'react'
import { styles } from './styles'

interface Props {
  text?: string
  loader?: string

  additionalCutHeight?: number
}

export const Loading: FC<Props> = ({ text, loader, additionalCutHeight }) => {
  return (
    <div css={styles.container(additionalCutHeight)} data-testid="loading-page">
      {text && <p css={styles.text}>{text}</p>}

      {loader && <img css={styles.loader} src={loader} />}
    </div>
  )
}
