import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

export interface Props {
  value: number
  onMinus: () => void
  onPlus: () => void

  minusDisabled?: boolean
  plusDisabled?: boolean

  extendStyles?: {
    container: SerializedStyles
  }
}

export const Counter: FC<Props> = ({
  value,
  onMinus,
  onPlus,
  minusDisabled,
  plusDisabled,
  extendStyles,
}) => {
  return (
    <div css={[styles.container, extendStyles?.container]}>
      <button css={styles.circle} onClick={onMinus} disabled={minusDisabled}>
        -
      </button>

      <div>{value}</div>

      <button css={styles.circle} onClick={onPlus} disabled={plusDisabled}>
        +
      </button>
    </div>
  )
}
