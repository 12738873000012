import { sample } from 'effector'
import { not } from 'patronum'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'
import { flexibleDateOptionToStringDate } from '~/features/onboarding/schedule-trip-dates/lib'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { Gate, nextStepNavigated, summaryStepSkipped } from './'

sample({
  clock: nextStepNavigated,
  source: {
    flexibleDates: scheduleTripDatesModel.$flexibleDates,
    stayDuration: scheduleTripDatesModel.$stayDuration,
  },
  filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
  fn: ({ flexibleDates, stayDuration }) => ({
    name: eventNames.completedDatesStep,
    properties: {
      type: 'flexible',
      dates: flexibleDates?.map(({ option }) => flexibleDateOptionToStringDate(option)),
      duration: stayDuration.option,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    datesRange: scheduleTripDatesModel.$exactDatesRange,
    shiftDates: scheduleTripDatesModel.$selectedDaysNearby,
  },
  filter: not(scheduleTripDatesModel.$isFlexibleOptionSelected),
  fn: ({ datesRange, shiftDates }) => ({
    name: eventNames.completedDatesStep,
    properties: {
      type: 'exact',
      start_date: datesRange.startDate.toISOString(),
      end_date: datesRange.endDate.toISOString(),
      shift_dates: shiftDates.option,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: summaryStepSkipped,
  fn: () => ({
    name: eventNames.completedSummaryStep,
    properties: {
      skipped: true,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  fn: () => ({
    name: eventNames.quizDatesVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    flexibleDates: scheduleTripDatesModel.$flexibleDates,
    stayDuration: scheduleTripDatesModel.$stayDuration,
  },
  filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
  fn: ({ flexibleDates, stayDuration }) => ({
    name: eventNames.quizDatesCompleted,
    properties: {
      type: 'flexible',
      dates: flexibleDates?.map(({ option }) => flexibleDateOptionToStringDate(option)),
      duration: stayDuration.option === 0 ? '2' : stayDuration.option,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    datesRange: scheduleTripDatesModel.$exactDatesRange,
    shiftDates: scheduleTripDatesModel.$selectedDaysNearby,
  },
  filter: not(scheduleTripDatesModel.$isFlexibleOptionSelected),
  fn: ({ datesRange, shiftDates }) => ({
    name: eventNames.quizDatesCompleted,
    properties: {
      type: 'exact',
      start_date: datesRange.startDate.toISOString(),
      end_date: datesRange.endDate.toISOString(),
      shift_dates: shiftDates.option,
    },
  }),
  target: analyticsModel.track,
})
