import { FlightOffer, FlightOfferDirection } from '@softcery/awayaway-apiclient'
import getSymbolFromCurrency from 'currency-symbol-map'
import { FC } from 'react'
import { CheckIcon } from '~/shared/assets/icons/components'
import { formatFromNanoseconds, formatToCustom } from '~/shared/lib/date'
import { normalizeStringCase } from '~/shared/lib/string'
import { OfferDetail } from './detail'
import { styles } from './styles'

interface Props {
  offer: FlightOffer
  onSelect?: (offer: FlightOffer) => void
  isSelected?: boolean
  showHeader?: boolean
  showFooter?: boolean
  flightType?: string
}

export const OfferCard: FC<Props> = ({
  offer,
  onSelect,
  isSelected,
  showHeader = true,
  showFooter = true,
  flightType,
}) => {
  const cabinClass = offer.cabinClass
    ? normalizeStringCase(offer.cabinClass) + ' ' + 'Class'
    : ''

  const formatData = (directions: FlightOfferDirection[] | undefined) => {
    const date = (destinationDate: string | undefined, type: 'hours' | 'days') =>
      destinationDate
        ? formatToCustom(
            new Date(destinationDate),
            type === 'days' ? 'ddd, DD MMM' : 'HH:mm',
          )
        : ''

    if (!directions || directions.length === 0) {
      return []
    }

    return directions
      .map((direction) => {
        const segments = direction.segments
        if (!segments || segments.length === 0) {
          return null // Handle case where segments are undefined or empty
        }

        const firstSegment = segments[0]
        const lastSegment = segments[segments.length - 1]

        const departureTime = firstSegment?.departingAt
          ? new Date(firstSegment.departingAt).getTime()
          : 0
        const arrivalTime = lastSegment?.arrivingAt
          ? new Date(lastSegment.arrivingAt).getTime()
          : 0
        const flightDurationMilliseconds =
          arrivalTime && departureTime ? arrivalTime - departureTime : 0

        const uniqueIATACodes: string[] = segments.reduce((codes, segment) => {
          if (segment.origin?.iataCode) {
            codes.push(segment.origin.iataCode)
          }
          if (segment.destination?.iataCode) {
            codes.push(segment.destination.iataCode)
          }
          return codes
        }, [] as string[])

        const uniqueIATACodesSet = new Set(uniqueIATACodes)
        if (firstSegment?.origin?.iataCode) {
          uniqueIATACodesSet.delete(firstSegment.origin.iataCode)
        }
        if (lastSegment?.destination?.iataCode) {
          uniqueIATACodesSet.delete(lastSegment.destination.iataCode)
        }
        const uniqueIATACodesArray = Array.from(uniqueIATACodesSet)

        return {
          departingDate: date(firstSegment?.departingAt, 'days'),
          arrivingDate: date(lastSegment?.arrivingAt, 'days'),
          departingTime: date(firstSegment?.departingAt, 'hours'),
          arrivingTime: date(lastSegment?.arrivingAt, 'hours'),
          originCity: firstSegment?.origin?.cityName || '',
          destinationCity: lastSegment?.destination?.cityName || '',
          originIATA: firstSegment?.origin?.iataCode || '',
          destinationIATA: lastSegment?.destination?.iataCode || '',
          flightDuration: formatFromNanoseconds(
            flightDurationMilliseconds * 1e6,
            'milliseconds',
          ),
          stopsAmount: uniqueIATACodesArray || [],
        }
      })
      .filter(Boolean)
  }

  return (
    <div css={styles.container(isSelected)}>
      <div css={styles.mainWrapper}>
        {formatData(offer.directions).map((direction, index) => (
          <div key={index} css={styles.main}>
            {showHeader && (
              <header css={styles.header}>
                {flightType === 'return' && (
                  <div css={styles.flightType}>
                    {flightType === 'return'
                      ? index === 0
                        ? 'Outbound'
                        : index === 1
                        ? 'Inbound'
                        : ''
                      : ''}
                  </div>
                )}

                <div css={styles.headerContainer}>
                  {offer.owner && (
                    <img css={styles.logo} src={offer.owner?.logoUrl} alt="owner-img" />
                  )}

                  <div css={styles.details}>
                    {cabinClass && <OfferDetail label={cabinClass} />}
                  </div>
                </div>
              </header>
            )}
            <div css={styles.flightWrapper}>
              <div>
                <p css={styles.time}>{direction!.departingTime}</p>
                <p css={styles.location}>{direction!.originCity}</p>
                <p css={styles.date}>{direction!.departingDate}</p>
              </div>

              <div css={styles.route.container}>
                <p css={styles.route.totalTime}>{direction!.flightDuration}</p>

                <div css={styles.route.dottedLine}>
                  <div css={styles.route.location}>
                    <div css={styles.route.locationIndicator} />

                    <p>{direction!.originIATA}</p>
                  </div>

                  {Boolean(direction!.stopsAmount.length) && (
                    <div css={styles.route.stop}>
                      <div />

                      <p>
                        {direction!.stopsAmount.length} stop
                        {direction!.stopsAmount.length > 1 ? 's' : ''}{' '}
                        {direction!.stopsAmount.length === 1 && (
                          <span>{direction!.stopsAmount[0]}</span>
                        )}
                      </p>
                    </div>
                  )}

                  <div css={styles.route.location}>
                    <div css={styles.route.locationIndicator} />

                    <p>{direction!.destinationIATA}</p>
                  </div>
                </div>
              </div>

              <div css={styles.destinationInfo}>
                <p css={styles.time}>{direction!.arrivingTime}</p>
                <p css={styles.location}>{direction!.destinationCity}</p>
                <p css={styles.date}>{direction!.arrivingDate}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showFooter && (
        <footer css={styles.footer}>
          <button css={styles.button(isSelected!)} onClick={() => onSelect?.(offer)}>
            {isSelected ? (
              <span>
                Selected <CheckIcon />
              </span>
            ) : (
              'Choose this flight'
            )}
          </button>

          <p css={styles.total.amount}>
            {getSymbolFromCurrency(offer.currency || '')}
            {offer.amount || 0} <span css={styles.total.label}>/total</span>
          </p>
        </footer>
      )}
    </div>
  )
}
