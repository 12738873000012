import { createEffect, createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { NavigateFunction } from 'react-router-dom'
import { LANDING_PAGE_URL, LocalStorageKeys } from '~/shared/config'

export const Gate = createGate<{
  navigate: NavigateFunction
  path: string
}>()

export const pathChanged = createEvent<string>()
export const pathReplace = createEvent<string>()
export const pathPushed = createEvent<string>()
export const landingPageNavigated = createEvent<string>()

export const goBack = createEvent()
export const reloadPage = createEvent()

export const $path = createStore('').on(Gate.state, (_, { path }) => path)
export const $landingPageUrl = createStore(LANDING_PAGE_URL)
persist({ store: $landingPageUrl, key: LocalStorageKeys.LandingPageUrl })

const navigateToPathFx = createEffect(
  async ({
    navigate,
    path,
    replace,
  }: {
    navigate: NavigateFunction
    path?: string
    replace?: boolean
  }) => {
    if (path != undefined) {
      navigate(path, replace ? { replace } : {})
    } else {
      navigate(-1)
    }
  },
)

const reloadPageFx = createEffect(() => window.location.reload())

sample({
  clock: reloadPage,
  target: reloadPageFx,
})

sample({
  clock: pathChanged,
  source: Gate.state,
  fn: ({ navigate }, path) => ({ navigate, path }),
  target: navigateToPathFx,
})

sample({
  clock: landingPageNavigated,
  filter: (url) => url !== undefined,
  target: $landingPageUrl,
})

sample({
  clock: pathPushed,
  source: { oldPath: $path, navigationState: Gate.state },
  fn: ({ oldPath, navigationState }, newPath) => ({
    navigate: navigationState.navigate,
    path: `${oldPath}/${newPath}`,
  }),
  target: navigateToPathFx,
})

sample({
  clock: pathReplace,
  source: Gate.state,
  fn: ({ navigate }, path) => ({ navigate, path, replace: true }),
  target: navigateToPathFx,
})

sample({
  clock: goBack,
  source: Gate.state,
  fn: ({ navigate }) => ({ navigate }),
  target: navigateToPathFx,
})
