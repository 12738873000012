import { css } from '@emotion/react'
import { calendarIcon } from '~/shared/assets/icons/primary'
import { Breakpoints, Colors } from '~/shared/styles'

const date = css`
  width: 100%;

  display: flex;
  align-items: center;

  border: 1px solid ${Colors.PRIMARY_GREEN};
  border-left: none;

  font-size: 18px;
  letter-spacing: 2%;
  color: ${Colors.NEUTRAL_BLACK};

  padding: 0 22px;
`

export const styles = {
  container: css`
    ${Breakpoints.SM} {
      display: none;
    }
  `,

  label: css`
    display: inline-block;
    width: 50%;

    font-size: 20px;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  datesWrapper: css`
    height: 70px;
    display: flex;

    margin-top: 6px;
  `,

  mapMarker: css`
    width: 70px;
    height: 100%;

    border: 1px solid ${Colors.PRIMARY_GREEN};

    background: url(${calendarIcon}) no-repeat center;
    background-color: ${Colors.PRIMARY_GREEN}0F;
  `,

  checkInDate: css`
    max-width: 280px;
    ${date}
  `,

  checkOutDate: css`
    max-width: 350px;
    ${date}
  `,

  dateRange: css`
    width: 100%;
    ${date}
  `,
}
