import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'
import { SelectImages, selectImagesModel } from '~/features/onboarding/select-images'

import { selectLocationsModel } from '~/features/onboarding/select-locations'

import { onboardingSessionModel } from '~/entities/onboarding-session'
import { ArrowRightIcon } from '~/shared/assets/icons/components'
import { isSafari } from '~/shared/lib/is-ios'
import { Button, Heading } from '~/shared/ui'

import { holidaysPageModel } from '..'

import { styles } from './styles'

export const HolidaysPage: FC = () => {
  useGate(holidaysPageModel.Gate)
  const selectedImageIds = useStore(selectImagesModel.$selectedImageIds)
  const isStepSkippable = useStore(holidaysPageModel.$isStepSkippable)

  const locations = useStore(selectLocationsModel.$selectedLocations)
  const countryCodes = locations.map((location) => location.countryCode)
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)
  const displayDesignTripOverlay = isIframe && (isMobile || isSafari)

  useGate(selectImagesModel.Gate, { countryCodes })

  return (
    <OnboardingLayout
      onReset={holidaysPageModel.stateReset}
      onNext={() => holidaysPageModel.nextStepNavigated()}
      text={`${selectedImageIds.length} Selected`}
      isStepSkippable={isStepSkippable}
    >
      <div css={styles.wrapper}>
        <Heading
          title="What does your trip look like?"
          subtitle="We cover global destinations, but we might be missing your exact location. Select the images that closely resemble your dream trip."
          description="The more inspiration, the better."
          css={styles.headingWrapper}
          extendStyles={{ title: styles.headingTitle }}
        />
        <SelectImages />

        {displayDesignTripOverlay && (
          <div css={styles.iframeOverlay.wrapper}>
            <div css={styles.iframeOverlay.content}>
              <h4 css={styles.iframeOverlay.title}>Get personalized recommendations</h4>
              <p css={styles.iframeOverlay.desc}>
                In the next step, you can select images that resemble the kind of travel
                you’re looking for. We’ll use these as inspiration for our recommendations
                to you.
              </p>
              <Button
                css={styles.iframeOverlay.button}
                onClick={() => holidaysPageModel.goToAppClicked()}
              >
                Design my trip
                <ArrowRightIcon color="#ffffff" height={22} width={22} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </OnboardingLayout>
  )
}
