import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  overview: {
    container: css`
      margin-bottom: 24px;
    `,

    wrapper: css`
      background-color: ${Colors.NEUTRAL_WHITE};
      padding: 20px;
      border-radius: 10px;
      width: 540px;
      border: 1px solid ${Colors.NEUTRAL_SILVER};

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,

    header: {
      wrapper: css`
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      `,

      img: css`
        height: 52px;
        width: 60px;
        border-radius: 5px;
        margin-right: 16px;
      `,

      content: css``,

      title: css`
        font-weight: 500;
        font-size: 20px;
        color: ${Colors.NEUTRAL_BLACK};

        margin-bottom: 4px;
      `,

      subtitle: css`
        font-weight: 400;
        font-size: 18px;
        color: ${Colors.NEUTRAL_BLACK};
      `,
    },
  },

  room: {
    title: css`
      font-size: 18px;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.28px;
      display: flex;
      margin-top: 8px;

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,

    board: css`
      display: flex;
      padding: 2px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: ${Colors.LIGHT_2};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.1;
      letter-spacing: 0.23px;
      margin-left: 8px;
      text-align: center;
    `,

    policy: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-top: 5px;
      svg {
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    `,
  },

  flights: {
    wrapper: css`
      margin-top: 9px;
      display: flex;
      align-items: center;
      gap: 8px;
    `,

    title: css`
      font-weight: 400;
      font-size: 18px;
      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,

    type: css`
      font-size: 14px;
      color: ${Colors.NEUTRAL_BLACK};
      background-color: ${Colors.LIGHT_2};
      border-radius: 4px;
      padding: 2px 4px;
    `,

    dates: css`
      margin-top: 4px;
      font-size: 14px;
      color: ${Colors.NEUTRAL_GREY};
    `,
  },

  baggage: {
    wrapper: css`
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;
    `,

    item: css`
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      color: ${Colors.NEUTRAL_BLACK};

      svg {
        margin-right: 8px;
      }

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,
  },

  seating: {
    wrapper: css`
      margin-top: 8px;
    `,

    row: css`
      display: flex;
      gap: 48px;
      margin-top: 8px;

      ${Breakpoints.MD} {
        flex-direction: column;
        gap: 16px;
      }
    `,

    col: css``,

    title: css`
      font-size: 14px;
      color: ${Colors.NEUTRAL_GREY};
    `,

    list: css`
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 4px;
    `,

    item: css`
      font-weight: 400;
      font-size: 18px;
      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,
  },

  guests: {
    wrapper: css`
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    item: css`
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: block;
        height: 2px;
        width: 2px;
        background-color: ${Colors.NEUTRAL_BLACK};
        border-radius: 50%;
      }
    `,

    name: css`
      font-size: 18px;
      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,

    date: css`
      font-size: 14px;
      color: ${Colors.NEUTRAL_GREY};
    `,
  },

  infoTile: css`
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: ${Colors.NEUTRAL_SILVER};
      margin-top: 16px;
    }
  `,

  item: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};

    :first-child {
      margin-top: 10px;
    }

    :not(:last-child) {
      margin-bottom: 10px;
    }
  `,
}
