import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  base: css`
    display: inline-block;
    font-family: ${Fonts.Calibre};
    font-weight: 400px;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 8px;
  `,

  required: css`
    position: relative;

    :after {
      content: '*';
      position: absolute;
      right: -10px;
      top: 0;
      color: #f10000;
      font-size: 18px;
    }
  `,
}
