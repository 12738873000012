import { SerializedStyles } from '@emotion/react'
import { FlightOffer } from '@softcery/awayaway-apiclient'
import dayjs from 'dayjs'
import { useStore } from 'effector-react'
import { FC, useState } from 'react'
import { STEPS } from '~/pages/booking/config'
import { $currentStep } from '~/pages/booking/model'
import { CalculateService } from '~/features/select-services/types'
import { SelectedHotelRoom } from '~/entities/room'
import { crossIcon } from '~/shared/assets/icons'
import { InfoIcon } from '~/shared/assets/icons/components'
import { calendarIcon as fadedCalendarIcon } from '~/shared/assets/icons/grey'
import { calendarIcon, moonIcon, usersIcon } from '~/shared/assets/icons/primary'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatToCustom } from '~/shared/lib/date'
import { normalizeStringCase, normalizeStringEnding } from '~/shared/lib/string'
import { Image, Modal, Tooltip } from '~/shared/ui'
import { formatBookingDates, getBookedRoom, getCancellationDeadlineDate } from '../../lib'
import { CancellationFeesDetails } from '../cancellation-policy/flexible-policy-details/cancellation-fees-details'
import { SidebarInfoTile } from '../sidebar-info-tile'
import { BookingTimer } from '../timer'
import { styles } from './styles'

interface Props {
  hotelImage?: string
  hotelName?: string
  destinationName?: string
  checkIn?: string | Date
  checkOut?: string | Date

  rooms?: SelectedHotelRoom[]
  guests?: {
    adults: number
    children: number
    rooms: number
  }
  flightOffer?: FlightOffer | null
  flightType?: string | null
  isFlightIncluded?: boolean

  totalPrice?: number
  currency?: string

  selectedRooms: SelectedHotelRoom[]

  additionalServices: CalculateService | null
  newDetails?: {
    newCheckIn?: Date
    newCheckOut?: Date
    newTotalPrice?: number
  }

  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }
  onEndTime?: () => void
}

export const BookingSidebar: FC<Props> = ({
  hotelImage,
  hotelName,
  destinationName,
  checkIn,
  checkOut,
  rooms,
  currency = '',
  guests,
  selectedRooms,
  extendStyles,
  flightOffer,
  flightType,
  isFlightIncluded,
  onEndTime,
  newDetails,
  additionalServices,
}) => {
  const [isPolicyDetailsVisible, setPolicyDetailsVisibility] = useState(false)
  const { newCheckIn, newCheckOut } = newDetails || {}
  const roomsPrice = selectedRooms.reduce((acc, room) => acc + room.price!, 0)
  const totalPrice =
    roomsPrice +
    (isFlightIncluded ? flightOffer?.amount || 0 : 0) +
    (additionalServices?.totalCount || 0)

  const currentStep = useStore($currentStep)
  const startDate = dayjs(checkIn)
  const endDate = dayjs(checkOut)

  const nightsCount = endDate.diff(startDate, 'day')

  const flightDirections = flightOffer?.directions
  const flightOrigin = flightDirections?.[0].segments?.[0].origin?.cityName
  const flightDestination =
    flightDirections?.[0].segments?.slice(-1)[0].destination?.cityName

  const isPaymentStep = currentStep === STEPS.PAYMENT

  return (
    <div key={flightOffer?.id ?? 'flight_id'}>
      {flightOffer?.expiresAt ? (
        <BookingTimer expiresAt={flightOffer?.expiresAt} onEndTime={onEndTime} />
      ) : null}

      {!isPaymentStep && (
        <div css={styles.container}>
          <Image
            image={hotelImage}
            desiredSize={'bigger'}
            extendStyle={styles.image}
            key={hotelImage}
          />

          <h1 css={styles.title}>{destinationName || '-'}</h1>

          {rooms && <p css={styles.subtitle}>{getBookedRoom(rooms)}</p>}

          <div css={styles.infoTile}>
            <SidebarInfoTile
              icon={newDetails ? fadedCalendarIcon : calendarIcon}
              title={newDetails ? 'Original dates' : 'Dates'}
              value={formatBookingDates(checkIn, checkOut)}
              faded={!!newDetails}
            />

            {newDetails && (
              <SidebarInfoTile
                icon={calendarIcon}
                title="New dates"
                value={formatBookingDates(newCheckIn, newCheckOut)}
              />
            )}
          </div>

          {guests && (
            <div css={styles.infoTile}>
              <SidebarInfoTile
                icon={usersIcon}
                title={'Guests'}
                value={`${guests.adults} Adults${
                  guests.children > 0 ? `, ${guests.children} Children ` : ''
                } `}
              />
            </div>
          )}

          <div css={styles.infoTile}>
            <SidebarInfoTile
              editable
              icon={usersIcon}
              title={'Hotel'}
              value={`${hotelName} `}
            />
          </div>

          {selectedRooms && (
            <div css={styles.infoTile}>
              <div>
                <SidebarInfoTile editable icon={moonIcon} title={'Rooms'} />
                {selectedRooms.map((room, index) => (
                  <div key={index} css={styles.item}>
                    <p css={styles.room.title}>
                      {normalizeStringCase(room.name)}{' '}
                      <span css={styles.room.board}>
                        {normalizeStringEnding(room.rate?.boardName)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {flightOffer && isFlightIncluded && (
            <div css={styles.infoTile}>
              <div>
                <SidebarInfoTile editable title={'Flights'} />
                <p css={styles.room.title}>
                  {`${flightOrigin} - ${flightDestination}`}
                  <span css={styles.room.board}>
                    {normalizeStringCase(flightType || '')}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      <div css={styles.price.container(isPaymentStep)}>
        <h1 css={styles.title}>Price Details</h1>

        <div css={styles.price.list}>
          {selectedRooms.map((room, index) => (
            <div key={index}>
              <div css={styles.price.item.row}>
                <p css={styles.price.item.title}>
                  Room {++index} x {nightsCount} {nightsCount > 1 ? 'nights' : 'night'}
                </p>
                <p css={styles.price.item.value}>
                  {getPriceWithCurrency(room.rate!.amount!, currency)}
                </p>
              </div>

              <div css={styles.price.item.subtitle}>
                {getPriceWithCurrency(room.rate!.dailyAmount!, currency)}/per night
              </div>

              {room.rate!.cancellationPolicies?.[0] &&
                room.rate!.refundability !== 'NON_REFUNDABLE' && (
                  <div>
                    <p css={styles.room.policy}>
                      Fully refundable before{' '}
                      {formatToCustom(
                        getCancellationDeadlineDate(
                          room.rate!.cancellationPolicies?.[0].from || '',
                        ),
                        'DD MMM',
                      )}
                      <Tooltip
                        content={
                          <CancellationFeesDetails
                            policies={room.rate!.cancellationPolicies || []}
                            currency={currency}
                          />
                        }
                        css={styles.rates.desktopTooltip.wrapper}
                        extendStyle={extendStyles?.tooltip || styles.tooltip.container}
                        tooltipPosition={{
                          side: 'bottom',
                          align: 'start',
                          alignOffset: 40,
                        }}
                      >
                        <div
                          css={[
                            extendStyles?.focusedText,
                            styles.rates.desktopTooltip.icon,
                          ]}
                        >
                          <InfoIcon color={'#8E8E8E'} />
                        </div>
                      </Tooltip>
                      <div css={styles.rates.mobileTooltip.wrapper}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            setPolicyDetailsVisibility(!isPolicyDetailsVisible)
                          }}
                        >
                          <InfoIcon color={'#8E8E8E'} />
                        </div>
                      </div>
                    </p>

                    <Modal
                      isOpen={isPolicyDetailsVisible}
                      onClose={() => setPolicyDetailsVisibility(false)}
                      centred
                    >
                      <div css={styles.rates.mobileTooltip.wrapper}>
                        <div css={styles.rates.mobileTooltip.container}>
                          <img
                            src={crossIcon}
                            css={styles.rates.mobileTooltip.cross}
                            onClick={() => setPolicyDetailsVisibility(false)}
                          />
                          <div css={styles.rates.mobileTooltip.top}>
                            <InfoIcon color={'#8E8E8E'} />
                            Cancellation details
                          </div>

                          <div css={styles.rates.mobileTooltip.content}>
                            You can cancel your reservation for a full refund, excluding
                            cancellation fees, until{' '}
                            {formatToCustom(
                              getCancellationDeadlineDate(
                                room.rate!.cancellationPolicies?.[0].from || '',
                              ),
                              'DD/MM/YYYY',
                            )}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                )}
            </div>
          ))}

          {!!additionalServices?.seat.totalCount && (
            <div css={styles.price.item.row}>
              <p css={styles.price.item.title}>
                Seats X {additionalServices.seat.totalCount}
              </p>
              <p css={styles.price.item.value}>
                {getPriceWithCurrency(
                  additionalServices.seat.totalSeatPrice || 0,
                  currency,
                )}
              </p>
            </div>
          )}

          {!!additionalServices?.baggage.totalCount && (
            <div css={styles.price.item.row}>
              <p css={styles.price.item.title}>
                Baggage X {additionalServices.baggage.totalCount}
              </p>
              <p css={styles.price.item.value}>
                {getPriceWithCurrency(
                  additionalServices.baggage.totalBaggagePrice || 0,
                  currency,
                )}
              </p>
            </div>
          )}

          {flightOffer && isFlightIncluded && (
            <div css={styles.price.item.row}>
              <p css={styles.price.item.title}>
                Flight X {normalizeStringCase(flightType || '')}
              </p>
              <p css={styles.price.item.value}>
                {getPriceWithCurrency(flightOffer?.amount || 0, currency)}
              </p>
            </div>
          )}
        </div>

        <div css={styles.price.total.row}>
          <div css={styles.price.total.title}>Total</div>
          <p css={styles.price.total.value}>
            {getPriceWithCurrency(totalPrice, currency)}
          </p>
        </div>
      </div>
    </div>
  )
}
