import dayjs from 'dayjs'
import { combine, createEvent, restore, sample, createStore } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { LocalStorageKeys } from '~/shared/config'
import { addDaysToDate } from '~/shared/lib/date'
import { MAX_EXACT_DATES_AMOUNT } from './config'
import {
  daysNearbyOptions,
  stayDurationOptions,
  monthAndYearOptions,
  daysBetweenSelectedDates,
} from './lib'
import {
  DaysNearbyOption,
  MonthAndYearOption,
  StayDurationOption,
  Tab,
  TabBarOption,
  Range,
} from './types'

export const tabSelected = createEvent<Tab>()
export const daysNearbyChanged = createEvent<TabBarOption<DaysNearbyOption>>()
export const stayDurationChanged = createEvent<TabBarOption<StayDurationOption>>()
export const customStayDurationChanged = createEvent<number>()
export const flexibleDateSelected = createEvent<TabBarOption<MonthAndYearOption>>()
export const exactDatesChanged = createEvent<Range>()
export const exactDatesRemoved = createEvent()
export const exactEndDateRemoved = createEvent()
export const stateReset = createEvent()
export const expiredDatesReset = createEvent()

export const $activeTab = restore(tabSelected, 2)
export const $isFlexibleOptionSelected = $activeTab.map((tab) => tab === 2)
export const $isExactDatesDurationLimitReached = createStore(false)

export const $selectedDaysNearby = restore(daysNearbyChanged, daysNearbyOptions[0])

//exact
export const $exactDatesRange = onboardingSessionModel.$savedDates.map(
  ({ startDate, endDate }) => ({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  }),
)

// flexible
export const $stayDuration = restore(stayDurationChanged, stayDurationOptions[0]).on(
  customStayDurationChanged,
  (state, durationDays) => ({
    ...state,
    option: durationDays,
  }),
)

export const $flexibleDates = createStore<TabBarOption<MonthAndYearOption>[]>([
  monthAndYearOptions[1],
])

export const $isFlexibleDatesTouched = createStore(false)

export const $displayingFlexibleDuration = combine(
  $stayDuration,
  $flexibleDates,
  (stayDuration, flexibleDates) => {
    const displayingDuration: string =
      stayDuration.label === 'Custom'
        ? stayDuration.option + (stayDuration.option === 1 ? ' day' : ' days')
        : stayDuration.label

    const dates = flexibleDates
      .map(({ option }) => {
        return (
          dayjs()
            .month(option.month - 1)
            .format('MMM') +
          ' ' +
          option.year
        )
      })
      .join(', ')

    return `${displayingDuration} in ${dates}`
  },
)

persist({ store: $selectedDaysNearby, key: LocalStorageKeys.ExactDatesDaysShift })
persist({ store: $stayDuration, key: LocalStorageKeys.FlexibleDatesStayDuration })
persist({ store: $flexibleDates, key: LocalStorageKeys.FlexibleDatesMonthAndYear })
persist({
  store: $isFlexibleDatesTouched,
  key: LocalStorageKeys.IsFlexibleDatesTouched,
})

sample({
  clock: flexibleDateSelected,
  source: {
    dates: $flexibleDates,
    isTouched: $isFlexibleDatesTouched,
  },
  fn: ({ dates, isTouched }, newDate) => {
    if (!isTouched) {
      return [newDate]
    }

    const alreadyExistedDateIndex = dates.findIndex(
      ({ option }) =>
        option.year === newDate.option.year && option.month === newDate.option.month,
    )

    if (alreadyExistedDateIndex >= 0) {
      if (dates.length === 1 && isTouched) {
        return dates
      } else {
        return dates.filter((_, i) => i !== alreadyExistedDateIndex)
      }
    }

    if (dates.length >= 3) {
      return dates
    }

    return [...dates, newDate]
  },
  target: $flexibleDates,
})

sample({
  clock: flexibleDateSelected,
  fn: () => true,
  target: $isFlexibleDatesTouched,
})

sample({
  clock: exactDatesChanged,
  filter: (range: Range): range is { startDate: Date; endDate: Date; key: string } =>
    Boolean(range.startDate && range.endDate),
  fn: (range) => ({
    startDate: range.startDate.toISOString(),
    endDate: range.endDate.toISOString(),
  }),
  target: onboardingSessionModel.exactDatesSaved,
})

sample({
  clock: exactDatesChanged,
  fn: (range: Range): range is { startDate: Date; endDate: Date } =>
    daysBetweenSelectedDates(range.startDate!, range.endDate!) > MAX_EXACT_DATES_AMOUNT,
  target: $isExactDatesDurationLimitReached,
})

sample({
  clock: exactDatesChanged,
  filter: $isExactDatesDurationLimitReached,
  target: exactEndDateRemoved,
})

sample({
  clock: exactEndDateRemoved,
  source: onboardingSessionModel.$exactDateStart,
  fn: (startDate) => addDaysToDate(new Date(startDate), 1).toISOString(),
  target: onboardingSessionModel.$exactDateEnd,
})

sample({
  clock: expiredDatesReset,
  source: onboardingSessionModel.$exactDateStart,
  filter: (startDate) => !Boolean(new Date(startDate) > new Date()),
  target: stateReset,
})

reset({
  clock: [tabSelected, onboardingSessionModel.statusReset],
  target: $isExactDatesDurationLimitReached,
})

reset({
  clock: onboardingSessionModel.statusReset,
  target: $isFlexibleDatesTouched,
})

reset({
  clock: stateReset,
  target: [
    $flexibleDates,
    $stayDuration,
    $exactDatesRange,
    $activeTab,
    $isExactDatesDurationLimitReached,
    $isFlexibleDatesTouched,
  ],
})
