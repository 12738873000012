import { SerializedStyles } from '@emotion/react'
import { FC, InputHTMLAttributes } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { TextInput, Label, Error } from '../../atoms'
import { styles } from './styles'

export interface InputWithLabelProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  register?: UseFormRegister<any>
  inputRef?: any
  error?: string
  label?: string
  required?: boolean
  extendStyle?: {
    wrapper?: SerializedStyles
    input?: SerializedStyles
    label?: SerializedStyles
  }
}

export const InputWithLabel: FC<InputWithLabelProps> = ({
  name,
  label,
  error,
  inputRef,
  register,
  required,
  extendStyle,

  ...props
}) => {
  return (
    <div css={[styles.wrapper, extendStyle?.wrapper]}>
      {label && (
        <Label required={required} extendStyles={extendStyle?.label}>
          {label}
        </Label>
      )}
      <TextInput
        inputRef={inputRef}
        name={name}
        error={!!error}
        register={register}
        extendStyle={extendStyle?.input}
        {...props}
      />
      {!!error && <Error>{error}</Error>}
    </div>
  )
}
