import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  wrapper: css`
    width: 722px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 10px;

    ${Breakpoints.MD} {
      width: 100%;
      padding: 20px;
    }
  `,

  services: {
    wrapper: css`
      margin-bottom: 32px;
    `,
  },

  card: css`
    margin-bottom: 32px;
  `,

  side: css`
    display: grid;
    grid-gap: 20px;
  `,

  error: css`
    margin: 12px 0;
  `,
}
