import { useGate, useStore } from 'effector-react'
import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'
import {
  ScheduleTripDates,
  scheduleTripDatesModel,
} from '~/features/onboarding/schedule-trip-dates'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { destinationModel } from '~/entities/trip'
import { formatToSlashDate } from '~/shared/lib/date'
import { Heading, PageLoader } from '~/shared/ui'
import { availabilityPageModel } from '..'
import { summaryPageModel } from '../../summary'
import { styles } from './styles'

export const AvailabilityPage = () => {
  useGate(onboardingSessionModel.Gate)
  useGate(availabilityPageModel.Gate)

  const { startDate, endDate } = useStore(scheduleTripDatesModel.$exactDatesRange)
  const isFlexibleOptionSelected = useStore(
    scheduleTripDatesModel.$isFlexibleOptionSelected,
  )
  const displayingFlexibleDuration = useStore(
    scheduleTripDatesModel.$displayingFlexibleDuration,
  )
  const summary = useStore(summaryPageModel.$currentSummary)

  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isTripsLoading = useStore(destinationModel.$isTripsLoading)
  const isSummaryGenerated = useStore(summaryPageModel.$initialSummary) !== ''
  const isLoadingMessagesPending = useStore(summaryPageModel.$isLoadingMessagesPending)
  const isLoadingSummaryPending = useStore(summaryPageModel.$isSummaryPending)
  const loadingMessages = useStore(summaryPageModel.$loadingMessages)
  const displayingExactDates = `${formatToSlashDate(startDate)} - ${formatToSlashDate(
    endDate,
  )}`

  return (
    <OnboardingLayout
      onNext={
        isSummaryGenerated || !!summary
          ? availabilityPageModel.nextStepNavigated
          : availabilityPageModel.newTripsInitiated
      }
      onReset={scheduleTripDatesModel.stateReset}
      text={isFlexibleOptionSelected ? displayingFlexibleDuration : displayingExactDates}
      isTripsLoading={isTripsLoading}
      isLoading={isLoadingSummaryPending}
    >
      {isTripsLoading || isNewSummaryPending ? (
        <PageLoader
          loadingMessages={loadingMessages}
          isMessagesLoading={isLoadingMessagesPending}
          isNewSummaryPending={isNewSummaryPending}
        />
      ) : (
        <div css={styles.wrapper}>
          <Heading
            title="When are you travelling?"
            subtitle="We check availability for your dates in advance."
            css={styles.headingWrapper}
            extendStyles={{ title: styles.headingTitle }}
          />
          <ScheduleTripDates />
        </div>
      )}
    </OnboardingLayout>
  )
}
