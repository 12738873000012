import { GroupWithRoomsResponseDto } from '@softcery/awayaway-nodejs-api-client'
import { useStore } from 'effector-react'
import { FC } from 'react'

import { CustomSelectedHotelRoom, RoomSkeleton, hotelRoomModel } from '~/entities/room'

import { Title } from '~/shared/ui'
import { RoomsList } from './rooms-list'
import { styles } from './styles'

interface CustomHotelRooms extends GroupWithRoomsResponseDto {
  rooms: CustomSelectedHotelRoom[]
}

export const SelectRoom: FC = () => {
  const hotelRooms: CustomHotelRooms[] | null = useStore(
    hotelRoomModel.$$getRoomsFactory.$groupWithRoomsList,
  )
  const loading = useStore(hotelRoomModel.$$getRoomsFactory.$loading)

  return (
    <div css={styles.wrapper}>
      <Title css={styles.title}>Your chosen stay: explore rooms </Title>

      {loading ? (
        <RoomSkeleton />
      ) : (
        <div css={styles.container}>
          {!!hotelRooms?.length ? (
            hotelRooms.map(({ rooms, occupancy }, listIndex) => (
              <RoomsList
                key={listIndex}
                rooms={rooms}
                occupancy={occupancy}
                listIndex={listIndex}
                roomAdded={hotelRoomModel.$$manageRoomsDataFactory.roomAdded}
              />
            ))
          ) : (
            <h3 css={styles.rooms.notRooms}>
              We're sorry, but there are no rooms available for this date.
            </h3>
          )}
        </div>
      )}
    </div>
  )
}
