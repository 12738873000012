import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { formatToCustom } from '~/shared/lib/date'
import { Tooltip } from '~/shared/ui/atoms'
import { getCancellationDeadlineDate } from '../../../lib'
import { CancellationFeesDetails } from './cancellation-fees-details'
import { styles } from './styles'

interface Props {
  policies: any[]
  currency: string
  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }
}

export const FlexiblePolicyDetails: FC<Props> = ({
  policies,
  currency,
  extendStyles,
}) => {
  if (policies.length === 0) return null
  return (
    <>
      You can cancel for free, with a full refund before{' '}
      <span css={styles.info.boldText}>
        {' '}
        {formatToCustom(
          getCancellationDeadlineDate(policies[0].from || ''),
          'DD/MM/YYYY',
        )}
      </span>
      . After this date standard{' '}
      <Tooltip
        content={<CancellationFeesDetails policies={policies} currency={currency} />}
        extendStyle={extendStyles?.tooltip || styles.tooltip.container}
        tooltipPosition={{
          side: 'bottom',
          align: 'start',
          alignOffset: 40,
        }}
      >
        <span css={[styles.info.dashedText, extendStyles?.focusedText]}>
          cancellation fees
        </span>
      </Tooltip>{' '}
      may apply.
    </>
  )
}
