import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const Price: FC<Props> = ({ width = 16, height = 16, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M9.00327 4.132L6.25527 9.04H8.60727V7.264H9.48327V9.04H10.7793V9.832H9.48327V11.5H8.60727V9.832H5.21127V9.16L8.04327 4.132H9.00327Z"
        fill="white"
      />
    </svg>
  )
}
