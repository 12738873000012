import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ThumbsUpIcon: FC<Props> = ({
  width = 24,
  height = 24,
  color = '#555555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83317 18.3334H3.33317C2.89114 18.3334 2.46722 18.1578 2.15466 17.8453C1.8421 17.5327 1.6665 17.1088 1.6665 16.6667V10.8334C1.6665 10.3914 1.8421 9.96746 2.15466 9.6549C2.46722 9.34234 2.89114 9.16675 3.33317 9.16675H5.83317M11.6665 7.50008V4.16675C11.6665 3.50371 11.4031 2.86782 10.9343 2.39898C10.4654 1.93014 9.82954 1.66675 9.1665 1.66675L5.83317 9.16675V18.3334H15.2332C15.6351 18.338 16.0252 18.1971 16.3314 17.9368C16.6377 17.6764 16.8396 17.3142 16.8998 16.9167L18.0498 9.41675C18.0861 9.17788 18.07 8.93398 18.0026 8.70196C17.9353 8.46993 17.8183 8.25533 17.6597 8.07301C17.5012 7.89069 17.3049 7.74502 17.0845 7.6461C16.8641 7.54717 16.6248 7.49735 16.3832 7.50008H11.6665Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
