import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  row: (wrap: boolean) => css`
    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: center;
    grid-column-gap: 70px;
    padding: 32px 0;

    ${Breakpoints.MD} {
      grid-template-columns: ${wrap
        ? 'repeat(2, max-content)'
        : 'repeat(4, max-content)'};
      grid-row-gap: ${wrap ? '15px' : '0'};
      padding: 16px 0;
    }
  `,
}
