import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const BorderedCircleCheckIcon: FC<Props> = ({
  width = 40,
  height = 40,
  color = '#009F9D',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6663 18.4666V19.9999C36.6643 23.594 35.5005 27.0911 33.3486 29.9696C31.1966 32.8482 28.1718 34.9541 24.7253 35.9731C21.2787 36.9921 17.5951 36.8698 14.2238 35.6242C10.8525 34.3787 7.9741 32.0768 6.01794 29.0617C4.06178 26.0467 3.13266 22.48 3.36914 18.8938C3.60561 15.3075 4.99502 11.8938 7.33014 9.16173C9.66526 6.42964 12.821 4.52557 16.3267 3.73351C19.8323 2.94145 23.5001 3.30383 26.783 4.7666"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6667 6.66675L20 23.3501L15 18.3501"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
