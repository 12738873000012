import { useStore } from 'effector-react'
import { googleIcon } from '~/shared/assets/icons'
import { ButtonWithLoader } from '~/shared/ui'
import { oauthModel } from '../model'
import { styles } from './styles'

export const OAuthButton = () => {
  const isPending = useStore(oauthModel.$isPending)
  return (
    <ButtonWithLoader
      loading={false}
      css={styles.button.wrapper}
      onClick={() => oauthModel.initiated('google')}
      disabled={isPending}
    >
      <img css={styles.button.icon} src={googleIcon} alt="google-icon" />
      Continue with Google
    </ButtonWithLoader>
  )
}
