import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: (height: number) => css`
    margin-top: 84px;
    min-height: calc(100vh + ${height}px);
    background-color: ${Colors.LIGHT_2};
  `,

  wrapper: css`
    width: fit-content;
    margin: 0 auto;

    ${Breakpoints.MD} {
      width: 100%;
      padding: 0 20px;
    }
  `,

  aside: css`
    padding: 20px 0 40px 0;
  `,

  previousButton: css`
    margin-bottom: 30px;
    text-align: left;
    text-decoration: underline;
    color: ${Colors.PRIMARY_GREEN};
  `,

  content: css`
    display: flex;
    flex-direction: column;
    width: 898px;
    background-color: ${Colors.LIGHT_1};

    ${Breakpoints.MD} {
      width: auto;
    }

    ${Breakpoints.SM} {
      padding: 24px 16px 28px 24px;
    }
  `,

  popupContent: {
    wrapper: css`
      padding: 24px;
      border-radius: 8px;
      background-color: ${Colors.LIGHT_1};
      max-width: 340px;
      text-align: center;
      position: relative;
    `,

    icon: css`
      margin-bottom: 16px;
    `,

    title: css`
      margin-bottom: 12px;
      font-size: 24px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    desc: css`
      margin-bottom: 24px;
      color: ${Colors.NEUTRAL_DARK};
      font-size: 18px;
    `,

    button: css`
      width: 100%;
    `,
  },
}
