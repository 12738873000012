import { createEffect, createEvent, createStore, sample, restore } from 'effector'
import { condition, debounce } from 'patronum'
import { TripIdea } from '~/entities/trip'
import { apiErrorHandler } from '~/shared/api'
import { getDestinations } from './api'

const DEBOUNCE_TIMEOUT_IN_MS = 300

export const initiated = createEvent<{ offset: number; limit: number }>()
export const getDestinationInitiated = createEvent<string>()
export const destinationReceived = createEvent<TripIdea>()
export const destinationReceivingFailed = createEvent<string>()

export const valueChanged = createEvent<string>()
export const searchInitiated = debounce({
  source: valueChanged,
  timeout: DEBOUNCE_TIMEOUT_IN_MS,
})

export const getDestinationsListFx = createEffect(
  async ({ offset, limit, value }: { offset: number; limit: number; value?: string }) => {
    try {
      const response = await getDestinations({ limit, offset, name: value })
      return response
    } catch (error) {
      return apiErrorHandler(error)
    }
  },
)

export const getDestinationByCodeFx = createEffect(async ({ code }: { code: string }) => {
  try {
    const matched = await getDestinations({ limit: 1, offset: 0, code })
    return matched.destinations[0]
  } catch (error) {
    return apiErrorHandler(error)
  }
})

export const $resultsLimit = createStore(0)
export const $searchValue = restore(valueChanged, '')

export const $results = createStore<TripIdea[]>([])

//get list if destinations
sample({
  clock: initiated,
  source: $searchValue,
  fn: (searchValue, searchData) =>
    searchValue ? { ...searchData, value: searchValue } : searchData,
  target: getDestinationsListFx,
})

sample({
  clock: getDestinationsListFx.doneData,
  filter: (results): results is NonNullable<typeof results> => Boolean(results),
  fn: (resp) => resp?.destinations || [],
  target: $results,
})

sample({
  clock: getDestinationsListFx.doneData,
  filter: (results): results is NonNullable<typeof results> => Boolean(results),
  fn: (resp) => resp?.total || 0,
  target: $resultsLimit,
})

//get single destination

sample({
  clock: getDestinationInitiated,
  fn: (code) => ({ code }),
  target: getDestinationByCodeFx,
})

condition({
  source: getDestinationByCodeFx.doneData,
  if: (data) => Boolean(data),
  then: destinationReceived,
  else: destinationReceivingFailed.prepend(() => 'No Destination Found'),
})

sample({
  clock: getDestinationByCodeFx.failData,
  fn: (error) => error.message,
  target: destinationReceivingFailed,
})
