import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'
import { errorIcon } from '../../../assets/icons'

export const styles = {
  statusIndicator: css`
    width: 20px;
    height: 20px;
    border: 2px solid ${Colors.LIGHT_GREY_2};
    border-radius: 50%;
    background-color: ${Colors.LIGHT_1};
  `,

  invalid: css`
    width: 20px;
    height: 20px;
    border: none;
    background-image: url(${errorIcon});
  `,
}
