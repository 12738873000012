import type { ListOfferOptionsResponse } from '@softcery/awayaway-apiclient/models'
import type { GetNearestAirportResponse } from '@softcery/awayaway-apiclient/models'
import type { FlightPlace } from '@softcery/awayaway-apiclient/models/FlightPlace'
import type { FlightPlaceAirport } from '@softcery/awayaway-apiclient/models/FlightPlaceAirport'
import { ListPlacesResponse } from '@softcery/awayaway-apiclient/models/ListPlacesResponse'
import { SearchFlightOffersResponse } from '@softcery/awayaway-apiclient/models/SearchFlightOffersResponse'
export type {
  ListPlacesResponse as ListPlacesRes,
  SearchFlightOffersResponse as SearchFlightOffersRes,
  ListOfferOptionsResponse as ListOfferOptionsRes,
  GetNearestAirportResponse as GetNearestAirportRes,
  FlightPlaceAirport as UserAirport,
  FlightPlace as FlightPlaceRes,
}

export type Airport = {
  id: string
  iata_code: string
  city_name: string | undefined
  iata_city_code: string | undefined
  longitude: number | undefined
  latitude: number | undefined
  name: string | undefined
}

export enum FLIGHT_TYPE {
  RETURN = 'return',
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}
