import { createEffect, createEvent, createStore, sample } from 'effector'
import { atom, bridge } from '~/shared/lib/factory'

export const countdownFactory = () =>
  atom(() => {
    const countdownAborted = createEvent()
    const countdownStarted = createEvent<number>()
    const countdownTicked = createEvent<number>()

    // Tick every 1 second
    const timerFx = createEffect(
      async (seconds: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, 1000)
        }),
    )

    const $isWorking = createStore(false)
    const $secondsRemain = createStore(0)

    const willTick = sample({
      source: timerFx.done.map(({ params }) => params - 1),
      filter: (seconds) => seconds >= 0,
    })

    // Working toggle
    bridge(() => {
      sample({
        clock: countdownStarted,
        fn: () => true,
        target: $isWorking,
      })

      sample({
        clock: countdownAborted,
        fn: () => false,
        target: $isWorking,
      })

      sample({
        source: timerFx.done,
        filter: ({ params }) => params === 0,
        fn: () => false,
        target: $isWorking,
      })
    })

    // Handle tick
    bridge(() => {
      sample({
        clock: countdownStarted,
        target: $secondsRemain,
      })

      sample({
        source: countdownStarted,
        filter: timerFx.pending.map((is) => !is),
        target: countdownTicked,
      })

      sample({
        clock: countdownTicked,
        target: timerFx,
      })

      sample({
        source: willTick,
        filter: $isWorking,
        target: [countdownTicked, $secondsRemain],
      })
    })

    return {
      countdownStarted,
      countdownAborted,
      countdownTicked,

      $secondsRemain,
      $isWorking,
    }
  })
