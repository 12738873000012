import { User } from '@softcery/awayaway-apiclient'
import { sample, createEffect, createEvent, createStore } from 'effector'
import { destinationsPageModel } from '~/pages/trips'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { viewerModel } from '~/entities/viewer'

const AA_INTERCOM_ID = process.env.AA_INTERCOM_ID

export const settingsUpdateInitiated = createEvent<User>()
export const intecomUpdateInitiated = createEvent()
export const launchStatusUpdateInitiated = createEvent<boolean>()
export const intercomHidden = createEvent()

export const updateSettingsFx = createEffect((settings: Intercom_.IntercomSettings) => {
  try {
    window.Intercom('update', settings)
  } catch (err) {
    throw new Error('Intercom update failed')
  }
})

export const reinitFx = createEffect(() => {
  try {
    window.Intercom('shutdown')
    window.Intercom('boot', intercomSettings || {})
  } catch {
    throw new Error('Intercom reinit failed')
  }
})

export const intercomHideFx = createEffect(() => {
  try {
    window.Intercom('shutdown')
  } catch {
    throw new Error('Intercom hide failed')
  }
})

export const $isIntercomChatActive = createStore(!!AA_INTERCOM_ID)

sample({
  source: viewerModel.$viewer,
  filter: $isIntercomChatActive,
  target: intecomUpdateInitiated,
})

sample({
  clock: intercomHidden,
  target: intercomHideFx,
})

sample({
  clock: intecomUpdateInitiated,
  source: { isAuthorized: viewerModel.$isAuthorized, viewer: viewerModel.$viewer },
  filter: ({ isAuthorized }) => Boolean(isAuthorized),
  fn: ({ viewer }) => viewer,
  target: settingsUpdateInitiated,
})

sample({
  clock: intecomUpdateInitiated,
  source: viewerModel.$isAuthorized,
  filter: (isAuthorized) => !Boolean(isAuthorized),
  target: reinitFx,
})

sample({
  clock: settingsUpdateInitiated,
  filter: (viewer) => !!viewer.hmacSecret,
  fn: (viewer) =>
    ({
      name: viewer.fullName,
      email: viewer.email,
      user_hash: viewer.hmacSecret,
    } as Intercom_.IntercomSettings),
  target: updateSettingsFx,
})

sample({
  clock: destinationsPageModel.Gate.open,
  fn: () => false,
  target: launchStatusUpdateInitiated,
})

sample({
  clock: selectLocationsModel.Gate.open,
  fn: () => true,
  target: launchStatusUpdateInitiated,
})

sample({
  clock: launchStatusUpdateInitiated,
  fn: (neededStatus) =>
    ({ hide_default_launcher: neededStatus } as Intercom_.IntercomSettings),
  target: updateSettingsFx,
})
