type EffectFinallyResponse =
  | {
      status: 'done'
      params: any
      result: any
    }
  | {
      status: 'fail'
      params: any
      error: Error
    }
  | null

export const getErrorMessage = (res: EffectFinallyResponse): string => {
  if (!res) return ''

  if (res.status === 'done') return ''

  return res?.error.message || 'Unknown error'
}
