import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const WifiIcon: FC<Props> = ({ width = 20, height = 20, color = '#333333' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16602 10.4584C5.81315 9.08643 7.88902 8.33514 10.0327 8.33514C12.1763 8.33514 14.2522 9.08643 15.8993 10.4584"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.18359 7.50004C3.61895 5.35334 6.75384 4.16891 10.0003 4.16891C13.2467 4.16891 16.3816 5.35334 18.8169 7.50004"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.10742 13.425C7.95342 12.8239 8.96548 12.501 10.0033 12.501C11.041 12.501 12.0531 12.8239 12.8991 13.425"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.6667H10.0083"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
