import { useEffect } from 'react'
import { disablePrevButton, enablePrevButton, getPrevButton } from './lib'

export const usePrevButtonDisable = (
  startDate: Date | undefined,
  minViewedDate: Date,
) => {
  useEffect(() => {
    if (startDate) {
      const prevButton = getPrevButton()

      if (startDate.getMonth() >= minViewedDate.getMonth()) {
        disablePrevButton(prevButton!)
      } else {
        enablePrevButton(prevButton!)
      }
    }
  }, [startDate, minViewedDate])
}
