import { sample } from 'effector'
import { createGate } from 'effector-react'
import { searchBookingModel } from '~/entities/booking'
import { atom, bridge } from '~/shared/lib/factory'

export const bookingListPageModel = atom(() => {
  const BookingsGate = createGate()

  bridge(() => {
    sample({
      clock: BookingsGate.open,
      target: searchBookingModel.$$getBookingsFactory.initiatedGetBookings,
    })
  })
  return {
    BookingsGate,
  }
})
