import { useStore } from 'effector-react'
import React, { FC } from 'react'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { hotelRoomModel } from '~/entities/room'
import { BorderedCircleCheckIcon } from '~/shared/assets/icons/components'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatToDate, formatToPrettyDate } from '~/shared/lib/date'
import { normalizeStringCase, normalizeStringEnding } from '~/shared/lib/string'
import { Image } from '~/shared/ui'

import { styles } from './styles'

interface Props {
  cancellationPolicyDetails?: React.ReactNode
  flightsType?: string
}

// TODO: of course we'll need to pass trip booking as a prop
export const HotelBookingSummary = ({ flightsType }: Props) => {
  const tripBooking = useStore(createBookingModel.$tripBooking)
  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)

  if (!tripBooking) {
    return null
  }

  const { flight, hotel, paymentTransactions, name } = tripBooking

  const checkIn = hotel?.checkIn ? formatToPrettyDate(formatToDate(hotel?.checkIn)) : '-'
  const checkOut = hotel?.checkOut
    ? formatToPrettyDate(formatToDate(hotel?.checkOut))
    : '-'

  const paidPayment =
    paymentTransactions?.find((payment) => payment.status === 'UNPAID')?.amount || 0

  const flightOrigin = flight?.segments?.[0]?.origin?.cityName
  const flightDestination = flight?.segments?.[0]?.destination?.cityName

  return (
    <div css={styles.container}>
      <div css={styles.heading}>
        <div css={styles.icon}>
          <BorderedCircleCheckIcon />
        </div>
        <h1 css={styles.title.main}>Thank you for booking!</h1>
        <p css={styles.text}>
          Your booking is in progress, an email will be sent once completed.
        </p>
      </div>

      <div css={styles.wrapper}>
        <Image
          image={hotel?.hotelImages?.[0]}
          extendStyle={styles.image}
          desiredSize="xl"
        />

        <h3 css={styles.hotelName}>{name}</h3>

        <div css={styles.section}>
          <Tile title="Dates">{`${checkIn} - ${checkOut}`}</Tile>
        </div>

        <div css={styles.section}>
          <Tile title="Guests">
            {hotel?.totalAdults} Adults
            {hotel?.totalChildren ? `, ${hotel?.totalChildren} Children` : ''}
          </Tile>
        </div>

        <div css={styles.section}>
          <Tile title="Rooms">
            {selectedRooms.map((room, index) => (
              <div key={index} css={styles.item}>
                <p css={styles.room.title}>
                  {normalizeStringCase(room.name)}{' '}
                  <span css={styles.room.board}>
                    {normalizeStringEnding(room.rate?.boardName)}
                  </span>
                </p>
              </div>
            ))}
          </Tile>
        </div>

        {flight && (
          <div css={styles.section}>
            <Tile title="Flight">
              {`${flightOrigin} - ${flightDestination}`}
              <span>{normalizeStringCase(flightsType)}</span>
            </Tile>
          </div>
        )}

        <div css={styles.section}>
          <Tile title="Total cost">{getPriceWithCurrency(paidPayment, 'GBP')}</Tile>
        </div>

        <div css={[styles.section, styles.borderNone]}>
          <Tile title="Booking Reference">{hotel?.reference}</Tile>
        </div>
      </div>
    </div>
  )
}

interface TileProps {
  title: string
}

export const Tile: FC<TileProps> = ({ title, children }) => (
  <div css={styles.tile.container}>
    <h1 css={styles.tile.title}>{title}</h1>
    <p css={styles.text}>{children}</p>
  </div>
)
