import { SerializedStyles } from '@emotion/react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import React from 'react'

interface Props extends TooltipPrimitive.TooltipProps {
  content: React.ReactNode
  extendStyle?: SerializedStyles
  tooltipPosition?: TooltipPrimitive.TooltipContentProps
}

export function Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  extendStyle,
  tooltipPosition,
  ...props
}: Props) {
  return (
    <TooltipPrimitive.TooltipProvider delayDuration={0}>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content css={extendStyle} {...tooltipPosition} {...props}>
          {content}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.TooltipProvider>
  )
}
