import { css } from '@emotion/react'
import { calendarIcon } from '~/shared/assets/icons/primary'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 231px;
    padding: 12px;
    border: 1px solid ${Colors.LIGHT_GREY_4};
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: ${Colors.NEUTRAL_BLACK};
    cursor: pointer;
  `,

  text: css`
    display: flex;
    &::before {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 14px;
      content: '';
      background-image: url(${calendarIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
}
