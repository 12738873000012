import { PaymentElement } from '@stripe/react-stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import React, { FC, useState } from 'react'
import { Button, Loader } from '~/shared/ui'
import { styles } from './styles'

interface Props {
  onPaymentCompleted: () => void
  onErrorOccurred: (error: StripeError) => void
  isBookingCompleting: boolean
  buttonIcon: string
  buttonCustomText?: string
}

export const Form: FC<Props> = ({
  onPaymentCompleted,
  onErrorOccurred,
  isBookingCompleting,
  buttonCustomText,
  buttonIcon,
}) => {
  const [isPaying, setPaying] = useState(false)
  const [isMounting, setMounting] = useState(true)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setPaying(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    })

    if (!error) {
      onPaymentCompleted()
    }

    if (error && error.type !== 'validation_error') {
      onErrorOccurred(error)
    }

    setPaying(false)
  }

  const isLoading = isPaying || isBookingCompleting || isMounting

  return (
    <div>
      <PaymentElement onReady={() => setMounting(false)} />

      <Button
        type="button"
        onClick={handleSubmit}
        disabled={isLoading || !stripe || !elements}
        extendStyle={styles.button}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {buttonCustomText || 'Complete Booking'}

            {buttonIcon && <img src={buttonIcon} />}
          </>
        )}
      </Button>
    </div>
  )
}
