import { SearchFlightOffersRequestBody } from '@softcery/awayaway-apiclient'
import { matchSorter } from 'match-sorter'
import { FLIGHT_TYPE } from '~/entities/flights/types'
import { isEqual } from '~/shared/lib/object-helpers'
import { Airport } from './types'

export const getMatchedSuggestions = (value: string, airports: Airport[]) => {
  const result =
    value.trim() === ''
      ? null
      : matchSorter(airports, value, {
          keys: [(airport) => `${airport.name}`],
        })

  return result
}

export const filterSuggestions = (suggestions: Airport[], airportsToFilter: Airport[]) =>
  suggestions.filter(
    (suggestion) => !airportsToFilter.some((airport) => isEqual(airport, suggestion)),
  )

export const getAirportSuggestions = (
  value: string,
  airports: Airport[],
  selectedAirports: Airport[],
) => {
  const matched = getMatchedSuggestions(value, airports)
  const result = matched ? filterSuggestions(matched, selectedAirports) : matched
  return result
}

export const airportToOption = (airport: Airport) => {
  return `${airport.city_name} (${airport.iata_code})`
}

export const parseAirportOption = (option: string) => {
  const values = option.split(' ')
  const iataCode = values[values.length - 1].replace('(', '').replace(')', '')

  return iataCode
}

export const generatePassengers = (adults: number, children: number) => {
  const passengers = []

  for (let i = 1; i <= adults; i++) {
    passengers.push({
      type: 'adult',
    })
  }

  for (let i = 1; i <= children; i++) {
    passengers.push({
      type: 'child',
    })
  }

  return passengers
}

export const getAirportByCityCode = (cityCode: string, airports: Airport[]) => {
  return airports.find(({ iata_city_code }) => iata_city_code === cityCode)
}

export const getAirportLabel = (airport: Airport) => {
  return `${
    airport.city_name ? airport.city_name : airport.name ? airport.name : 'any'
  } ${airport.iata_code ? `(${airport.iata_code})` : ''}`
}

export const convertToDataBodyForSearchFlight = ({
  passengers,
  origin,
  destination,
  startDate,
  endDate,
  flightType,
  isFlightsTestEnv,
}: {
  passengers: Record<'type', string>[]
  origin: string
  destination: string
  startDate: string
  endDate: string
  flightType: FLIGHT_TYPE
  isFlightsTestEnv: boolean
}) => {
  switch (flightType) {
    case FLIGHT_TYPE.INBOUND:
      return {
        journeys: [
          {
            origin: destination,
            destination: origin,
            departureDate: endDate,
          },
        ],
        passengers,
        type: 'inbound',
        enableTestMode: isFlightsTestEnv,
      } as SearchFlightOffersRequestBody
    case FLIGHT_TYPE.OUTBOUND:
      return {
        journeys: [
          {
            origin,
            destination,
            departureDate: startDate,
          },
        ],
        passengers,
        type: 'outbound',
        enableTestMode: isFlightsTestEnv,
      } as SearchFlightOffersRequestBody
    case FLIGHT_TYPE.RETURN:
      return {
        journeys: [
          {
            origin,
            destination,
            departureDate: startDate,
          },
          {
            origin: destination,
            destination: origin,
            departureDate: endDate,
          },
        ],
        passengers,
        type: 'return',
        enableTestMode: isFlightsTestEnv,
      } as SearchFlightOffersRequestBody
    default: {
      throw new Error('Unknown flight type')
    }
  }
}
