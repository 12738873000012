import { LowestDailyRates } from '~/entities/room'
import { Room } from '~/entities/trip-info'
import {
  formatToDate,
  isDateBetween,
  isDatesSame,
  convertJsMonthToCalendar,
  addDaysToDate,
  formatToCustom,
  formatToYearMonth,
} from '~/shared/lib/date'

import { CalendarRange } from './types'

export function isRangeContainsDates(dateRange: CalendarRange, dates: string[]) {
  if (!dates.length || !dateRange.startDate || !dateRange.endDate) {
    return false
  }

  for (const date of dates) {
    const formattedDate = formatToDate(date)

    const isDateInRange =
      isDatesSame(formattedDate, dateRange.startDate) ||
      isDateBetween(formattedDate, dateRange.startDate, dateRange.endDate) ||
      isDatesSame(formattedDate, dateRange.endDate)

    if (isDateInRange) {
      return true
    }
  }

  return false
}

export const mergeDates = (dates: string[], newDates: string[]) =>
  dates.concat(newDates.filter((item) => dates.indexOf(item) < 0))

export const mergeRates = (...objects: LowestDailyRates[]) =>
  objects.reduce((acc, cur) => ({ ...acc, ...cur }))

export interface AvailabilityCheckProps extends Props {
  checkedMonth: Map<number, number[]>
}

export interface Props {
  rooms: Room[]
  datesRange: CalendarRange
  selectedHotelCode: number | null
  date?: Date
}

// export const getAvailabilityRequestBody = ({
//   rooms,
//   datesRange,
//   selectedHotelCode,
//   date,
// }: Props): GetRoomsRequestProps => {
//   const firstDisplayedDate = addDaysToDate(new Date(), 1)

//   const checkedDate = date || datesRange.startDate || firstDisplayedDate

//   const stringDate = formatToYearMonth(checkedDate)

//   return {
//     codes: [selectedHotelCode] as number[],
//     stay: {
//       occupancies: rooms.map((room) => ({
//         adults: room.adults.length,
//         childrenAges: room.children.length
//           ? room.children.map(({ age }) => age)
//           : undefined,
//         rooms: 1,
//         children: room.children.length || undefined,
//       })),
//       shiftDays: 5,
//       dates: [stringDate],
//       duration: 1,
//       onlyWeekend: false,
//     },
//   }
// }

export const isCalendarMonthNeedCheck = (
  date: Date,
  checkedMonth: Map<number, number[]>,
) => {
  const month = convertJsMonthToCalendar(date.getMonth())
  const year = date.getFullYear()
  const checkedMonthInYear = checkedMonth.get(year)
  return checkedMonthInYear ? checkedMonthInYear.indexOf(month) === -1 : true
}

export const getRangeSummaryTitle = (startDate: Date, endDate: Date) =>
  isDatesSame(startDate, endDate)
    ? formatToCustom(startDate, 'Do MMMM') + ' -  Check Out Date : '
    : formatToCustom(startDate, 'Do MMMM') +
      ' - ' +
      formatToCustom(endDate, 'Do MMMM') +
      ': '
