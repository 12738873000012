import { css } from '@emotion/react'
import { isWindows } from 'react-device-detect'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: calc(100vh - 100px); */
    width: 100%;
    padding-top: 130px;

    ${Breakpoints.MD} {
      padding-top: 70px;
    }
  `,

  description: css`
    display: flex;
    align-items: center;
    color: ${Colors.NEUTRAL_DARK};
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
    ${Breakpoints.SM} {
      font-size: 14px;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
    }
  `,

  icon: css`
    width: 25px;
    height: 24px;
    margin-right: 8px;
  `,

  textareaWrapper: (isEditing?: boolean) => css`
    position: relative;
    width: 836px;
    border-radius: 6px;

    ${Breakpoints.LG} {
      width: 100%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: ${isWindows ? '22px' : '23px'};
      width: ${isWindows ? '22px' : '23px'};
      background-color: ${Colors.NEUTRAL_SILVER};
      bottom: ${isWindows ? '-5px' : '-7px'};
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border-bottom: 2px solid ${isEditing ? Colors.PRIMARY_GREEN : 'transparent'};
      border-right: 2px solid ${isEditing ? Colors.PRIMARY_GREEN : 'transparent'};
      border-radius: ${isWindows ? '2px' : '0'};
      transition: 0.3s all ease-in-out;
    }
  `,

  textarea: (isEditing?: boolean) => css`
    width: 100%;
    padding: 52px;
    border-radius: 6px;
    background-color: ${Colors.NEUTRAL_SILVER};
    border: 2px solid ${isEditing ? Colors.PRIMARY_GREEN : 'transparent'};
    outline: none;
    text-align: center;
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 32px;
    resize: none;
    transition: 0.3s all ease-in-out;
    overflow: hidden;

    ${Breakpoints.LG} {
      width: 100%;
      padding: 40px 30px;
    }

    ${Breakpoints.SM} {
      padding: 40px 20px;
      font-size: 24px;

      font-size: 18px;
      letter-spacing: 0.36px;
    }
  `,

  btn: {
    wrapper: css`
      display: flex;
      justify-content: center;
      margin-top: 45px;

      ${Breakpoints.SM} {
        margin-top: 30px;
      }
    `,

    inner: css`
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      ${Breakpoints.SM} {
        flex-direction: column;
      }
    `,
  },

  base: css`
    color: ${Colors.PRIMARY_GREEN};
    background-color: transparent;
    border: 1px solid ${Colors.NEUTRAL_STONE};
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border-radius: 4px;
    padding: 10.5px;
    width: 254px;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  `,

  next: (isNewSummaryPending: boolean) => css`
    color: ${isNewSummaryPending ? 'transparent' : Colors.LIGHT_1};
    background-color: ${Colors.PRIMARY_GREEN};
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border-radius: 4px;
    padding: 11.5px;
    width: 254px;
  `,

  note: css`
    text-align: center;
    margin-top: 60px;
    font-size: 16px;
    color: ${Colors.NEUTRAL_GREY};
    max-width: 570px;

    ${Breakpoints.MD} {
      margin-top: 72px;
    }

    ${Breakpoints.SM} {
      margin-top: 65px;
    }
  `,
}
