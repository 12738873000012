import { FC } from 'react'
import { styles } from './styles'

interface Props {
  symbol: string
  segment: string
  price: string
  selected: boolean

  onSelect: () => void
}

export const PriceCard: FC<Props> = ({ price, segment, symbol, selected, onSelect }) => {
  return (
    <div onClick={onSelect} css={[styles.wrapper, selected && styles.selected]}>
      <small css={[styles.symbol, selected && styles.greenColor]}>{symbol}</small>

      <p css={[styles.segment, selected && styles.greenColor]}>{segment}</p>

      <span css={styles.price}>{price}</span>
    </div>
  )
}
