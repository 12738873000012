import { ListPlacesRes } from '~/entities/flights/types'
import { apiService, errorHandler } from '~/shared/api'

export const searchPlaces = async (name: string): Promise<ListPlacesRes> => {
  try {
    return await apiService().searchPlaces({ name })
  } catch (res: any) {
    return errorHandler(res)
  }
}
