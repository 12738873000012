import { css } from '@emotion/react'
import { Fonts, Colors, Breakpoints } from '~/shared/styles'

export const styles = {
  wrapper: css`
    background-color: ${Colors.LIGHT_1};
    padding: 32px 40px;
    width: 100%;
    border-radius: 8px;
    max-width: 800px;

    ${Breakpoints.LG} {
      padding: 24px;
      max-width: 600px;
    }

    ${Breakpoints.MD} {
      padding: 16px;
      max-width: 450px;
    }

    ${Breakpoints.SM} {
      padding: 16px 12px;
      max-width: 354px;
    }
  `,

  top: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  `,

  closeBtn: css`
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;

      ${Breakpoints.MD} {
        width: 14px;
        height: 14px;
      }
    }
  `,

  name: css`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;

    ${Breakpoints.MD} {
      font-size: 20px;
      margin-bottom: 16px;
    }
  `,

  about: {
    wrapper: css`
      margin-top: 20px;

      ${Breakpoints.MD} {
        margin-top: 16px;
      }
    `,

    title: css`
      font-size: 24px;
      font-weight: 400;

      ${Breakpoints.MD} {
        font-size: 18px;
      }
    `,

    content: css`
      padding: 16px 0;
      display: flex;
      align-items: center;
      gap: 16px;

      ${Breakpoints.MD} {
        flex-direction: column;
        align-items: flex-start;
      }
    `,

    description: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};

      ${Breakpoints.MD} {
        font-size: 16px;
        letter-spacing: 0.32px;
      }
    `,

    tags: css`
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      ${Breakpoints.MD} {
        gap: 12px;
      }
    `,

    tagsItem: css`
      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.02em;

      color: ${Colors.NEUTRAL_BLACK};

      ${Breakpoints.MD} {
        gap: 8px;
        font-size: 14px;
        letter-spacing: 0.28px;
      }
    `,
  },

  details: {
    wrapper: css`
      padding: 24px 0;

      ${Breakpoints.MD} {
        padding: 12px 0;
      }
    `,

    title: css`
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 16px;

      ${Breakpoints.MD} {
        font-size: 18px;
      }
    `,

    content: css``,
  },

  seeMoreButton: css`
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: ${Colors.NEUTRAL_DARK};
    text-decoration: underline;
  `,

  slider: {
    wrapper: css`
      position: relative;
    `,

    nav: css`
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 16px;

      ${Breakpoints.MD} {
        padding: 0 12px;
      }
    `,
  },

  bottom: {
    wrapper: css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      margin-top: 24px;

      ${Breakpoints.MD} {
        margin-top: 16px;
        justify-content: center;
      }
    `,

    selectButton: (selected?: boolean) => css`
      background-color: ${selected ? Colors.LIGHT_GREY_2 : Colors.PRIMARY_GREEN};
      width: 160px;

      &:hover,
      &:focus {
        background-color: ${selected && Colors.LIGHT_GREY_2};
      }

      ${Breakpoints.MD} {
        font-size: 18px;
        width: auto;
      }

      ${Breakpoints.SM} {
        width: 50%;
      }
    `,

    cancelButton: css`
      background-color: ${Colors.LIGHT_1};
      border: 1px solid ${Colors.NEUTRAL_GREY};
      color: ${Colors.NEUTRAL_GREY};
      width: 160px;

      &:hover,
      &:focus {
        background-color: ${Colors.LIGHT_1};
        opacity: 0.5;
      }

      ${Breakpoints.MD} {
        width: auto;
        font-size: 18px;
      }

      ${Breakpoints.SM} {
        width: 50%;
      }
    `,
  },
}
