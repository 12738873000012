import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  side: css`
    display: grid;
    grid-gap: 20px;
  `,

  payment: {
    wrapper: css`
      background-color: ${Colors.NEUTRAL_WHITE};
      padding: 40px;
      border-radius: 10px;
      width: 540px;

      ${Breakpoints.MD} {
        width: 100%;
        padding: 20px;
      }
    `,

    title: css`
      font-family: Calibre;
      font-weight: 500;
      font-size: 20px;
      color: ${Colors.NEUTRAL_BLACK};

      margin-bottom: 24px;
    `,

    container: css`
      display: flex;
      flex-direction: column;
      gap: 19px;
    `,
  },

  popupContent: {
    wrapper: css`
      padding: 24px;
      border-radius: 8px;
      background-color: ${Colors.LIGHT_1};
      max-width: 340px;
      text-align: center;
      position: relative;
    `,

    icon: css`
      margin-bottom: 16px;
    `,

    title: css`
      margin-bottom: 12px;
      font-size: 24px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    desc: css`
      margin-bottom: 24px;
      color: ${Colors.NEUTRAL_DARK};
      font-size: 18px;
    `,

    button: css`
      width: 100%;
    `,
  },

  overview: {
    container: css`
      margin-bottom: 24px;
    `,
  },
}
