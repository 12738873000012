import { GroupWithRoomsResponse, HotelWithRoomsResponse } from '~/entities/room/types'

export const getPriceAmountForRoomsPerNight = ({
  selectedHotel,
}: {
  selectedHotel: GroupWithRoomsResponse[]
}) => {
  return selectedHotel.reduce((acc, group) => {
    // get preselectedRoom to get price per night
    const copyGroup = { ...group }
    const preselectedRoom = copyGroup?.rooms.sort(
      (a, b) => a.lowestDailyRateAmount - b.lowestDailyRateAmount,
    )[0]
    if (!preselectedRoom) return acc
    // get into groupedRates to check if baseBoardName === preselectedRoom.preselectedRate.boardName.
    // If true then will get amount from baseDailyAmount

    const copyGroupedRates = [...preselectedRoom.groupedRates]
    const roomGroupData =
      copyGroupedRates.sort((a, b) => {
        return a.baseDailyAmount - b.baseDailyAmount
      })[0]?.baseDailyAmount ?? 0
    return acc + roomGroupData
  }, 0)
}

export const sortRoomsBySelected = (
  selectedHotel: HotelWithRoomsResponse[],
): HotelWithRoomsResponse[] => {
  return selectedHotel.map((hotel) => {
    hotel.group = hotel.group.map((group) => {
      const isPreselectedIndex = group.rooms.findIndex(
        (room) => room.isMandatoryPreselected,
      )
      if (isPreselectedIndex !== -1 && isPreselectedIndex < group.rooms.length) {
        const elementToMove = group.rooms.splice(isPreselectedIndex, 1)[0]
        group.rooms.unshift(elementToMove)
      }
      return group
    })
    return hotel
  })
}
