import { css } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    gap: 16px;
  `,

  skeleton: css`
    width: 80px;
    height: 38px;
    border-radius: 100px;
  `,
}
