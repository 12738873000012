import { FC } from 'react'
import { styles } from './styles'

interface Props {
  label: string
  icon?: string
}

export const OfferDetail: FC<Props> = ({ label, icon }) => {
  return (
    <div css={styles.container}>
      {icon && <img src={icon} />} <span css={styles.label}>{label}</span>
    </div>
  )
}
