import { sample } from 'effector'
import { selectImagesModel } from '~/features/onboarding/select-images'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { Gate, stepSkipped } from './core'

sample({
  clock: listImageRecommendationsModel.imagesLabelsSelected,
  fn: (labels) => ({
    name: eventNames.completedInspirationStep,
    properties: {
      selected_images: labels || 'not selected',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: stepSkipped,
  fn: () => ({
    name: eventNames.completedInspirationStep,
    properties: {
      selected_images: 'not selected',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  fn: () => ({
    name: eventNames.quizImagesVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: listImageRecommendationsModel.imagesLabelsSelected,
  source: selectImagesModel.$selectedImageIds,
  fn: (images, labels) => ({
    name: eventNames.quizImagesCompleted,
    properties: {
      selected_images: labels || 'not selected',
      selected_images_ids: images || 'not selected',
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: stepSkipped,
  fn: () => ({
    name: eventNames.quizImagesCompleted,
    properties: {
      selected_images: 'not selected',
    },
  }),
  target: analyticsModel.track,
})
