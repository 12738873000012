import { createEvent, createStore, sample } from 'effector'
import { reset } from 'patronum'

export const $additionalDetails = createStore('')

export const additionalDetailsUpdated = createEvent<string>()
export const stateReset = createEvent()

sample({
  clock: additionalDetailsUpdated,
  target: $additionalDetails,
})

reset({
  clock: stateReset,
  target: $additionalDetails,
})
