import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  modal: (centred: boolean, isLoaderVisible?: boolean) => css`
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    padding: ${isLoaderVisible ? '0' : '90px 20px'};
    background: ${isLoaderVisible ? `${Colors.LIGHT_1}` : 'rgba(0, 0, 0, 0.5)'};

    ${Breakpoints.SM} {
      padding: ${isLoaderVisible ? '0' : '90px 10px'};
      display: flex;
      align-items: ${centred ? 'center' : 'flex-start'};
    }
  `,

  wrapper: css`
    width: fit-content;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,

  content: {
    wrapper: css`
      padding: 24px;
      border-radius: 8px;
      background-color: ${Colors.LIGHT_1};
      max-width: 340px;
      text-align: center;
      position: relative;
    `,

    icon: css`
      margin-bottom: 16px;
    `,

    title: css`
      margin-bottom: 12px;
      font-size: 24px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    desc: css`
      margin-bottom: 24px;
      color: ${Colors.NEUTRAL_DARK};
      font-size: 18px;
    `,

    button: css`
      width: 100%;
    `,

    closeButton: css`
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      padding: 8px;
    `,
  },
}

export const globalStyles = css`
  .modal-enter {
    opacity: 0;
  }

  .modal-enter-active {
    transition: opacity 500ms;
    opacity: 1;
  }

  .modal-exit {
    margin-left: var(--scrollbar-compensation); /* fix shift on close */
    opacity: 1;
  }

  .modal-exit-active {
    transition: opacity 500ms;
    opacity: 0;
  }
`
