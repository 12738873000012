import { styles } from './styles'

const ITEMS = 3

export const RoomSkeleton = () => {
  return (
    <div css={styles.wrapper}>
      {[...Array(ITEMS)].map((_, i) => (
        <div key={i} css={styles.container}>
          <div css={[styles.image, styles.animation]}></div>
          <div css={styles.content}>
            <div css={styles.info.wrapper}>
              <div css={styles.info.col}>
                <div css={[styles.info.title, styles.animation]}></div>
                <div css={[styles.info.facilities, styles.animation]}></div>
                <div css={[styles.info.facilities, styles.animation]}></div>
                <div css={[styles.info.details, styles.animation]}></div>
              </div>

              <div css={styles.info.col}>
                <div css={[styles.info.price.button, styles.animation]}></div>
                <div css={[styles.info.price.value, styles.animation]}></div>
              </div>
            </div>

            <div css={styles.board.row}>
              <div css={styles.board.col}>
                <div css={[styles.board.title, styles.animation]}></div>
                <div css={[styles.board.item, styles.animation]}></div>
                <div css={[styles.board.item, styles.animation]}></div>
              </div>
              <div css={styles.board.col}>
                <div css={[styles.board.title, styles.animation]}></div>
                <div css={[styles.board.item, styles.animation]}></div>
                <div css={[styles.board.item, styles.animation]}></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
