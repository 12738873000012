import { AppRoute } from '~/shared/config'
import { addDaysToDate } from '~/shared/lib/date'

export const ONBOARDING_STEPS = {
  [AppRoute.Destination]: 1,
  [AppRoute.Inspiration]: 2,
  [AppRoute.Guests]: 3,
  [AppRoute.Budget]: 4,
  [AppRoute.Dates]: 5,
  [AppRoute.Summary]: 6,
}

export type DateString = string
interface DatesRange {
  startDate: DateString
  endDate: DateString
}

export const defaultRange: DatesRange = {
  startDate: addDaysToDate(new Date(), 1).toISOString(),
  endDate: addDaysToDate(new Date(), 8).toISOString(),
}
