import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    max-width: 380px;
  `,

  title: css`
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;

    ${Breakpoints.SM} {
      font-size: 26px;
    }
  `,

  subtitle: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 32px;
  `,

  bold: css`
    font-weight: 500;
  `,

  input: css`
    margin-bottom: 20px;
  `,

  button: css`
    width: 100%;
    margin-bottom: 8px;
  `,

  errorContainer: css`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  `,

  return: {
    container: css`
      max-width: 1110px;
      margin: 0 auto;
      margin-top: -30px;
      margin-left: -25px;
      position: absolute;
      display: flex;
      align-items: center;

      ${Breakpoints.SM} {
        margin-top: 4px;
        margin-left: -13px;
      }
    `,

    button: css`
      background-color: transparent;
      border: 0;
      cursor: pointer;

      font-size: 18px;
      color: ${Colors.PRIMARY_GREEN};
    `,
  },
  closeIcon: css`
    position: absolute;
    top: 24px;
    right: 22px;
    cursor: pointer;
    width: 18px;
    height: 18px;
  `,
}
