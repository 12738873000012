import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: (cutHeight?: number) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 84px - ${cutHeight || 0}px);

    ${Breakpoints.SM} {
      height: calc(100vh - 80px);
    }
  `,

  text: css`
    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
  `,

  loader: css`
    width: 100px;
    height: 100px;
  `,
}
