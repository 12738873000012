import { useStore, useGate } from 'effector-react'
import { FC, useCallback } from 'react'
import { PaymentForm } from '~/features/make-payment'
import { searchFlightsModel } from '~/features/search-flights/model'
import { selectFlightsServicesModel } from '~/features/select-services'
import { addGuestsInfoModel } from '~/features/trip-booking/add-guests-info'
import { infoPopupModel } from '~/features/trip-booking/info-popup'
import { BookingSidebar } from '~/entities/booking'
import { isRedirectBookingError } from '~/entities/booking/lib'
import { hotelsForTripModel } from '~/entities/hotel'
import { navigationModel } from '~/entities/navigation'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { crossErrorIcon, lockIcon } from '~/shared/assets/icons'
import { AppRoute } from '~/shared/config'
import { Button, NotificationPopup } from '~/shared/ui'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'
import { bookingModel } from '../..'
import { Overview } from './overview'
import { styles } from './styles'

export const PaymentPage: FC = () => {
  useGate(bookingModel.CheckoutStepGate)

  const destination = useStore(destinationModel.$selectedDestination)
  const checkIn = useStore(tripInfoModel.$checkIn)
  const checkOut = useStore(tripInfoModel.$checkOut)
  const selectedHotel = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )
  const selectedDestination = useStore(destinationModel.$selectedDestination)
  const childrenAmount = useStore(tripInfoModel.$childrenAmount)
  const adultsAmount = useStore(tripInfoModel.$adultsAmount)
  const selectedRoomsAmount = useStore(tripInfoModel.$roomsAmount)
  const paymentError = useStore(bookingModel.$$model.$paymentError)
  const confirmError = useStore(bookingModel.$$model.$confirmError)

  const selectedFlighsOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )
  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )
  const flightsType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )

  const currency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)

  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)
  const guestsGroups = useStore(addGuestsInfoModel.$guestGroups)
  const error = confirmError || paymentError
  const hotelImage = selectedHotel?.images?.[0]

  const additionalServices = useStore(
    selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.$additionalServices,
  )
  const onClose = useCallback(
    (error: string) => {
      if (isRedirectBookingError(error)) {
        navigationModel.pathChanged(
          `${AppRoute.Destinations}/${destination?.destinationCode}`,
        )
        hotelsForTripModel.resetAllState()
      }

      bookingModel.$$model.resetError()
    },
    [navigationModel, AppRoute, destination, bookingModel],
  )

  const onEndTimeHandler = () => {
    infoPopupModel.open()
    hotelsForTripModel.resetAllState()
  }

  if (!guestsGroups) {
    navigationModel.pathChanged(
      `${AppRoute.Destinations}/${destination?.destinationCode}`,
    )
  }

  const mainContent = (
    <div>
      <div css={styles.overview.container}>
        <Overview />
      </div>

      <div css={styles.payment.container}>
        {/* Error popup */}
        <NotificationPopup isOpen={!!error} onClose={() => onClose(error)}>
          <div css={styles.popupContent.wrapper}>
            <div css={styles.popupContent.icon}>
              <img src={crossErrorIcon} alt="status-icon" />
            </div>
            <h2 css={styles.popupContent.title}>Payment failed</h2>
            <p css={styles.popupContent.desc}>{error}</p>
            <Button css={styles.popupContent.button} onClick={() => onClose(error)}>
              Try again
            </Button>
          </div>
        </NotificationPopup>

        <div css={styles.payment.wrapper}>
          <h1 css={styles.payment.title}>Payment method</h1>
          <PaymentForm
            model={bookingModel.$$model}
            buttonCustomText={'Complete Booking'}
            buttonIcon={lockIcon}
          />
        </div>
      </div>
    </div>
  )

  return (
    <WithSidebar
      main={mainContent}
      sidebarContent={
        <div css={styles.side}>
          <BookingSidebar
            checkIn={checkIn}
            checkOut={checkOut}
            hotelImage={hotelImage}
            hotelName={selectedHotel?.name}
            destinationName={selectedDestination?.name}
            guests={{
              adults: adultsAmount,
              children: childrenAmount,
              rooms: selectedRoomsAmount,
            }}
            selectedRooms={selectedRooms}
            currency={currency}
            flightOffer={selectedFlighsOffer}
            flightType={flightsType}
            isFlightIncluded={isFlightsIncluded}
            onEndTime={onEndTimeHandler}
            additionalServices={additionalServices}
          />
        </div>
      }
      position="right"
    />
  )
}
