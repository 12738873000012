import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    width: 100%;
  `,

  icon: css`
    width: 20px;
    height: 20px;
    margin-right: 10px;
  `,

  row: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,

  edit: css`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
    color: ${Colors.PRIMARY_GREEN};
    cursor: pointer;
  `,

  container: css`
    display: grid;
    grid-row-gap: 8px;
    width: 100%;

    p {
      ${Breakpoints.MD} {
        font-size: 16px;
      }
    }
  `,

  text: (faded?: boolean) => css`
    font-family: ${Fonts.Calibre};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;
    color: ${faded ? Colors.NEUTRAL_GREY : Colors.NEUTRAL_BLACK};
  `,

  title: css`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: ${Colors.NEUTRAL_GREY};
  `,
}
