import { SerializedStyles } from '@emotion/react'
import {
  GroupedRateDto,
  OccupancyOptions,
  RateDto,
  RoomsResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { useStore } from 'effector-react'
import { FC, useEffect, useState } from 'react'
import { BoardsList } from '~/features/trip-booking/book-room/ui/rate-list'
import { getCancellationDeadlineDate } from '~/entities/booking/lib'
import { CancellationFeesDetails } from '~/entities/booking/ui/cancellation-policy/flexible-policy-details/cancellation-fees-details'
import { CustomSelectedHotelRoom, SelectedHotelRoom } from '~/entities/room'
import { crossIcon } from '~/shared/assets/icons'
import {
  CheckIcon,
  InfoIcon,
  RadioButtonIcon,
  SelectedRadioButtonIcon,
  WifiIcon,
} from '~/shared/assets/icons/components'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatToCustom } from '~/shared/lib/date'
import { normalizeStringCase, normalizeStringEnding } from '~/shared/lib/string'
import { Image, Modal } from '~/shared/ui'
import { Tooltip } from '~/shared/ui/atoms'

import { selectRoomModel } from '../..'
import { roomDetailsOpened } from '../../model'
import { RoomDetails } from '../room-details'
import { styles } from './styles'

interface Props {
  room: CustomSelectedHotelRoom
  roomIndex: number
  listIndex: number
  occupancy: OccupancyOptions
  currency: string
  onSelect: (room: SelectedHotelRoom) => void
  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
    modal?: SerializedStyles
  }
}

const processString = (inputString: string) => {
  const match = inputString.match(/^(.*?)(?=\d)/)
  return match ? match[0].trim() : inputString.trim()
}

export const Room: FC<Props> = ({
  room,
  currency,
  onSelect,
  roomIndex,

  occupancy,
  extendStyles,
}) => {
  const previewImage = room.images[0]

  const roomDetails = useStore(selectRoomModel.$roomDetails)

  const [isDetailsModalVisible, setDetailsModalVisibility] = useState(false)
  const [isPolicyDetailsVisible, setPolicyDetailsVisibility] = useState(false)

  const roomDetailsHandler = (room: RoomsResponseDto) => {
    setDetailsModalVisibility(true)
    roomDetailsOpened(room)
  }

  const [selectedBoardOption, setSelectedBoardOption] = useState({} as GroupedRateDto)
  const [selectedPolicy, setSelectedPolicy] = useState({} as RateDto)

  const isSelected = !!room.isSelected
  let isPreselected = false

  const refundCost = (amountPerNight: number) => {
    return amountPerNight - selectedBoardOption.baseAmount
  }
  const roomInternet = room.facilities?.find((facility) => facility.code === 5200)

  //Preselect room logic
  useEffect(() => {
    const preselectedRoom: GroupedRateDto =
      room.groupedRates?.find((rate) => {
        return rate.baseBoardName === room.preselectedRate.boardName
      }) || ({} as GroupedRateDto)

    setSelectedBoardOption(preselectedRoom)

    const preselectedPolicy: RateDto = preselectedRoom?.rates?.[0] || ({} as RateDto)

    setSelectedPolicy(preselectedPolicy)
  }, [])

  useEffect(() => {
    if (room.isMandatoryPreselected && !isPreselected) {
      isPreselected = true
      selectRoom(room!)
    }

    if (isSelected) {
      selectRoom(room!)
    }
  }, [selectedPolicy])

  const selectBoardOption = (rate: GroupedRateDto) => {
    setSelectedBoardOption(rate)
    setSelectedPolicy(rate.rates![0])
  }

  //Select handlers
  const selectRoom = ({ targetRoomKey, images, name }: CustomSelectedHotelRoom) => {
    const selectedRoom: SelectedHotelRoom = {
      targetRoomKey,
      images,
      name,
      rate: selectedPolicy,
      roomIndex,
      guests: occupancy,
      price: selectedPolicy.amount,
      roomId: selectedPolicy.roomId,
      packageId: selectedPolicy.packageId,
      sessionId: selectedPolicy.sessionId,
    }

    onSelect(selectedRoom)
  }

  return (
    <div css={styles.wrapper(isSelected!)}>
      <div css={styles.container}>
        <div css={styles.content}>
          <div css={styles.imageWrapper} onClick={() => roomDetailsHandler(room)}>
            <Image image={previewImage} extendStyle={styles.image} />
          </div>

          <button
            css={[styles.selectButton(isSelected), styles.selectButtonMobile(isSelected)]}
            onClick={() => selectRoom(room!)}
          >
            {isSelected ? (
              <span>
                Selected <CheckIcon />
              </span>
            ) : (
              'Select this room'
            )}
          </button>

          <div css={styles.info.container}>
            <div css={styles.info.wrapper}>
              <div>
                <h2 css={styles.title} onClick={() => roomDetailsHandler(room)}>
                  {normalizeStringCase(room.name!)}
                </h2>

                <div css={styles.info.facilities.wrapper}>
                  {/* {roomSize && roomSize.value! > 0 && (
                    <div css={styles.info.facilities.item}>
                      <SizeIcon /> {roomSize.value} sq ft
                    </div>
                  )} */}

                  {roomInternet && (
                    <div css={styles.info.facilities.item}>
                      <WifiIcon width={16} height={16} /> {roomInternet.description}
                    </div>
                  )}
                </div>

                <button
                  css={styles.detailsButton}
                  onClick={() => roomDetailsHandler(room)}
                >
                  View details
                </button>
              </div>
              <div css={styles.price.wrapper}>
                <div>
                  <p css={styles.price.value}>
                    {getPriceWithCurrency(selectedPolicy.dailyAmount || 0, currency)}
                    <span>/night</span>
                  </p>

                  <p css={styles.price.total}>
                    {getPriceWithCurrency(selectedPolicy.amount || 0, currency)} total
                  </p>
                </div>

                <button
                  css={styles.selectButton(isSelected)}
                  onClick={() => selectRoom(room)}
                >
                  {isSelected ? (
                    <span>
                      Selected <CheckIcon />
                    </span>
                  ) : (
                    'Select this room'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {room.groupedRates?.length === 1 && room.groupedRates[0].rates?.length === 1 ? (
          <div css={styles.rates.withoutChoice.wrapper}>
            <div css={styles.rates.withoutChoice.board}>
              {normalizeStringEnding(room.groupedRates[0].baseBoardName)}
            </div>

            <div>
              {room.groupedRates[0]?.rates[0]?.refundability === 'NON_REFUNDABLE' ? (
                <div css={styles.rates.withoutChoice.policy}>
                  <span>Non-refundable</span>
                </div>
              ) : (
                <div css={styles.rates.withoutChoice.policy}>
                  <div css={styles.rates.desktopTooltip.wrapper}>
                    <span>Fully refundable</span>before{' '}
                    {formatToCustom(
                      getCancellationDeadlineDate(
                        room.groupedRates[0]?.rates[0]?.cancellationPolicies?.[0]?.from ||
                          '',
                      ),
                      'DD MMM',
                    )}
                    <Tooltip
                      content={
                        <CancellationFeesDetails
                          policies={selectedPolicy.cancellationPolicies || []}
                          currency={currency}
                        />
                      }
                      extendStyle={extendStyles?.tooltip || styles.tooltip.container}
                      tooltipPosition={{
                        side: 'bottom',
                        align: 'start',
                        alignOffset: 40,
                      }}
                    >
                      <div css={[extendStyles?.focusedText, styles.rates.icon]}>
                        <InfoIcon color={'#8E8E8E'} />
                      </div>
                    </Tooltip>
                  </div>

                  <div css={styles.rates.mobileTooltip.wrapper}>
                    Fully refundable before{' '}
                    {formatToCustom(
                      getCancellationDeadlineDate(
                        selectedPolicy.cancellationPolicies?.[0].from || '',
                      ),
                      'DD MMM',
                    )}
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setPolicyDetailsVisibility(!isPolicyDetailsVisible)
                      }}
                    >
                      <InfoIcon color={'#8E8E8E'} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div css={styles.rates.row}>
            <div css={styles.rates.col}>
              <h3 css={styles.rates.title}>Board options</h3>
              <BoardsList
                rates={room.groupedRates!}
                selectedRate={selectedBoardOption}
                onSelect={selectBoardOption}
              />
            </div>
            <div css={styles.rates.col}>
              <h3 css={styles.rates.title}>Cancellation Policy</h3>

              <div css={styles.rates.wrapper}>
                {selectedBoardOption.rates?.map((rate) => (
                  <div
                    key={rate.roomId}
                    onClick={() => {
                      setSelectedPolicy(rate!)
                    }}
                  >
                    {rate.refundability === 'NON_REFUNDABLE' ? (
                      <div css={styles.rates.item}>
                        {selectedBoardOption.rates!.length > 1 && (
                          <div>
                            {rate.packageId === selectedPolicy.packageId ? (
                              <SelectedRadioButtonIcon />
                            ) : (
                              <RadioButtonIcon />
                            )}
                          </div>
                        )}
                        Non-refundable
                      </div>
                    ) : (
                      <div css={styles.rates.item}>
                        <div css={styles.rates.desktopTooltip.wrapper}>
                          {selectedBoardOption.rates!.length > 1 && (
                            <div>
                              {rate.packageId === selectedPolicy.packageId ? (
                                <SelectedRadioButtonIcon />
                              ) : (
                                <RadioButtonIcon />
                              )}
                            </div>
                          )}
                          Fully refundable before{' '}
                          {formatToCustom(
                            getCancellationDeadlineDate(
                              rate.cancellationPolicies?.[0].from || '',
                            ),
                            'DD MMM',
                          )}
                          {refundCost(rate.amount!) !== 0 && (
                            <div css={styles.rates.refundCost}>
                              +
                              {getPriceWithCurrency(
                                refundCost(rate.amount!),
                                currency,
                                false,
                              )}
                            </div>
                          )}
                          <Tooltip
                            content={
                              <CancellationFeesDetails
                                policies={selectedPolicy.cancellationPolicies || []}
                                currency={currency}
                              />
                            }
                            extendStyle={
                              extendStyles?.tooltip || styles.tooltip.container
                            }
                            tooltipPosition={{
                              side: 'bottom',
                              align: 'start',
                              alignOffset: 40,
                            }}
                          >
                            <div css={[extendStyles?.focusedText, styles.rates.icon]}>
                              <InfoIcon color={'#8E8E8E'} />
                            </div>
                          </Tooltip>
                        </div>

                        <div css={styles.rates.mobileTooltip.wrapper}>
                          <div css={styles.rates.mobileItem}>
                            {selectedBoardOption.rates.length > 1 && (
                              <div>
                                {rate.packageId === selectedPolicy.packageId ? (
                                  <SelectedRadioButtonIcon />
                                ) : (
                                  <RadioButtonIcon />
                                )}
                              </div>
                            )}
                            Fully refundable before{' '}
                            {refundCost(rate.amount) !== 0 && (
                              <div css={styles.rates.refundCost}>
                                +
                                {getPriceWithCurrency(
                                  refundCost(rate.amount),
                                  currency,
                                  false,
                                )}
                              </div>
                            )}
                            {formatToCustom(
                              getCancellationDeadlineDate(
                                rate.cancellationPolicies?.[0].from || '',
                              ),
                              'DD MMM',
                            )}
                            <div
                              css={styles.rates.mobileTooltip.icon}
                              onClick={(e) => {
                                e.stopPropagation()
                                setPolicyDetailsVisibility(!isPolicyDetailsVisible)
                              }}
                            >
                              <InfoIcon color={'#8E8E8E'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div css={styles.rates.mobilePrice.wrapper}>
          <p css={styles.price.value}>
            {getPriceWithCurrency(selectedPolicy.dailyAmount || 0, currency)}
            <span>/night</span>
          </p>

          <p css={styles.price.total}>
            {getPriceWithCurrency(selectedPolicy.amount || 0, currency)} total
          </p>
        </div>
      </div>

      <Modal
        isOpen={isDetailsModalVisible}
        onClose={() => setDetailsModalVisibility(false)}
      >
        <RoomDetails
          room={roomDetails}
          selectRoom={selectRoom}
          selectBoardOption={selectBoardOption}
          selectedBoardOption={selectedBoardOption}
          selectPolicy={setSelectedPolicy}
          selectedPolicy={selectedPolicy}
          isRoomSelected={isSelected}
          occupancy={occupancy}
          currency={currency}
          onClose={() => setDetailsModalVisibility(false)}
        />
      </Modal>

      <Modal
        isOpen={isPolicyDetailsVisible}
        onClose={() => setPolicyDetailsVisibility(false)}
        centred
      >
        <div css={styles.rates.mobileTooltip.wrapper}>
          <div css={styles.rates.mobileTooltip.container}>
            <img
              src={crossIcon}
              css={styles.rates.mobileTooltip.cross}
              onClick={() => setPolicyDetailsVisibility(false)}
            />
            <div css={styles.rates.mobileTooltip.top}>
              <InfoIcon color={'#8E8E8E'} />
              Cancellation details
            </div>

            <div css={styles.rates.mobileTooltip.content}>
              You can cancel your reservation for a full refund, excluding cancellation
              fees, until{' '}
              {formatToCustom(
                getCancellationDeadlineDate(
                  selectedPolicy.cancellationPolicies?.[0].from || '',
                ),
                'DD/MM/YYYY',
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
