import { RegenerateItineraryDto } from '@softcery/awayaway-nodejs-api-client'
import { attach, createEvent, createStore, sample } from 'effector'
import { reset } from 'patronum'
import { destinationModel } from '~/entities/trip'
import { atom } from '~/shared/lib/factory'

export const regenerateItineraryModel = atom(() => {
  const resetState = createEvent()
  const initiate = createEvent()
  const openPopup = createEvent()
  const closePopup = createEvent()
  const inputValueChanged = createEvent<string>()
  const regenerateRequested = createEvent<{
    id?: string
    destinationCode?: string
    fields: RegenerateItineraryDto
  }>()

  const regenerateFx = attach({ effect: destinationModel.regenerateTripItinerariesFx })

  const $popupIsOpen = createStore(false)
  const $inputValue = createStore('')

  sample({
    clock: openPopup,
    fn: () => true,
    target: $popupIsOpen,
  })

  sample({
    clock: closePopup,
    fn: () => false,
    target: $popupIsOpen,
  })

  sample({
    clock: inputValueChanged,
    target: $inputValue,
  })

  sample({
    clock: initiate,
    source: {
      trip: destinationModel.$selectedDestination,
      itinerary: destinationModel.$itineraries,
      adjustments: $inputValue,
    },
    filter: ({ adjustments }) => adjustments !== '',
    fn: ({ trip, itinerary, adjustments }) => ({
      id: trip?.id,
      destinationCode: trip?.destinationCode || '',
      fields: {
        itinerary,
        adjustments,
      },
    }),
    target: [regenerateFx, regenerateRequested],
  })

  sample({
    clock: initiate,
    target: [closePopup, resetState],
  })

  reset({
    clock: resetState,
    target: $inputValue,
  })

  return {
    initiate,
    openPopup,
    closePopup,
    inputValueChanged,
    regenerateRequested,

    $popupIsOpen,
    $inputValue,
  }
})
