import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { arrowLeft } from '~/shared/assets/icons/primary'
import { styles } from './styles'

interface Props extends NavLinkProps {
  extendStyles?: SerializedStyles
}

export const NavigationBack: FC<Props> = ({ extendStyles, children, ...linkProps }) => {
  return (
    <NavLink {...linkProps} css={[styles.navLink, extendStyles]}>
      <img src={arrowLeft} />

      {children}
    </NavLink>
  )
}
