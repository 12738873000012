import { useGate, useStore } from 'effector-react'
import { FC } from 'react'

import { locationsPageModel } from '~/pages/onboarding/locations'
import { summaryPageModel } from '~/pages/onboarding/summary'
import { Header } from '~/widgets/header'
import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'

import { hotelsForTripModel } from '~/entities/hotel'
import { navigationModel } from '~/entities/navigation'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { Pagination } from '~/entities/pagination'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { Trip } from '~/entities/trip/ui/trip'
import { viewerModel } from '~/entities/viewer'
import { mapIcon } from '~/shared/assets/icons'
import {
  CrossIcon,
  InfoIcon,
  RefreshIcon,
  ZapIcon,
} from '~/shared/assets/icons/components'
import { AppRoute } from '~/shared/config'
import { changeImageResolution } from '~/shared/lib/image'
import { Button, Error, WithHeader } from '~/shared/ui'

import { destinationsPageModel } from '..'
import { PAGINATION_PAGE_SIZE } from '../config'
import { SkeletonLoader } from './skeleton-loader'
import { styles } from './styles'

export const DestinationsPage: FC = () => {
  useGate(destinationsPageModel.Gate)

  const { error, loading } = useStore(destinationModel.$getTripsStatus)
  const destinations = useStore(destinationModel.$trips)
  const greetingMessage = useStore(destinationModel.$greetingMessage)
  const displayedTrips = useStore(destinationsPageModel.$displayedTrips)
  const isTripsNotFound = useStore(destinationModel.$tripsReceivingFailed)
  const isMultipleDestinations = useStore(locationsPageModel.$isMultipleDestinations)
  const viewer = useStore(viewerModel.$viewer)
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  const viewerFirstName = viewer.fullName?.split(' ')[0]

  const backgroundImage = displayedTrips[0]?.images?.[0] || ''

  //TODO: move to model
  const regenerateHandler = () => {
    destinationsPageModel.regenerateTripsInitiated()
    onboardingSessionModel.statusReset()
    navigationModel.pathChanged(AppRoute.Summary)
    summaryPageModel.summaryEdited()
    destinationModel.stateReset()
    hotelRoomModel.$$getRoomsFactory.stateReset()
  }

  //TODO: move to model
  const backToQuizHandler = () => {
    destinationsPageModel.backToQuizNavigated()
    onboardingSessionModel.statusReset()
    navigationModel.pathChanged(AppRoute.Destinations)
    destinationModel.stateReset()
  }

  const openIntercomHandler = () => {
    destinationsPageModel.openMessengerInitiated()
    if (window.Intercom) {
      window.Intercom('show')
    }
  }

  return (
    <WithHeader
      header={
        <div
          css={styles.header.wrapper(
            backgroundImage
              ? changeImageResolution(backgroundImage, 1920, 1080)
              : undefined,
          )}
        >
          <div css={styles.header.filter}>
            <div css={styles.header.container}>
              <Header
                onLogoClick={onboardingLayoutModel.logoClicked}
                type="light"
                extendStyles={{
                  container: styles.header.header,
                }}
              />

              <div css={styles.header.footer}>
                {isAuthorized && (
                  <div>
                    <h1 css={styles.header.title}>Hello, {viewerFirstName}!</h1>
                    <p css={styles.header.message}>{greetingMessage}</p>
                  </div>
                )}

                {!isTripsNotFound && (
                  <Button
                    onClick={backToQuizHandler}
                    css={styles.header.reOnboardingButton.base}
                  >
                    Back to Quiz
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      extendStyles={styles.container}
    >
      {isTripsNotFound ? (
        <div css={styles.notFound.wrapper}>
          <div css={styles.notFound.content}>
            <img css={styles.notFound.icon} src={mapIcon} />
            <div css={styles.notFound.title}>
              Sorry! It seems we haven’t found a perfect match
            </div>
            <p css={styles.notFound.description}>
              But you can try adjusting your choice by restarting the quiz
            </p>
            <Button
              variant="outlined"
              css={styles.notFound.button}
              onClick={backToQuizHandler}
            >
              Back to Quiz
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {isMultipleDestinations && (
            <div css={styles.notification.container}>
              <div css={styles.notification.wrapper}>
                <div css={styles.notification.title}>
                  <InfoIcon width={20} height={20} color="#009F9D" /> Sorry!
                </div>
                <div css={styles.notification.desc}>
                  Multiple destinations aren’t currently supported. You'll be the first to
                  know when this feature is available.
                </div>
              </div>

              <div
                css={styles.notification.close}
                onClick={() => locationsPageModel.destinationsTypeChanged(false)}
              >
                <CrossIcon width={16} height={16} />
              </div>
            </div>
          )}

          <div css={styles.title}>
            <ZapIcon />

            <p>Top ideas for your trip</p>
          </div>

          {loading && (
            <div css={styles.loader.wrapper}>
              <div css={styles.loader.container}>
                <SkeletonLoader />
              </div>
            </div>
          )}

          {!loading && Boolean(destinations?.length) && (
            <div css={styles.trips}>
              {displayedTrips.map((trip) => (
                <div key={trip.destinationCode}>
                  <Trip
                    trip={trip}
                    onGetHotels={(hotels) =>
                      hotelsForTripModel.$$getHotelsFactory.updateHotelsRequest(hotels)
                    }
                  />
                </div>
              ))}

              <div css={styles.regenerate.wrapper}>
                <p css={styles.regenerate.text}>Not what you are looking for?</p>
                <div css={styles.button.wrapper}>
                  <Button
                    extendStyle={styles.regenerateButton.main}
                    onClick={() => regenerateHandler()}
                  >
                    Regenerate
                    <span css={styles.regenerateButton.icon}>
                      <RefreshIcon color="#fff" />
                    </span>
                  </Button>

                  <Button
                    variant="outlined"
                    extendStyle={styles.consultantButton.main}
                    onClick={() => openIntercomHandler()}
                  >
                    Message travel consultant
                  </Button>
                </div>
              </div>

              {error && <Error>{error}</Error>}

              {!error && Boolean(destinations?.length) && (
                <Pagination
                  totalItems={destinations.length}
                  pageSize={PAGINATION_PAGE_SIZE}
                  model={destinationsPageModel.$$pagination}
                />
              )}
            </div>
          )}
        </div>
      )}
    </WithHeader>
  )
}
