import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { LocalStorageKeys } from '~/shared/config'
import { atom, bridge } from '~/shared/lib/factory'

export const infoPopupModel = atom(() => {
  const open = createEvent()
  const close = createEvent()
  const $isOpen = createStore(false)
  const $isExpiredFlight = createStore(false)

  bridge(() => {
    sample({
      clock: open,
      fn: () => true,
      target: $isOpen,
    })

    sample({
      clock: close,
      fn: () => false,
      target: $isOpen,
    })

    sample({
      clock: $isExpiredFlight,
      filter: (isExpiredFlight) => !!isExpiredFlight,
      target: open,
    })

    sample({
      clock: $isExpiredFlight,
      filter: (isExpiredFlight) => !isExpiredFlight,
      target: close,
    })

    sample({
      clock: close,
      fn: () => localStorage.removeItem(LocalStorageKeys.SelectedFlightOfferTimeExpired),
    })
  })

  persist({
    store: $isExpiredFlight,
    key: LocalStorageKeys.SelectedFlightOfferTimeExpired,
  })

  return {
    open,
    close,
    $isOpen,
  }
})
