import { createEffect, createEvent, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { LocalStorageKeys } from '~/shared/config'
import { atom } from '~/shared/lib/factory'
import { getHolidaysRecommendations } from './api'

export const listImageRecommendationsModel = atom(() => {
  const stateReset = createEvent()

  const getHolidaysRecommendationsFx = createEffect(getHolidaysRecommendations)

  const imagesLabelsSelected = createEvent<string[]>()
  const $imagesLabels = restore(imagesLabelsSelected, [])

  reset({
    clock: stateReset,
    target: $imagesLabels,
  })

  persist({ store: $imagesLabels, key: LocalStorageKeys.SelectedHolidayCategories })

  return {
    $imagesLabels,

    stateReset,
    imagesLabelsSelected,

    getHolidaysRecommendationsFx,
  }
})
