import { createEvent, restore } from 'effector'
import { reset } from 'patronum'
import { atom } from '~/shared/lib/factory'

export const paginationModelFactory = () =>
  atom(() => {
    const offsetChanged = createEvent<number>()
    const currentPageChanged = createEvent<number>()
    const stateReset = createEvent()

    const $offset = restore(offsetChanged, 0)
    const $currentPage = restore(currentPageChanged, 1)

    reset({
      clock: stateReset,
      target: [$offset, $currentPage],
    })
    return { currentPageChanged, offsetChanged, $offset, $currentPage, stateReset }
  })
