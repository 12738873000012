// colors for whole app
export enum Colors {
  LIGHT_1 = '#fff',
  LIGHT_2 = '#f8f8f8',
  LIGHT_4 = '#f0f0f0',

  DARK_1 = '#000',

  PRIMARY_GREEN = '#009F9D',

  LIGHT_GREEN = '#6EC6C0',

  NEUTRAL_WHITE = '#FDFDFD',

  NEUTRAL_BLACK = '#333333',
  NEUTRAL_DARK = '#555555',
  NEUTRAL_GREY = '#8E8E8E',
  NEUTRAL_SILVER = '#EAEAEA',
  NEUTRAL_STONE = '#D1D1D1',
  NEUTRAL_LIGHT = '#F8F8F8',

  LIGHT_GREY_1 = '#d1d1d1',
  LIGHT_GREY_2 = '#eaeaea',
  LIGHT_GREY_3 = '#e7e7e7',
  LIGHT_GREY_4 = '#D5D4DC',

  DARK_GREY_2 = '#7b7b7b',
  DARK_GREY_4 = '#707070',

  ACCENT_1 = '#008381',
  ACCENT_3 = '#5bc0bf',
  ACCENT_4 = '#009f9d0f',

  RED_1 = ' #ea0000',
  ERROR = '#F10000',
}

// fonts for whole app
export enum Fonts {
  Calibre = 'calibre, arial, sans-serif',
  Joane = 'joane, serif',
  OpticianSans = 'opticiansans, arial, sans-serif',
  Montserrat = 'montserrat, arial, sans-serif',
}
