import { css } from '@emotion/react'

export const styles = {
  base: css`
    height: 60px;
    cursor: pointer;
    padding: 2px;
  `,

  dark: css`
    filter: invert(1);
  `,
}
