import { useEffect, useRef } from 'react'

export const useScrollIntoView = (dep: any) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (dep) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [dep])

  return ref
}
