import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: ${Colors.LIGHT_2};
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.32px;

    display: flex;
    gap: 4px;

    padding: 6px 8px;
  `,

  label: css`
    text-transform: capitalize;
  `,
}
