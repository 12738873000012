import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  avatar: css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 200px;
    font-family: ${Fonts.Calibre};
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: ${Colors.PRIMARY_GREEN};
    background-color: rgba(0, 159, 157, 0.08);
  `,
}
