import { FC } from 'react'
import { Colors } from '~/shared/styles'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const CloudIcon: FC<Props> = ({
  width = 17,
  height = 16,
  color = Colors.NEUTRAL_STONE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cloud" clipPath="url(#clip0_10750_4706)">
        <path
          id="Vector"
          d="M12.4999 6.6665H11.6599C11.4105 5.7005 10.8951 4.82383 10.1723 4.1361C9.44957 3.44837 8.54839 2.97716 7.57119 2.77602C6.594 2.57488 5.57997 2.65186 4.64435 2.99823C3.70872 3.3446 2.889 3.94646 2.27835 4.73543C1.66769 5.52439 1.29057 6.46882 1.18985 7.46141C1.08913 8.45399 1.26884 9.45493 1.70856 10.3505C2.14828 11.246 2.83039 12.0003 3.67737 12.5275C4.52434 13.0548 5.50223 13.3339 6.49991 13.3332H12.4999C13.384 13.3332 14.2318 12.982 14.8569 12.3569C15.4821 11.7317 15.8332 10.8839 15.8332 9.99984C15.8332 9.11578 15.4821 8.26794 14.8569 7.64281C14.2318 7.01769 13.384 6.6665 12.4999 6.6665Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10750_4706">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
