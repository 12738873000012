import React, { FC, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
// eslint-disable-next-line import/order
import { styles } from './styles'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMediaQuery } from 'react-responsive'
import { Dimensions } from '~/shared/styles'

export const SkeletonLoader: FC = () => {
  useEffect(() => {
    document.body.style.overflowX = 'hidden'

    return () => {
      document.body.style.overflowX = 'auto'
    }
  }, [])

  return (
    <div css={styles.wrapper}>
      <TripLayout />
      <TripLayout />
      <TripLayout />
    </div>
  )
}

export const TripLayout: FC = () => {
  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Xl}px)` })
  const isMobile = useMediaQuery({ query: `(max-width: ${Dimensions.Sm}px)` })

  return (
    <div css={styles.container}>
      <div css={styles.images.container}>
        <div css={styles.images.firstImage}>
          <Skeleton
            height={isMobile ? 263 : 467}
            borderRadius={isDesktop ? '16px 0 0 16px' : 16}
          />
        </div>

        {isDesktop && (
          <>
            <div css={styles.images.secondImage}>
              <Skeleton height={231} borderRadius={'0 16px 0 0'} />
            </div>
            <div css={styles.images.thirdImage}>
              <Skeleton height={231} borderRadius={'0 0 16px 0'} />
            </div>
          </>
        )}
      </div>

      <div css={styles.content}>
        <Skeleton height={isDesktop ? 40 : 30} />

        <div css={styles.dates}>
          <div css={styles.date}>
            <Skeleton width={'100%'} height={31} borderRadius={6} />
          </div>
          <div css={styles.date}>
            <Skeleton width={'100%'} height={31} borderRadius={6} />
          </div>
          <div css={styles.date}>
            <Skeleton width={'100%'} height={31} borderRadius={6} />
          </div>
        </div>

        {!isMobile && (
          <div css={styles.flexContainer}>
            <Skeleton width={118} height={31} borderRadius={6} />

            <Skeleton width={118} height={31} borderRadius={6} />
          </div>
        )}

        <p css={styles.description}>
          <Skeleton height={84} />
        </p>

        <div css={styles.keyPoints}>
          <Skeleton width={150} height={39} borderRadius={100} />
          <Skeleton width={144} height={39} borderRadius={100} />

          {isDesktop && (
            <>
              <Skeleton width={204} height={39} borderRadius={100} />
              <Skeleton width={187} height={39} borderRadius={100} />
            </>
          )}
        </div>

        <div css={styles.button}>
          <Skeleton height={58} borderRadius={0} />
        </div>
      </div>
    </div>
  )
}
