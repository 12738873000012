import { createFactory } from '@withease/factories'
import { createEffect, createEvent, restore, sample } from 'effector'
import { reset } from 'patronum'
import { UserAirport } from '~/entities/flights/types'
import { bridge } from '~/shared/lib/factory'
import { getNearestAirport } from './api'

export const getUserPositionAirportFactory = createFactory(() => {
  const getUserNearestAirportFx = createEffect(getNearestAirport)

  const resetError = createEvent()
  const updateError = createEvent<string>()
  const $error = restore(updateError, null)

  const updateUserAirport = createEvent<UserAirport>()
  const $userAirport = restore<UserAirport>(updateUserAirport, null)

  const $pending = getUserNearestAirportFx.pending

  // success handling
  bridge(() => {
    sample({
      clock: getUserNearestAirportFx.doneData,
      filter: ({ data }) => !!data?.airport,
      fn: ({ data }) => data!.airport!,
      target: updateUserAirport,
    })
  })

  // error handling
  bridge(() => {
    sample({
      clock: getUserNearestAirportFx.failData,
      fn: (error) => error.message,
      target: updateError,
    })
  })

  // reset store
  bridge(() => {
    reset({
      clock: resetError,
      target: $error,
    })

    reset({
      clock: updateError,
      target: $userAirport,
    })
  })

  return {
    $userAirport,
    $pending,
    $error,

    updateError,
    resetError,
    updateUserAirport,

    getUserNearestAirportFx,
  }
})

export type GetUserPosition = ReturnType<typeof getUserPositionAirportFactory>
