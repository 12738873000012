import { FC } from 'react'
import { logoLineIcon } from '~/shared/assets/icons'
import { styles } from './styles'

interface Props {
  onClick?: () => void
  type: 'light' | 'dark'
}

export const Logo: FC<Props> = ({ onClick, type, ...props }) => (
  <img
    src={logoLineIcon}
    css={[styles.base, type === 'light' && styles.light]}
    onClick={onClick}
    {...props}
  />
)
