import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    width: 423px;
    min-height: 84px;
    border: solid 1px ${Colors.LIGHT_GREY_2};
    background-color: ${Colors.LIGHT_1};
    padding: 20px 24px;
    border-radius: 8px;
    z-index: 10;

    box-shadow: 0px -4px 76px 0px rgba(52, 52, 52, 0.1);
    ${Breakpoints.SM} {
      min-height: 80px;
      width: 90%;
      padding: 12px;
      bottom: 24px;
    }

    /* fix increasing this container width when hiding the scroll */
    .lock & {
      width: calc(100% - var(--scrollbar-compensation));
    }
  `,

  wrapper: css`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1110px;
    height: 100%;
    margin: 0 auto;
  `,

  widthLimiter: {
    base: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,

    limit: css`
      max-width: 760px;
      padding-right: 30px;
    `,
  },

  text: css`
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    max-width: 200px;

    ${Breakpoints.SM} {
      font-size: 16px;
      line-height: 1.2;
      max-width: 165px;
    }
  `,

  linkButton: css`
    padding: 0;
    font-size: 16px;
    color: ${Colors.PRIMARY_GREEN};
    transition: all 0.3s;
    margin-top: 4px;
  `,
}
