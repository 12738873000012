import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { FLIGHT_TYPE } from '~/entities/flights/types'
import { DropdownIndicator, SelectInput } from '~/shared/ui'
import { searchFlightsModel } from '../../model/model'
import { DefaultValues } from '../../types'
import { AirportInput } from './location'
import { styles } from './styles'

export const SearchBar: FC<DefaultValues> = (defaultValues) => {
  useGate(searchFlightsModel.Gate, defaultValues)

  const startDate = useStore(searchFlightsModel.$$searchFlightsFactory.$startDate)
  const endDate = useStore(searchFlightsModel.$$searchFlightsFactory.$endDate)

  const adultsCount = useStore(searchFlightsModel.$$searchFlightsFactory.$adultsCount)
  const childrenCount = useStore(searchFlightsModel.$$searchFlightsFactory.$childrenCount)

  const fromAirport = useStore(searchFlightsModel.$$searchFlightsFactory.$fromAirport)
  const toAirport = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$toAirport,
  )

  const isFlightOneWay = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$oneWayFlight,
  )
  const flightType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )

  const loading = useStore(searchFlightsModel.$loading)

  const isSearchBarDisabled = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isSearchDisabled,
  )
  const isSearchButtonDisabled =
    isSearchBarDisabled || !fromAirport || !toAirport || !startDate || loading

  const flightsOptions = [
    { label: 'Return', value: FLIGHT_TYPE.RETURN },
    { label: 'Inbound', value: FLIGHT_TYPE.INBOUND },
    { label: 'Outbound', value: FLIGHT_TYPE.OUTBOUND },
  ]

  return (
    <div css={styles.container}>
      {/* <div css={styles.journeyType}>
        <Label>Journey type</Label>

        <RadioGroup
          value={isFlightOneWay ? '2' : '1'}
          onChange={(value) =>
            searchFlightsModel.flightIsOneWayChanged(value === '1' ? false : true)
          }
          items={[
            { value: '1', label: 'Return' },
            { value: '2', label: 'One way' },
          ]}
          disabled={isSearchBarDisabled}
        />
      </div> */}

      <div css={styles.airports}>
        <AirportInput
          label="Your location"
          airport={fromAirport!}
          disabled={loading}
          onSelect={searchFlightsModel.$$searchFlightsFactory.fromAirportChanged}
        />

        <SelectInput
          label="Type"
          value={flightType}
          disabled={loading}
          onChange={(value) => {
            searchFlightsModel.$$manageLocalStorageForSearchFlights.flightTypeChanged(
              value.value,
            )
          }}
          options={flightsOptions}
          components={{ DropdownIndicator }}
          isSearchable={false}
          extendStyles={{
            control: styles.select.control,
            container: styles.select.container,
          }}
        />

        {/* <AirportInput
          label="To"
          airport={toAirport!}
          onSelect={searchFlightsModel.toAirportChanged}
          extendStyles={styles.toAirportInput}
          disabled={isSearchBarDisabled}
        /> */}
      </div>

      {/* <DatesInput
        startDate={startDate}
        endDate={endDate}
        onDatesChange={(dates) =>
          searchFlightsModel.datesChanged({
            startDate: dates.startDate || new Date(),
            endDate: dates.endDate || new Date(),
          })
        }
        disabled={isSearchBarDisabled}
      />

      <PassengersCounter
        adultsCount={adultsCount}
        childrenCount={childrenCount}
        onAdultsChange={searchFlightsModel.adultsCountChanged}
        onChildrenChange={searchFlightsModel.childrenCountChanged}
        disabled={isSearchBarDisabled}
      /> */}

      {/* <Button
        onClick={() => searchFlightsModel.initiate()}
        disabled={isSearchButtonDisabled}
        extendStyle={styles.button}
      >
        {loading ? <Loader /> : 'Search'}
      </Button> */}
    </div>
  )
}
