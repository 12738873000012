import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,

  rateContent: css`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    color: ${Colors.NEUTRAL_BLACK};
  `,
}
