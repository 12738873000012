import { motion } from 'framer-motion'
import { FC, Fragment } from 'react'
import { styles } from './styles'

export interface Props {
  id: number
  url: string
  selected?: boolean
  onSelect: () => void
}

export const Holiday: FC<Props> = ({ url, selected, onSelect, id }) => {
  return (
    <motion.div
      onClick={onSelect}
      css={styles.container}
      data-testid={`holidays-tile-${id}`}
      transition={{ duration: 0.4, type: 'spring' }}
      layout
    >
      <picture>
        <source srcSet={`${url}.webp`} type="image/webp" />
        <source srcSet={`${url}.jpg`} type="image/jpeg" />
        <img css={styles.image} src={`${url}.jpg`} loading="lazy" />
      </picture>
      {/* <img css={styles.image} loading="lazy" src={image} /> */}
      {selected && (
        <Fragment>
          <div css={styles.border}>
            <div css={styles.overlay} data-testid={`holidays-tile-${id}-blur`} />

            <div css={styles.selected}>Selected</div>
          </div>
        </Fragment>
      )}
    </motion.div>
  )
}
