import { createFactory } from '@withease/factories'
import { createEffect, createEvent, restore, sample } from 'effector'
import { reset } from 'patronum'
import { getHotelsForTrip } from '~/entities/hotel/model/get-hotels/api'

import { bridge } from '~/shared/lib/factory'
import { TripIdeaHotelRes } from '../../types'

export type GetHotelsFactory = ReturnType<typeof getHotelsFactory>
export const getHotelsFactory = createFactory(() => {
  const initiated = createEvent<number[]>()

  const resetState = createEvent()

  // todo with this request
  const getHotelsForTripFx = createEffect(getHotelsForTrip)

  const $loading = getHotelsForTripFx.pending

  const updateError = createEvent()
  const $error = restore(updateError, null)

  const updateHotelsRequest = createEvent<TripIdeaHotelRes[]>()

  // todo use this logic -----
  // start fetching
  // bridge(() => {
  //   sample({
  //     clock: initiated,
  //     target: getHotelsForTripFx,
  //   })
  // })

  // handle success
  bridge(() => {
    sample({
      clock: getHotelsForTripFx.doneData,
      fn: (hotels) => hotels,
      target: updateHotelsRequest,
    })
  })

  // handle error
  bridge(() => {
    sample({
      clock: getHotelsForTripFx.failData,
      fn: (error) => error.message,
      target: updateError,
    })
  })

  reset({
    clock: resetState,
    target: [$error],
  })

  // todo -------

  return {
    $loading,
    $error,

    updateHotelsRequest,
    resetState,
    initiated,
  }
})
