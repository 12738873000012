/* import { SerializedStyles } from '@emotion/react'
import { FC, InputHTMLAttributes } from 'react'
import { Control, UseFormRegister, useWatch } from 'react-hook-form'
import { getCardVendorCode } from '~/shared/lib/get-card-vendor-code'

import { PayCard } from '../../atoms'
import { InputWithLabel } from '../input-with-label'
import { styles } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  message: string | undefined
  control: Control<any>
  register: UseFormRegister<any>
  extendStyles?: {
    wrapper: SerializedStyles
    input: SerializedStyles
  }
}

export const PayCardInput: FC<Props> = ({
  name,
  message,
  control,
  register,
  extendStyles,
  ...props
}) => {
  const value = useWatch({ control, name })
  const cardVendorCode = getCardVendorCode(value)

  return (
    <div css={[styles.wrapper, extendStyles?.wrapper]} {...props}>

      <PayCard error={!!message} cardVendorCode={cardVendorCode} />
    </div>
  )
}
 */

import { SerializedStyles } from '@emotion/react'
import { forwardRef } from 'react'
import MaskedInput, { MaskedInputProps, Mask } from 'react-text-mask'
import { getCardVendorCode } from '~/shared/lib/get-card-vendor-code'
import { Label, PayCard } from '../../atoms'
import { styles } from './styles'

export interface InputWithMaskProps extends MaskedInputProps {
  mask: Mask

  error?: string
  label?: string
  extendStyle?: {
    wrapper?: SerializedStyles
    input?: SerializedStyles
    label?: SerializedStyles
  }
}

export const PayCardInput = forwardRef<any, InputWithMaskProps>(
  (
    {
      mask,

      error,
      label,
      extendStyle,

      ...props
    },
    ref,
  ) => {
    const cardVendorCode = getCardVendorCode(String(props.value))

    return (
      <div css={[styles.wrapper, extendStyle?.wrapper]}>
        <Label extendStyles={styles.label}>Card Number</Label>

        <MaskedInput
          ref={ref}
          mask={mask}
          guide={false}
          placeholder="1234 1234 1234 1234"
          css={[styles.input, error && styles.invalid, extendStyle?.input]}
          {...props}
        />

        <PayCard error={!!error} cardVendorCode={cardVendorCode} />
      </div>
    )
  },
)
