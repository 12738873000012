import { css } from '@emotion/react'
import { Fonts, Colors, Breakpoints } from '~/shared/styles'

export const styles = {
  container: (selected?: boolean, isSingleHotel?: boolean) => css`
    width: 100%;
    max-width: ${isSingleHotel ? '1092px' : '445px'};
    background-color: ${Colors.LIGHT_1};
    display: flex;
    flex-direction: ${isSingleHotel ? 'row' : 'column'};
    align-items: ${isSingleHotel && 'center'};
    min-height: 100%;
    border-radius: 12px;
    overflow: hidden;
    border: ${selected
      ? `1.5px solid ${Colors.PRIMARY_GREEN}`
      : `1.5px solid ${Colors.LIGHT_GREY_2}`};
    position: relative;
    padding: ${isSingleHotel && '20px'};

    ${Breakpoints.MD} {
      flex-direction: column;
      padding: 0;
    }
  `,

  image: (isSingleHotel?: boolean) => css`
    cursor: pointer;

    width: ${isSingleHotel ? '50%' : '100%'};

    ${Breakpoints.MD} {
      width: 100%;
    }
  `,

  info: css`
    margin-inline: 24px;
    margin-block: 20px 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    letter-spacing: 0.18px;
    word-break: break-word;
    color: ${Colors.DARK_GREY_2};
    background-color: ${Colors.LIGHT_1};
    font-family: ${Fonts.Calibre};
    font-size: 18px;
    font-weight: normal;
    height: 100%;
    justify-content: space-between;
    flex: 1;
  `,
  title: css`
    text-align: start;
    font-size: 24px;
    font-weight: normal;
    font-family: ${Fonts.Calibre};

    cursor: pointer;
  `,

  chooseButton: (selected?: boolean, isSingleHotel?: boolean) => css`
    display: flex;
    font-size: 16px;
    font-weight: ${selected ? '500' : '400'};
    letter-spacing: 0.32px;
    color: ${selected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_LIGHT};
    padding: 10.5px 16px;
    justify-content: center;
    align-items: center;
    width: auto;
    border-radius: 100px;
    border: ${selected
      ? `1px solid ${Colors.PRIMARY_GREEN}`
      : `1px solid ${Colors.NEUTRAL_GREY}`};
    background: ${selected ? Colors.LIGHT_1 : 'rgba(0, 0, 0, 0.4)'};
    backdrop-filter: blur(1px);

    position: absolute;
    top: 16px;
    right: 16px;
    left: ${isSingleHotel && '24px'};
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  `,

  descriptionBlock: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  description: css`
    margin-bottom: 8px;
  `,

  descriptionCut: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  `,
  dates: {
    wrapper: css`
      display: flex;
      align-items: center;
    `,

    item: css`
      width: max-content;
      min-width: fit-content;

      display: flex;
      align-items: center;
      gap: 10px;

      padding: 6px 10px;
      border-radius: 6px;

      background-color: ${Colors.NEUTRAL_LIGHT};
      color: ${Colors.NEUTRAL_BLACK};

      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.02em;
    `,
  },

  averagePrice: css`
    /* margin-block: 12px; */
    color: ${Colors.DARK_1};
    border-radius: 2px;
    font-family: ${Fonts.Calibre};
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    margin-left: 16px;

    span {
      color: ${Colors.NEUTRAL_DARK};
    }
  `,

  facilities: {
    wrapper: css`
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
    `,

    item: css`
      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.02em;

      color: ${Colors.NEUTRAL_BLACK};
    `,
  },

  greyContainer: css`
    background-color: rgb(244 242 242 / 75%);
    padding: 19px 24px;
    margin-top: 12px;
  `,

  bookButton: css`
    display: block;
    align-self: flex-end;
    min-width: 183px;
    min-height: 63px;
    max-height: 63px;
    margin-top: auto;
    margin-bottom: 20px;
  `,

  readMoreButton: css`
    position: relative;
    z-index: 3;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_GREY};
    text-decoration: underline;
    text-decoration-thickness: 1px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin-top: 10px;
  `,
}
