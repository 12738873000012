import { SerializedStyles } from '@emotion/react'
import { FC, ComponentProps } from 'react'
import { styles } from './styles'
interface Props extends ComponentProps<'h1'> {
  extendStyles?: SerializedStyles
}

export const Title: FC<Props> = ({ children, extendStyles, ...rest }) => (
  <h1 css={[styles.base, extendStyles]} {...rest}>
    {children}
  </h1>
)
