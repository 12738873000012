import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  feedback: {
    wrapper: css`
      display: flex;
      justify-content: center;
    `,

    container: css`
      display: flex;
      align-items: center;
      gap: 16px;
    `,

    button: css`
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    `,
  },

  modal: {
    wrapper: css`
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${Colors.LIGHT_1};
      width: 558px;
      min-height: 340px;
      padding: 24px 60px;
      position: relative;

      ${Breakpoints.MD} {
        width: 458px;
        padding: 24px 16px;
      }

      ${Breakpoints.SM} {
        width: 328px;
        padding: 24px 16px;
      }
    `,

    container: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `,

    closeBtn: css`
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 2px;
    `,

    title: css`
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    text: css`
      font-size: 16px;
      color: ${Colors.NEUTRAL_BLACK};
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      margin-top: 12px;
      text-align: center;
    `,

    textarea: css`
      width: 400px;
      height: 124px;
      margin: 24px 0;
      border-radius: 6px;
      resize: none;
      outline: none;
      padding: 10px;
      font-family: ${Fonts.Calibre};

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: ${Colors.NEUTRAL_BLACK};
      border: 1px solid ${Colors.LIGHT_GREY_4};
      transition: all 0.2s ease-in-out;

      &:focus {
        border: 1px solid ${Colors.PRIMARY_GREEN};
      }

      ${Breakpoints.MD} {
        width: 100%;
        margin: 16px 0;
      }
    `,

    button: css`
      height: 58px;
      width: 215px;

      ${Breakpoints.MD} {
        width: 100%;
        height: 44px;
        font-size: 18px;
      }
    `,

    submittedTitle: css`
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      color: ${Colors.NEUTRAL_BLACK};
      margin-top: 24px;
    `,

    submittedText: css`
      font-size: 16px;
      color: ${Colors.NEUTRAL_BLACK};
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      margin-top: 12px;
    `,
  },
}
