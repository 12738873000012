import { useForm } from 'effector-forms'

import { inputMasks } from '~/shared/config'
import { InputWithMask } from '~/shared/ui'
import { InputWithLabel } from '~/shared/ui/molecules/input-with-label'

import { addContactInfoModel } from '..'

import { styles } from './styles'

export const ContactsForm = () => {
  const { fields, errorText } = useForm(addContactInfoModel.$$contactForm)

  return (
    <form>
      <h1 css={styles.title}>Contact details</h1>

      <div css={styles.inputs.container}>
        <div css={styles.inputs.row}>
          <InputWithLabel
            type="text"
            name="firstName"
            label="First Name"
            placeholder="John"
            required
            css={styles.textInput}
            extendStyle={{ wrapper: styles.fullWidth }}
            value={fields.firstName.value}
            error={errorText('firstName')}
            onChange={(e) => fields.firstName.onChange(e.target.value)}
            onBlur={() => fields.firstName.onBlur()}
          />
          <InputWithLabel
            type="text"
            name="lastName"
            label="Last Name"
            placeholder="Doe"
            required
            css={styles.textInput}
            value={fields.lastName.value}
            extendStyle={{ wrapper: styles.fullWidth }}
            error={errorText('lastName')}
            onChange={(e) => fields.lastName.onChange(e.target.value)}
            onBlur={() => fields.lastName.onBlur()}
          />
        </div>

        <div>
          <InputWithLabel
            type="text"
            name="leadEmail"
            label="Email address"
            placeholder="Enter email address"
            required
            css={styles.textInput}
            value={fields.leadEmail.value}
            error={errorText('leadEmail')}
            onChange={(e) => fields.leadEmail.onChange(e.target.value)}
            onBlur={() => fields.leadEmail.onBlur()}
          />
          <p css={styles.helperText}>Confirmation email goes to this address</p>
        </div>

        <div>
          <InputWithMask
            type="text"
            name="leadPhone"
            label="Phone Number"
            placeholder="+447700900000"
            css={styles.textInput}
            value={fields.leadPhone.value}
            required
            error={errorText('leadPhone')}
            onChange={(e) => fields.leadPhone.onChange(e.target.value)}
            onBlur={() => fields.leadPhone.onBlur()}
            mask={inputMasks.PHONE_NUMBER}
            guide={false}
          />
          <p css={styles.helperText}>This number will be used for booking flights</p>
        </div>
      </div>
    </form>
  )
}
