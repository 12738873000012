import { css, keyframes } from '@emotion/react'
import { Colors } from '~/shared/styles'

// spinning animation
export const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const styles = {
  base: css`
    animation: ${spinning} 1.2s linear infinite;
    fill: ${Colors.LIGHT_1};
  `,
}
