import { sample, createEvent } from 'effector'
import { createForm } from 'effector-forms'
import { persist } from 'effector-storage/local'
import { viewerModel } from '~/entities/viewer'
import { LocalStorageKeys } from '~/shared/config'
import { rules } from '~/shared/lib/validation'

import { ContactDetails } from '../types'

export const $$contactForm = createForm<ContactDetails>({
  validateOn: ['submit'],
  fields: {
    firstName: {
      init: '',
      rules: [rules.firstName()],
      validateOn: ['blur'],
    },
    lastName: {
      init: '',
      rules: [rules.lastName()],
      validateOn: ['blur'],
    },
    leadEmail: {
      init: '',
      rules: [rules.leadEmail()],
      validateOn: ['blur'],
    },
    leadPhone: {
      init: '',
      rules: [rules.leadPhone()],
      validateOn: ['blur'],
    },
  },
})

export const $isContactFormValid = $$contactForm.$isValid
export const contactDetailsFormInit = createEvent()
export const validationInitiated = createEvent()
export const stateReset = createEvent()
export const contactDetailsSubmitted = createEvent<boolean>()
export const contactDetailsReceived = createEvent()

sample({
  clock: contactDetailsFormInit,
  source: viewerModel.$viewer,
  fn: ({ email, fullName }) => ({
    leadEmail: email,
    firstName: fullName!.split(' ')[0],
    lastName: fullName?.split(' ')[1] || '',
  }),
  target: $$contactForm.setForm,
})

sample({
  clock: validationInitiated,
  target: $$contactForm.validate,
})

sample({
  clock: $$contactForm.formValidated,
  target: contactDetailsReceived,
})

sample({
  clock: stateReset,
  target: $$contactForm.reset,
})

persist({ store: $$contactForm.$values, key: LocalStorageKeys.ContactForm })
