import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    gap: 16px;
  `,

  container: css`
    margin-top: 7px;
    margin-bottom: 16px;
  `,

  title: css`
    height: 16px;
    width: 64px;
    border-radius: 22px;
    margin-bottom: 11px;
  `,

  row: css`
    height: 16px;
    border-radius: 22px;
    margin-bottom: 7px;
  `,

  timeline: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  icon: css`
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${Colors.NEUTRAL_LIGHT};
  `,

  line: css`
    position: relative;
    width: 1px;
    height: 100%;
    background: ${Colors.NEUTRAL_STONE};
  `,
}
