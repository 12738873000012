import { sample } from 'effector'
import { tripInfoModel } from '~/entities/trip-info'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { Gate, nextStepNavigated } from './'

sample({
  clock: nextStepNavigated,
  source: {
    rooms: tripInfoModel.$roomsAmount,
    adults: tripInfoModel.$adultsAmount,
    children: tripInfoModel.$childrenAmount,
    childrenAges: tripInfoModel.$childrenAges,
  },
  fn: ({ rooms, adults, children, childrenAges }) => ({
    name: eventNames.completedGuestsStep,
    properties: {
      rooms_number: rooms,
      adults_number: adults,
      children_number: children,
      ...(children && { children_ages: childrenAges }),
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: Gate.open,
  fn: () => ({
    name: eventNames.quizGuestsVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: nextStepNavigated,
  source: {
    rooms: tripInfoModel.$roomsAmount,
    adults: tripInfoModel.$adultsAmount,
    children: tripInfoModel.$childrenAmount,
    childrenAges: tripInfoModel.$childrenAges,
  },
  fn: ({ rooms, adults, children, childrenAges }) => ({
    name: eventNames.quizGuestsCompleted,
    properties: {
      rooms_number: rooms,
      adults_number: adults,
      children_number: children,
      ...(children && { children_ages: childrenAges }),
    },
  }),
  target: analyticsModel.track,
})
