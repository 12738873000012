import { FC } from 'react'
import { Counter } from '~/shared/ui/organisms/counter'
import { styles } from './styles'

interface Props {
  label: string
  description: string
  value: number

  onMinus: () => void
  onPlus: () => void

  minusDisabled?: boolean
  plusDisabled?: boolean
}

export const Option: FC<Props> = ({
  label,
  description,
  value,
  onMinus,
  onPlus,
  minusDisabled,
  plusDisabled,
}) => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <div>
          <h1 css={styles.label}>{label}</h1>
          <p css={styles.description}>{description}</p>
        </div>

        <Counter
          value={value}
          onMinus={onMinus}
          onPlus={onPlus}
          minusDisabled={minusDisabled}
          plusDisabled={plusDisabled}
          extendStyles={{ container: styles.counter }}
        />
      </div>
    </div>
  )
}
