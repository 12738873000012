import { useScroll, motion } from 'framer-motion'
import { FC, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { Colors } from '~/shared/styles'
import { styles } from './styles'

interface Props {
  title: string
  description: string
  Icon: React.ComponentType<{ color?: string }>
  isLast?: boolean
}

export const ItineraryFragment: FC<Props> = ({ title, Icon, description, isLast }) => {
  const ref = useRef(null)
  const [isAnimation, setAnimation] = useState(false)
  const [isAnimated, setAnimated] = useState(false)

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 0.8', '1 0.8'],
  })

  useEffect(() => {
    scrollYProgress.on('change', (value) => {
      if (value) {
        setAnimation(true)
      } else {
        setAnimation(false)
      }
    })

    return () => scrollYProgress.clearListeners()
  }, [])

  useDebounce(
    () => {
      if (isAnimation) {
        setAnimated(true)
      }
    },
    1000,
    [isAnimation],
  )

  return (
    <div css={styles.container} ref={ref}>
      <div css={styles.timeline}>
        <div css={styles.icon(isAnimation, isAnimation && !isAnimated)}>
          <Icon color={isAnimation ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_STONE} />
        </div>

        <div css={styles.line(isLast)}>
          <motion.div css={styles.progress} style={{ scaleY: scrollYProgress }} />
        </div>
      </div>

      <div css={styles.content}>
        <h3 css={styles.title}>{title}</h3>
        <p css={styles.text}>{description}</p>
      </div>
    </div>
  )
}
