import { Effect, Event, createEffect, createEvent, sample } from 'effector'
import { ABORT_MESSAGE } from './vars'

export function createAbortableEffect<Params, Done, Fail = Error>(
  effect: (abortSignal: AbortSignal) => Effect<Params, Done, Fail>,
): [Effect<Params, Done, Fail>, Event<void>] {
  let abortController: AbortController = new AbortController()

  const effectAborted = createEvent()

  const abortFx = createEffect(() => {
    if (abortController) {
      abortController.abort(ABORT_MESSAGE)
      abortController = new AbortController()
    }
  })

  sample({
    clock: effectAborted,
    target: abortFx,
  })

  return [
    createEffect<Params, Done, Fail>((params: Params) => {
      return effect(abortController.signal)(params)
    }),
    effectAborted,
  ]
}

export const handleAbortError = (signal: AbortSignal) => {
  throw new Error(signal.reason)
}
