import { createEffect, createEvent, createStore, sample } from 'effector'
import { AnalyticsEvent, Properties, Analytics, EventName } from './types'
export * as EventType from './types'
export * from './config'

//events
const init = createEvent<{ analytics: Analytics; key?: string }>()
const identify = createEvent<{ id: string; properties?: Properties }>()
const track = createEvent<AnalyticsEvent>()
const trackPage = createEvent<{ properties?: Properties }>()
const trackConversion = createEvent<{ properties?: Properties }>()
const reset = createEvent()
//stores
const $analytics = createStore<Analytics[]>([])

//effects
const initFx = createEffect(
  ({ analytics, key }: { analytics: Analytics; key?: string }) => {
    if (analytics.init && key) analytics.init(key)
    return analytics
  },
)

const trackConversionFx = createEffect(
  ({ analytics, properties }: { analytics: Analytics[]; properties?: Properties }) => {
    analytics.forEach((analyticsInstance) => {
      analyticsInstance.trackConversion && analyticsInstance.trackConversion(properties)
    })
  },
)

const trackFx = createEffect(
  ({
    analytics,
    name,
    properties,
  }: {
    analytics: Analytics[]
    name: EventName
    properties?: Properties
  }) => {
    analytics.forEach((analyticsInstance) => {
      analyticsInstance.trackEvent &&
        analyticsInstance.trackEvent(name[analyticsInstance.name], properties)
    })
  },
)

const trackPageFx = createEffect(
  ({
    analytics,

    properties,
  }: {
    analytics: Analytics[]
    properties?: Properties
  }) => {
    analytics.forEach((analyticsInstance) => {
      analyticsInstance.trackPage && analyticsInstance.trackPage(properties)
    })
  },
)

const identifyFx = createEffect(
  ({
    analytics,
    id,
    properties,
  }: {
    analytics: Analytics[]
    id: string
    properties?: Properties
  }) => {
    analytics.forEach((analyticsInstance) => {
      analyticsInstance.identify && analyticsInstance.identify(id, properties)
    })
  },
)

const resetFx = createEffect(({ analytics }: { analytics: Analytics[] }) => {
  analytics.forEach((analyticsInstance) => {
    analyticsInstance.reset && analyticsInstance.reset()
  })
})

sample({
  clock: init,
  source: $analytics,
  filter: (analyticsList, newAnalytics) =>
    !analyticsList.find(({ name }) => name === newAnalytics.analytics.name),
  fn: (_, analytics) => analytics,
  target: initFx,
})

sample({
  clock: initFx.doneData,
  source: $analytics,
  fn: (analytics, newAnalytic) => [...analytics, newAnalytic],
  target: $analytics,
})

sample({
  clock: track,
  source: $analytics,
  fn: (analytics, { name, properties }) => ({ analytics, name, properties }),
  target: trackFx,
})

sample({
  clock: trackConversion,
  source: $analytics,
  fn: (analytics, { properties }) => ({ analytics, properties }),
  target: trackConversionFx,
})

sample({
  clock: trackPage,
  source: $analytics,
  fn: (analytics, { properties }) => ({ analytics, properties }),
  target: trackPageFx,
})

sample({
  clock: identify,
  source: $analytics,
  fn: (analytics, { id, properties }) => ({ analytics, id, properties }),
  target: identifyFx,
})

sample({
  clock: reset,
  source: $analytics,
  fn: (analytics) => ({ analytics }),
  target: resetFx,
})

export const analyticsModel = {
  track,
  trackPage,
  trackConversion,
  identify,
  init,
  reset,
}
