import { HotelBooking } from '@softcery/awayaway-apiclient'
import dayjs from 'dayjs'
import { REDIRECT_BOOKING_ERRORS } from '~/entities/booking/config'
import { normalizeStringCase } from '~/shared/lib/string'
import { RefundTransactionInfo } from './types'

export const getBookedRoom = (rooms: any[]) =>
  rooms.length > 1
    ? 'Multiple rooms'
    : `${normalizeStringCase(rooms[0]?.description)} room` || null

export const getRoomsLabelForCancelBookingSidebar = (
  rooms: { name?: string }[],
  countOnly = false,
) => {
  if (countOnly) {
    return rooms.length === 1 ? '1 room' : `${rooms.length} rooms`
  }

  return rooms.length > 1
    ? 'Multiple rooms'
    : `${normalizeStringCase(rooms[0]?.name)} room` || null
}

export const formatDate = (format: string, date?: string | Date) =>
  date ? dayjs(date).format(format) : null

export const formatBookingDates = (checkIn?: string | Date, checkOut?: string | Date) =>
  checkIn && checkOut
    ? `${formatDate('ddd, DD MMM', checkIn)} - 
      ${formatDate('ddd, DD MMM', checkOut)}`
    : '-'

export const getRefundTransactionsInfo = (
  booking: HotelBooking,
  roomId?: number,
): RefundTransactionInfo[] => {
  const transactions = booking?.lastRefundedTransactions
  if (!transactions) return []
  const refundTransactionsInfo: RefundTransactionInfo[] = []

  for (const transaction of transactions) {
    const { refunds } = transaction
    if (!refunds) continue

    const filteredRefunds = roomId
      ? refunds.filter((refund) => refund.hotelBookingRoomId === roomId)
      : refunds

    //need to take the latest refund amount as it is related to the last user action
    const refundAmount = filteredRefunds[filteredRefunds.length - 1].amount

    const refundInfo = {
      refundAmount: refundAmount,
      cardLastDigits: transaction.paymentCardLastFourDigits,
      cardNetwork: transaction.cardNetwork,
    } as RefundTransactionInfo

    refundTransactionsInfo.push(refundInfo)
  }
  return refundTransactionsInfo
}

export const isRedirectBookingError = (error: string) =>
  Object.values(REDIRECT_BOOKING_ERRORS).includes(error)

export const getCancellationDeadlineDate = (dateString: string): Date => {
  // keep hotel local time by cutting timezone offset
  const formattingDate = new Date(dateString.substring(0, 19))
  return formattingDate
}
