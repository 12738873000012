import { css } from '@emotion/react'

export const styles = {
  additionalIcon: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    width: 21px;
    height: 23px;

    cursor: pointer;
    margin: auto 0;
  `,
  input: css`
    padding-right: 40px;
  `,
}
