import { useEffect, useState, RefObject } from 'react'

export const useComponentWidth = <T extends HTMLElement>(
  componentRef: RefObject<T> | undefined,
) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (!componentRef) return
    const updateWidth = () => {
      if (componentRef.current) {
        setWidth(componentRef.current.clientWidth)
      }
    }

    // Call updateWidth on mount and whenever the window is resized
    updateWidth()
    window.addEventListener('resize', updateWidth)

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [componentRef])
  return { width }
}
