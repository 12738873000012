import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const Inspiration: FC<Props> = ({ width = 16, height = 16, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M9.34981 6.112C9.34981 5.356 8.83381 4.864 8.01781 4.864C7.12981 4.864 6.64981 5.488 6.57781 6.34H5.60581C5.74981 4.972 6.66181 4.024 8.01781 4.024C9.42181 4.024 10.2618 4.84 10.2618 6.1C10.2618 7.036 9.51781 7.768 8.77381 8.608L6.90181 10.66H10.4178V11.5H5.60581V10.876L7.98181 8.26C8.74981 7.42 9.34981 6.808 9.34981 6.112Z"
        fill="white"
      />
    </svg>
  )
}
