import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const modalStyles = css`
  padding: 40px 48px;
  border-radius: 10px;
  background: ${Colors.LIGHT_1};
  max-width: 528px;
  min-width: 450px;
  width: 100%;

  ${Breakpoints.SM} {
    min-width: 410px;
    width: auto;
    padding: 24px;
  }

  @media (max-width: 425px) {
    min-width: 360px;
  }

  @media (max-width: 350px) {
    min-width: unset;
  }
`

export const modalWithLoaderStyles = css`
  padding: 0;
  border-radius: 0;
  background: ${Colors.LIGHT_1};
  height: 100%;
  width: 100%;

  @media (max-width: 375px) {
    height: auto;
    padding-bottom: 20px;
  }
`

export const modalForgotPasswordStyles = css`
  padding: 48px;
  border-radius: 10px;
  background: ${Colors.LIGHT_1};
  width: fit-content;

  ${Breakpoints.SM} {
    width: auto;
    padding: 24px;
  }
`
