import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 27px 0;
    user-select: none;
  `,

  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1224px;
    height: max-content;

    ${Breakpoints.XL} {
      margin: 0 20px;
    }
    ${Breakpoints.MD} {
      margin: 0 10px;
    }
  `,

  menu: {
    container: css`
      display: flex;
      color: ${Colors.DARK_1};
    `,

    light: css`
      filter: invert(1);
    `,

    item: css`
      display: flex;
      align-items: center;
      text-decoration: none;
      letter-spacing: 0.01em;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;

      &:not(:last-child) {
        margin-right: 40px;
      }

      img {
        margin-right: 12px;
      }
    `,
  },
}
