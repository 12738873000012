import { CreateTripRequestBodyFlightServicesInner } from '@softcery/awayaway-apiclient'
import { createFactory } from '@withease/factories'
import { createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { initialServicesOptions } from '~/features/select-services/const'
import { changeSelectedServiceOption } from '~/features/select-services/lib'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'
import { CalculateService, ServiceOptionType } from '../../types'

export type ManageServiceFlightDetailsFactory = ReturnType<
  typeof manageServiceFlightDetailsFactory
>
export const manageServiceFlightDetailsFactory = createFactory(() => {
  const resetFlightsOptions = createEvent()

  const servicesSelected = createEvent<CreateTripRequestBodyFlightServicesInner[]>()
  const $flightServices = restore<CreateTripRequestBodyFlightServicesInner[]>(
    servicesSelected,
    [],
  )

  const clickedForSelectSeat = createEvent<boolean>()
  const $isClickedForSelectSeat = restore(clickedForSelectSeat, false)

  const clickedConfirmForSeats = createEvent<boolean>()
  const $clickedConfirmForSeats = restore(clickedConfirmForSeats, false)

  const updateClickedButtonTypeOption = createEvent<ServiceOptionType | null>()
  const $clickedButtonTypeOption = restore<ServiceOptionType | null>(
    updateClickedButtonTypeOption,
    null,
  )

  const changeAdditionalServices = createEvent<CalculateService>()
  const $additionalServices = createStore<CalculateService>(initialServicesOptions)

  // manage logic with updated services
  bridge(() => {
    sample({
      clock: changeAdditionalServices,
      source: {
        oldSelected: $additionalServices,
        clickedButtonTypeOption: $clickedButtonTypeOption,
      },
      fn: ({ oldSelected, clickedButtonTypeOption }, newSelected) =>
        changeSelectedServiceOption({
          newSelected,
          oldSelected,
          clickedButtonTypeOption,
        }),
      target: $additionalServices,
    })
  })

  persist({ store: $additionalServices, key: LocalStorageKeys.SelectedFlightOptions })

  reset({
    clock: resetFlightsOptions,
    target: $additionalServices,
  })

  return {
    servicesSelected,
    resetFlightsOptions,
    changeAdditionalServices,
    updateClickedButtonTypeOption,
    clickedConfirmForSeats,
    clickedForSelectSeat,

    $isClickedForSelectSeat,
    $clickedConfirmForSeats,
    $clickedButtonTypeOption,
    $flightServices,
    $additionalServices,
  }
})
