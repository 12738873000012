import { combine, createEvent, sample } from 'effector'
import { createGate } from 'effector-react'
import { condition, reset } from 'patronum'
import { makePaymentModel } from '~/features/make-payment'
import { searchFlightsModel } from '~/features/search-flights/model'
import { selectFlightsServicesModel } from '~/features/select-services'
import { addAdditionalDetailsModel } from '~/features/trip-booking/add-additional-details'
import { addContactInfoModel } from '~/features/trip-booking/add-contact-info'
import { addGuestsInfoModel } from '~/features/trip-booking/add-guests-info'
import { confirmBookingModel } from '~/features/trip-booking/confirm-booking'
import {
  CreateBookingData,
  createBookingModel,
} from '~/features/trip-booking/create-booking'
import { logoutModel } from '~/features/user/logout'
import { hotelsForTripModel } from '~/entities/hotel'
import { navigationModel } from '~/entities/navigation'
import { PopupType, popupModel } from '~/entities/popup'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { AppRoute } from '~/shared/config'
import { STEPS } from '../config'
import { contactDetailsPageModel } from '../ui/contact-details'
import { flightDetailsPageModel } from '../ui/flights/details'

export const BookingGate = createGate<{ destinationCode?: string; hotelCode?: string }>()
export const TripInfoStepGate = createGate()
export const RoomsStepGate = createGate()
export const CheckoutStepGate = createGate()
export const FlightsStepGate = createGate()
export const BookingSummaryGate = createGate()

export const tripInfoStepOpened = createEvent()
export const contactsStepOpened = createEvent()
export const flightsStepOpened = createEvent()

export const confirmBookingInitiated = createEvent<string>()

export const $currentStep = combine(navigationModel.$path, (path) => {
  if (path.includes(STEPS.CONTACTS)) {
    return STEPS.CONTACTS
  }
  if (path.includes(STEPS.FLIGHTS)) {
    return STEPS.FLIGHTS
  }
  if (path.includes(STEPS.PAYMENT)) {
    return STEPS.PAYMENT
  }
  if (path.includes(STEPS.SUMMARY)) {
    return STEPS.SUMMARY
  }

  return STEPS.TRIP_INFO
})

export const $$model = makePaymentModel({
  token: createBookingModel.$tripPaymentClientToken,
  confirmFx: confirmBookingModel.confirmTripBookingFx,
})

// get selected hotel from selected destination

sample({
  clock: CheckoutStepGate.open,
  source: {
    isAuthorized: viewerModel.$isAuthorized,
    isLoggedOut: logoutModel.$isLogouted,
    visiblePopup: popupModel.$visiblePopup,
  },
  filter: ({ isAuthorized, visiblePopup }) => !isAuthorized && !visiblePopup,
  fn: () => PopupType.Login,
  target: popupModel.visiblePopupChanged,
})

sample({
  clock: tripInfoStepOpened,
  target: [createBookingModel.stateReset],
})

sample({
  clock: tripInfoStepOpened,
  source: destinationModel.$selectedDestination,
  fn: (destination) => `${AppRoute.Destinations}/${destination?.destinationCode}`,
  target: navigationModel.pathChanged,
})

const redirectToFlightsPage = createEvent()
const createBookingInitiated = createEvent()

condition({
  source: contactDetailsPageModel.pageCompleted,
  if: searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  then: redirectToFlightsPage,
  else: createBookingInitiated,
})

sample({
  clock: flightDetailsPageModel.pageCompleted,
  target: createBookingInitiated,
})

sample({
  clock: redirectToFlightsPage,
  source: {
    destination: destinationModel.$selectedDestination,
    hotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  fn: ({ destination, hotel }) =>
    `${AppRoute.Destinations}/${destination?.destinationCode}/${hotel?.code}/flights`,
  target: navigationModel.pathChanged,
})

sample({
  clock: createBookingInitiated,
  source: {
    checkIn: tripInfoModel.$checkIn,
    checkOut: tripInfoModel.$checkOut,
    hotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
    selectedRooms: hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms,
    selectedTrip: destinationModel.$selectedDestination,

    contactDetails: addContactInfoModel.$$contactForm.$values,
    guestGroups: addGuestsInfoModel.$guestGroups,
    additionalDetails: addAdditionalDetailsModel.$additionalDetails,

    flightServices:
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.$flightServices,
    flightOffer: searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  },
  fn: ({
    hotel,
    selectedRooms,
    additionalDetails,
    checkIn,
    checkOut,
    flightOffer,
    contactDetails,
    guestGroups,
    flightServices,
    selectedTrip,
  }) => {
    return {
      tripId: selectedTrip!.id,
      hotelCode: hotel?.code || 0,
      checkIn,
      checkOut,
      contactDetails,
      guestGroups,
      additionalDetails,
      selectedRooms,
      flightOffer,
      flightServices,
    } as CreateBookingData
  },
  target: createBookingModel.initiated,
})

sample({
  clock: createBookingModel.bookingSuccessfullyCreated,
  source: {
    destination: destinationModel.$selectedDestination,
    hotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  fn: ({ destination, hotel }) =>
    `${AppRoute.Destinations}/${destination?.destinationCode}/${hotel?.code}/payment`,
  target: navigationModel.pathChanged,
})

sample({
  clock: confirmBookingModel.tripBookingConfirmed,
  source: {
    destination: destinationModel.$selectedDestination,
    hotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  fn: ({ destination, hotel }) =>
    `${AppRoute.Destinations}/${destination?.destinationCode}/${hotel?.code}/summary`,
  target: navigationModel.pathChanged,
})

sample({
  clock: contactsStepOpened,
  target: createBookingModel.stateReset,
})

// Confirm booking
sample({
  clock: $$model.paymentCompleted,
  source: createBookingModel.$tripBooking,
  fn: (tripBooking) => tripBooking!.id!,
  target: confirmBookingModel.confirmTripBookingFx,
})

sample({
  clock: BookingGate.close,
  target: [
    tripInfoModel.selectedDatesReset,
    hotelRoomModel.$$getRoomsFactory.stateReset,
    addContactInfoModel.stateReset,
    addGuestsInfoModel.stateReset,
    createBookingModel.stateReset,
    selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.resetFlightsOptions,
    selectFlightsServicesModel.$$manageUiForServicesOptionsFactory.resetSeatsToDefault,
  ],
})

reset({
  clock: BookingGate.close,
  target: $currentStep,
})
