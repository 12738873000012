import { useGate, useStore } from 'effector-react'
import { FC, Fragment, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'

import { backToOnboardingNavigated } from '~/processes/onboarding-process'
import { SentryDebug } from '~/pages/sentry-debug'
import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'
import { selectBudgetModel } from '~/features/onboarding/select-budget'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { LoginProfile } from '~/features/user/login-profile'
import { ForgotPassword, UpdatePassword } from '~/features/user/reset-password'
import { SaveProfile } from '~/features/user/save-profile'
import { navigationModel } from '~/entities/navigation'
import { landingPageNavigated } from '~/entities/navigation/model'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import {
  modalStyles,
  modalWithLoaderStyles,
  popupModel,
  PopupType,
} from '~/entities/popup'
import { modalForgotPasswordStyles } from '~/entities/popup/ui/styles'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { analyticsModel } from '~/shared/api/analytics'
import { AppRoute, isStaging, ManageUrlQueryKeys } from '~/shared/config'
import { urlParamActions } from '~/shared/hooks'
import { Modal } from '~/shared/ui'

import { AccountPage } from './account'
import { VerificationPage } from './account/verification-page'
import { BookingPage } from './booking'
import { DestinationPage } from './destination'
import { DestinationDetailsPage } from './destinations-list/destination-details'
import { AllDestinationsPage } from './destinations-list/destinations-results'
import { Onboarding } from './onboarding'
import { summaryPageModel } from './onboarding/summary'
import { DestinationsPage, destinationsPageModel } from './trips'

export const Routing: FC = () => {
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const landingPageUrl = queryParams.get('landingPageUrl')
  const resetOnboardingData = queryParams.get('resetOnboardingData')

  const onboardingFinished = useStore(onboardingSessionModel.$onboardingFinished)
  const bookingConfirmationFromMange = useStore(
    onboardingSessionModel.$bookingConfirmation,
  )
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  const bookingConfirmation = urlParamActions().getParam(
    ManageUrlQueryKeys.BOOKING_CONFIRMATION,
  )

  useEffect(() => {
    if (bookingConfirmation && !isAuthorized) {
      onboardingSessionModel.updateBookingConfirmation(bookingConfirmation)
    }
    if ((bookingConfirmation || bookingConfirmationFromMange) && isAuthorized) {
      navigationModel.pathChanged(
        `${AppRoute.AccountBookings}/${
          bookingConfirmation ?? bookingConfirmationFromMange
        }`,
      )
      localStorage.removeItem(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
      destinationModel.openTripDetailsFromEmail()
    }
    urlParamActions().deleteParam(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
  }, [])

  useGate(navigationModel.Gate, {
    navigate: useNavigate(),
    path: pathname,
  })

  // send page_view when location changes
  useEffect(() => {
    analyticsModel.trackPage({
      properties: { page_name: pathname },
    })

    backToOnboardingNavigated(pathname)

    onboardingSessionModel.pageChanged()
  }, [pathname])

  useEffect(() => {
    const ref = queryParams.get('ref')

    if (ref) {
      viewerModel.referralCodeUpdated(ref)
    }
  }, [])

  useEffect(() => {
    landingPageNavigated(landingPageUrl!)
  }, [])

  useEffect(() => {
    const destinationName = queryParams.get('destinationName')
    const destinationCode = queryParams.get('destinationCode')
    const isIframe = queryParams.get('isIframe')

    const resetAllOnboardingData = () => {
      destinationsPageModel.backToQuizNavigated()
      scheduleTripDatesModel.stateReset()
      selectBudgetModel.budgetReset()
      tripInfoModel.stateReset()
      destinationModel.stateReset()
      onboardingSessionModel.statusReset()
      onboardingLayoutModel.stateReset()
    }

    const redirectToInspirationPage = () => {
      navigationModel.pathChanged(AppRoute.Inspiration)
      onboardingSessionModel.stepChanged('/inspiration')
    }

    // TODO: move to model
    if (resetOnboardingData) {
      resetAllOnboardingData()
      redirectToInspirationPage()
    }

    // TODO: move to model
    if (isIframe) {
      resetAllOnboardingData()
      redirectToInspirationPage()
      onboardingSessionModel.iframeInitiated()
    }

    if (destinationName && destinationCode) {
      selectLocationsModel.publicDestinationSelected({
        name: destinationName.replace(/%/g, ' '),
        countryCode: destinationCode,
      })
    }
  }, [queryParams, resetOnboardingData])

  return (
    <Fragment>
      <Routes>
        {!isAuthorized && (
          <Route path={AppRoute.Verification} element={<VerificationPage />} />
        )}

        {/*temporary routes for list all destinations functionality */}
        {isStaging && (
          <Route path={AppRoute.DestinationsList} element={<AllDestinationsPage />} />
        )}
        {isStaging && (
          <Route
            path={`${AppRoute.DestinationsList}/:destinationCode`}
            element={<DestinationDetailsPage />}
          />
        )}

        {isStaging && <Route path={AppRoute.SentryDebug} element={<SentryDebug />} />}

        {(!onboardingFinished || resetOnboardingData) && (
          <Route path="/*" element={<Onboarding />} />
        )}

        {onboardingFinished && <Route path="/*" element={<DestinationsPage />} />}

        {isAuthorized && <Route path={AppRoute.Account} element={<AccountPage />} />}
        {onboardingFinished && (
          <Route
            path={`${AppRoute.Destinations}/:destinationCode`}
            element={<DestinationPage />}
          />
        )}
        {onboardingFinished && (
          <Route
            path={`${AppRoute.Destinations}/:destinationCode/:hotelCode/*`}
            element={<BookingPage />}
          />
        )}

        {/* reset password popup (with connection to url)*/}
        <Route path={AppRoute.ResetPassword} element={<ResetPasswordPopup />} />
      </Routes>

      {/* popups */}
      {!isAuthorized && <Popups />}
    </Fragment>
  )
}

const Popups = () => {
  const onboardingFinished = useStore(onboardingSessionModel.$onboardingFinished)
  const visiblePopup = useStore(popupModel.$visiblePopup)
  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isTripsLoading = useStore(destinationModel.$isTripsLoading)

  const popupIsOpen = (popupType: string) => visiblePopup === popupType

  const handleClose = () => {
    if (!onboardingFinished) {
      popupModel.visiblePopupChanged(PopupType.None)
    }
  }

  return (
    <Fragment>
      {/* Register popup */}

      <Modal
        isOpen={popupIsOpen(PopupType.Register)}
        extendStyles={
          isTripsLoading || isNewSummaryPending
            ? { wrapper: modalWithLoaderStyles }
            : { wrapper: modalStyles }
        }
      >
        <SaveProfile />
      </Modal>

      {/* Login popup */}
      <Modal
        isOpen={popupIsOpen(PopupType.Login)}
        extendStyles={
          isTripsLoading || isNewSummaryPending
            ? { wrapper: modalWithLoaderStyles }
            : { wrapper: modalStyles }
        }
        onClose={handleClose}
      >
        <LoginProfile />
      </Modal>

      {/* Forgot password popup */}
      <Modal
        isOpen={popupIsOpen(PopupType.ForgotPassword)}
        extendStyles={{ wrapper: modalForgotPasswordStyles }}
        onClose={handleClose}
      >
        <ForgotPassword />
      </Modal>
    </Fragment>
  )
}

const ResetPasswordPopup = () => {
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  return (
    <Fragment>
      <DestinationsPage />

      <Modal
        extendStyles={{ wrapper: modalStyles }}
        onClose={() =>
          isAuthorized ? navigationModel.pathChanged(AppRoute.Destinations) : null
        }
        isOpen
      >
        <UpdatePassword />
      </Modal>
    </Fragment>
  )
}
