import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const Quests: FC<Props> = ({ width = 16, height = 16, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M7.44823 8.128V7.324H7.94023C8.72023 7.324 9.32023 6.88 9.32023 6.088C9.32023 5.38 8.80423 4.84 7.97623 4.84C7.14823 4.84 6.62023 5.38 6.51223 6.148H5.58823C5.76823 4.9 6.66823 4.024 7.97623 4.024C9.38023 4.024 10.2202 4.864 10.2202 6.04C10.2202 6.856 9.74023 7.516 9.06823 7.72C9.86023 7.948 10.3642 8.608 10.3642 9.436C10.3642 10.648 9.47623 11.608 8.00023 11.608C6.56023 11.608 5.56423 10.732 5.44423 9.4H6.36823C6.50023 10.252 7.06423 10.792 7.98823 10.792C8.86423 10.792 9.46423 10.264 9.46423 9.448C9.46423 8.62 8.80423 8.128 7.96423 8.128H7.44823Z"
        fill="white"
      />
    </svg>
  )
}
