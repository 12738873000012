import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  navLink: css`
    display: inline-flex;
    align-items: center;
    gap: 0 9px;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${Colors.PRIMARY_GREEN};
  `,
}
