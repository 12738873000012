import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;

    border-radius: 10px;
    background-color: ${Colors.LIGHT_1};
    padding: 36px 40px 40px 40px;
  `,

  header: {
    container: css`
      display: flex;
      justify-content: space-between;
    `,

    title: css`
      font-family: Calibre;
      font-weight: 500;
      font-size: 20px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    reference: css`
      font-family: Calibre;
      font-weight: 400;
      font-size: 20px;
      color: ${Colors.NEUTRAL_GREY};
    `,
  },

  info: {
    container: css`
      margin: 40px 0 24px 0;
      border: 1px solid ${Colors.NEUTRAL_SILVER};
      border-radius: 12px;

      padding: 24px 20px;
    `,

    gap: css`
      margin-top: 20px;
    `,

    title: css`
      font-family: Calibre;
      font-weight: 400;
      font-size: 20px;
      color: ${Colors.NEUTRAL_BLACK};

      margin-bottom: 8px;
    `,

    text: css`
      font-family: Calibre;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 2%;
      line-height: 21.6px;
      color: ${Colors.NEUTRAL_GREY};
    `,

    error: css`
      border-color: ${Colors.ERROR};
    `,
  },

  button: css`
    max-width: 324px;
    margin-top: 16px;
    font-family: Calibre;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    padding: 23px 0;
  `,

  inputsContainer: css`
    max-width: 502px;
  `,

  error: {
    container: css`
      display: flex;
    `,
    text: css`
      font-size: 15px;
    `,
    icon: css`
      width: 13px;
      height: 13px;
      margin-top: 2px;
      margin-right: 5px;
    `,
  },

  flights: {
    info: css`
      margin-top: 12px;
    `,

    text: css`
      font-size: 18px;
    `,

    type: css`
      font-size: 15px;
      background-color: ${Colors.NEUTRAL_LIGHT};
      border-radius: 4px;
      padding: 2px 4px;
      margin-left: 8px;
    `,

    price: css`
      font-size: 20px;
      margin-top: 12px;
    `,
  },
}
