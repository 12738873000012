import { css } from '@emotion/react'
import { FONTS_PATHS } from '~/constants'

export const fonts = css`
  @font-face {
    font-family: Calibre;
    src: url('/${FONTS_PATHS.CALIBRE}/Calibre-Medium.woff2') format('woff2'),
      url('/${FONTS_PATHS.CALIBRE}/Calibre-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Calibre;
    src: url('/${FONTS_PATHS.CALIBRE}/Calibre-SemiBold.woff2') format('woff2'),
      url('/${FONTS_PATHS.CALIBRE}/Calibre-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Calibre;
    src: url('/${FONTS_PATHS.CALIBRE}/Calibre-Regular.woff2') format('woff2'),
      url('/${FONTS_PATHS.CALIBRE}/Calibre-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Joane;
    font-weight: 300;
    src: url('/${FONTS_PATHS.JOANE}/Joane-Light.woff2') format('woff2'),
      url('/${FONTS_PATHS.JOANE}/Joane-Light.woff') format('woff');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Joane;
    font-weight: 400;
    font-style: normal;
    src: url('/${FONTS_PATHS.JOANE}/Joane-Regular.woff2') format('woff2'),
      url('/${FONTS_PATHS.JOANE}/Joane-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Joane;
    font-weight: 600;
    font-style: normal;
    src: url('/${FONTS_PATHS.JOANE}/Joane-SemiBold.woff2') format('woff2'),
      url('/${FONTS_PATHS.JOANE}/Joane-SemiBold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: OpticianSans;
    font-weight: 400;
    font-style: normal;
    src: url('/${FONTS_PATHS.OPTICIAN_SANS}/OpticianSans-Regular.woff2') format('woff2'),
      url('/${FONTS_PATHS.OPTICIAN_SANS}/OpticianSans-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    src: url('/${FONTS_PATHS.MONTSERRAT}/Montserrat-Regular.woff2') format('woff2'),
      url('/${FONTS_PATHS.MONTSERRAT}/Montserrat-Regular.woff') format('woff');
    font-display: swap;
  }
`
