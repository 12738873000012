import { useStore } from 'effector-react'
import RcPagination from 'rc-pagination'
import { FC } from 'react'

import { useWindowSize } from 'react-use'
import { Dimensions } from '~/shared/styles'

import { paginationModelFactory } from '..'
import { PaginationItem } from './pagination-item'
import { styles } from './styles'

interface Props {
  totalItems: number
  pageSize: number
  model: ReturnType<typeof paginationModelFactory>
}

export const Pagination: FC<Props> = ({ totalItems, pageSize, model }) => {
  const offset = useStore(model.$offset)
  const currentPage = useStore(model.$currentPage)
  const { width } = useWindowSize()

  const handleChange = (current: number) => {
    model.currentPageChanged(current)
    model.offsetChanged(
      current * pageSize - pageSize > totalItems ? offset : current * pageSize - pageSize,
    )
    window.scrollTo({ top: 0 })
  }

  return (
    <div css={styles.container}>
      <RcPagination
        showLessItems={width <= Dimensions.Sm}
        total={totalItems}
        hideOnSinglePage
        pageSize={pageSize}
        itemRender={(c, t) => (
          <PaginationItem current={c} type={t} isCurrent={c === currentPage} />
        )}
        onChange={(c) => handleChange(c)}
        css={styles.pagination}
        current={currentPage}
      />
    </div>
  )
}
