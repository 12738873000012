import getSymbolFromCurrency from 'currency-symbol-map'

export const getFormattedPrice = (number: number, withCents?: boolean) => {
  const fractionDigitsNumber = withCents ? 2 : 0

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigitsNumber,
    maximumFractionDigits: fractionDigitsNumber,
  })
  const formattedNumber = formatter.format(number)

  return formattedNumber
}

export const getPriceWithCurrency = (
  number: number,
  currency?: string,
  withCents: boolean | undefined = true,
) => {
  const formatted = getFormattedPrice(number, withCents)

  if (!currency) return formatted

  const symbol = getSymbolFromCurrency(currency) || ''
  return `${symbol}${formatted}`
}
