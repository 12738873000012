import { createFactory } from '@withease/factories'
import { createEffect, createEvent, restore, sample } from 'effector'
import { ManageLocalStorageForSearchFlights } from '~/features/search-flights/model'
import { bridge } from '~/shared/lib/factory'
import { searchFlightsOption } from './api'

export type SearchFlightsOptionFactory = ReturnType<typeof searchFlightsOptionFactory>

export const searchFlightsOptionFactory = createFactory(
  ({
    $$manageLocalStorageForSearchFlights,
  }: {
    $$manageLocalStorageForSearchFlights: ManageLocalStorageForSearchFlights
  }) => {
    const searchFlightsOptionFx = createEffect(searchFlightsOption)
    const flightsOptionChanged = createEvent<string>()
    const updateError = createEvent<string>()

    const $flightsOption = restore(flightsOptionChanged, 'recommended')
    const $error = restore(updateError, '')

    const $loading = searchFlightsOptionFx.pending

    bridge(() => {
      sample({
        clock: flightsOptionChanged,
        source: { offers: $$manageLocalStorageForSearchFlights.$offers },
        filter: (_, option) => option != 'recommended',
        fn: ({ offers }, option) => ({
          type: option as string,
          low: offers[0].flightId!,
          high: offers[offers.length - 1].flightId!,
          size: 3,
          id: offers[0].id!,
        }),
        target: searchFlightsOptionFx,
      })

      sample({
        clock: flightsOptionChanged,
        source: {
          recommendedOffers: $$manageLocalStorageForSearchFlights.$recommendedOffers,
        },
        filter: (_, option) => option === 'recommended',
        fn: ({ recommendedOffers }) => recommendedOffers,
        target: $$manageLocalStorageForSearchFlights.updateOffers,
      })

      sample({
        clock: flightsOptionChanged,
        source: {
          recommendedOptions: $$manageLocalStorageForSearchFlights.$recommendedOptions,
        },
        filter: (_, option) => option === 'recommended',
        fn: ({ recommendedOptions }) => recommendedOptions,
        target: $$manageLocalStorageForSearchFlights.updateOptions,
      })
    })

    // success
    bridge(() => {
      sample({
        clock: searchFlightsOptionFx.doneData,
        fn: (res) => res.data?.offers || [],
        target: $$manageLocalStorageForSearchFlights.updateOffers,
      })
    })

    // error
    bridge(() => {
      sample({
        clock: searchFlightsOptionFx.failData,
        fn: (error) => error.message,
        target: updateError,
      })
    })

    sample({
      clock: [flightsOptionChanged, searchFlightsOptionFx.finally],
      fn: () => null,
      target: $$manageLocalStorageForSearchFlights.offerSelected,
    })

    return {
      $loading,
      $flightsOption,
      $error,

      updateError,
      flightsOptionChanged,

      searchFlightsOptionFx,
    }
  },
)
