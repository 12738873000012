import { invoke } from '@withease/factories'
import { atom } from '~/shared/lib/factory'
import { manageServiceFlightDetailsFactory } from './manage-service-flight-details-factory'
import { manageUiForServicesOptionsFactory } from './manage-ui-for-services-options'

export const selectFlightsServicesModel = atom(() => {
  const $$manageServiceFlightDetailsFactory = invoke(manageServiceFlightDetailsFactory)

  const $$manageUiForServicesOptionsFactory = invoke(manageUiForServicesOptionsFactory, {
    $$manageServiceFlightDetailsFactory,
  })
  return {
    $$manageServiceFlightDetailsFactory,
    $$manageUiForServicesOptionsFactory,
  }
})
