import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px;
    min-height: min(100vh, 900px);
    max-width: 1440px;
    margin: auto;

    ${Breakpoints.SM} {
      padding-top: 0;
    }
  `,

  iframeWrapper: css`
    padding-top: 0;
  `,

  mainContent: (isAuthorized: boolean) => css`
    flex: 1;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    ${Breakpoints.SM} {
      padding-top: ${isAuthorized ? '115px' : '145px'};
    }
  `,

  iframeMainContent: (isAuthorized: boolean) => css`
    padding-top: 24px;

    ${Breakpoints.LG} {
      padding-top: ${isAuthorized ? '115px' : '145px'};
    }
  `,

  actions: css`
    width: 100%;
    gap: 24px;
    display: flex;
    justify-content: flex-end;
  `,
}
