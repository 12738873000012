import { SerializedStyles } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { styles } from './styles'

interface Props {
  sidebarContent: ReactNode
  main: ReactNode
  position: 'left' | 'right'

  extendsStyles?: {
    container?: SerializedStyles
    main?: SerializedStyles
    sidebar?: SerializedStyles
  }
}

export const WithSidebar: FC<Props> = ({
  sidebarContent,
  main,
  position,
  extendsStyles,
}) => {
  const sidebarMarkup = (
    <div css={[styles.sidebar(position), extendsStyles?.sidebar]}>{sidebarContent}</div>
  )

  return (
    <div css={[styles.container, extendsStyles?.container]}>
      {position === 'left' && sidebarMarkup}
      <div css={[styles.main, extendsStyles?.main]}>{main}</div>
      {position === 'right' && sidebarMarkup}
    </div>
  )
}
