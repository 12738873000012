import { css } from '@emotion/react'
import { Fonts, Colors } from '~/shared/styles'

export const styles = {
  container: (active: boolean, disabled: boolean) => css`
    display: flex;
    align-items: center;
    padding: 12.5px 22px;

    cursor: ${disabled ? 'default' : 'pointer'};
    opacity: ${disabled ? 0.4 : 1};

    &:hover {
      background: ${!disabled ? `${Colors.PRIMARY_GREEN}0D` : 'transparent'};
      transition: all 0.2s linear;
    }

    ${active &&
    css`
      background-color: ${Colors.PRIMARY_GREEN}0D;
      border-left: 2px solid ${Colors.PRIMARY_GREEN};
    `}
  `,

  icon: css`
    width: 20px;
    height: 20px;
    margin-right: 14px;
  `,

  name: (active: boolean | undefined) => css`
    font-family: ${Fonts.Calibre};
    font-weight: 400px;
    font-size: 20px;
    color: ${Colors.NEUTRAL_DARK};

    ${active &&
    css`
      color: ${Colors.NEUTRAL_BLACK};
      font-weight: 500;
    `}
  `,
}
