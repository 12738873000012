import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  wrapper: css`
    max-width: 688px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 10px;

    ${Breakpoints.MD} {
      width: 100%;
      padding: 20px;
    }
  `,

  side: css`
    display: grid;
    grid-gap: 20px;
  `,

  button: css`
    margin-top: 25px;
    width: 100%;
    height: 58px;
    gap: 10px;
  `,

  sidebar: css`
    width: 370px;
    margin: 0 0 0 34px;
  `,

  error: css`
    margin-top: 25px;
  `,
}
