const IS_DEV_ENV = process.env.NODE_ENV === 'development'

const FONTS_PATH = IS_DEV_ENV ? 'shared/assets/fonts' : 'fonts'

export const FONTS_PATHS = {
  CALIBRE: IS_DEV_ENV ? `${FONTS_PATH}/Calibre` : FONTS_PATH,
  JOANE: IS_DEV_ENV ? `${FONTS_PATH}/Joane` : FONTS_PATH,
  OPTICIAN_SANS: IS_DEV_ENV ? `${FONTS_PATH}/OpticianSans` : FONTS_PATH,
  MONTSERRAT: IS_DEV_ENV ? `${FONTS_PATH}/Montserrat` : FONTS_PATH,
}
