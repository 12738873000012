import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    min-height: 600px;
  `,
  divider: css`
    border-bottom: 1px solid ${Colors.LIGHT_GREY_2};
    padding-bottom: 40px;
  `,

  grid: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    padding: 30px 0;

    ${Breakpoints.SM} {
      gap: 48px;
    }
  `,

  text: css`
    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    max-width: 650px;
    text-align: center;
    margin-top: 16px;
    margin-inline: auto;
  `,
}
