import { SearchFlightOffersRequestBody } from '@softcery/awayaway-apiclient'
import { GetNearestAirportRequest } from '@softcery/awayaway-apiclient'
import { SearchFlightOffersRes } from '~/entities/flights/types'
import { GetNearestAirportRes } from '~/entities/flights/types'
import { apiErrorHandler, apiService, errorHandler } from '~/shared/api'

export const searchFlights = async (
  fields: SearchFlightOffersRequestBody,
): Promise<SearchFlightOffersRes> => {
  try {
    return await apiService().searchFlightOffers({ fields })
  } catch (e) {
    return errorHandler(e)
  }
}

export const getHotelNearestAirport = async (
  fields: GetNearestAirportRequest,
): Promise<GetNearestAirportRes> => {
  try {
    return await apiService().getNearestAirport(fields)
  } catch (e: any) {
    return apiErrorHandler(e)
  }
}
