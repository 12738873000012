import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { Button } from '~/shared/ui'
import { styles } from './styles'

export interface Props {
  disabled?: boolean
  isStepSkippable?: boolean
  onPrev?: () => void
  onNext: () => void
  extendStyles?: {
    container: SerializedStyles
  }
  isLoading?: boolean
}

export const NavigationControls: FC<Props> = ({
  disabled,
  onPrev,
  onNext,
  extendStyles,
  isStepSkippable,
  isLoading,
}) => {
  return (
    <div css={[styles.wrapper, extendStyles?.container]}>
      {onPrev && (
        <Button variant="link" css={[styles.base, styles.prevButton]} onClick={onPrev}>
          Previous
        </Button>
      )}

      <Button
        variant="default"
        css={[styles.base, styles.next]}
        onClick={onNext}
        disabled={disabled}
        data-testid="navigation-bar-next-button"
      >
        {isLoading ? 'Loading...' : isStepSkippable ? 'Skip' : 'Next'}
      </Button>
    </div>
  )
}
