import { TripBooking } from '@softcery/awayaway-apiclient'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '~/shared/ui'
import { styles } from './styles'

export const BookingManagementSidebar: FC<{ booking: TripBooking }> = ({ booking }) => {
  const navigate = useNavigate()

  const bookingCancelInitiated = () => {
    navigate(`cancel`, { relative: 'path' })
  }

  return (
    <div css={styles.container}>
      <header css={styles.header.container}>
        <h1 css={styles.header.title}>Cancel trip</h1>
      </header>

      <div>
        <Button onClick={bookingCancelInitiated} variant="outlined" disabled={false}>
          Request Cancellation
        </Button>
      </div>
    </div>
  )
}
