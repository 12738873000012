import { useStore } from 'effector-react'

import { addGuestsInfoModel, GuestType } from '..'
import { GuestForm } from './guest-form'
import { styles } from './styles'

export const GuestDetailsForm = () => {
  const guestsFormGroups = useStore(addGuestsInfoModel.$guestGroupsForms)

  const guestGroups = useStore(addGuestsInfoModel.$guestGroups)

  if (!guestGroups) return null

  return (
    <div>
      <h3 css={styles.title}>Guests Details</h3>

      {Object.keys(guestsFormGroups).map((roomKey, roomIndex) => {
        const adults = guestGroups[roomKey].filter(
          (guest) => guest.type === GuestType.Adult,
        ).length
        const children = guestGroups[roomKey].filter(
          (guest) => guest.type === GuestType.Child,
        ).length

        const guestForms = guestsFormGroups[roomKey]

        return (
          <div key={roomIndex} css={styles.guests.container}>
            <div>
              <span css={styles.room.num}>Room {roomIndex + 1}:</span>{' '}
              <span css={styles.room.guests}>
                {adults} Adults
                {children > 0 && `, ${children} Children`}
              </span>
            </div>

            <div css={styles.guests.formsContainer}>
              {guestForms.map((form, i) => (
                <GuestForm key={i} form={form} guestNumber={i + 1} />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}
