import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: calc(100vh - 100px); */
    width: 100%;
    padding-top: 130px;

    ${Breakpoints.MD} {
      padding-top: 0;
    }
  `,

  content: css`
    max-width: 500px;
  `,

  animation: css`
    max-height: 300px;

    ${Breakpoints.SM} {
      max-height: 200;
    }
  `,
  lottie: css`
    height: 180px;
    ${Breakpoints.SM} {
      height: 100px;
    }
  `,
  text: css`
    color: ${Colors.DARK_1};
    text-align: center;
    font-family: ${Fonts.Joane};
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin-top: 32px;

    ${Breakpoints.SM} {
      font-size: 14px;
      line-height: 24px;
      margin-top: 15px;
      height: 48px;
    }
  `,
}
