import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: (highlighted?: boolean) => css`
    display: flex;

    border-radius: 10px;
    background-color: ${Colors.LIGHT_1};
    border: 1px solid ${Colors.LIGHT_GREY_1};

    padding: 20px 24px;

    ${highlighted &&
    css`
      border: 1px solid ${Colors.PRIMARY_GREEN};
      border-radius: 10px;
    `}

    ${Breakpoints.LG} {
      flex-direction: column;
      gap: 8px;
      padding: 0;
    }
  `,

  header: css``,

  headerContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    ${Breakpoints.LG} {
      margin-bottom: 8px;
    }
  `,

  logo: css`
    max-height: 30px;
    width: auto;
    margin-right: 20px;
  `,

  details: css`
    display: flex;
    gap: 10px;
  `,

  mainWrapper: css`
    width: 100%;

    ${Breakpoints.LG} {
      padding: 16px;
    }
  `,

  main: css`
    border-left: none;
    border-right: none;

    &:nth-of-type(2) {
      padding-top: 20px;
      margin-top: 25px;

      background-image: linear-gradient(to right, #eaeaea 50%, rgba(255, 255, 255, 0) 0%);
      background-position: top;
      background-size: 20px 1px;
      background-repeat: repeat-x;

      ${Breakpoints.SM} {
        margin-top: 45px;
      }
    }
  `,

  flightWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  flightType: css`
    font-size: 14px;
    color: ${Colors.NEUTRAL_GREY};
    margin-bottom: 8px;
  `,

  destinationInfo: css`
    text-align: right;
  `,

  time: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 36px;
    font-weight: 500;

    margin-bottom: 8px;

    ${Breakpoints.SM} {
      font-size: 18px;
    }
  `,

  location: css`
    color: ${Colors.NEUTRAL_DARK};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;

    ${Breakpoints.SM} {
      font-size: 14px;
    }
  `,

  date: css`
    color: ${Colors.NEUTRAL_GREY};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;

    ${Breakpoints.SM} {
      font-size: 12px;
    }
  `,

  route: {
    container: css`
      width: 60%;
      max-width: 271px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      ${Breakpoints.LG} {
        max-width: 135px;
      }
    `,

    totalTime: css`
      color: ${Colors.NEUTRAL_GREY};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      text-align: center;
    `,

    dottedLine: css`
      position: relative;
      height: 1.5px;
      background-image: linear-gradient(
        to right,
        ${Colors.NEUTRAL_STONE} 25%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 6px 4px;
      background-repeat: repeat-x;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    `,

    stop: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: -3px;

      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;

      div {
        width: 7px;
        height: 7px;
        background-color: ${Colors.NEUTRAL_STONE};
        border-radius: 50%;
      }

      p {
        color: ${Colors.NEUTRAL_GREY};
      }

      span {
        color: ${Colors.NEUTRAL_DARK};
      }

      ${Breakpoints.SM} {
        font-size: 12px;
      }
    `,

    location: css`
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: -1px;

      ${Colors.NEUTRAL_DARK};

      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;

      ${Breakpoints.SM} {
        font-size: 12px;
      }
    `,

    locationIndicator: css`
      background-color: ${Colors.PRIMARY_GREEN};
      width: 32px;
      height: 3px;
      border-radius: 9999px;
    `,

    locationCode: css`
      color: ${Colors.NEUTRAL_DARK};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
    `,
  },

  footer: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    padding-left: 40px;
    border-left: 1px solid ${Colors.NEUTRAL_SILVER};
    margin-left: 40px;
    min-width: 190px;

    ${Breakpoints.LG} {
      min-width: unset;
      padding: 20px 16px 16px;
      border-left: none;
      margin-left: 0;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
      align-items: center;
    }
  `,

  total: {
    amount: css`
      color: ${Colors.NEUTRAL_BLACK};
      font-size: 24px;
      font-weight: 500;
    `,

    label: css`
      color: ${Colors.NEUTRAL_GREY};
      font-size: 16px;
      font-weight: 400;
    `,
  },

  button: (isSelected: boolean) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    border-radius: 100px;
    border: 1px solid ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_DARK};
    backdrop-filter: blur(1px);
    cursor: pointer;
    font-size: 16px;
    font-weight: ${isSelected ? '500' : '400'};
    letter-spacing: 0.32px;
    color: ${isSelected ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_DARK};
    background-color: ${Colors.LIGHT_1};
    span {
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }
  `,
}
