import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  priceCards: css`
    grid-template-columns: repeat(3, minmax(240px, 1fr));
    grid-template-rows: auto auto;
    grid-column-gap: 16px;
    gap: 24px;
    display: grid;

    ${Breakpoints.MD} {
      grid-template-columns: repeat(1, minmax(240px, 1fr));
    }

    ${Breakpoints.SM} {
      gap: 16px;
    }
  `,

  description: css`
    text-align: center;
    margin-top: 8px;
    font-size: 16px;
    color: ${Colors.NEUTRAL_GREY};

    ${Breakpoints.MD} {
      margin-top: 24px;
    }

    ${Breakpoints.SM} {
      font-size: 14px;
      margin-top: 16px;
    }
  `,
}
