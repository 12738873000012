import { createForm } from 'effector-forms'

import { rules } from '~/shared/lib/validation'

import { documentTypeOptions, guestGenderOptions, guestTitleOptions } from '../config'
import { DocumentType, GuestGroupForms, GuestGroups, Guest, GuestType } from '../types'
import { getAvailableDocumentTypeOptions } from './get-available-document-type-options'

export const createGuestGroupsForms = (
  guestGroups: GuestGroups,
  documentTypes: DocumentType[],
): GuestGroupForms => {
  const getFields = (guest: Guest) => ({
    type: {
      init: guest.type,
    },
    age: {
      init: guest.age,
    },
    firstName: {
      init: guest?.firstName || '',
      rules: [rules.firstName('Please enter a first name')],
    },
    lastName: {
      init: guest?.lastName || '',
      rules: [rules.lastName('Please enter a last name')],
    },
    gender: {
      init: guestGenderOptions[0].value,
    },
    dateOfBirth: {
      init: guest?.dateOfBirth || '',
      rules: [
        rules.required('Please enter a date of birth'),
        rules.dateOfBirth(guest.type === GuestType.Adult),
      ],
    },
    title: {
      init: guestTitleOptions[0].value,
    },
    ...(documentTypes.length && {
      documentType: {
        init: getAvailableDocumentTypeOptions(documentTypes, documentTypeOptions)[0]
          .value,
      },
      identifier: {
        init: guest?.identifier || '',
        rules: [rules.required('Document identifier is required')],
      },

      expiresOn: {
        init: guest?.expiresOn || '',
        rules: [rules.required('Please enter expiry date')],
      },

      issuingCountryCode: {
        init: guest?.issuingCountryCode || '',
        rules: [
          rules.required('Please enter issuing country'),
          rules.minLength(2, 'Issuing country code must be 2 characters long'),
        ],
      },
    }),
  })

  const forms: GuestGroupForms = {}

  Object.keys(guestGroups).forEach((groupKey) => {
    const guests = guestGroups[groupKey]
    const guestForms = guests.map((guest) =>
      createForm({
        fields: getFields(guest),
      }),
    )

    // @ts-expect-error TODO: fix types
    forms[groupKey] = guestForms
  })

  return forms
}
