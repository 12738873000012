import { format } from 'date-fns'
import { combine, createEvent, createStore, sample, attach, restore } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset, spread } from 'patronum'

import { hotelsForTripModel } from '~/entities/hotel'
import { hotelRoomModel, LowestDailyRates } from '~/entities/room'
import { destinationModel, SelectedDatesForAllDestinations } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { analyticsModel } from '~/shared/api/analytics'
import { eventNames } from '~/shared/api/analytics/config'
import { LocalStorageKeys } from '~/shared/config'
import {
  isDatesSame,
  isValidDate,
  formatToHyphenDate,
  addDaysToDate,
  isDateInFuture,
} from '~/shared/lib/date'
import { bridge } from '~/shared/lib/factory'
import { initialRange } from './config'

import { CalendarRange, MonthCheckInitiatedProps, DatesRange } from './types'

export const TripInfoGate = createGate()

export const getLastCheckOutDateFx = attach({
  effect: hotelRoomModel.$$getRoomsFactory.getRoomsFx,
})

export const stateReset = createEvent()
export const bookingDatesChanged = createEvent<CalendarRange | null>()
export const bookingDatesChangeInitiated = createEvent<DatesRange>()
export const datesRemoved = createEvent()
export const exactDatesCheckInitiated = createEvent()

export const calendarViewChanged = createEvent<Date>()
export const calendarUpdated = createEvent()
export const nextMonthCheckInitiated = createEvent<Date>()

export const availabilityCheckInitiated = createEvent<Date | undefined>()
export const checkedMonthReset = createEvent()
// export const checkedMonthAdded = createEvent<GetRoomsRequestProps>()
export const viewedMonthsCheckInitiated = createEvent<MonthCheckInitiatedProps>()
export const oneMonthCheckInitiated = createEvent<MonthCheckInitiatedProps>()
export const bothMonthsCheckInitiated = createEvent<MonthCheckInitiatedProps>()

export const calendarOpened = createEvent()
export const calendarClosed = createEvent()

export const getLastCheckOutDateInitiated = createEvent()
export const lastCheckOutDateReset = createEvent()

export const $range = createStore<CalendarRange>(initialRange)
export const $checkedMonths = createStore(new Map())

export const $unavailableDates = createStore<string[]>([])
export const $checkOutOnlyDates = createStore<string[]>([])
export const $availableDatesWithRates = createStore<LowestDailyRates>({})
export const $lastCheckOutDate = createStore<Date | null>(null)
export const $isCalendarVisible = createStore(false)

export const $isDateRangeInvalid = combine(
  $range,
  ({ startDate, endDate }) =>
    !startDate || !endDate || !isValidDate(endDate) || isDatesSame(startDate, endDate),
)

export const $isGetLastCheckoutPending = getLastCheckOutDateFx.pending

export const resetSelectedDateForDestinations = createEvent()
export const updateSelectedDateForDestination = createEvent<
  SelectedDatesForAllDestinations[] | null
>()
export const $selectedDateForDestinations = restore(
  updateSelectedDateForDestination,
  null,
)

// save selected date for destinations
bridge(() => {
  sample({
    clock: tripInfoModel.$selectedTripDates,
    source: {
      selectedDestination: destinationModel.$selectedDestination,
      selectedDateForDestinations: $selectedDateForDestinations,
    },
    filter: (_, { startDate, endDate }) => !!startDate && !!endDate,
    fn: (
      { selectedDestination, selectedDateForDestinations },
      { startDate, endDate },
    ) => {
      const convertedStartDate = format(new Date(startDate), 'yyyy-MM-dd')
      const convertedEndDate = format(new Date(endDate), 'yyyy-MM-dd')

      if (!selectedDateForDestinations) {
        return [
          {
            destinationCode: selectedDestination?.destinationCode || '',
            startDate: convertedStartDate,
            endDate: convertedEndDate,
          },
        ]
      }

      const existingDestination = selectedDateForDestinations.find(
        (dest) => dest.destinationCode === selectedDestination?.destinationCode,
      )

      if (!existingDestination) {
        return [
          ...selectedDateForDestinations,
          {
            destinationCode: selectedDestination?.destinationCode || '',
            startDate: convertedStartDate,
            endDate: convertedEndDate,
          },
        ]
      }

      return selectedDateForDestinations.map((dest) =>
        dest.destinationCode === selectedDestination?.destinationCode
          ? {
              ...dest,
              startDate: convertedStartDate,
              endDate: convertedEndDate,
            }
          : dest,
      )
    },
    target: updateSelectedDateForDestination,
  })
})

sample({
  clock: bookingDatesChangeInitiated,
  filter: ({ startDate, endDate }) =>
    Boolean(startDate && endDate) && isDateInFuture(new Date(startDate)),
  fn: ({ startDate, endDate }) => ({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  }),
  target: bookingDatesChanged,
})

sample({
  clock: bookingDatesChanged,
  filter: (range): range is CalendarRange => Boolean(range),
  target: $range,
})

sample({
  clock: datesRemoved,
  fn: () => initialRange,
  target: bookingDatesChanged,
})

sample({
  clock: $range,
  target: spread({
    targets: {
      startDate: tripInfoModel.checkInChanged,
      endDate: tripInfoModel.checkOutChanged,
    },
  }),
})

//exact range checking block
sample({
  clock: [
    hotelsForTripModel.$$manageHotelFactory.updateSelectedHotelForTrip,
    tripInfoModel.checkOutChanged,
  ],
  source: {
    isDateRangeInvalid: $isDateRangeInvalid,
    hotelCard: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  filter: ({ isDateRangeInvalid, hotelCard }) => !isDateRangeInvalid && !!hotelCard,
  target: exactDatesCheckInitiated,
})

sample({
  clock: exactDatesCheckInitiated,
  source: {
    rooms: tripInfoModel.$tripInfo,
    datesRange: $range,
    selectedHotelCard: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  filter: ({ selectedHotelCard }) => selectedHotelCard?.code !== undefined,
  fn: ({ rooms, datesRange, selectedHotelCard }) => ({
    codes: [selectedHotelCard?.code] as number[],
    stay: {
      occupancies: rooms.map((room) => ({
        adults: room.adults.length,
        children: room.children.length || undefined,
        childrenAges: room.children.length
          ? room.children.map(({ age }) => age!)
          : undefined,
        rooms: 1,
      })),
      checkIn: formatToHyphenDate(datesRange.startDate!),
      checkOut: formatToHyphenDate(datesRange.endDate!),
    },
  }),
  target: hotelRoomModel.$$getRoomsFactory.getRoomsFx,
})

sample({
  clock: calendarOpened,
  source: $range,
  fn: ({ startDate }) => (!!startDate ? startDate : addDaysToDate(new Date(), 1)),
  target: calendarViewChanged,
})

sample({
  source: hotelRoomModel.$$getRoomsFactory.getRoomsFx.doneData,
  filter: (res) => !res.data?.hotels?.[0].lowestTotalAmount,
  target: datesRemoved,
})

sample({
  source: hotelRoomModel.$$getRoomsFactory.getRoomsFx.fail,
  target: datesRemoved,
})

//availability request triggers

sample({
  clock: TripInfoGate.open,
  source: $range,
  fn: ({ startDate }) => (!!startDate ? startDate : addDaysToDate(new Date(), 1)),
  target: [availabilityCheckInitiated, nextMonthCheckInitiated],
})

sample({
  clock: calendarOpened,
  fn: () => true,
  target: $isCalendarVisible,
})

sample({
  clock: calendarClosed,
  fn: () => false,
  target: $isCalendarVisible,
})

//reset block

sample({
  clock: checkedMonthReset,
  fn: () => new Map(),
  target: $checkedMonths,
})

reset({
  clock: lastCheckOutDateReset,
  target: $lastCheckOutDate,
})

//Analytics
sample({
  clock: calendarOpened,
  fn: () => ({
    name: eventNames.tripDatesOpened,
  }),
  target: analyticsModel.track,
})

sample({
  clock: calendarClosed,
  fn: () => ({
    name: eventNames.tripDatesClosed,
  }),
  target: analyticsModel.track,
})

persist({
  store: $selectedDateForDestinations,
  key: LocalStorageKeys.SelectedDateForAllDestinations,
})

reset({
  clock: destinationModel.stateReset,
  target: $selectedDateForDestinations,
})
