import { css } from '@emotion/react'
import { StylesConfig } from 'react-select'
import { Colors } from '~/shared/styles'
import { SelectOption } from './types'

export const styles = {
  container: css``,

  label: css`
    display: block;

    font-size: 16px;
    line-height: 20px;
    color: ${Colors.NEUTRAL_DARK};

    margin-bottom: 8px;
  `,
}

export const selectStyles: StylesConfig<SelectOption, false> = {
  control: (provided, state) => ({
    ...provided,

    width: '100%',
    height: 46,

    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '2%',
    color: Colors.NEUTRAL_BLACK,

    borderRadius: 0,
    border: state.isFocused ? `1px solid ${Colors.PRIMARY_GREEN}` : `1px solid #D5D4DC`,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${Colors.PRIMARY_GREEN}`,
    },
    transition: 'all 0.1s linear',
  }),

  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 12px',
  }),

  option: (provided, state) => ({
    ...provided,
    width: '100%',
    background: state.isSelected
      ? Colors.PRIMARY_GREEN
      : state.isFocused
      ? `${Colors.PRIMARY_GREEN}33`
      : 'transparent',
    cursor: 'pointer',

    transition: 'all 0.1s linear',

    '&:active': {
      background: `${Colors.PRIMARY_GREEN}33`,
    },
  }),
}
