import { createEvent, createStore, createEffect, sample } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { selectImagesModel } from '~/features/onboarding/select-images'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { AppRoute, isStaging } from '~/shared/config/app-route'
import { appStarted } from '~/shared/config/init'
import { summaryPageModel } from '../../summary'

export const Gate = createGate<void>()
export const stateReset = createEvent()
export const stepSkipped = createEvent()
export const nextStepNavigated = createEvent()
export const previousStepNavigated = createEvent()
export const $isStepSkippable = createStore(true)
export const goToAppClicked = createEvent()

const checkWhetherPageIsReloadedFx = createEffect(() => {
  const { pathname } = window.location
  return pathname === AppRoute.Inspiration
})

sample({
  clock: Gate.open,
  target: onboardingSessionModel.scrollToTopFx,
})

sample({
  clock: appStarted,
  target: checkWhetherPageIsReloadedFx,
})

sample({
  clock: checkWhetherPageIsReloadedFx.doneData,
  filter: Boolean,
  target: selectImagesModel.selectionReset,
})

sample({
  clock: goToAppClicked,
  fn: () =>
    isStaging
      ? `https://app.stag.awayaway.com/?resetOnboardingData=true`
      : `https://app.awayaway.com/?resetOnboardingData=true`,
  target: onboardingSessionModel.exitIframeModeFx,
})

sample({
  clock: [selectImagesModel.imageIdSelected, selectImagesModel.imageIdRemoved, Gate.open],
  source: selectImagesModel.$selectedImageIds,
  fn: (selectedImageIds) => !Boolean(selectedImageIds.length),
  target: $isStepSkippable,
})

sample({
  clock: nextStepNavigated,
  target: selectImagesModel.getImageCategoriesInitiated,
})

sample({
  clock: nextStepNavigated,
  filter: $isStepSkippable,
  target: stepSkipped,
})

sample({
  clock: selectImagesModel.imageCategoriesReceived,
  target: listImageRecommendationsModel.imagesLabelsSelected,
})

sample({
  clock: stateReset,
  target: [
    selectImagesModel.selectionReset,
    summaryPageModel.stateReset,
    listImageRecommendationsModel.stateReset,
  ],
})

reset({
  clock: stateReset,
  target: $isStepSkippable,
})
