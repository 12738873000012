import { CreateOrderPassenger } from '@duffel/components'
import {
  Guest,
  GuestGender,
  GuestType,
  Passenger,
} from '~/features/trip-booking/add-guests-info'
import { formatDateStringToCustom } from '~/shared/lib/date'

export const convertGuestsToDuffelPassengers = (
  guests: Guest[],
  passengers: Passenger[],
): CreateOrderPassenger[] => {
  let adultIndex = 0
  let childIndex = 0

  const adults = passengers.filter((passenger) => passenger.type === 'adult')
  const children = passengers.filter((passenger) => passenger.type === 'child')

  return guests.map((guest) => {
    let passengerId
    if (guest.type === GuestType.Adult) {
      passengerId = adults?.[adultIndex]?.id
      adultIndex += 1
    } else {
      passengerId = children?.[childIndex]?.id
      childIndex += 1
    }

    return {
      id: passengerId || '',
      title: guest.title,
      given_name: guest.firstName,
      family_name: guest.lastName,
      gender: guest.gender === GuestGender.Male ? 'm' : 'f',
      born_on: formatDateStringToCustom(guest.dateOfBirth, 'DD/MM/YYYY', 'YYYY-MM-DD'),
    } as CreateOrderPassenger
  })
}
