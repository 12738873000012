import { useStore } from 'effector-react'
import { motion, LayoutGroup } from 'framer-motion'
import { FC } from 'react'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { magicWand } from '~/shared/assets/icons/grey'
import { IconComponent } from '~/shared/assets/icons/onboarding'
import { magicWand as magicWandActive } from '~/shared/assets/icons/primary'
import { useWindowSize } from '~/shared/lib/use-window-size'
import { Colors } from '~/shared/styles'
import { onboardingLayoutModel } from '../..'
import { styles } from './styles'

export interface Step {
  order: number
  name: string
  isActive: boolean
  isDisabled?: boolean
  isStepCompleted?: boolean
}

interface Props {
  steps: Step[]
}

const NavStep = ({ step }: { step: Step }) => {
  const { width } = useWindowSize()
  const isSummaryStep = step.name === 'summary'
  const isMobile = width < 576
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)

  return (
    <motion.button
      onClick={() => onboardingLayoutModel.stepNavigated(step.order)}
      css={[
        styles.wrapper(step.isActive),
        step.isStepCompleted && styles.completed,
        step.isDisabled && styles.disabled,
      ]}
      disabled={step.isDisabled}
      key={step.order}
      transition={{ duration: 0.4, type: 'spring' }}
      animate={
        isMobile
          ? { width: step.isActive ? 120 : 33 }
          : { width: step.isActive ? 120 : 40 }
      }
    >
      <div css={styles.text}>
        {isSummaryStep ? (
          <img css={styles.step.icon} src={step.isActive ? magicWandActive : magicWand} />
        ) : (
          <IconComponent
            name={isIframe ? step.order - 1 : step.order}
            css={styles.numberLabel}
            color={
              step.isActive
                ? Colors.PRIMARY_GREEN
                : step.isStepCompleted
                ? Colors.LIGHT_GREEN
                : Colors.LIGHT_GREY_1
            }
          />
        )}

        <span css={styles.step.title(step.isActive)}>{step.isActive && step.name}</span>
      </div>
    </motion.button>
  )
}
export const Navbar: FC<Props> = ({ steps }) => {
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)
  return (
    <LayoutGroup>
      <div css={styles.container}>
        {steps
          .filter((step, index) => !isIframe || index > 0)
          .map((step, index) => (
            <NavStep step={step} key={step.order} />
          ))}
      </div>
    </LayoutGroup>
  )
}
