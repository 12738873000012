import { TripBooking } from '@softcery/awayaway-apiclient'
import { FC, Fragment } from 'react'
import { arrowRight } from '~/shared/assets/icons/primary'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatDate } from '../../lib'
import { Column } from './column'
import { Row } from './row'
import { styles } from './styles'

export const BookingDetails: FC<{ booking: TripBooking }> = ({ booking }) => {
  const totalGuests =
    (booking?.hotel?.totalAdults || 0) + (booking?.hotel?.totalChildren || 0)

  const lastPaymentTransaction = booking?.paymentTransactions?.slice(-1)[0]

  const totalAmount = (booking?.hotel?.amount || 0) + (booking?.flight?.amount || 0)

  const flightTimeStyle = { color: '#8E8E8E', fontSize: '16px', marginTop: '4px' }

  const totalRooms = booking?.hotel?.packages?.length || 0

  return (
    <div css={styles.container}>
      <header css={styles.header.container}>
        <h1 css={styles.header.title}>Trip Details</h1>
        <p css={styles.header.reference}>Reference #{booking?.hotel?.reference}</p>
      </header>

      <div>
        <Row>
          <Column title="Accommodation">{booking?.hotel?.hotelName || '-'}</Column>
        </Row>

        <Row extendStyles={styles.dates.container}>
          <Column title="Check-in">
            {formatDate('ddd, MMM DD, YYYY', booking?.hotel?.checkIn) || '-'}
          </Column>

          <img src={arrowRight} css={styles.dates.icon} />

          <Column title="Check-out">
            {formatDate('ddd, MMM DD, YYYY', booking?.hotel?.checkOut) || '-'}
          </Column>
        </Row>

        <Row wrap>
          <Column title="Length of Stay">
            {booking?.hotel?.lengthOfStay
              ? `${booking?.hotel?.lengthOfStay} nights`
              : '-'}
          </Column>

          <Column title="Total Guests">{totalGuests || '-'}</Column>

          <Column title="Total Rooms:">
            {/* {'-'} */}
            {totalRooms ? `${totalRooms} rooms` : '-'}{' '}
            {/* {!!roomsCount && (
              <Link
                to={`${AppRoute.AccountBookings}/${booking.id}/rooms`}
                css={styles.link}
              >
                (View)
              </Link>
            )} */}
          </Column>
        </Row>

        {booking?.flight?.segments?.length && booking.flight.segments.length > 0 && (
          <Fragment>
            <Row>
              <Column title="Flight departure">
                {booking?.flight?.segments[0]?.origin?.cityName || '-'}
                <p style={flightTimeStyle}>
                  {formatDate(
                    'ddd, DD MMM, HH:mm',
                    booking?.flight?.segments[0]?.departingAt,
                  )}
                </p>
              </Column>

              <img src={arrowRight} css={styles.dates.icon} />

              <Column title="Flight arrival">
                {booking?.flight?.segments[0]?.destination?.cityName || '-'}
                <p style={flightTimeStyle}>
                  {formatDate(
                    'ddd, DD MMM, HH:mm',
                    booking?.flight?.segments[0]?.arrivingAt,
                  )}
                </p>
              </Column>
            </Row>

            <Row>
              <Column title="Flight departure">
                {booking?.flight?.segments[1]?.origin?.cityName || '-'}
                <p style={flightTimeStyle}>
                  {formatDate(
                    'ddd, DD MMM, HH:mm',
                    booking?.flight?.segments[1]?.departingAt,
                  )}
                </p>
              </Column>

              <img src={arrowRight} css={styles.dates.icon} />

              <Column title="Flight arrival">
                {booking?.flight?.segments[1]?.destination?.cityName || '-'}
                <p style={flightTimeStyle}>
                  {formatDate(
                    'ddd, DD MMM, HH:mm',
                    booking?.flight?.segments[1]?.arrivingAt,
                  )}
                </p>
              </Column>
            </Row>
          </Fragment>
        )}

        <Row>
          <Column title="Total Price">
            {getPriceWithCurrency(totalAmount, lastPaymentTransaction?.paymentCurrency)}
          </Column>
        </Row>
      </div>
    </div>
  )
}
