import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    justify-content: center;

    ${Breakpoints.LG} {
      flex-direction: column-reverse;
    }
  `,

  sidebar: (position: 'left' | 'right') => css`
    width: 350px;
    margin: ${position === 'left' ? '0 30px 0 0' : '0 0 0 30px'};

    ${Breakpoints.LG} {
      width: 100%;
      margin: 0;
    }
  `,

  main: css`
    max-width: 730px;
    flex: 1;

    ${Breakpoints.LG} {
      margin-top: 24px;
      width: 100%;
    }
  `,
}
