import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { selectLocationsModel } from '..'
import { searchOptions } from '../lib'
import { styles } from './styles'

export const SelectLocations: FC = () => {
  useGate(selectLocationsModel.Gate)

  const { initialized } = useStore(selectLocationsModel.$googleMapsStatus)

  const locationValue = useStore(selectLocationsModel.$locationValue)
  const isMaxLocationsReached = useStore(selectLocationsModel.$isMaxLocationsReached)

  if (!initialized) {
    return null
  }

  return (
    <PlacesAutocomplete
      value={locationValue}
      onChange={(location) => selectLocationsModel.locationChanged(location)}
      onSelect={(address) => {
        return selectLocationsModel.locationSelected(address)
      }}
      searchOptions={searchOptions}
    >
      {(props) => {
        const { getInputProps, suggestions, getSuggestionItemProps, loading } = props
        selectLocationsModel.suggestionChanged([...suggestions])

        const handleInputKeyDown = (e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()

            if (suggestions?.length > 0) {
              const firstSuggestion = suggestions[0]
              selectLocationsModel.locationSelected(firstSuggestion.description)
            }
          }
        }

        return (
          <div>
            {/* <label css={styles.label(isMaxLocationsReached)}>Add Destinations:</label> */}

            <div css={styles.container(isMaxLocationsReached)}>
              <div css={styles.icon(isMaxLocationsReached)} />

              {/* INPUT */}
              <div css={styles.input.wrapper}>
                <input
                  {...getInputProps({
                    placeholder: isMaxLocationsReached
                      ? 'You have already selected 10 places'
                      : 'Search for countries, cities or regions',
                    css: styles.input.field,
                    disabled: isMaxLocationsReached,
                  })}
                  onKeyDown={handleInputKeyDown}
                />
              </div>
            </div>

            {/* DROPDOWN */}
            {(loading || (!!suggestions.length && !!locationValue)) && (
              <div css={styles.dropdown.wrapper}>
                <div css={styles.dropdown.container}>
                  {loading && <div css={styles.dropdown.loading}>Loading...</div>}

                  {suggestions.map((suggestion, i) => (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        css: [
                          styles.dropdown.item,
                          suggestion.active && styles.dropdown.active,
                        ],
                      })}
                      key={i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )
      }}
    </PlacesAutocomplete>
  )
}
