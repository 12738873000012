import { useStore } from 'effector-react'
import { FC, useEffect } from 'react'
import { FlightsSkeleton } from '~/entities/flights/flights-skeleton'
import { hotelsForTripModel } from '~/entities/hotel'
import { tripInfoModel } from '~/entities/trip-info'

import { mapIcon } from '~/shared/assets/icons'
import { flameImage } from '~/shared/assets/images/other'
import { Button } from '~/shared/ui'
import { searchFlightsModel } from '../../model/model'
import { OfferCard } from '../offer-card'
import { SearchBar } from '../search-bar'
import { styles } from './styles'

export const Offers: FC = () => {
  const checkIn = useStore(tripInfoModel.$checkIn)
  const checkOut = useStore(tripInfoModel.$checkOut)
  const selectedHotel = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )

  const childrenAmount = useStore(tripInfoModel.$childrenAmount)
  const adultsAmount = useStore(tripInfoModel.$adultsAmount)

  const hotelCoords =
    selectedHotel?.latitude && selectedHotel?.longitude
      ? {
          latitude: selectedHotel.latitude,
          longitude: selectedHotel.longitude,
        }
      : undefined

  const offers = useStore(searchFlightsModel.$$manageLocalStorageForSearchFlights.$offers)

  const isFlightsSelected = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )
  const options: string[] | null = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$options,
  )

  const flightType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )
  const selectedFlightsOption = useStore(
    searchFlightsModel.$$searchFlightsOptionFactory.$flightsOption,
  )
  const selectedOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )

  const loading = useStore(searchFlightsModel.$loading)

  useEffect(() => {
    if (isFlightsSelected || !offers.length) return
    searchFlightsModel.$$manageLocalStorageForSearchFlights.offerSelected(offers[0])
  }, [selectedOffer, offers])

  return (
    <div css={styles.container}>
      <SearchBar
        startDate={new Date(checkIn)}
        endDate={new Date(checkOut)}
        adultsAmount={adultsAmount}
        childrenAmount={childrenAmount}
        destinationCoords={hotelCoords}
        destinationCityCode={selectedHotel?.destinationCode}
      />

      {selectedFlightsOption === 'recommended' && !loading && offers.length !== 0 && (
        <div css={styles.priceLabel}>
          <img src={flameImage} alt="cheapest-flights-img" /> Cheapest flights
        </div>
      )}
      {loading ? (
        <FlightsSkeleton />
      ) : offers.length === 0 ? (
        <div css={styles.notFound.wrapper}>
          <div css={styles.notFound.content}>
            <img css={styles.notFound.icon} src={mapIcon} />
            <div css={styles.notFound.title}>
              Sorry! It seems we haven’t found a perfect match
            </div>
            <p css={styles.notFound.description}>
              But you can try adjusting your choice by restarting the quiz
            </p>
          </div>
        </div>
      ) : (
        <div css={styles.offers}>
          {offers.map((offer) => (
            <OfferCard
              key={offer.flightId}
              offer={offer}
              onSelect={
                searchFlightsModel.$$manageLocalStorageForSearchFlights.offerSelected
              }
              isSelected={selectedOffer?.flightId === offer.flightId}
              flightType={flightType}
            />
          ))}
        </div>
      )}

      {
        <div css={styles.flightsOptions.wrapper}>
          <p css={styles.flightsOptions.desc}>
            {(selectedFlightsOption !== 'recommended' || !!options?.length) &&
              'Not suitable?'}
          </p>
          <div css={styles.flightsOptions.container}>
            {selectedFlightsOption !== 'recommended' && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'recommended',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Cheapest
              </Button>
            )}
            {options?.includes('business') && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'business',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Business Class only
              </Button>
            )}
            {options?.includes('outbound_arriving_later') && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'outbound_arriving_later',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Outbound arrival later
              </Button>
            )}
            {options?.includes('outbound_arriving_sooner') && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'outbound_arriving_sooner',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Outbound arrival sooner
              </Button>
            )}
            {flightType === 'return' && options?.includes('inbound_arriving_sooner') && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'inbound_arriving_sooner',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Inbound arrival sooner
              </Button>
            )}
            {flightType === 'return' && options?.includes('inbound_arriving_later') && (
              <Button
                onClick={() =>
                  searchFlightsModel.$$searchFlightsOptionFactory.flightsOptionChanged(
                    'inbound_arriving_later',
                  )
                }
                variant="outlined"
                css={styles.flightsOptions.button}
                disabled={loading}
              >
                Inbound arrival later
              </Button>
            )}
          </div>
        </div>
      }
    </div>
  )
}
