import { SerializedStyles } from '@emotion/react'
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox'
import { useStore } from 'effector-react'
import { FC, useEffect, useState, useRef } from 'react'
import { searchFlightsModel } from '~/features/search-flights/model/model'
import { getAirportLabel, getAirportSuggestions } from '../../../lib'
import { Airport } from '../../../types'
import { styles } from './styles'

interface Props {
  label: string
  airport: Airport
  onSelect: (airport: Airport) => void
  disabled?: boolean
  extendStyles?: {
    container?: SerializedStyles
  }
}

export const AirportInput: FC<Props> = ({
  label,
  airport,
  onSelect,
  disabled,
  extendStyles,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [selectedAirport, setSelectedAirport] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [isInputInFocus, setInputFocus] = useState(false)
  const [suggestions, setSuggestions] = useState<Airport[] | null>(null)

  const airports = useStore(searchFlightsModel.$$searchPlacesFactory.$places)
  const userAirport = useStore(searchFlightsModel.$$getUserPositionFactory.$userAirport)

  const maxSuggestionsToDisplay = 10

  useEffect(() => {
    if (userAirport?.cityName || userAirport?.name) {
      setInputValue(userAirport?.cityName ?? userAirport?.name ?? '')
      setInputFocus(false)
    }
  }, [userAirport])

  useEffect(() => {
    if (isInputInFocus && inputValue.length > 0) {
      searchFlightsModel.$$searchPlacesFactory.searchPlacesInitiated(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (isInputInFocus) {
      setSuggestions(getAirportSuggestions(inputValue, airports, [airport]))
    }
  }, [airports])

  useEffect(() => {
    if (airport) {
      const airportLabel = getAirportLabel(airport)

      setSelectedAirport(airportLabel)
      setInputValue(airportLabel)
    }
  }, [airport])

  const selectAirportHandler = (airportId: string) => {
    const selectedAirport = airports.find(({ id }) => id === airportId)!
    const airportLabel = getAirportLabel(selectedAirport)

    onSelect(selectedAirport)
    setSelectedAirport(airportLabel)
    setInputValue(airportLabel)
    setSuggestions(null)
    setInputFocus(false)
  }

  useEffect(() => {
    const handler = () => {
      if (airport) {
        setInputValue(selectedAirport)
      }
    }

    if (inputValue !== selectedAirport) {
      window.addEventListener('mousedown', handler)
    } else {
      window.removeEventListener('mousedown', handler)
    }

    return () => window.removeEventListener('mousedown', handler)
  }, [inputValue])

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <label css={styles.label}>{label}</label>
      <Combobox onSelect={selectAirportHandler} css={styles.combobox}>
        <div css={[styles.container, extendStyles?.container]}>
          {/* INPUT */}
          <div css={styles.input.wrapper}>
            <ComboboxInput
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
              disabled={disabled}
              css={styles.input.field}
            />
          </div>
        </div>
        {suggestions && (
          <ComboboxPopover css={styles.dropdown.wrapper} portal={false}>
            <ComboboxList css={styles.dropdown.container}>
              {suggestions.length > 0 ? (
                suggestions.slice(0, maxSuggestionsToDisplay).map((suggestion) => {
                  return (
                    <ComboboxOption
                      key={suggestion.id}
                      value={suggestion.id}
                      css={styles.dropdown.item}
                      onClick={() => selectAirportHandler(suggestion.id)}
                    >
                      {getAirportLabel(suggestion)}
                    </ComboboxOption>
                  )
                })
              ) : (
                <span css={styles.dropdown.item}>No results found</span>
              )}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  )
}
