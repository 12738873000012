import { StripeError } from '@stripe/stripe-js'
import { createEvent, sample, createStore, Effect, Store } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
interface MakePaymentModelOptions {
  token: Store<string>
  confirmFx: Effect<any, any, Error> //retype any
}

export const makePaymentModel = ({ token, confirmFx }: MakePaymentModelOptions) => {
  const PaymentGate = createGate()

  const paymentCompleted = createEvent()
  const paymentErrorOccured = createEvent<StripeError>()

  const $loading = confirmFx.pending
  const $paymentError = createStore('')
  const $confirmError = createStore('')
  const $paymentToken = token

  const resetError = createEvent()

  sample({
    clock: paymentErrorOccured,
    fn: (err) => err.message || '',
    target: $paymentError,
  })

  sample({
    clock: confirmFx.failData,
    fn: (err) => err.message || '',
    target: $confirmError,
  })

  //reset

  reset({
    clock: paymentCompleted,
    target: $paymentError,
  })

  reset({
    clock: confirmFx.done,
    target: $confirmError,
  })

  reset({
    clock: [PaymentGate.close, resetError],
    target: [$paymentError, $confirmError],
  })

  return {
    $loading,
    $paymentError,
    $confirmError,
    $paymentToken,

    resetError,
    paymentCompleted,
    PaymentGate,
    paymentErrorOccured,
  }
}
