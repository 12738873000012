import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    padding-top: 32px;

    border-top: 1px solid ${Colors.NEUTRAL_SILVER};
  `,

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    margin-bottom: 16px;
  `,

  subtitle: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 32px;
  `,

  largeInput: css`
    margin-top: 8px;
    min-height: 183px;
    width: 100%;
    padding: 12px;
    font-family: Calibre;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};
    resize: none;
    padding: 14px 60px 14px 20px;
    border: 1px solid ${Colors.LIGHT_GREY_2};
    font-size: 18px;
    line-height: 20px;

    &:active,
    &:focus {
      outline: none;
    }
    :placeholder {
      color: ${Colors.NEUTRAL_GREY};
    }
  `,
}
