import { useStore } from 'effector-react'
import { FC } from 'react'
import {
  cancelBookingModel,
  CancelTripCancellationPolicy,
  searchBookingModel,
} from '~/entities/booking'
import { errorInfoIcon } from '~/shared/assets/icons'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { normalizeStringCase } from '~/shared/lib/string'
import { ButtonWithLoader, Error } from '~/shared/ui'
import { styles } from './styles'

export const BookingCancellationRequest: FC = () => {
  const bookedTrip = useStore(searchBookingModel.$$getBookingFactory.$bookedTripDetails)
  const error = useStore(cancelBookingModel.$error)
  const loading = useStore(cancelBookingModel.$loading)

  const isStrictPolicy = bookedTrip?.hotel?.cancellationType === 'strict'
  const currency = bookedTrip?.paymentTransactions?.slice(-1)[0]?.paymentCurrency || ''
  const origin = bookedTrip?.flight?.segments?.[0]?.origin?.cityName || ''
  const destination = bookedTrip?.flight?.segments?.[0]?.destination?.cityName || ''
  const flightType = bookedTrip?.flight?.offerType || ''

  const cancellationPolicies =
    bookedTrip?.hotel?.packages?.[0].cancellationPolicies?.cancellationPolicies ?? []
  const flightCancellationPolicies = bookedTrip?.flight?.isCancellable ?? false

  const formatCancellationPolicies = (
    policies: any[],
  ): { from: string; amount: number }[] =>
    policies.map((policy) => ({
      from: policy.dateFrom,
      amount: policy.cancellationFee.amount,
    }))

  return (
    <div css={styles.container}>
      <header css={styles.header.container}>
        <h1 css={styles.header.title}>Request cancellation</h1>
        <p css={styles.header.reference}>Reference #{bookedTrip?.hotel?.reference}</p>
      </header>

      <div css={[styles.info.container, error && styles.info.error]}>
        <h1 css={styles.info.title}>
          Cancellation policy: {isStrictPolicy ? `Strict` : `Flexible`}
        </h1>

        <p css={styles.info.text}>
          <CancelTripCancellationPolicy
            policies={formatCancellationPolicies(cancellationPolicies)}
            currency={currency}
            isStrict={isStrictPolicy}
          />
        </p>
      </div>

      {bookedTrip?.flight && (
        <div css={[styles.info.container, error && styles.info.error, styles.info.gap]}>
          <h1 css={styles.info.title}>
            Flight Offer:{' '}
            {flightCancellationPolicies ? 'Flexible' : 'Cannot be automatically canceled'}
          </h1>

          {!flightCancellationPolicies && (
            <p css={styles.info.text}>
              This offer will be manually canceled by our team.
            </p>
          )}

          <p css={styles.flights.info}>
            <span css={styles.flights.text}>{`${origin} - ${destination}`}</span>
            <span css={styles.flights.type}>{normalizeStringCase(flightType)}</span>
          </p>

          <p css={styles.flights.price}>
            {getPriceWithCurrency(bookedTrip.flight.amount!, bookedTrip.flight.currency!)}
          </p>
        </div>
      )}

      {error && (
        <div css={styles.error.container}>
          <img src={errorInfoIcon} css={styles.error.icon} />
          <Error extendStyles={styles.error.text}>{error}</Error>
        </div>
      )}
      <ButtonWithLoader
        extendStyle={styles.button}
        onClick={() => cancelBookingModel.bookingCancelInitiated()}
        loading={loading}
        disabled={!!error || loading}
      >
        {/* {isStrictPolicy ? 'Contact Hotel' : 'Request Cancellation'} */}
        Request Cancellation
      </ButtonWithLoader>
    </div>
  )
}
