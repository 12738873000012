import { useStore } from 'effector-react'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { hotelRoomModel } from '~/entities/room'
import { Dimensions } from '~/shared/styles'
import { Button, Error } from '~/shared/ui'
import {
  $itineraries,
  $getItinerariesStatus,
  $itineraryRegenerateStarted,
} from '../../model'
import { ButtonLoader } from './button-loader'
import { Itinerary } from './itinerary'
import { ItinerarySkeletonLoader } from './itinerary/skeleton/skeleton'
import { NavigationButton } from './navigation-button'
import { NavigationSkeleton } from './navigation-button/skeleton/skeleton'
import { styles } from './styles'
import { useActiveDay } from './use-active-day'
import { useScrollIntoView } from './use-scroll-into-view'
import { useStickyHeader } from './use-sticky-header'

interface Props {
  onRegenerate: () => void
}

export const Itineraries: FC<Props> = ({ onRegenerate }) => {
  const itineraries = useStore($itineraries)
  const { loading, error } = useStore($getItinerariesStatus)
  const itineraryRegenerateStarted = useStore($itineraryRegenerateStarted)
  const roomsLoading = useStore(hotelRoomModel.$$getRoomsFactory.$loading)
  const isMobile = useMediaQuery({ query: `(max-width: ${Dimensions.Md}px)` })
  const { headerRef, isHeaderSticky } = useStickyHeader(roomsLoading)

  const { itineraryDaysRef, activeDay } = useActiveDay([loading])
  const containerRef = useScrollIntoView(itineraryRegenerateStarted)

  return (
    <div ref={containerRef} css={styles.container}>
      <div css={styles.hero}>
        <div css={styles.titleWrapper}>
          <h1 css={styles.title}>{isMobile ? 'Itinerary' : 'Itinerary Suggestions'}</h1>

          {isMobile && !loading && (
            <div
              onClick={loading ? undefined : onRegenerate}
              css={styles.regenerateButton.mobile}
            >
              Regenerate {loading && <ButtonLoader />}
            </div>
          )}
        </div>

        <p css={styles.subtitle}>
          Here’s an example itinerary based on your interests and the location.
        </p>

        {/* <p css={styles.subtitle}>
          Our itineraries currently extend to a maximum of{' '}
          <span css={styles.semiBold}>14 nights</span>, ensuring bespoke luxury throughout
          your journey.
        </p> */}
      </div>

      <div css={styles.header.placeholder}>
        <div ref={headerRef} css={styles.header.wrapper(isHeaderSticky)}>
          <div css={styles.header.header(isHeaderSticky)}>
            {!loading && (
              <div css={styles.navigation}>
                {itineraries.map(({ day: date }, index) => {
                  const day = index + 1

                  return (
                    <NavigationButton key={date} day={day} isActive={day === activeDay} />
                  )
                })}
              </div>
            )}

            {loading && <NavigationSkeleton />}

            {!isMobile && (
              <Button
                onClick={onRegenerate}
                disabled={loading}
                extendStyle={styles.regenerateButton.base}
              >
                <div css={styles.regenerateButton.background} />
                Regenerate itinerary {loading && <ButtonLoader />}
              </Button>
            )}
          </div>
        </div>
      </div>

      {!loading && (
        <div ref={itineraryDaysRef} css={styles.content}>
          {itineraries.map((itinerary, index) => (
            <Itinerary
              key={itinerary.day}
              itinerary={itinerary}
              dayNumber={index + 1}
              isLast={index + 1 === itineraries.length}
            />
          ))}
        </div>
      )}

      {loading && (
        <div css={styles.content}>
          <ItinerarySkeletonLoader />
          <ItinerarySkeletonLoader />
          <ItinerarySkeletonLoader isLast />
        </div>
      )}

      {error && <Error>{error}</Error>}
    </div>
  )
}
