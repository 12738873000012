import { useEvent, useGate, useStore } from 'effector-react'
import { FC, Fragment, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { OnboardingLayout } from '~/widgets/layouts/onboarding/ui'

import { destinationModel } from '~/entities/trip'
import { magicWand } from '~/shared/assets/icons/grey'
import { awayLoader } from '~/shared/assets/loaders'

import { Button, Loading, PageLoader } from '~/shared/ui'
import { summaryPageModel } from '..'
import { styles } from './styles'
const RESTRICTED_CHARACTERS_COUNT = 2000
export const SummaryPage: FC = () => {
  useGate(summaryPageModel.Gate)

  const isEditing = useStore(summaryPageModel.$isEditing)
  const isSummaryPending = useStore(summaryPageModel.$isSummaryPending)
  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const summary = useStore(summaryPageModel.$currentSummary)
  const isTripsLoading = useStore(destinationModel.$isTripsLoading)
  const isLoadingMessagesPending = useStore(summaryPageModel.$isLoadingMessagesPending)

  const loadingMessages = useStore(summaryPageModel.$loadingMessages)

  const handleInputChanges = (value: string) => {
    if (RESTRICTED_CHARACTERS_COUNT > value.length) {
      summaryPageModel.summaryChanged(value)
    }
  }

  const onPaste = (value: string) => {
    if (!!value) {
      summaryPageModel.summaryChanged(value.slice(0, RESTRICTED_CHARACTERS_COUNT))
    }
  }

  const summaryTextInputRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (isEditing && summaryTextInputRef.current) {
      summaryTextInputRef.current?.focus()
      const length = summaryTextInputRef.current?.value.length || 0
      setTimeout(() => {
        summaryTextInputRef.current?.setSelectionRange(length, length)
      }, 1)
    }
  }, [isEditing])

  const handleSummaryChange = () => {
    if (isEditing) {
      summaryPageModel.summaryEdited()
    } else {
      summaryPageModel.summaryEdited()
    }
  }

  return (
    <OnboardingLayout>
      {isTripsLoading || isNewSummaryPending ? (
        <PageLoader
          loadingMessages={loadingMessages}
          isMessagesLoading={isLoadingMessagesPending}
          isNewSummaryPending={isNewSummaryPending}
        />
      ) : (
        <Fragment>
          {isSummaryPending ? (
            <Loading loader={awayLoader} additionalCutHeight={50} />
          ) : (
            <div css={styles.wrapper}>
              <div css={styles.description}>
                <img css={styles.icon} src={magicWand} />
                <p>
                  {isEditing
                    ? 'Direct your journey! Provide or tweak details here for a more personalised and relevant set of recommendations.'
                    : "Based on what you've told us, we think you're looking for..."}
                </p>
              </div>

              <div css={styles.textareaWrapper(isEditing)}>
                <TextareaAutosize
                  ref={(ref) => (summaryTextInputRef.current = ref)}
                  rows={1}
                  css={styles.textarea(isEditing)}
                  value={summary}
                  onChange={(e) => handleInputChanges(e.target.value)}
                  onPaste={(e) => onPaste(e.clipboardData.getData('text'))}
                  readOnly={!isEditing}
                  data-hj-allow
                />
              </div>

              <div css={styles.btn.wrapper}>
                {isEditing ? (
                  <div css={styles.btn.inner}>
                    <Button
                      variant="default"
                      css={styles.next(isNewSummaryPending)}
                      onClick={() => summaryPageModel.summaryUpdated()}
                      disabled={!summary}
                    >
                      Update
                    </Button>
                    <Button
                      variant="default"
                      css={styles.base}
                      onClick={() => summaryPageModel.summaryEditCanceled()}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div css={styles.btn.inner}>
                    <Button
                      variant="default"
                      css={styles.next(isNewSummaryPending)}
                      onClick={() => summaryPageModel.nextStepNavigated()}
                    >
                      Perfect
                    </Button>
                    <Button
                      variant="default"
                      css={styles.base}
                      onClick={handleSummaryChange}
                    >
                      Not quite, I'd like to change this
                    </Button>
                  </div>
                )}
              </div>

              <div css={styles.note}>
                Our trip-builder might not be human, but it still makes mistakes. We
                expect a few hiccups along the way as it learns to perfect the art of
                travel planning and booking.
              </div>
            </div>
          )}
        </Fragment>
      )}
    </OnboardingLayout>
  )
}
