import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: {
    main: (isLoaderVisible: boolean) => css`
      display: flex;
      max-width: ${!isLoaderVisible && '528px'};

      ${Breakpoints.LG} {
        flex-direction: column-reverse;
        gap: 20px;
      }

      ${Breakpoints.SM} {
        padding: 0 20px;
        gap: 0;
      }
    `,

    title: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,

    content: (isLoaderVisible: boolean) => css`
      width: ${isLoaderVisible ? '50%' : '100%'};
      padding: ${isLoaderVisible ? '80px 120px' : '0'};

      ${Breakpoints.XL} {
        padding: ${isLoaderVisible ? '40px 48px' : '0'};
      }

      ${Breakpoints.LG} {
        width: 100%;
      }

      ${Breakpoints.SM} {
        padding: ${isLoaderVisible ? '20px' : '0'};
        border: ${isLoaderVisible ? `1px solid ${Colors.LIGHT_GREY_3}` : 'none'};
        border-radius: ${isLoaderVisible ? '12px' : '0'};
        box-shadow: ${isLoaderVisible ? '0px -4px 40px 0px rgba(43,43,43,0.1)' : 'none'};
      }
    `,

    inputs: css`
      display: grid;
      gap: 24px;
    `,
  },

  loader: {
    wrapper: css`
      width: 50%;
      border-left: 1px solid ${Colors.LIGHT_GREY_3};
      padding: 80px 110px;

      ${Breakpoints.XL} {
        padding: 40px 48px;
      }

      ${Breakpoints.LG} {
        width: 100%;
        border-left: none;
      }

      ${Breakpoints.SM} {
        padding: 20px 30px;
      }
    `,
  },

  title: css`
    color: ${Colors.DARK_1};
    font-family: ${Fonts.Joane};
    font-size: 32px;

    ${Breakpoints.SM} {
      font-size: 24px;
    }
  `,

  inputsContainer: css`
    display: grid;
    gap: 24px;
  `,

  input: css`
    padding: 12px 40px 12px 12px;
    font-size: 16px;
  `,

  loginLink: css`
    margin-top: 16px;
    cursor: pointer;
    text-align: start;
    letter-spacing: 0.18px;
    color: ${Colors.PRIMARY_GREEN};
    font-family: ${Fonts.Calibre};
    font-size: 16px;
    margin-bottom: 24px;
  `,

  textWrapper: css`
    margin-top: 18px;
  `,
  text: css`
    font-family: ${Fonts.Calibre};
    font-size: 18px;
    font-weight: 400;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  buttonWrapper: css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  `,

  divider: css`
    display: flex;
    position: relative;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: ${Colors.NEUTRAL_GREY};
    padding: 24px 0;

    span {
      position: relative;
      background-color: ${Colors.NEUTRAL_WHITE};
      z-index: 2;
      padding: 0 8px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${Colors.LIGHT_GREY_1};
    }
  `,

  errorContainer: css`
    margin: 10px 0;
  `,

  previousButton: css`
    margin: 0 30px;
    text-align: left;
    color: ${Colors.NEUTRAL_GREY};
  `,

  button: css`
    margin-top: 28px;
    padding: 20px 60px;
    width: 100%;
    height: 58px;
  `,

  resetButton: css`
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,

  return: {
    container: css`
      align-items: center;
      height: 32px;
      margin-bottom: 35px;

      ${Breakpoints.SM} {
        margin-bottom: 20px;
      }
    `,

    button: css`
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: transparent;
      border: 0;
      cursor: pointer;

      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};
    `,
  },

  infoText: css`
    margin-top: 8px;
    font-size: 14px;
    color: ${Colors.NEUTRAL_DARK};
  `,
}
