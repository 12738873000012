import { FC } from 'react'

export interface Props {
  color?: string
}

export const CircleCheckIcon: FC<Props> = ({ color = '#009F9D' }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="14" fill={color} />
      <path
        d="M18 11L12.5 16.5L10 14"
        stroke="#FDFDFD"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
