import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 20px;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};
    border: 1px solid ${Colors.NEUTRAL_SILVER};
  `,

  image: css`
    width: 100%;
    background-color: ${Colors.NEUTRAL_SILVER};
    height: 136px;
    object-position: center;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 16px;
  `,

  title: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  subtitle: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    color: ${Colors.NEUTRAL_GREY};
    margin-top: 5px;
  `,

  infoTile: css`
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: ${Colors.NEUTRAL_SILVER};
      margin-top: 16px;
    }
  `,

  item: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};

    :first-of-type {
      margin-top: 10px;
    }

    :not(:last-child) {
      margin-bottom: 10px;
    }
  `,

  tooltip: {
    container: css`
      z-index: 2;
      padding: 12px 16px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      opacity: 0.8;
      border-radius: 8px;
    `,

    title: css`
      margin-bottom: 12px;
      font-weight: 500;
    `,

    text: css`
      font-size: 16px;
      font-weight: 400;
      color: ${Colors.LIGHT_1};
    `,

    boldText: css`
      font-weight: 500;
    `,

    helper: css`
      margin-top: 16px;
      opacity: 0.75;
    `,
  },

  rates: {
    desktopTooltip: {
      icon: css`
        ${Breakpoints.MD} {
          display: none;
        }
      `,

      wrapper: css`
        display: block;

        ${Breakpoints.MD} {
          display: none;
        }
      `,
    },

    mobileTooltip: {
      wrapper: css`
        display: none;

        ${Breakpoints.MD} {
          display: flex;
          align-items: center;
          height: 100%;
          max-width: 340px;
        }
      `,

      top: css`
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        color: ${Colors.NEUTRAL_BLACK};
      `,

      cross: css`
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 5px;
      `,

      container: css`
        position: relative;
        background-color: ${Colors.LIGHT_1};
        max-width: 340px;
        padding: 16px !important;
        border-radius: 8px;
      `,

      button: (isPolicyDetailsVisible: boolean) => css`
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: ${Colors.NEUTRAL_DARK};

        span {
          display: flex;
          align-items: center;
          margin-left: 6px;
          svg {
            transform: rotate(${isPolicyDetailsVisible ? '180deg' : '0deg'});
            transition: transform 0.3s;
            margin-left: 4px;
          }
        }
      `,

      content: css`
        margin-top: 10px;
        font-size: 16px;
        color: ${Colors.NEUTRAL_DARK};
      `,
    },
  },

  room: {
    title: css`
      font-size: 16px;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.28px;
      display: flex;
      margin-top: 8px;
    `,

    board: css`
      display: flex;
      padding: 2px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: ${Colors.LIGHT_2};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.1;
      letter-spacing: 0.23px;
      margin-left: 8px;
      text-align: center;
    `,

    policy: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-top: 5px;
      svg {
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    `,
  },

  price: {
    container: (isPaymentStep?: boolean) => css`
      padding: 20px;
      border-radius: 10px;
      background: ${Colors.LIGHT_1};
      margin-top: ${isPaymentStep ? '0' : '16px'};
      border: 1px solid ${Colors.NEUTRAL_SILVER};
    `,

    list: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0;
      border-top: 1px solid ${Colors.LIGHT_GREY_2};
      border-bottom: 1px solid ${Colors.LIGHT_GREY_2};
      margin: 16px 0;
    `,

    item: {
      row: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `,

      title: css`
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
      `,

      value: css`
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
      `,

      subtitle: css`
        font-size: 14px;
        font-weight: 400;
        line-height: 19px; /* 135.714% */
        color: ${Colors.NEUTRAL_GREY};
        letter-spacing: 0.28px;
        margin-top: 4px;
      `,
    },

    total: {
      row: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `,

      title: css`
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.36px;
        color: ${Colors.NEUTRAL_BLACK};
      `,

      value: css`
        font-size: 24px;
        font-weight: 400;
        color: ${Colors.NEUTRAL_BLACK};
      `,
    },
  },
}
