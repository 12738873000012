import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  modal: {
    modal: css`
      display: flex;
      align-items: center;
    `,
  },

  container: css`
    background-color: #fff;
    padding: 24px 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    position: relative;

    ${Breakpoints.MD} {
      padding: 24px;
      padding-top: 48px;
    }
  `,

  crossIcon: css`
    position: absolute;
    top: 16px;
    right: 16px;

    width: 16px;
    height: 16px;

    cursor: pointer;
  `,

  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    text-align: center;
    font-size: 20px;
    font-weight: 400;

    max-width: 315px;
  `,

  textarea: css`
    min-width: 400px;

    width: 100%;
    padding: 12px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #d5d4dc;
    outline: none;
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 16px;
    resize: none;
    transition: 0.3s all ease-in-out;
    overflow: hidden;

    &:focus {
      border-color: ${Colors.PRIMARY_GREEN};
    }

    ${Breakpoints.MD} {
      min-width: auto;
      font-size: 18px;
    }
  `,

  button: css`
    width: 215px;
    height: 58px;

    ${Breakpoints.MD} {
      width: 100%;
    }
  `,
}
