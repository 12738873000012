import { css } from '@emotion/react'

export const styles = {
  button: css`
    width: 100%;
    height: 58px;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  `,
}
