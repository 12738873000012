import { useStore } from 'effector-react'
import { FC } from 'react'
import { navigationModel } from '~/entities/navigation'
import { destinationModel } from '~/entities/trip'
import { AppRoute } from '~/shared/config'
import { styles } from './styles'

interface Props {
  icon?: string
  title: string
  value?: string
  editable?: boolean
  faded?: boolean
  onClick?: () => void
  path?: string
}

export const SidebarInfoTile: FC<Props> = ({
  icon,
  editable,
  title,
  value,
  faded,
  onClick,
  path,
}) => {
  const destination = useStore(destinationModel.$selectedDestination)

  const editClickHandler = () => {
    if (onClick) {
      onClick()
    } else
      navigationModel.pathChanged(
        `${AppRoute.Destinations}/${destination?.destinationCode}${
          path ? `/${path}` : ''
        }`,
      )
  }
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <div css={styles.row}>
          <h1 css={[styles.text(), styles.title]}>{title}</h1>
          {editable && (
            <div css={styles.edit} onClick={() => editClickHandler()}>
              Edit
            </div>
          )}
        </div>
        {value && <p css={styles.text(faded)}>{value}</p>}
      </div>
    </div>
  )
}
