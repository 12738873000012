import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    width: max-content;

    display: flex;
    align-items: center;
    width: max-content;
    gap: 9px;

    border-radius: 100px;
    border: 1px solid ${Colors.NEUTRAL_SILVER};
    padding: 10px 16px;

    color: #000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;

    ${Breakpoints.SM} {
      font-size: 14px;
      padding: 10px 10px;
      letter-spacing: 0.28px;
    }
  `,
}
