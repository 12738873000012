import { sample } from 'effector'
import { createGate } from 'effector-react'
import { destinationsListModel } from '~/features/get-destinations-list'
import { paginationModelFactory } from '~/entities/pagination'
import { ITEMS_PER_PAGE } from './config'

export const Gate = createGate()
export const $$pagination = paginationModelFactory()

sample({
  clock: destinationsListModel.searchInitiated,
  target: $$pagination.stateReset,
})

sample({
  clock: [Gate.open, $$pagination.$offset, destinationsListModel.searchInitiated],
  source: $$pagination.$offset,
  fn: (offset) => ({ offset, limit: ITEMS_PER_PAGE }),
  target: destinationsListModel.initiated,
})
