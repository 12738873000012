import { TripBooking } from '@softcery/awayaway-apiclient'
import { createFactory } from '@withease/factories'
import { createEvent, createEffect, sample, restore } from 'effector'
import { reset } from 'patronum'
import { bridge } from '~/shared/lib/factory'
import { getBookings } from './api'

export type GetBookingsFactory = ReturnType<typeof getBookingsFactory>
export const getBookingsFactory = createFactory(() => {
  const initiatedGetBookings = createEvent()
  const getBookingsFx = createEffect(getBookings)

  const $loading = getBookingsFx.pending
  const resetState = createEvent()

  const updateError = createEvent<string | null>()
  const $error = restore(updateError, null)

  const updateBookedTrips = createEvent<TripBooking[] | null>()
  const $bookedTrips = restore<TripBooking[] | null>(updateBookedTrips, null)

  // initiate get bookings
  bridge(() => {
    sample({
      clock: initiatedGetBookings,
      target: getBookingsFx,
    })
  })

  // handle success
  bridge(() => {
    sample({
      clock: getBookingsFx.doneData,
      filter: (bookedTrips) => !!bookedTrips?.bookings,
      fn: (bookedTrips) => bookedTrips.bookings!,
      target: updateBookedTrips,
    })
  })

  // handle error
  bridge(() => {
    sample({
      clock: getBookingsFx.failData,
      fn: (error) => error.message,
      target: updateError,
    })
  })

  reset({
    clock: resetState,
    target: [$error, $bookedTrips],
  })
  return {
    $error,
    $bookedTrips,
    $loading,

    initiatedGetBookings,
    resetState,
    updateBookedTrips,
  }
})
