import { useStore } from 'effector-react'
import { FC } from 'react'
import Select from 'react-select'
import { childAgesOptions, tripInfoModel, tripLimits } from '~/entities/trip-info'
import { DropdownIndicator } from '~/shared/ui'
import { Option } from './option'
import { innerStyles, styles } from './styles'

export const SelectGuests: FC = () => {
  const rooms = useStore(tripInfoModel.$tripInfo)

  return (
    <div css={styles.container}>
      {rooms.map((room, index) => (
        <div key={index}>
          <h1 css={styles.title}>Room {index + 1}</h1>
          {index !== 0 && (
            <button css={styles.button} onClick={() => tripInfoModel.roomRemoved(index)}>
              Remove room
            </button>
          )}

          <div css={styles.options}>
            <Option
              label="Adults"
              description="12 years and older"
              value={room.adults.length}
              onMinus={() => tripInfoModel.adultRemoved(index)}
              onPlus={() => tripInfoModel.adultAdded(index)}
              minusDisabled={room.adults.length <= tripLimits.min.adults}
              plusDisabled={room.adults.length >= tripLimits.max.adults}
            />

            <Option
              label="Children"
              description="11 years and younger"
              value={room.children.length}
              onMinus={() => tripInfoModel.childRemoved(index)}
              onPlus={() => tripInfoModel.childAdded(index)}
              minusDisabled={!room.children.length}
              plusDisabled={room.children.length >= tripLimits.max.children}
            />
          </div>

          {!!room.children.length && (
            <div css={styles.ages.container}>
              <h2 css={styles.ages.title}>
                How old will any children be during the trip?
              </h2>

              <div css={styles.ages.dropdowns}>
                {room.children.map((child, childIndex) => (
                  <Select
                    key={childIndex}
                    components={{ DropdownIndicator }}
                    styles={innerStyles}
                    options={childAgesOptions}
                    value={childAgesOptions[child.age || 0]}
                    defaultValue={childAgesOptions[0]}
                    isSearchable={false}
                    onChange={(value) =>
                      tripInfoModel.ageAdded({
                        age: value?.value || 0,
                        roomIndex: index,
                        childIndex,
                      })
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      ))}

      <button
        css={styles.button}
        disabled={rooms.length >= 10}
        onClick={() => tripInfoModel.roomAdded()}
      >
        + Add another room
      </button>
    </div>
  )
}
