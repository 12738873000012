import { useForm } from 'effector-forms'
import { useGate, useStore, useUnit } from 'effector-react'
import React, { FC } from 'react'

import { isMobile } from 'react-device-detect'
import { summaryPageModel } from '~/pages/onboarding/summary'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { popupModel, PopupType } from '~/entities/popup'
import { destinationModel } from '~/entities/trip'
import { ArrowLeftIcon } from '~/shared/assets/icons/components'
import { Colors } from '~/shared/styles'
import { ButtonWithLoader, Error, InputWithLabel, PageLoader } from '~/shared/ui'
import { OAuthButton } from '../../oauth'
import { saveProfileModel } from '../model'
import { styles } from './styles'

export const SaveProfile: FC = () => {
  useGate(saveProfileModel.Gate)

  const { fields, errorText } = useForm(saveProfileModel.$$form)

  const inSaveProfileProgress = useStore(saveProfileModel.$inSaveProfileInProgress)
  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isLoadingMessagesPending = useStore(summaryPageModel.$isLoadingMessagesPending)
  const loadingMessages = useStore(summaryPageModel.$loadingMessages)
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)

  const isTripsLoading = useStore(destinationModel.$isTripsLoading)

  const isLoaderVisible = isTripsLoading || isNewSummaryPending

  const [error, isError] = useUnit([saveProfileModel.$error, saveProfileModel.$isError])

  const isGoogleAuthButtonHidden = isIframe && isMobile

  return (
    <div css={styles.container.main(isLoaderVisible)}>
      <div css={styles.container.content(isLoaderVisible)}>
        <div css={styles.return.container}>
          <button
            onClick={() => {
              onboardingSessionModel.statusReset()
              onboardingSessionModel.authBackClicked()
            }}
            css={styles.return.button}
          >
            <ArrowLeftIcon color={Colors.NEUTRAL_BLACK} width={24} height={24} />
            Back to quiz
          </button>
        </div>
        <h2 css={styles.title}>Let's save your profile</h2>
        <div
          css={styles.loginLink}
          onClick={() => popupModel.visiblePopupChanged(PopupType.Login)}
        >
          Already have an account? Login
        </div>
        <div css={styles.inputsContainer}>
          <InputWithLabel
            label="Name"
            type="text"
            name="name"
            placeholder="Enter your first name"
            extendStyle={{ input: styles.input }}
            value={fields.fullName.value}
            error={errorText('fullName')}
            onChange={(e) => fields.fullName.onChange(e.target.value)}
          />

          <InputWithLabel
            label="Email address"
            type="text"
            name="email"
            placeholder="Enter your email address"
            extendStyle={{ input: styles.input }}
            value={fields.email.value}
            error={errorText('email')}
            onChange={(e) => fields.email.onChange(e.target.value)}
          />
        </div>

        <p css={styles.infoText}>
          We'll send you well-curated trips and travel inspiration only, no spam.
        </p>

        <div css={styles.buttonWrapper}>
          <ButtonWithLoader
            css={styles.button}
            variant="default"
            onClick={() => saveProfileModel.initiated()}
            loading={inSaveProfileProgress}
          >
            Save
          </ButtonWithLoader>

          {!isGoogleAuthButtonHidden && (
            <>
              <div css={styles.divider}>
                <span>or</span>
              </div>

              <OAuthButton />
            </>
          )}
        </div>

        {isError && (
          <div css={styles.errorContainer}>
            <Error>{error}</Error>
          </div>
        )}
      </div>

      {isLoaderVisible && (
        <div css={styles.loader.wrapper}>
          <PageLoader
            loadingMessages={loadingMessages}
            isMessagesLoading={isLoadingMessagesPending}
            isNewSummaryPending={isNewSummaryPending}
          />
        </div>
      )}
    </div>
  )
}
