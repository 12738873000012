import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

const active = css`
  font-weight: 500;
  color: ${Colors.PRIMARY_GREEN};
  border-bottom: 2px solid #009f9d;
  cursor: default;
`

export const styles = {
  container: (isActive?: boolean) => css`
    max-width: 246px;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    letter-spacing: 2%;
    color: ${Colors.NEUTRAL_GREY};

    cursor: pointer;

    ${isActive && active}
  `,
}
