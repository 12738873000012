import { ResetPasswordRequestBody } from '@softcery/awayaway-apiclient'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { popupModel } from '~/entities/popup'
import { viewerModel } from '~/entities/viewer'
import { apiService, errorHandler, getErrorMessage } from '~/shared/api'

export const ForgotPasswordGate = createGate()
export const UpdatePasswordGate = createGate()

export const emailSent = createEvent<string>()
export const passwordUpdated = createEvent<ResetPasswordRequestBody>()

const sendEmailFx = createEffect(async (email: string) => {
  try {
    return await apiService().sendResetPasswordLink({ fields: { email } })
  } catch (res: any) {
    return errorHandler(res)
  }
})

const updatePasswordFx = createEffect(async (fields: ResetPasswordRequestBody) => {
  try {
    return await apiService().resetPassword({ fields })
  } catch (res: any) {
    return errorHandler(res, 'Failed to update password')
  }
})

export const $sentEmail = createStore('')
  .reset(ForgotPasswordGate.close)
  .on(sendEmailFx.done, (_, res) => res.params)

export const $sendEmailStatus = combine({
  loading: sendEmailFx.pending,
  error: restore(sendEmailFx.finally, null).map(getErrorMessage).reset(sendEmailFx),
})

export const $updatePasswordStatus = combine({
  loading: updatePasswordFx.pending,
  error: restore(updatePasswordFx.finally, null)
    .map(getErrorMessage)
    .reset(updatePasswordFx),
})

export const $isPasswordUpdated = createStore(false)

sample({
  clock: UpdatePasswordGate.open,
  target: popupModel.popupHidden,
})

sample({
  clock: UpdatePasswordGate.open,
  source: viewerModel.$isAuthorized,
  filter: Boolean,
  target: popupModel.popupHidden,
})

sample({
  clock: emailSent,
  target: sendEmailFx,
})

sample({
  clock: passwordUpdated,
  target: updatePasswordFx,
})

sample({
  clock: updatePasswordFx.done,
  fn: () => true,
  target: $isPasswordUpdated,
})

reset({
  clock: UpdatePasswordGate.close,
  target: $isPasswordUpdated,
})
