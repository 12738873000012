import { useGate, useStore } from 'effector-react'
import { FC, useCallback, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Header } from '~/widgets/header'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { infoPopupModel } from '~/features/trip-booking/info-popup'
import { InfoBookingPopup } from '~/entities/booking'
import { hotelsForTripModel } from '~/entities/hotel'
import { navigationModel } from '~/entities/navigation'
import { destinationModel } from '~/entities/trip'
import { crossErrorIcon } from '~/shared/assets/icons'
import { AppRoute } from '~/shared/config'
import { Button, NotificationPopup, WithHeader } from '~/shared/ui'
import { bookingModel } from '..'
import { STEPS } from '../config'
import { $currentStep } from '../model'
import { ContactDetailsPage } from './contact-details'
import { FlightsDetailsPage } from './flights/details'
import { PaymentPage } from './payment'
import { Steps } from './steps'
import { styles } from './styles'
import { SummaryPage } from './summary'

export const BookingPage: FC = () => {
  const { pathname } = useLocation()
  const { destinationCode, hotelCode } = useParams()
  const currentStep = useStore($currentStep)
  const isOpen = useStore(infoPopupModel.$isOpen)
  const destination = useStore(destinationModel.$selectedDestination)
  const bookingError = useStore(createBookingModel.$error)
  useGate(bookingModel.BookingGate, { destinationCode, hotelCode })

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  const onClose = useCallback(() => {
    navigationModel.pathChanged(
      `${AppRoute.Destinations}/${destination?.destinationCode}`,
    )
    hotelsForTripModel.resetAllState()

    bookingModel.$$model.resetError()
  }, [navigationModel, AppRoute, destination, bookingModel])

  return (
    <WithHeader
      extendStyles={styles.container(0)}
      header={
        <Header
          type="dark"
          onLogoClick={() => navigationModel.pathChanged(AppRoute.Destinations)}
        />
      }
    >
      <div css={styles.wrapper}>
        {currentStep !== STEPS.SUMMARY && (
          <div css={styles.aside}>
            <Steps />
          </div>
        )}

        <InfoBookingPopup
          onClose={() => {
            navigationModel.pathChanged(
              `${AppRoute.Destinations}/${destination?.destinationCode}`,
            )

            infoPopupModel.close()
          }}
          error="Unfortunately, your flight offers have expired. You should go back and select other flight."
          isOpen={isOpen}
          title="Flight offers have expired"
          buttonText="Choose another flight"
        />

        <NotificationPopup isOpen={!!bookingError} onClose={() => onClose()}>
          <div css={styles.popupContent.wrapper}>
            <div css={styles.popupContent.icon}>
              <img src={crossErrorIcon} alt="error-icon" />
            </div>
            <h2 css={styles.popupContent.title}>Booking failed</h2>
            <p css={styles.popupContent.desc}>{bookingError}</p>
            <Button css={styles.popupContent.button} onClick={() => onClose()}>
              Choose another hotel
            </Button>
          </div>
        </NotificationPopup>

        <Routes>
          <Route path="contacts" element={<ContactDetailsPage />} />

          <Route path="flights" element={<FlightsDetailsPage />} />

          <Route path="payment" element={<PaymentPage />} />

          <Route path="summary" element={<SummaryPage />} />

          <Route path="*" element={<Navigate to={currentStep} />} />
        </Routes>
      </div>
    </WithHeader>
  )
}
