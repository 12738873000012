import { createFactory } from '@withease/factories'
import { createEffect, createEvent, createStore, restore, sample } from 'effector'
import { debounce, reset } from 'patronum'
import { bridge } from '~/shared/lib/factory'
import { airportsMapper } from '../../lib'
import { Airport } from '../../types'
import { searchPlaces } from './/api'

const TIMEOUT_FOR_SEARCH_PLACES = 800
export const searchPlacesFactory = createFactory(() => {
  const searchPlacesFx = createEffect(searchPlaces)
  const updateError = createEvent<string>()
  const searchPlacesInitiated = createEvent<string>()
  const $places = createStore<Airport[]>([])

  const $error = restore(updateError, '')
  const $placesLoading = searchPlacesFx.pending

  debounce({
    source: searchPlacesInitiated,
    timeout: TIMEOUT_FOR_SEARCH_PLACES,
    target: searchPlacesFx,
  })

  //success handling
  bridge(() => {
    sample({
      clock: searchPlacesFx.doneData,
      fn: (res) => airportsMapper(res.places) || [],
      target: $places,
    })
  })

  // error handling
  bridge(() => {
    sample({
      clock: searchPlacesFx.failData,
      fn: (error) =>
        error?.message ? error.message : 'Something went wrong. Please try again later.',
      target: updateError,
    })
  })

  reset({
    clock: searchPlacesFx,
    target: $error,
  })

  return { $placesLoading, $error, $places, updateError, searchPlacesInitiated }
})
