import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css``,

  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,

  itemWrapper: (disabled?: boolean) => css`
    display: flex;
    gap: 8px;
    opacity: ${disabled ? 0.5 : 1};

    label {
      margin-bottom: 0;
      color: ${Colors.NEUTRAL_BLACK};
      cursor: pointer;
    }
  `,

  item: (disabled?: boolean) => css`
    background-color: white;
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding: 0;
    border-radius: 100%;
    border: 1px solid ${Colors.NEUTRAL_GREY};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `,

  indicator: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: relative;
    margin-left: -1px;
    margin-top: -1px;

    border-radius: 100%;
    background-color: ${Colors.PRIMARY_GREEN};

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: white;
    }
  `,
}
