import { yupResolver } from '@hookform/resolvers/yup'
import { useGate, useStore } from 'effector-react'
import { FC, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useSearchParams } from 'react-router-dom'
import { navigationModel } from '~/entities/navigation'
import { popupModel, PopupType } from '~/entities/popup'
import { viewerModel } from '~/entities/viewer'
import { AppRoute } from '~/shared/config'
import { ButtonWithLoader, Link, Title, Error, PasswordInput, Button } from '~/shared/ui'

import { resetPasswordModel } from '../..'
import { updatePasswordSchema } from '../../lib'
import { $updatePasswordStatus, passwordUpdated, UpdatePasswordGate } from '../../model'
import { styles } from './styles'

const initialValues = {
  password: '',
  confirmPassword: '',
}

export const UpdatePassword: FC = () => {
  useGate(UpdatePasswordGate)

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const isAuthorized = useStore(viewerModel.$isAuthorized)
  const { loading, error } = useStore($updatePasswordStatus)
  const isPasswordUpdated = useStore(resetPasswordModel.$isPasswordUpdated)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: {
      ...initialValues,
    },
  })

  if (!token) {
    return <Navigate to={AppRoute.Destinations} replace />
  }

  return (
    <div>
      <Title css={styles.title}>Create your new password</Title>

      {!isPasswordUpdated && (
        <div css={styles.content}>
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Enter your new password"
            extendStyle={{ wrapper: styles.input }}
            error={errors.password?.message}
            register={register}
          />

          <PasswordInput
            name="confirmPassword"
            label="Confirm password"
            placeholder="Confirm your new password"
            extendStyle={{ wrapper: styles.input }}
            error={errors.confirmPassword?.message}
            register={register}
          />

          {error && (
            <div css={styles.errorContainer}>
              <Error>{error}</Error>
            </div>
          )}

          <ButtonWithLoader
            css={styles.button}
            variant="default"
            onClick={handleSubmit(({ password }) => passwordUpdated({ password, token }))}
            loading={loading}
          >
            Update password
          </ButtonWithLoader>
          {!isAuthorized && <Link to="#">Back to log in</Link>}
        </div>
      )}
      {isPasswordUpdated && (
        <Fragment>
          <p css={styles.subtitle}>Your password has been successfully reset.</p>
          <Button
            css={styles.button}
            variant="default"
            onClick={() =>
              isAuthorized
                ? navigationModel.pathChanged(AppRoute.Destinations)
                : popupModel.visiblePopupChanged(PopupType.Login)
            }
          >
            {isAuthorized ? 'Back to application' : 'Back to log in'}
          </Button>
        </Fragment>
      )}
    </div>
  )
}
