import { useGate, useStore } from 'effector-react'
import { FC } from 'react'
import { bookingModel } from '~/pages/booking'
import { searchFlightsModel } from '~/features/search-flights/model'
import { OfferCard } from '~/features/search-flights/ui/offer-card'
import { ServicesForm } from '~/features/select-services'
import { selectFlightsServicesModel } from '~/features/select-services/model'
import { addGuestsInfoModel } from '~/features/trip-booking/add-guests-info'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { infoPopupModel } from '~/features/trip-booking/info-popup'
import { BookingSidebar } from '~/entities/booking'
import { hotelsForTripModel } from '~/entities/hotel'
import { navigationModel } from '~/entities/navigation'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { AppRoute } from '~/shared/config'
import { ButtonWithLoader } from '~/shared/ui'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'
import { flightDetailsPageModel } from '..'
import { styles } from './styles'

export const FlightsDetailsPage: FC = () => {
  useGate(bookingModel.FlightsStepGate)

  const checkIn = useStore(tripInfoModel.$checkIn)
  const checkOut = useStore(tripInfoModel.$checkOut)
  const selectedHotel = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )
  const selectedDestination = useStore(destinationModel.$selectedDestination)
  const childrenAmount = useStore(tripInfoModel.$childrenAmount)
  const adultsAmount = useStore(tripInfoModel.$adultsAmount)
  const selectedRoomsAmount = useStore(tripInfoModel.$roomsAmount)

  const isBookingCreating = useStore(createBookingModel.$isCreating)
  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)

  const selectedOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )

  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )

  const flightsType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )
  const guestsGroups = useStore(addGuestsInfoModel.$guestGroups)

  const currency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)
  const destination = useStore(destinationModel.$selectedDestination)

  const hotelImage = selectedHotel?.images?.[0]
  const additionalServices = useStore(
    selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.$additionalServices,
  )

  if (!guestsGroups || !selectedOffer) {
    navigationModel.pathChanged(
      `${AppRoute.Destinations}/${destination?.destinationCode}`,
    )
    return null
  }

  const guests = Object.values(guestsGroups).flat()

  const onEndTimeHandler = () => {
    infoPopupModel.open()
    hotelsForTripModel.resetAllState()
  }

  return (
    <WithSidebar
      main={
        <div css={styles.wrapper}>
          <div css={styles.card}>
            <OfferCard offer={selectedOffer} showFooter={false} />
          </div>

          <div css={styles.services.wrapper}>
            <ServicesForm guests={guests} selectedOffer={selectedOffer!} />
          </div>

          <ButtonWithLoader
            onClick={() => flightDetailsPageModel.nextPageInitiated()}
            loading={isBookingCreating}
          >
            Proceed to payment
          </ButtonWithLoader>
        </div>
      }
      sidebarContent={
        <div css={styles.side}>
          <BookingSidebar
            checkIn={checkIn}
            checkOut={checkOut}
            hotelImage={hotelImage}
            hotelName={selectedHotel?.name}
            destinationName={selectedDestination?.name}
            guests={{
              adults: adultsAmount,
              children: childrenAmount,
              rooms: selectedRoomsAmount,
            }}
            selectedRooms={selectedRooms}
            currency={currency}
            flightOffer={selectedOffer}
            flightType={flightsType}
            isFlightIncluded={isFlightsIncluded}
            onEndTime={onEndTimeHandler}
            additionalServices={additionalServices}
          />
        </div>
      }
      position="right"
    />
  )
}
