import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  layout: {
    wrapper: css`
      min-height: 200px;
      padding: 40px;
      border-radius: 10px;
      background: ${Colors.LIGHT_1};
    `,

    container: css`
      max-width: 597px;
    `,

    tile: css`
      display: flex;
      flex-direction: column;
      gap: 28px;
    `,

    rowContainer: css`
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-top: 4px;
    `,

    actionsContainer: css`
      display: flex;
      gap: 24px;
      margin-top: 40px;
    `,
  },

  title: css`
    font-family: Calibre;
    font-weight: 500;
    font-size: 20px;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  divider: css`
    width: 100%;
    height: 1px;
    background-color: ${Colors.NEUTRAL_SILVER};
    margin: 42px 0 39px 0;
  `,

  input: {
    container: css`
      display: flex;
      align-items: center;
    `,

    wrapper: css`
      width: 100%;
      font-family: Calibre;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.2px;
    `,

    text: css`
      padding: 12px;
      width: 100%;
      font-family: Calibre;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: ${Colors.NEUTRAL_BLACK};
      border: 1px solid #d5d4dc;
      :placeholder {
        color: ${Colors.NEUTRAL_GREY};
      }
    `,

    checkbox: css`
      appearance: none;
      width: 18px;
      height: 18px;
      border: 1px solid #009f9d;
      border-radius: 4px;
      margin-right: 8px;
      cursor: pointer;
      &:checked {
        background: #009f9d;
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.00049 4.46154L3.99714 7.15385L10.8466 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
      }
    `,
  },

  statusBlock: (isError: boolean) => css`
    width: 100%;
    padding: 13px 16px;
    font-family: ${Fonts.Calibre};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    border-radius: 4px;
    background-color: ${isError ? '#f100001f' : 'rgba(36, 195, 119, 0.11)'};
    color: ${isError ? Colors.ERROR : '#006937'};
    margin-bottom: 26px;
  `,
}
