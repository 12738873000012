import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 732px;
    width: 100%;
    padding-bottom: 130px;
  `,

  container: css`
    width: 100%;
    margin: 0 auto 30px;
  `,

  headingWrapper: css`
    margin: 36px auto 60px;
    max-width: 675px;
    text-align: center;

    ${Breakpoints.SM} {
      margin: 24px 0 32px;
    }

    p {
      ${Breakpoints.SM} {
        font-size: 16px;
        line-height: 19px;
      }
    }
  `,

  headingTitle: css`
    text-align: center;
    font-weight: 300;

    ${Breakpoints.SM} {
      font-size: 24px;
      font-weight: 400;
    }
  `,

  skip: {
    button: css`
      margin: 0;
      padding: 0;
      color: ${Colors.ACCENT_1};
      font-size: 18px;
      &:hover {
        color: ${Colors.ACCENT_1};
      }
    `,
  },
}
