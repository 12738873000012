import { CreateOrder } from '@duffel/api/types'
import { DuffelAncillaries } from '@duffel/components'
import { OnPayloadReadyMetadata } from '@duffel/components/types/DuffelAncillariesProps'
import { FlightOffer } from '@softcery/awayaway-apiclient'
import { FC, useEffect, useRef } from 'react'

import {
  useClickedConfirmForSeats,
  useGetTypeFromButtonService,
} from '~/features/select-services/hooks/use-get-type-from-button-service'
import { convertToServiceAddOptionObj } from '~/features/select-services/lib'
import { Guest } from '~/features/trip-booking/add-guests-info'

import { selectFlightsServicesModel } from '..'
import { convertGuestsToDuffelPassengers } from '../lib/convert-guests-to-duffel-passengers'
import { styles } from './styles'

interface Props {
  selectedOffer: FlightOffer
  guests: Guest[]
}

export const ServicesForm: FC<Props> = ({ guests, selectedOffer }) => {
  const mainElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mainElementRef?.current) {
      selectFlightsServicesModel.$$manageUiForServicesOptionsFactory.initialed()
    }
  }, [mainElementRef?.current])

  useGetTypeFromButtonService(
    (type) =>
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.updateClickedButtonTypeOption(
        type,
      ),
    () => {
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.clickedForSelectSeat(
        false,
      )
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.clickedConfirmForSeats(
        false,
      )
    },
  )
  useClickedConfirmForSeats(
    (clicked) => {
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.clickedConfirmForSeats(
        clicked,
      )
    },
    (select) => {
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.clickedForSelectSeat(
        select,
      )
    },
  )

  const onPayloadReady = (payload: CreateOrder, metadata: OnPayloadReadyMetadata) => {
    const addOptionObj = convertToServiceAddOptionObj(metadata)
    const services = payload?.services?.map((service) => ({
      id: service.id,
      quantity: service.quantity,
    }))

    if (addOptionObj) {
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.changeAdditionalServices(
        addOptionObj,
      )
    }

    if (services) {
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.servicesSelected(
        services,
      )
    }
  }

  return (
    <div css={styles.wrapper} ref={mainElementRef} id="flights-service-form">
      <DuffelAncillaries
        offer_id={selectedOffer!.flightId!}
        client_key={selectedOffer!.flightClientKey!}
        services={['bags', 'seats']}
        passengers={convertGuestsToDuffelPassengers(guests, selectedOffer!.passengers!)}
        styles={{
          accentColor: '2, 159, 157',
        }}
        onPayloadReady={onPayloadReady}
      />
    </div>
  )
}
