import { css } from '@emotion/react'
import { Breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
    justify-content: center;
    padding-bottom: 20px;
    overflow: auto;

    ${Breakpoints.MD} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${Breakpoints.SM} {
      grid-gap: 15px;
      padding-bottom: 100px;
    }
  `,
}
