import { SerializedStyles } from '@emotion/react'
import { FC, ReactChild, useRef } from 'react'
import Carousel, { CarouselProps, CarouselInternalState } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { chevronLeftIcon, chevronRightIcon } from '~/shared/assets/icons/grey'
import { useComponentWidth } from '~/shared/lib/use-component-width'
import { Dimensions } from '~/shared/styles'

import { CustomSlider } from './custom-slider/custom-slider'
import { styles } from './styles'
import './styles.css'
import { usePartialVisibilityGutter } from './use-partial-visibility-gutter'

const ArrowPrev = ({
  onClick,
  extendStyle,
}: {
  onClick?: () => void
  extendStyle?: SerializedStyles
}) => {
  return (
    <div onClick={onClick} css={[styles.arrow.base, styles.arrow.prev, extendStyle]}>
      <img src={chevronLeftIcon} />
    </div>
  )
}

const ArrowNext = ({
  onClick,
  extendStyle,
}: {
  onClick?: () => void
  extendStyle?: SerializedStyles
}) => {
  return (
    <div onClick={onClick} css={[styles.arrow.base, styles.arrow.next, extendStyle]}>
      <img src={chevronRightIcon} />
    </div>
  )
}

interface Props extends Omit<CarouselProps, 'responsive'> {
  responsiveItemsCount: [number, number, number] // [mobile, tablet, desktop]
  customItemClass?: string
  extendStyles?: {
    arrows?: SerializedStyles
  }
  withSlider?: boolean
  usePartialVisibility?: boolean
  slideWidth?: number
}

export const ImageCarousel: FC<Props> = ({
  children,
  responsiveItemsCount,
  customItemClass,
  withSlider,
  extendStyles,
  usePartialVisibility,
  slideWidth = 475,

  ...props
}) => {
  const ref = useRef<Carousel>(null)

  const { width } = useComponentWidth(ref.current?.containerRef)
  const { partialVisibilityGutter } = usePartialVisibilityGutter({
    width,
    slideWidth: slideWidth,
    itemsToShow: responsiveItemsCount,
  })

  const updateCarouselState = (props: Partial<CarouselInternalState>) => {
    ref.current?.setState(props)
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: Dimensions.Lg },
      items: responsiveItemsCount[2],
      partialVisibilityGutter: usePartialVisibility ? partialVisibilityGutter[2] : 0,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: Dimensions.Lg, min: Dimensions.Md },
      items: responsiveItemsCount[1],
      partialVisibilityGutter: usePartialVisibility ? partialVisibilityGutter[1] : 0,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: Dimensions.Md, min: 0 },
      items: responsiveItemsCount[0],
      partialVisibilityGutter: usePartialVisibility ? partialVisibilityGutter[0] : 0,
    },
  }

  return (
    <Carousel
      partialVisible
      ref={ref}
      itemClass={customItemClass || 'carousel-item'}
      draggable={false}
      autoPlay={false}
      renderButtonGroupOutside={true}
      customButtonGroup={
        withSlider ? <CustomSlider updateCarousel={updateCarouselState} /> : undefined
      }
      dotListClass="custom-dot-list-style"
      shouldResetAutoplay={false}
      customLeftArrow={<ArrowPrev extendStyle={extendStyles?.arrows} />}
      customRightArrow={<ArrowNext extendStyle={extendStyles?.arrows} />}
      responsive={responsive}
      {...props}
    >
      {children as ReactChild[]}
    </Carousel>
  )
}
