import { User as Viewer } from '@softcery/awayaway-apiclient'
import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset as resetStores } from 'patronum'
import { LocalStorageKeys } from '~/shared/config/localstorage'

export const updated = createEvent<Partial<Viewer>>()
export const reset = createEvent()
export const accessTokenUpdated = createEvent<string>()
export const refreshTokenUpdated = createEvent<string>()
export const referralCodeUpdated = createEvent<string>()
export const referralCodeReset = createEvent()

export const $accessToken = createStore('')
persist({ store: $accessToken, key: LocalStorageKeys.Token })

export const $refreshToken = createStore('')
persist({ store: $refreshToken, key: LocalStorageKeys.RefreshToken })

export const $isAuthorized = $accessToken.map(Boolean)

export const $viewer = createStore<Viewer>({
  id: 0,
  email: '',
  fullName: '',
  hmacSecret: '',
})
persist({ store: $viewer, key: LocalStorageKeys.Viewer })

export const $referralCode = createStore('')
persist({ store: $referralCode, key: LocalStorageKeys.ReferralCode })

sample({
  clock: updated,
  source: $viewer,
  fn: (viewer, newViewerFields) => ({ ...viewer, ...newViewerFields }),
  target: $viewer,
})

sample({
  clock: referralCodeUpdated,
  target: $referralCode,
})

sample({
  clock: accessTokenUpdated,
  target: $accessToken,
})

sample({
  clock: refreshTokenUpdated,
  target: $refreshToken,
})

resetStores({
  clock: referralCodeReset,
  target: $referralCode,
})

resetStores({
  clock: reset,
  target: [$viewer, $accessToken, $refreshToken],
})
