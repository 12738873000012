import { SerializedStyles } from '@emotion/utils'
import { FC, InputHTMLAttributes } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { styles } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  error: boolean
  register?: UseFormRegister<any>

  inputRef?: any
  extendStyle?: SerializedStyles
}

export const TextInput: FC<Props> = ({
  name,
  error,
  register,
  extendStyle,
  inputRef,
  ...props
}) => {
  if (inputRef) {
    return (
      <input
        css={[styles.input, error && styles.invalid, extendStyle]}
        ref={inputRef}
        {...props}
      />
    )
  }

  if (register) {
    return (
      <input
        css={[styles.input, error && styles.invalid, extendStyle]}
        {...register(name)}
        {...props}
      />
    )
  }
  return <input css={[styles.input, error && styles.invalid, extendStyle]} {...props} />
}
