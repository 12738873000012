import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  step: (isActive: boolean) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    color: ${isActive ? Colors.PRIMARY_GREEN : Colors.NEUTRAL_STONE};
    min-width: fit-content;
    background: transparent;
    border: 0;
    padding: 0;

    cursor: pointer;

    :disabled {
      cursor: default;
      color: ${isActive ? Colors.PRIMARY_GREEN : `${Colors.NEUTRAL_STONE}99`};
    }

    ${Breakpoints.SM} {
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.28px;
      gap: 4px;
    }
  `,

  stepDone: css`
    color: ${Colors.PRIMARY_GREEN} !important;
  `,

  item: {
    num: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: ${Colors.LIGHT_GREY_1};
      color: #fff;

      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.3px;
    `,
  },

  line: css`
    height: 1px;
    width: 100%;
    background-color: ${Colors.LIGHT_GREY_1};
    margin-left: 5px;

    ${Breakpoints.SM} {
      display: none;
    }
  `,

  lineDone: css`
    background-color: ${Colors.PRIMARY_GREEN};
  `,
}
