import { css } from '@emotion/react'
import { arrowLeftIcon, arrowRightIcon } from '~/shared/assets/icons/grey'
import {
  arrowLeft as arrowLeftPrimary,
  arrowRight as arrowRightPrimary,
} from '~/shared/assets/icons/primary'
import { Colors } from '~/shared/styles'

const prevArrow = css`
  left: -42px;
`

const nextArrow = css`
  right: -42px;
`

export const styles = {
  container: (isArrowRight?: boolean, isDisabled?: boolean) => css`
    /* z-index: 99999; */
    position: absolute;

    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${isDisabled ? Colors.NEUTRAL_STONE : Colors.PRIMARY_GREEN};
    border-radius: 100%;
    background-color: transparent !important;

    background-image: url(${isArrowRight
      ? isDisabled
        ? arrowRightIcon
        : arrowRightPrimary
      : isDisabled
      ? arrowLeftIcon
      : arrowLeftPrimary});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    cursor: ${isDisabled ? 'default' : 'pointer'};

    ${isArrowRight ? nextArrow : prevArrow}
  `,
}
