import { GroupedRateDto } from '@softcery/awayaway-nodejs-api-client'
import { FC, ReactNode } from 'react'
import {
  RadioButtonIcon,
  SelectedRadioButtonIcon,
} from '~/shared/assets/icons/components'
import { styles } from './styles'

interface Props {
  rate: GroupedRateDto | number
  title: string
  content: ReactNode
  isSelected: boolean

  onSelect: (rate: any) => void
}

export const Rate: FC<Props> = ({ rate, isSelected, onSelect, title, content }) => {
  return (
    <button
      onClick={() => onSelect(rate!)}
      disabled={isSelected}
      css={styles.container.base}
    >
      <div css={styles.rate.container}>
        <div css={styles.rate.meta}>
          {isSelected ? <SelectedRadioButtonIcon /> : <RadioButtonIcon />}
          <div css={styles.rate.name}>{title}</div>
          {content}
        </div>
      </div>
    </button>
  )
}
