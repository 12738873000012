import { useStore } from 'effector-react'
import { FC, useEffect } from 'react'

import { searchFlightsModel } from '~/features/search-flights/model'
import { selectFlightsServicesModel } from '~/features/select-services'
import { AdditionalDetailsForm } from '~/features/trip-booking/add-additional-details'
import { ContactsForm } from '~/features/trip-booking/add-contact-info'
import { GuestDetailsForm } from '~/features/trip-booking/add-guests-info'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { infoPopupModel } from '~/features/trip-booking/info-popup'
import { BookingSidebar } from '~/entities/booking'
import { hotelsForTripModel } from '~/entities/hotel'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { ButtonWithLoader, Error } from '~/shared/ui'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'

import { contactDetailsPageModel } from '..'
import { styles } from './styles'

export const ContactDetailsPage: FC = () => {
  const checkIn = useStore(tripInfoModel.$checkIn)
  const checkOut = useStore(tripInfoModel.$checkOut)
  const selectedHotel = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )
  const selectedDestination = useStore(destinationModel.$selectedDestination)
  const childrenAmount = useStore(tripInfoModel.$childrenAmount)
  const adultsAmount = useStore(tripInfoModel.$adultsAmount)
  const selectedRoomsAmount = useStore(tripInfoModel.$roomsAmount)
  const contactDetailsHasValidationError = useStore(
    contactDetailsPageModel.$contactDetailsHasValidationError,
  )

  const isBookingCreating = useStore(createBookingModel.$isCreating)

  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)

  const currency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)

  const additionalServices = useStore(
    selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.$additionalServices,
  )
  const hotelImage = selectedHotel?.images?.[0]

  const selectedFlighsOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )
  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )
  const flightsType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )

  useEffect(() => {
    contactDetailsPageModel.pageOpened()
  }, [])

  const onEndTimeHandler = () => {
    infoPopupModel.open()
    hotelsForTripModel.resetAllState()
  }

  return (
    <WithSidebar
      main={
        <div css={styles.wrapper}>
          <ContactsForm />
          <GuestDetailsForm />
          <AdditionalDetailsForm />

          {contactDetailsHasValidationError && (
            <Error extendStyles={styles.error}>
              Please ensure all contact details are filled out correctly
            </Error>
          )}

          <ButtonWithLoader
            loading={isBookingCreating}
            css={styles.button}
            variant="default"
            onClick={() => contactDetailsPageModel.nextPageInitiated()}
          >
            {isFlightsIncluded ? 'Continue to flights' : 'Continue to Payment'}
          </ButtonWithLoader>
        </div>
      }
      sidebarContent={
        <div css={styles.side}>
          <BookingSidebar
            checkIn={checkIn}
            checkOut={checkOut}
            hotelImage={hotelImage}
            hotelName={selectedHotel?.name}
            destinationName={selectedDestination?.name}
            guests={{
              adults: adultsAmount,
              children: childrenAmount,
              rooms: selectedRoomsAmount,
            }}
            selectedRooms={selectedRooms}
            currency={currency}
            flightOffer={selectedFlighsOffer}
            flightType={flightsType}
            isFlightIncluded={isFlightsIncluded}
            onEndTime={onEndTimeHandler}
            additionalServices={additionalServices}
          />
        </div>
      }
      extendsStyles={{
        sidebar: styles.sidebar,
      }}
      position="right"
    />
  )
}
