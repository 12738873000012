import { ChangeUserRequestBody } from '@softcery/awayaway-apiclient'
import { sample, createEvent, createStore, createEffect } from 'effector'
import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'
import { condition, reset } from 'patronum'
import { viewerModel } from '~/entities/viewer'
import { apiService, apiErrorHandler } from '~/shared/api'

import { fileApiUploader } from '~/shared/api/file-upload'
import { rules } from '~/shared/lib/validation'
import { fileUploadFactory } from '~/shared/ui/organisms/file-uploader'

import { trimStringsInObject } from '../lib'

export const Gate = createGate()

export const $$fileUploadModel = fileUploadFactory()
export const $$fileApiUploaderModel = fileApiUploader()

export const submitInitiated = createEvent()
export const uploadFileToApiInitiated = createEvent()
export const updateUserInitiated = createEvent()
export const marketingEmailsToggled = createEvent<boolean>()
export const marketingEmailsAllowInitiated = createEvent()

export const updateUserInfoFx = createEffect(async (fields: ChangeUserRequestBody) => {
  try {
    const { user } = await apiService().changeUser({ fields })
    return user!
  } catch (error) {
    return apiErrorHandler(error)
  }
})

export const $$personalInfoForm = createForm({
  fields: {
    fullName: {
      init: '',
      rules: [rules.fullName()],
    },
    notifications: {
      init: false,
    },
  },
  validateOn: ['submit'],
})

export const $error = createStore('')
export const $successMessage = createStore('')
export const $isFormContainsChanges = createStore(false)

//allows to refresh avatar when image updates but link to s3 remains the same
export const $avatar = viewerModel.$viewer.map(
  (viewer) => viewer.settings?.avatarImageURI + '?' + new Date().getTime(),
)

sample({
  clock: Gate.open,
  source: { user: viewerModel.$viewer },
  fn: ({ user }) => ({
    fullName: user.fullName,
    notifications: user.settings?.allowSendMarketingEmails,
  }),
  target: $$personalInfoForm.setForm,
})

sample({
  source: { files: $$fileUploadModel.$files, isTouched: $$personalInfoForm.$touched },
  fn: ({ files, isTouched }) => files.length > 0 || isTouched,
  target: $isFormContainsChanges,
})

sample({
  clock: submitInitiated,
  target: $$personalInfoForm.validate,
})

condition({
  source: sample({
    clock: $$personalInfoForm.formValidated,
    source: $$fileUploadModel.$files,
  }),
  if: (files) => !!files.length,
  then: uploadFileToApiInitiated,
  else: updateUserInitiated,
})

//if no files uploaded
sample({
  clock: updateUserInitiated,
  source: $$personalInfoForm.$values.map(trimStringsInObject),
  fn: (formFields) => ({
    ...formFields,
    settings: { allowSendMarketingEmails: formFields.notifications },
  }),
  target: updateUserInfoFx,
})

//with uploaded new files
sample({
  clock: uploadFileToApiInitiated,
  source: $$fileUploadModel.$files,
  fn: (files) => files[0],
  target: $$fileApiUploaderModel.uploadInitiated,
})

sample({
  clock: $$fileApiUploaderModel.fileUploaded,
  source: $$personalInfoForm.$values.map(trimStringsInObject),
  fn: (formFields, avatarLink) => ({
    ...formFields,
    settings: {
      avatarImageURI: avatarLink,
      allowSendMarketingEmails: formFields.notifications,
    },
  }),
  target: updateUserInfoFx,
})

sample({
  clock: $$fileApiUploaderModel.$error,
  target: $error,
})

//user update handle
sample({
  clock: updateUserInfoFx.done,
  fn: () => 'Changes have been saved',
  target: $successMessage,
})

sample({
  clock: updateUserInfoFx.doneData,
  target: viewerModel.updated,
})

sample({
  clock: updateUserInfoFx.failData,
  fn: (err) => err.message,
  target: $error,
})

//reset logic

sample({
  clock: [Gate.close, updateUserInfoFx.done],
  target: $$personalInfoForm.resetTouched,
})

reset({
  clock: updateUserInfoFx.done,
  target: $$fileUploadModel.$files,
})

reset({
  clock: [updateUserInitiated, uploadFileToApiInitiated],
  target: [$error, $successMessage],
})

reset({
  clock: Gate.close,
  target: [$error, $successMessage, $$fileUploadModel.$files],
})
