import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

const active = css`
  color: ${Colors.PRIMARY_GREEN};
  border: 2px solid ${Colors.PRIMARY_GREEN};
  font-weight: 500;
  cursor: default;
`

const disabled = css`
  opacity: 0.5;
  cursor: not-allowed;
`

export const styles = {
  container: (isActive?: boolean, isDisabled?: boolean) => css`
    display: inline-block;

    padding: 6px 12px;

    border-radius: 999px;
    border: 1px solid ${Colors.NEUTRAL_SILVER};

    font-size: 14px;
    line-height: 16.62px;
    letter-spacing: 2%;
    color: #000000;

    cursor: pointer;
    user-select: none;

    ${isActive && active}

    ${isDisabled && disabled}
  `,
}
