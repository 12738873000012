import { sample } from 'effector'
import { analyticsModel } from '~/shared/api/analytics'
import { updateUserInfoFx } from './'

sample({
  clock: updateUserInfoFx.doneData,
  fn: ({ email, fullName, settings }) => ({
    id: email!,
    properties: {
      $name: fullName,
      $email: email,
      full_name: fullName,
      ...(settings?.avatarImageURI && {
        avatar_image_uri: settings.avatarImageURI,
      }),
    },
  }),
  target: analyticsModel.identify,
})
