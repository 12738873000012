import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 40px;
    border-radius: 10px;
    background-color: ${Colors.LIGHT_1};
  `,

  title: css`
    font-family: ${Fonts.Calibre};
    font-weight: 500;
    font-size: 20px;
    color: ${Colors.NEUTRAL_BLACK};
    margin-bottom: 10px;
  `,

  subtitle: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 20px;
  `,

  bold: css`
    font-weight: 500;
  `,

  error: css`
    margin-top: 20px;
  `,
}
