import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
    height: 100%;
    min-width: 400px;

    background-color: ${Colors.NEUTRAL_WHITE};
    gap: 12px;
    padding: 8px;
    border-radius: 12px;

    ${Breakpoints.LG} {
      min-width: unset;
      width: 100%;
      padding: 0;
    }
  `,

  container: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,

  icon: css`
    width: 24px;
    height: 24px;

    svg {
      ${Breakpoints.MD} {
        width: 18px !important;
        height: 18px !important;
      }
    }

    ${Breakpoints.MD} {
      width: 18px;
      height: 18px;
    }
  `,

  title: css`
    color: #000;
    font-size: 18px;
    font-weight: 400;

    ${Breakpoints.MD} {
      font-size: 16px;
    }
  `,

  message: css`
    color: ${Colors.NEUTRAL_DARK};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.36px;

    ${Breakpoints.MD} {
      font-size: 14px;
    }
  `,
}
