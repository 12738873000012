import { FC } from 'react'

import { styles } from './styles'

interface Props {
  current: number
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next'
  isCurrent: boolean
}

export const PaginationItem: FC<Props> = ({ current, type, isCurrent }) => {
  if (type === 'prev' || type === 'next') {
    return null
  }
  if (type === 'jump-prev' || type === 'jump-next') {
    return <div css={[styles.base, styles.jump]}>. . .</div>
  }

  return (
    <div css={[styles.base, styles.page, isCurrent && styles.currentPage]}>{current}</div>
  )
}
