import {
  init,
  browserTracingIntegration,
  replayIntegration,
  httpClientIntegration,
  captureConsoleIntegration,
} from '@sentry/react'
import { useEffect } from 'react'
import { Routing } from '~/pages'
import { appStarted, appStopped } from '~/shared/config/init'
import { worker } from '~/shared/worker'
import { withHocs } from './hocs'

import './model'

init({
  environment: process.env.SENTRY_ENV,
  dsn: 'https://1bc7e25065eac15464d1f4e89d5f6162@o4507067570257920.ingest.us.sentry.io/4507067802189824',
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    httpClientIntegration({
      failedRequestStatusCodes: [[500, 599]],
    }),
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const App = () => {
  //must trigger before routes rendered
  appStarted()

  useEffect(() => {
    if (worker) {
      worker.port.start()
    } else {
      console.log('Your browser does not support SharedWorker.')
    }

    return () => appStopped()
  }, [])

  return <Routing />
}

export default withHocs(App)
