import { createEffect, createEvent, createStore, sample } from 'effector'
import { reset } from 'patronum'
import { apiService } from './api'

type FileUrl = string

export const fileApiUploader = () => {
  const uploadInitiated = createEvent<File>()
  const fileUploaded = createEvent<FileUrl>()
  const $error = createStore('')

  const uploadFileFx = createEffect(async (file: File) => {
    try {
      const { signedLink } = await apiService().getSignedLink({
        mimeType: file.type,
      })
      if (!signedLink) throw new Error('File uploading error occured')

      const response = await fetch(signedLink, {
        method: 'PUT',
        body: file,
      })

      if (!response.ok) {
        throw new Error('File uploading error occured')
      }

      const resultUrl = response.url.split('?', 1)[0]
      return resultUrl
    } catch (error) {
      throw new Error('File uploading error occured')
    }
  })

  sample({
    clock: uploadInitiated,
    target: uploadFileFx,
  })

  sample({
    clock: uploadFileFx.doneData,
    target: fileUploaded,
  })

  sample({
    clock: uploadFileFx.failData,
    fn: (error) => error.message,
    target: $error,
  })

  reset({
    clock: uploadFileFx,
    target: $error,
  })

  return {
    uploadInitiated,
    fileUploaded,
    $error,
  }
}
