import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { resetPasswordModel } from '~/features/user/reset-password'

export const SecurityPageGate = createGate()

reset({
  clock: SecurityPageGate.close,
  target: resetPasswordModel.$sendEmailStatus,
})
