import { sample } from 'effector'

import { viewerModel } from '~/entities/viewer'
import { analyticsModel } from '~/shared/api/analytics'
import { googleAnalytics } from '~/shared/api/analytics/google'
import { hotjarAnalytics } from '~/shared/api/analytics/hotjar'
import { mixpanelAnalytics } from '~/shared/api/analytics/mixpanel'
import { appStarted } from '~/shared/config/init'

//init analytics
sample({
  clock: appStarted,
  filter: () => Boolean(process.env.AA_MIXPANEL_TOKEN),
  fn: () => ({
    analytics: mixpanelAnalytics,
    key: process.env.AA_MIXPANEL_TOKEN,
  }),
  target: analyticsModel.init,
})

sample({
  clock: appStarted,
  filter: () => Boolean(process.env.AA_HOTJAR_ID),
  fn: () => ({
    analytics: hotjarAnalytics,
    key: process.env.AA_HOTJAR_ID,
  }),
  target: analyticsModel.init,
})

sample({
  clock: appStarted,
  filter: () => Boolean(process.env.AA_GTAG_ID),
  fn: () => ({
    analytics: googleAnalytics,
  }),
  target: analyticsModel.init,
})

//identify user
sample({
  clock: appStarted,
  source: viewerModel.$viewer,
  filter: (viewer) => Boolean(viewer.email),
  fn: (viewer) => ({ id: String(viewer.email) }),
  target: analyticsModel.identify,
})

//track initial page view
sample({
  clock: appStarted,
  fn: () => ({
    properties: { page_name: window.location.pathname },
  }),
  target: analyticsModel.trackPage,
})
