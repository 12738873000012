import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 20px;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};
  `,

  image: css`
    width: 100%;
    background-color: ${Colors.NEUTRAL_SILVER};
    height: 136px;
    object-position: center;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  `,

  title: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.NEUTRAL_BLACK};
    margin-bottom: 3px;
  `,

  subtitle: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    color: ${Colors.NEUTRAL_GREY};
    margin-top: 5px;
  `,

  infoTile: css`
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: ${Colors.NEUTRAL_SILVER};
      margin-top: 20px;
    }
  `,

  item: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};

    margin-left: 32px;

    :first-of-type {
      margin-top: 10px;
    }

    :not(:last-child) {
      margin-bottom: 10px;
    }
  `,
}
