import { css } from '@emotion/react'
import { StylesConfig } from 'react-select'
import { Colors } from '~/shared/styles'
import { SelectOption } from '../../types'

export const styles = {
  container: css``,

  label: css`
    display: block;

    font-size: 16px;
    line-height: 20px;
    color: ${Colors.NEUTRAL_BLACK};

    margin-bottom: 8px;
  `,
}

export const selectStyles: StylesConfig<SelectOption, false> = {
  control: (provided, state) => ({
    ...provided,

    width: 142,
    height: 36,

    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '2%',
    color: Colors.NEUTRAL_BLACK,

    borderRadius: 0,
    border: state.isFocused
      ? `1px solid ${Colors.PRIMARY_GREEN}`
      : `1px solid ${Colors.NEUTRAL_STONE}`,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${Colors.PRIMARY_GREEN}`,
    },
    transition: 'all 0.1s linear',
  }),

  indicatorSeparator: () => ({}),

  menu: (provided) => ({
    ...provided,
    maxWidth: 142,
    zIndex: 100,
  }),

  menuList: (provided) => ({
    ...provided,
    '@media (max-width: 480px)': {
      maxHeight: 140,
    },
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? Colors.PRIMARY_GREEN
      : state.isFocused
      ? `${Colors.PRIMARY_GREEN}33`
      : 'transparent',
    cursor: 'pointer',

    transition: 'all 0.1s linear',

    '&:active': {
      background: `${Colors.PRIMARY_GREEN}33`,
    },
  }),
}
