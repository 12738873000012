import { SerializedStyles } from '@emotion/react'
import { useStore } from 'effector-react'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { viewerModel } from '~/entities/viewer'
import { NavigationBar } from '~/shared/ui'
import { onboardingLayoutModel } from '..'
import { getNavbarData } from '../lib'
import { OnboardingHeader } from './header'
import { Navbar } from './navbar'
import { styles } from './styles'

interface Props {
  children: JSX.Element
  onNext?: () => void
  onPrev?: () => void
  onReset?: () => void
  isStepSkippable?: boolean
  isTripsLoading?: boolean
  text?: string
  extendStyles?: {
    content?: SerializedStyles
  }
  customActionElement?: JSX.Element
  isLoading?: boolean
}

export const OnboardingLayout = ({
  children,
  onNext,
  extendStyles,
  customActionElement,
  onReset,
  isStepSkippable,
  isTripsLoading,
  text,
  isLoading,
}: Props) => {
  const isAuthorized = useStore(viewerModel.$isAuthorized)
  const currentStep = useStore(onboardingSessionModel.$step)
  const lastCompletedStep = useStore(onboardingLayoutModel.$lastCompletedStep)
  const isIframe = useStore(onboardingSessionModel.$isAppOpenedInIframe)

  return (
    <div css={[styles.wrapper, isIframe && styles.iframeWrapper]}>
      <OnboardingHeader
        isShowSkipLink={!isAuthorized}
        onLogoClick={onboardingLayoutModel.logoClicked}
        onPrev={
          currentStep !== 1 ? onboardingLayoutModel.previousStepNavigated : undefined
        }
        navbar={<Navbar steps={getNavbarData(currentStep, lastCompletedStep)} />}
      />

      <div
        css={[
          styles.mainContent(isAuthorized),
          extendStyles?.content,
          isIframe && styles.iframeMainContent(isAuthorized),
        ]}
      >
        {children}
      </div>
      <div css={styles.actions}>
        {customActionElement && <>{customActionElement}</>}
        {!isTripsLoading && onNext && (
          <NavigationBar
            text={text}
            isStepSkippable={isStepSkippable}
            onNext={onNext}
            onPrev={
              currentStep !== 1 ? onboardingLayoutModel.previousStepNavigated : undefined
            }
            onReset={onReset}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  )
}
