import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  extendStyles?: SerializedStyles
  required?: boolean
}

export const Label: FC<Props> = ({ children, extendStyles, required }) => {
  return (
    <label css={[styles.base, extendStyles, required && styles.required]}>
      {children}
    </label>
  )
}
