import {
  attractionsIcon,
  beachIcon,
  cameraIcon,
  cultureIcon,
  heartIcon,
  landmarkIcon,
  mapIcon,
  natureIcon,
  outdoorIcon,
  restaurantIcon,
  seaIcon,
} from './assets'
import { type TripIdea } from './types'

//add image services links if needed to optimize image size for browser
const DYNAMIC_RESIZE_SUPPORTED_SOURCES = ['images.unsplash.com']

export const isDynamicImageResizeSupported = (imageUrl: string) => {
  return DYNAMIC_RESIZE_SUPPORTED_SOURCES.some((source) => imageUrl.includes(source))
}

export const populateDestinationsInfo = (
  destinationCodes: string[],
  destinations: TripIdea[],
): TripIdea[] => {
  const populatedDestinations = destinationCodes
    .map((code) => {
      const fullDestinationInfo = destinations.find(
        (destination) => destination.destinationCode === code,
      )

      return fullDestinationInfo
    })
    .filter((destination): destination is TripIdea => !!destination)
  return populatedDestinations
}

export const keyPointsIcons = {
  culture: cultureIcon,
  landmark: landmarkIcon,
  restaurant: restaurantIcon,
  map: mapIcon,
  sea: seaIcon,
  camera: cameraIcon,
  attractions: attractionsIcon,
  heart: heartIcon,
  nature: natureIcon,
  beach: beachIcon,
  outdoor: outdoorIcon,
}
