import { FlightOffer, TripIdea } from '@softcery/awayaway-apiclient'
import { createFactory } from '@withease/factories'
import { combine, createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { FLIGHT_TYPE } from '~/entities/flights/types'
import { LocalStorageKeys } from '~/shared/config'

export type ManageLocalStorageForSearchFlights = ReturnType<
  typeof manageLocalStorageForSearchFlightsFactory
>
export const manageLocalStorageForSearchFlightsFactory = createFactory(() => {
  const flightTypeChanged = createEvent<FLIGHT_TYPE>()
  const resetManageLocalStorageForSearchFlights = createEvent()
  const offerSelected = createEvent<FlightOffer | null>()
  const updateOffers = createEvent<FlightOffer[] | []>()
  const updateOptions = createEvent<string[] | null>()
  const updateRecommendedOptions = createEvent<string[] | null>()
  const updateRecommendedOffers = createEvent<FlightOffer[] | []>()
  const toAirportChanged = createEvent<TripIdea | null>()
  const setFlightsTestEnv = createEvent<boolean>()

  const flightsIncludeChanged = createEvent<boolean>()
  const $isFlightsIncluded = restore(flightsIncludeChanged, true)

  const $isFlightsTestEnv = createStore(false)

  const $flightType = restore(flightTypeChanged, FLIGHT_TYPE.RETURN)
  const $oneWayFlight = createStore(true)
  const $outboundOffer = createStore<FlightOffer | null>(null)
  const $selectedOffer = restore<FlightOffer | null>(offerSelected, null)
  const $selectedOfferFare = createStore<FlightOffer | null>(null)
  const $isFlightsConfirmed = createStore(false)
  const $offers = restore<FlightOffer[] | []>(updateOffers, [])
  const $options = restore<string[] | null>(updateOptions, null)
  const $recommendedOffers = restore<FlightOffer[] | []>(updateRecommendedOffers, [])
  const $recommendedOptions = restore<string[] | null>(updateRecommendedOptions, null)
  const $toAirport = restore<TripIdea | null>(toAirportChanged, null)
  const $toAirportCode = createStore<string>('')

  const $isFlightsSelected = combine($selectedOffer, (selectedOffer) =>
    Boolean(selectedOffer),
  )

  const $isSearchDisabled = $outboundOffer.map((offer) => Boolean(offer))

  sample({
    clock: setFlightsTestEnv,
    fn: () => false,
    target: $isFlightsTestEnv,
  })

  reset({
    clock: resetManageLocalStorageForSearchFlights,
    target: [
      $oneWayFlight,
      $selectedOffer,
      $outboundOffer,
      $selectedOfferFare,
      $isFlightsConfirmed,
      $offers,
      $toAirport,
      $toAirportCode,
    ],
  })

  sample({
    clock: $isFlightsIncluded,
    filter: (isFlightsIncluded) => !isFlightsIncluded,
    fn: () => null,
    target: offerSelected,
  })

  persist({ store: $flightType, key: LocalStorageKeys.FlightType })
  persist({ store: $oneWayFlight, key: LocalStorageKeys.FlightsIsOneWay })
  persist({ store: $outboundOffer, key: LocalStorageKeys.FlightsOutboundOffer })
  persist({ store: $selectedOffer, key: LocalStorageKeys.SelectedFlightOffer })
  persist({ store: $selectedOfferFare, key: LocalStorageKeys.FlightsOfferFare })
  persist({ store: $isFlightsConfirmed, key: LocalStorageKeys.FlightsConfirmed })
  persist({ store: $isFlightsSelected, key: LocalStorageKeys.isFlightsSelected })
  persist({ store: $offers, key: LocalStorageKeys.FlightsOffers })
  persist({ store: $recommendedOffers, key: LocalStorageKeys.RecommendedFlightsOffers })
  persist({ store: $options, key: LocalStorageKeys.FlightsOptions })
  persist({ store: $recommendedOptions, key: LocalStorageKeys.RecommendedFlightsOptions })
  persist({ store: $toAirport, key: LocalStorageKeys.SelectedDestination })
  persist({ store: $isFlightsTestEnv, key: LocalStorageKeys.IsFlightsTestEnv })
  persist({ store: $toAirportCode, key: LocalStorageKeys.SelectedToAirportCode })
  persist({ store: $isFlightsIncluded, key: LocalStorageKeys.isFlightsIncluded })

  return {
    $flightType,
    $oneWayFlight,
    $outboundOffer,
    $isSearchDisabled,
    $selectedOffer,
    $isFlightsSelected,
    $selectedOfferFare,
    $isFlightsConfirmed,
    $offers,
    $recommendedOffers,
    $toAirport,
    $toAirportCode,
    $options,
    $recommendedOptions,
    $isFlightsTestEnv,
    $isFlightsIncluded,

    setFlightsTestEnv,
    updateOptions,
    updateRecommendedOptions,
    updateOffers,
    flightTypeChanged,
    resetManageLocalStorageForSearchFlights,
    offerSelected,
    updateRecommendedOffers,
    toAirportChanged,
    flightsIncludeChanged,
  }
})
