import * as yup from 'yup'
import { passwordPattern } from '~/shared/lib/validation'

export const sendEmailSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const updatePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('please enter password')
    .matches(passwordPattern, 'password must be over 6 characters'),
  confirmPassword: yup
    .string()
    .required('please confirm password')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
})
