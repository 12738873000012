import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const CheckedBagIcon: FC<Props> = ({
  width = 16,
  height = 16,
  color = '#555555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16444_19304)">
        <path
          d="M13.25 4.75H2.75C2.33579 4.75 2 5.08579 2 5.5V13.75C2 14.1642 2.33579 14.5 2.75 14.5H13.25C13.6642 14.5 14 14.1642 14 13.75V5.5C14 5.08579 13.6642 4.75 13.25 4.75Z"
          stroke="#555555"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.5 8.125V11.125" stroke="#555555" strokeLinecap="round" />
        <path
          d="M4.625 14.5V16"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.375 14.5V16"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.5 8.125V11.125" stroke="#555555" strokeLinecap="round" />
        <path
          d="M6.125 1H9.875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 1V4.75" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_16444_19304">
          <rect width={width} height={height} fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
