import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  title: css`
    color: ${Colors.NEUTRAL_BLACK};
    font-size: 24px;
    font-weight: 400;

    margin-bottom: 32px;
  `,

  subtitle: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 18px;
    color: ${Colors.NEUTRAL_DARK};
    margin-bottom: 40px;
  `,

  textInput: css`
    padding: 12px;
    width: 100% !important;
    font-family: Calibre;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};
    border: 1px solid ${Colors.LIGHT_GREY_4};

    :placeholder {
      color: ${Colors.NEUTRAL_GREY};
    }
  `,

  fullWidth: css`
    width: 100%;
  `,

  largeInput: css`
    margin-top: 8px;
    min-height: 183px;
    width: 100%;
    padding: 12px;
    font-family: Calibre;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};
    resize: none;
    padding: 14px 60px 14px 20px;
    border: 1px solid ${Colors.LIGHT_GREY_2};
    font-size: 18px;
    line-height: 20px;

    &:active,
    &:focus {
      outline: none;
    }
    :placeholder {
      color: ${Colors.NEUTRAL_GREY};
    }
  `,

  inputs: {
    container: css`
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 32px;
      border-bottom: 1px solid #eaeaea;
    `,

    label: css`
      margin-bottom: 8px;
    `,

    row: css`
      width: 100%;
      display: flex;
      gap: 16px;

      ${Breakpoints.SM} {
        flex-direction: column;
      }
    `,
  },

  helperText: css`
    font-size: 14px;
    font-family: Calibre;
    letter-spacing: 0.2px;
    line-height: 18px;
    font-weight: 400;
    color: ${Colors.NEUTRAL_GREY};
    padding-top: 8px;
  `,

  button: css`
    margin-top: 25px;
    width: 100%;
    height: 58px;
    gap: 10px;
  `,

  passengers: css`
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: ${Colors.NEUTRAL_SILVER};
      margin-block: 40px;
    }
  `,
  room: {
    num: css`
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    guests: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-bottom: 24px;
    `,
  },

  optionalRequest: {
    container: css`
      padding-top: 32px;

      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
    `,
    title: css`
      margin-bottom: 12px;
    `,
  },

  error: css`
    margin-top: 10px;
  `,
}
