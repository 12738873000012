import { SerializedStyles } from '@emotion/react'
import { useState } from 'react'
import { placeholderImage as placeholder } from '~/shared/assets/images/other'
import { hotelBedsUrlProvider } from './hotelbeds-provider'
import { ImageUrlProvider, Size } from './provider'

interface Props {
  image?: string
  placeholderImage?: string
  desiredSize?: Size
  alt?: string
  height?: number | string
  width?: number | string
  extendStyle?: SerializedStyles | SerializedStyles[]
  imageUrlProvider?: ImageUrlProvider
}

export const Image = ({
  image,
  placeholderImage = placeholder,
  desiredSize,
  alt,
  height,
  width,
  extendStyle,
  imageUrlProvider = hotelBedsUrlProvider,
}: Props) => {
  const [currentImageUrl, setCurrentImageUrl] = useState(image ? image : placeholderImage)

  const handleImageError = async () => {
    const validImageUrl = await imageUrlProvider.getValidImageUrl(image, desiredSize)
    setCurrentImageUrl(validImageUrl || placeholderImage)
  }

  return (
    <img
      src={currentImageUrl}
      height={height}
      width={width}
      onError={handleImageError}
      css={extendStyle}
      alt={alt}
    />
  )
}
