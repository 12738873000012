import { SerializedStyles } from '@emotion/react'
import {
  GroupedRateDto,
  OccupancyOptions,
  RateDto,
  RoomsResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BoardsList } from '~/features/trip-booking/book-room/ui/rate-list'

import { getCancellationDeadlineDate } from '~/entities/booking/lib'
import { CancellationFeesDetails } from '~/entities/booking/ui/cancellation-policy/flexible-policy-details/cancellation-fees-details'
import { crossIcon } from '~/shared/assets/icons'
import {
  BorderedCircleCheckIcon,
  ChevronDownIcon,
  InfoIcon,
  RadioButtonIcon,
  SelectedRadioButtonIcon,
  SizeIcon,
  UserIcon,
  WifiIcon,
} from '~/shared/assets/icons/components'
import { chevronLeftIcon, chevronRightIcon } from '~/shared/assets/icons/grey'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { formatToCustom } from '~/shared/lib/date'
import { normalizeStringCase, normalizeStringEnding } from '~/shared/lib/string'
import { Dimensions } from '~/shared/styles'
import { Button, Tooltip } from '~/shared/ui'
import { hotelBedsUrlProvider } from '~/shared/ui/atoms/image/hotelbeds-provider'
import { ImageUrlProvider } from '~/shared/ui/atoms/image/provider'
import { styles } from './styles'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/pagination'

import './styles.css'

interface Props {
  room: RoomsResponseDto
  onClose: () => void
  selectRoom: (room: RoomsResponseDto) => void
  selectBoardOption: (boardOption: GroupedRateDto) => void
  selectPolicy: (policy: RateDto) => void
  selectedBoardOption: GroupedRateDto
  selectedPolicy: RateDto
  isRoomSelected: boolean
  imageUrlProvider?: ImageUrlProvider
  occupancy: OccupancyOptions
  currency: string
  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }
}

export const RoomDetails: FC<Props> = ({
  room,
  selectRoom,
  selectBoardOption,
  selectPolicy,
  selectedBoardOption,
  selectedPolicy,
  isRoomSelected,
  onClose,
  currency,
  occupancy,
  extendStyles,
  imageUrlProvider = hotelBedsUrlProvider,
}) => {
  const [thumbsRoomSwiper, setThumbsRoomSwiper] = useState<any | null>(null)
  const [roomImages, setRoomImages] = useState(room.images || [])
  const [isReadMore, setReadMore] = useState(false)
  const [isViewMore, setViewMore] = useState(false)
  const [isPolicyDetailsVisible, setPolicyDetailsVisibility] = useState(false)
  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Lg}px)` })
  const refundCost = (amountPerNight: number) => {
    return amountPerNight - selectedBoardOption.baseAmount!
  }

  const sleeps = occupancy.adults + (occupancy.children || 0)

  // Facilities
  const roomInternet = room.facilities.find((facility) => facility.code === 5200)

  const renderFacilities = isViewMore ? room?.facilities : room?.facilities.slice(0, 3)

  const handleImageError = (image: string) => {
    setRoomImages((prevImages) => prevImages.filter((img) => img !== image))
  }

  return (
    <div css={styles.container}>
      <div css={styles.top.wrapper}>
        <p css={styles.top.title}>Room details</p>
        <img src={crossIcon} css={styles.cross} onClick={onClose} />
      </div>

      <div css={styles.slider.wrapper}>
        {isDesktop && (
          <div>
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={{
                nextEl: '.custom-next',
                prevEl: '.custom-prev',
              }}
              thumbs={{ swiper: thumbsRoomSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mainRoomSlider"
            >
              {roomImages.map((image) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={image}
                >
                  <div className="blurred-room-background"></div>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}

              <div css={styles.slider.nav}>
                <div
                  className="custom-prev"
                  onClick={() => thumbsRoomSwiper.current?.slidePrev()}
                >
                  <img className="custom-icon" src={chevronLeftIcon} />
                </div>
                <div
                  className="custom-next"
                  onClick={() => thumbsRoomSwiper.current?.slideNext()}
                >
                  <img className="custom-icon" src={chevronRightIcon} />
                </div>
              </div>
            </Swiper>

            <Swiper
              onSwiper={setThumbsRoomSwiper}
              loop={true}
              spaceBetween={4}
              slidesPerView={8}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="thumbsRoomSwiper"
            >
              {roomImages.map((image) => (
                <SwiperSlide key={image}>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!isDesktop && (
          <div>
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={{
                nextEl: '.custom-room-next',
                prevEl: '.custom-room-prev',
              }}
              modules={[FreeMode, Navigation, Thumbs, Pagination]}
              className="mainSlider"
              pagination={{
                dynamicBullets: true,
                dynamicMainBullets: 4,
              }}
            >
              {roomImages.map((image) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={image}
                >
                  <div className="blurred-background"></div>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div css={styles.slider.nav}>
              <div className="custom-room-prev">
                <img className="custom-room-icon" src={chevronLeftIcon} />
              </div>
              <div className="custom-room-next">
                <img className="custom-room-icon" src={chevronRightIcon} />
              </div>
            </div>
          </div>
        )}
      </div>

      {room?.name && <h2 css={styles.title}>{normalizeStringCase(room.name)}</h2>}

      <div css={styles.highlights.wrapper}>
        <div css={styles.highlights.title}>Highlights</div>
        <div css={styles.highlights.list}>
          <div css={styles.highlights.item}>
            <UserIcon width={16} height={16} /> {sleeps} sleeps
          </div>

          {roomInternet && (
            <div css={styles.highlights.item}>
              <WifiIcon width={16} height={16} /> {roomInternet.description}
            </div>
          )}
        </div>
      </div>

      {renderFacilities && renderFacilities.length > 0 && (
        <div css={styles.amenities.wrapper}>
          <div css={styles.amenities.heading}>Amenities</div>
          <div css={styles.amenities.row}>
            <div css={styles.amenities.col}>
              <div css={styles.amenities.title}>
                <BorderedCircleCheckIcon width={20} height={20} color={'#333333'} />
                Room facilities
              </div>
              <ul css={styles.content.list}>
                {renderFacilities.map((facility) => (
                  <li key={facility.code} css={styles.content.item}>
                    {normalizeStringCase(facility.description)}
                  </li>
                ))}
              </ul>
              <button
                css={styles.rateComment.button}
                onClick={(e) => {
                  e.stopPropagation()
                  setViewMore(!isViewMore)
                }}
              >
                {isViewMore ? 'View less' : 'View more'}
              </button>
            </div>
            {roomInternet && (
              <div css={styles.amenities.col}>
                <div css={styles.amenities.title}>
                  <WifiIcon width={20} height={20} color={'#333333'} /> Internet
                </div>
                <ul css={styles.content.list}>
                  <li css={styles.content.item}>{roomInternet?.description}</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {room.groupedRates?.length === 1 && room.groupedRates[0].rates?.length === 1 ? (
        <div css={styles.rates.withoutChoice.wrapper}>
          <div css={styles.rates.withoutChoice.board}>
            {normalizeStringEnding(room.groupedRates[0].baseBoardName)}
          </div>
          <div>
            {room.groupedRates[0]?.rates[0]?.refundability === 'NON_REFUNDABLE' ? (
              <div css={styles.rates.withoutChoice.policy}>
                <span>Non-refundable</span>
              </div>
            ) : (
              <div css={styles.rates.withoutChoice.policy}>
                <div css={styles.rates.desktopTooltip.wrapper}>
                  <span>Fully refundable</span>before{' '}
                  {formatToCustom(
                    getCancellationDeadlineDate(
                      room.groupedRates[0]?.rates[0]?.cancellationPolicies?.[0]?.from ||
                        '',
                    ),
                    'DD MMM',
                  )}
                  <Tooltip
                    content={
                      <CancellationFeesDetails
                        policies={selectedPolicy.cancellationPolicies || []}
                        currency={currency}
                      />
                    }
                    extendStyle={extendStyles?.tooltip || styles.tooltip.container}
                    tooltipPosition={{
                      side: 'bottom',
                      align: 'start',
                      alignOffset: 40,
                    }}
                  >
                    <div css={[extendStyles?.focusedText, styles.rates.icon]}>
                      <InfoIcon color={'#8E8E8E'} />
                    </div>
                  </Tooltip>
                </div>

                <div css={styles.rates.mobileTooltip.wrapper}>
                  Fully refundable before{' '}
                  {formatToCustom(
                    getCancellationDeadlineDate(
                      selectedPolicy.cancellationPolicies?.[0].from || '',
                    ),
                    'DD MMM',
                  )}
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      setPolicyDetailsVisibility(!isPolicyDetailsVisible)
                    }}
                  >
                    <InfoIcon color={'#8E8E8E'} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div css={styles.rates.row}>
          <div css={styles.rates.col}>
            <h3 css={styles.rates.title}>Board options</h3>
            <BoardsList
              rates={room.groupedRates!}
              selectedRate={selectedBoardOption}
              onSelect={selectBoardOption}
            />
          </div>
          <div css={styles.rates.col}>
            <h3 css={styles.rates.title}>Cancellation Policy</h3>

            <div css={styles.rates.wrapper}>
              {selectedBoardOption.rates?.map((rate) => (
                <div
                  key={rate.roomId}
                  onClick={() => {
                    selectPolicy(rate)
                  }}
                >
                  {rate.refundability === 'NON_REFUNDABLE' ? (
                    <div css={styles.rates.item}>
                      {selectedBoardOption.rates!.length > 1 && (
                        <div>
                          {rate.packageId === selectedPolicy.packageId ? (
                            <SelectedRadioButtonIcon />
                          ) : (
                            <RadioButtonIcon />
                          )}
                        </div>
                      )}
                      Non-refundable
                    </div>
                  ) : (
                    <div css={styles.rates.item}>
                      <div css={styles.rates.desktopTooltip.wrapper}>
                        {selectedBoardOption.rates!.length > 1 && (
                          <div>
                            {rate.packageId === selectedPolicy.packageId ? (
                              <SelectedRadioButtonIcon />
                            ) : (
                              <RadioButtonIcon />
                            )}
                          </div>
                        )}
                        Fully refundable before{' '}
                        {formatToCustom(
                          getCancellationDeadlineDate(
                            rate.cancellationPolicies?.[0].from || '',
                          ),
                          'DD MMM',
                        )}
                        {refundCost(rate.amount!) !== 0 && (
                          <div css={styles.rates.refundCost}>
                            +
                            {getPriceWithCurrency(
                              refundCost(rate.amount!),
                              currency,
                              false,
                            )}
                          </div>
                        )}
                        <Tooltip
                          content={
                            <CancellationFeesDetails
                              policies={selectedPolicy.cancellationPolicies || []}
                              currency={currency}
                            />
                          }
                          extendStyle={extendStyles?.tooltip || styles.tooltip.container}
                          tooltipPosition={{
                            side: 'bottom',
                            align: 'start',
                            alignOffset: 40,
                          }}
                        >
                          <div css={[extendStyles?.focusedText, styles.rates.icon]}>
                            <InfoIcon color={'#8E8E8E'} />
                          </div>
                        </Tooltip>
                      </div>

                      <div css={styles.rates.mobileTooltip.wrapper}>
                        <div css={styles.rates.mobileItem}>
                          {selectedBoardOption.rates.length > 1 && (
                            <div>
                              {rate.packageId === selectedPolicy.packageId ? (
                                <SelectedRadioButtonIcon />
                              ) : (
                                <RadioButtonIcon />
                              )}
                            </div>
                          )}
                          Fully refundable before{' '}
                          {refundCost(rate.amount) !== 0 && (
                            <div css={styles.rates.refundCost}>
                              +
                              {getPriceWithCurrency(
                                refundCost(rate.amount),
                                currency,
                                false,
                              )}
                            </div>
                          )}
                          {formatToCustom(
                            getCancellationDeadlineDate(
                              rate.cancellationPolicies?.[0].from || '',
                            ),
                            'DD MMM',
                          )}
                          <div
                            css={styles.rates.icon}
                            onClick={(e) => {
                              e.stopPropagation()
                              setPolicyDetailsVisibility(!isPolicyDetailsVisible)
                            }}
                          >
                            <InfoIcon color={'#8E8E8E'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div css={styles.price.mobile}>
        <div css={styles.price.perNight}>
          {getPriceWithCurrency(selectedPolicy.dailyAmount, currency, false)}
          <span>/night</span>
        </div>
        <div css={styles.price.total}>
          {getPriceWithCurrency(selectedPolicy.amount!, currency, false)} total
        </div>
      </div>

      <div css={styles.bottom.wrapper}>
        <div css={styles.price.desktop}>
          <div css={styles.price.perNight}>
            {getPriceWithCurrency(selectedPolicy.dailyAmount, currency, false)}
            <span>/night</span>
          </div>
          <div css={styles.price.total}>
            {getPriceWithCurrency(selectedPolicy.amount!, currency, false)} total
          </div>
        </div>

        <div css={styles.bottom.row}>
          <Button css={styles.bottom.cancelButton} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            css={styles.bottom.selectButton(isRoomSelected)}
            onClick={() => {
              selectRoom(room!)
              onClose()
            }}
            disabled={isRoomSelected}
          >
            {isRoomSelected ? 'Selected' : 'Select room'}
          </Button>
        </div>
      </div>
    </div>
  )
}
