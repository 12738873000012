import { Loader } from '@googlemaps/js-api-loader'

export const googleMapsLoader = new Loader({
  apiKey: 'AIzaSyDGJVN5QLAF_RzAaN_NkW5xUi97fNbE98E',
  // apiKey: 'AIzaSyBBy00wtenkkwcPSub7mjlZHUxnXwmv0Iw',
  version: 'weekly',
  libraries: ['places'],
  language: 'en',
})

export const searchOptions = {
  types: [
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'locality',
    'point_of_interest',
  ],
}
