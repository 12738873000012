import { css } from '@emotion/react'

export const styles = {
  button: (loading: boolean) => css`
    position: relative;
    display: flex;
    align-items: center;

    ${loading &&
    css`
      color: transparent;
    `}
  `,

  loader: css`
    position: absolute;
    left: 47.5%;
    transform: translate(-50%, -50%);
  `,
}
