import { css } from '@emotion/react'

export const styles = {
  base: (image?: string) => css`
    aspect-ratio: 1/1;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `,
}
