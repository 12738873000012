import { invoke } from '@withease/factories'
import { atom } from '~/shared/lib/factory'
import { getRoomsFactory } from './get-rooms'
import { manageRoomsDataFactory } from './manage-rooms-data'

export const hotelRoomModel = atom(() => {
  const $$getRoomsFactory = invoke(getRoomsFactory)
  const $$manageRoomsDataFactory = invoke(manageRoomsDataFactory, { $$getRoomsFactory })

  return {
    $$getRoomsFactory,
    $$manageRoomsDataFactory,
  }
})
