import { useState, useRef, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Dimensions } from '~/shared/styles'

export const useStickyHeader = (loading?: boolean) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Dimensions.Md}px)` })

  const [stickyOffsetY] = useState(isMobile ? 100 : 50)
  const [isHeaderSticky, setHeaderSticky] = useState(false)

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const sticky = (headerRef.current?.offsetTop || 0) - stickyOffsetY

    const stickyHeader = () => {
      if (window.scrollY >= sticky) {
        setHeaderSticky(true)
      } else {
        setHeaderSticky(false)
      }
    }

    window.addEventListener('scroll', stickyHeader)

    return () => window.removeEventListener('scroll', stickyHeader)
  }, [headerRef.current, loading])

  return { headerRef, isHeaderSticky }
}
