import { createEvent, sample } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { tripInfoModel } from '~/entities/trip-info'

export const Gate = createGate<void>()
export const stateReset = createEvent()
export const nextStepNavigated = createEvent()

sample({
  clock: Gate.open,
  target: onboardingSessionModel.scrollToTopFx,
})

reset({
  clock: stateReset,
  target: tripInfoModel.$tripInfo,
})
