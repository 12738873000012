import { useGate, useStore } from 'effector-react'
import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { BookingManagementSidebar, searchBookingModel } from '~/entities/booking'
import { BookingDetails } from '~/entities/booking/ui/booking-details'
import { Loader } from '~/shared/ui'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'

import { bookingDetailsPageModel } from '../'
import { styles } from './styles'

export const BookingDetailsPage: FC = () => {
  const { bookingId } = useParams()

  useGate(bookingDetailsPageModel.BookingDetailsGate, Number(bookingId))

  const booking = useStore(searchBookingModel.$$getBookingFactory.$bookedTripDetails)
  const loading = useStore(searchBookingModel.$$getBookingFactory.$loading)

  return (
    <Fragment>
      <WithSidebar
        sidebarContent={
          <Fragment>{booking && <BookingManagementSidebar booking={booking} />}</Fragment>
        }
        position="right"
        main={
          <Fragment>
            {loading && <Loader extendStyle={styles.states.loader} />}

            {!loading && booking && <BookingDetails booking={booking} />}
          </Fragment>
        }
        extendsStyles={{ main: styles.fullWidth }}
      />
    </Fragment>
  )
}
