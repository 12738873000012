import { Fragment } from 'react'
import { styles } from './styles'

interface Props {
  cardCount: number
}

export const ImagesSkeleton = ({ cardCount }: Props) => {
  const renderSkeletonCards = () => {
    const skeletonCards = []
    for (let i = 0; i < cardCount; i++) {
      skeletonCards.push(<div css={styles.card} key={i} />)
    }
    return skeletonCards
  }

  return <Fragment>{renderSkeletonCards()}</Fragment>
}
