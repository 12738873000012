import { css } from '@emotion/react'
import { notification } from '~/shared/lib/notification'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    width: 100%;
    margin: 0 auto;
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    ${Breakpoints.XL} {
      padding-inline: 20px;
    }

    ${Breakpoints.SM} {
      margin-top: 0;
    }
  `,

  title: css`
    max-width: 1224px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;

    display: flex;
    align-items: center;
    gap: 8px;

    color: ${Colors.NEUTRAL_BLACK};

    font-size: 20px;
    font-weight: 400;
    line-height: 27px;

    ${Breakpoints.SM} {
      margin-bottom: 24px;
    }
  `,

  trips: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    ${Breakpoints.SM} {
      gap: 48px;
    }
  `,

  header: {
    wrapper: (imageUrl?: string) => css`
      background: url(${imageUrl}) no-repeat center;
      position: relative;
      height: 340px;
      background-size: cover;

      ${Breakpoints.SM} {
        max-height: 280px;
      }
    `,

    filter: css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 340px;

      background-image: linear-gradient(
        35deg,
        rgba(0, 0, 0, 0.82) 5%,
        rgba(58, 58, 58, 0.4) 114%,
        rgba(84, 84, 84, 0.2) 163%
      );

      padding-bottom: 56px;

      ${Breakpoints.SM} {
        max-height: 280px;
        padding-bottom: 24px;
      }
    `,

    container: css`
      max-width: 1224px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    `,

    footer: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: auto;

      ${Breakpoints.XL} {
        padding: 0 20px;
      }

      ${Breakpoints.MD} {
        flex-direction: column;
        align-items: flex-start;
      }

      ${Breakpoints.SM} {
        padding: 0 16px 24px;
      }
    `,

    title: css`
      color: ${Colors.NEUTRAL_LIGHT};

      font-family: ${Fonts.Joane};
      font-size: 40px;
      font-weight: 600;

      ${Breakpoints.SM} {
        font-size: 28px;
      }
    `,

    message: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: ${Colors.NEUTRAL_LIGHT};
      max-width: 680px;
      margin-top: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;

      ${Breakpoints.SM} {
        font-size: 16px;
        letter-spacing: 0.32px;
        line-height: 1.1;
        -webkit-line-clamp: 4;
      }
    `,

    reOnboardingButton: {
      base: css`
        border: 1px solid ${Colors.NEUTRAL_LIGHT};
        color: ${Colors.NEUTRAL_BLACK};
        font-size: 18px;
        font-weight: 400;
        min-width: 157px;

        background: ${Colors.LIGHT_1};

        transition: all 0.3s;
        position: relative;
        z-index: 1;
        overflow: hidden;

        margin-left: auto;

        &:hover,
        &:focus {
          background: transparent;
          color: ${Colors.LIGHT_1};

          div {
            margin-bottom: 0;
          }

          svg {
            opacity: 1;
          }
        }

        ${Breakpoints.MD} {
          margin-left: unset;
          margin-top: 24px;
          min-width: unset;
        }

        ${Breakpoints.SM} {
          /* display: none; */
          padding: 11px 20px;
          background: transparent;
          color: ${Colors.LIGHT_1};
          &:hover,
          &:focus {
            background: ${Colors.LIGHT_1};
            color: ${Colors.NEUTRAL_BLACK};
          }
        }
      `,

      background: css`
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: -100%;
        width: 100%;
        height: 100%;

        background-image: linear-gradient(transparent, transparent);
        transition: 0.3s;
        z-index: -1;
      `,
    },

    header: css`
      position: relative;
    `,
  },

  destination: css`
    margin-inline: auto;
    max-width: 1130px;
    &:nth-last-of-type(2) {
      border-bottom: none;
    }
  `,

  noContent: {
    container: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-block: 40px;
      border-bottom: solid 1px ${Colors.LIGHT_GREY_2};
      margin-bottom: 36px;
    `,

    text: css`
      color: ${Colors.NEUTRAL_GREY};
      font-size: 18px;
      max-width: 650px;
      text-align: center;
      margin-top: 16px;
    `,
  },

  hotels: {
    container: css`
      margin-left: max(calc((50vw - 565px)), 20px);
      ${Breakpoints.XL} {
        margin-left: 0px;
      }
    `,
  },

  loader: {
    wrapper: css`
      max-width: 1224px;
      width: 100%;
      align-self: center;
    `,

    container: css`
      width: 100%;
      position: absolute;
      z-index: 0;
      backdrop-filter: blur(1px);

      ${Breakpoints.SM} {
        position: static;
      }
    `,
  },

  regenerate: {
    wrapper: css`
      display: flex;
      flex-direction: column;
      align-items: center;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,

    text: css`
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      color: ${Colors.NEUTRAL_BLACK};
      margin-bottom: 30px;

      ${Breakpoints.SM} {
        margin-bottom: 25px;
      }
    `,
  },

  button: {
    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: 16px;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,
  },

  regenerateButton: {
    main: css`
      width: 360px;

      &:hover {
        background-color: ${Colors.PRIMARY_GREEN};
        svg {
          display: block;
          transition: all 1s;
          transform: rotate(-360deg);
        }
      }

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,

    icon: css`
      display: flex;
      align-items: center;
      margin-left: 10px;
    `,
  },

  consultantButton: {
    main: css`
      width: 360px;
      color: ${Colors.PRIMARY_GREEN};
      border: 1.5 solid ${Colors.PRIMARY_GREEN};
      transition: all 0.3s;

      ${Breakpoints.SM} {
        width: 100%;
      }
    `,
  },

  notFound: {
    wrapper: css`
      max-width: 1224px;
      width: 100%;
      padding-top: 100px;
      padding-bottom: 140px;
      align-self: center;

      ${Breakpoints.MD} {
        padding-top: 40px;
        padding-bottom: 100px;
      }
    `,

    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `,

    icon: css``,

    title: css`
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-top: 20px;
    `,

    description: css`
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_GREY};
      max-width: 290px;
      margin-top: 8px;
    `,

    button: css`
      font-size: 18px;
      color: ${Colors.PRIMARY_GREEN};
      border: 1px solid ${Colors.PRIMARY_GREEN};
      padding: 11.5xp 20px;
      margin-top: 40px;
    `,
  },

  notification: {
    container: css`
      max-width: 1224px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 48px;
      position: relative;

      ${Breakpoints.SM} {
        margin-bottom: 24px;
      }
    `,

    wrapper: css`
      gap: 12px;
      padding: 8px 12px;
      background-color: #ecfaf4;
      border-radius: 4px;
    `,

    title: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: ${Colors.NEUTRAL_BLACK};
      gap: 12px;
      margin-bottom: 4px;
    `,

    desc: css`
      font-size: 16px;
      font-weight: 400;
      color: ${Colors.NEUTRAL_BLACK};
      padding-left: 32px;

      ${Breakpoints.SM} {
        font-size: 14px;
      }
    `,

    close: css`
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px 12px;
      cursor: pointer;
    `,
  },
}
