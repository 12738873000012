import { useStore } from 'effector-react'
import { FC } from 'react'

import { selectBudgetModel } from '../'
import { Budget } from '../types'
import { PriceCard } from './price-card'
import { styles } from './styles'

const prices: Budget[] = [
  {
    index: 1,
    price: 'Under £300',
    segment: 'Better Value',
    symbol: '££',
    category: 'better',
  },

  {
    index: 2,
    price: '£300-500',
    segment: 'Premium',
    symbol: '£££',
    category: 'premium',
  },

  {
    index: 3,
    price: '£500+',
    segment: 'Luxury',
    symbol: '££££',
    category: 'luxury',
  },
]

export const SelectBudget: FC = () => {
  const selectedBudget = useStore(selectBudgetModel.$selectedBudget)

  return (
    <div>
      <div css={styles.priceCards}>
        {prices.map((b) => (
          <PriceCard
            key={b.segment}
            price={b.price}
            segment={b.segment}
            symbol={b.symbol}
            onSelect={() => selectBudgetModel.budgetUpdated(b)}
            selected={b.segment === selectedBudget?.segment}
          />
        ))}
      </div>

      <p css={styles.description}>
        What's considered a Better Value, Premium or Luxury hotel is relative to each
        destination, and we take that into consideration. Select the pricing bucket you've
        allocated for a night's stay.
      </p>
    </div>
  )
}
