import { Form, useForm } from 'effector-forms'
import { useStore } from 'effector-react'
import { FC } from 'react'

import { inputMasks } from '~/shared/config'
import {
  DropdownIndicator,
  InputWithLabel,
  InputWithMask,
  SelectInput,
} from '~/shared/ui'

import { addGuestsInfoModel } from '../..'
import {
  documentTypeConfigByDocumentType,
  documentTypeOptions,
  guestGenderOptions,
  guestTitleOptions,
} from '../../config'
import { getAvailableDocumentTypeOptions } from '../../lib'
import { DocumentType, Guest, GuestType } from '../../types'
import { styles } from './styles'

interface Props {
  form: Form<Guest>
  guestNumber: number
}

export const GuestForm: FC<Props> = ({ form, guestNumber }) => {
  const { fields, errorText } = useForm(form)

  const availableDocumentTypes = useStore(addGuestsInfoModel.$availableDocumentTypes)

  const renderDocumentInputs = (documentType: DocumentType) => {
    const { label, placeholder } = documentTypeConfigByDocumentType[documentType]

    return (
      <div css={styles.fields.documentInputs}>
        <SelectInput
          label="Document Type"
          options={getAvailableDocumentTypeOptions(
            availableDocumentTypes,
            documentTypeOptions,
          )}
          value={fields.documentType?.value}
          onChange={(e) => fields.documentType?.onChange(e.value)}
          components={{ DropdownIndicator }}
          isSearchable={false}
        />

        <InputWithLabel
          type="text"
          name="identifier"
          label={label}
          placeholder={placeholder}
          value={fields.identifier?.value}
          onChange={(e) => fields.identifier?.onChange(e.target.value)}
          onBlur={() => fields.identifier?.onBlur()}
          error={errorText('identifier')}
          extendStyle={styles.fields.identifier}
        />

        <InputWithLabel
          type="text"
          name="issuingCountry"
          label="Issuing country code"
          placeholder="GB"
          value={fields.issuingCountryCode?.value}
          onChange={(e) => fields.issuingCountryCode?.onChange(e.target.value)}
          onBlur={() => fields.issuingCountryCode?.onBlur()}
          error={errorText('issuingCountryCode')}
          extendStyle={styles.fields.issuingCountryCode}
        />

        <InputWithMask
          label={'Expires on'}
          placeholder="MM / YY"
          mask={inputMasks.EXPIRATION_DATE}
          value={fields.expiresOn?.value}
          onChange={(e) => fields?.expiresOn?.onChange(e.target.value)}
          onBlur={() => fields.expiresOn?.onBlur()}
          error={errorText('expiresOn')}
          extendStyle={styles.fields.expiresOn}
          guide={false}
        />
      </div>
    )
  }

  return (
    <div css={styles.container}>
      <div css={styles.type}>
        {fields.type?.value === GuestType.Adult
          ? `Adult ${guestNumber}`
          : `Child ${guestNumber}`}
      </div>

      <div css={styles.fields.container}>
        <SelectInput
          label="Title"
          options={guestTitleOptions}
          value={fields.title?.value}
          onChange={fields.title?.onChange}
          components={{ DropdownIndicator }}
          isSearchable={false}
        />

        <InputWithLabel
          type="text"
          name="firstName"
          label="First name"
          placeholder="John"
          value={fields.firstName.value}
          onChange={(e) => fields.firstName.onChange(e.target.value)}
          onBlur={() => fields.firstName.onBlur()}
          error={errorText('firstName')}
          extendStyle={styles.fields.firstName}
        />

        <InputWithLabel
          type="text"
          name="lastName"
          label="Last name"
          placeholder="Doe"
          value={fields.lastName.value}
          onChange={(e) => fields.lastName.onChange(e.target.value)}
          onBlur={() => fields.lastName.onBlur()}
          error={errorText('lastName')}
          extendStyle={styles.fields.secondName}
        />

        <SelectInput
          label="Gender"
          options={guestGenderOptions}
          value={fields.gender?.value}
          onChange={fields.gender?.onChange}
          components={{ DropdownIndicator }}
          isSearchable={false}
        />

        <InputWithMask
          label="Date of birth"
          placeholder="DD / MM / YYYY"
          mask={inputMasks.DATE}
          value={fields.dateOfBirth.value}
          onChange={(e) => fields.dateOfBirth.onChange(e.target.value)}
          onBlur={() => fields.dateOfBirth.onBlur()}
          error={errorText('dateOfBirth')}
          extendStyle={styles.fields.dateOfBirth}
          guide={false}
        />
      </div>

      {fields.documentType?.value && renderDocumentInputs(fields.documentType.value)}
    </div>
  )
}
