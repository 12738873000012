import { HolidayImage } from '~/entities/holiday'
import { GENERATED_IMAGES_MAX_NUMBER } from '../config'

export const filterDuplicates = (
  originalItems: HolidayImage[],
  updatedItems: HolidayImage[],
) => {
  const filtered = updatedItems.filter((updatedItem) => {
    return !originalItems.some((originalItem) => updatedItem.id === originalItem.id)
  })
  return filtered.slice(0, GENERATED_IMAGES_MAX_NUMBER)
}

export const isCountryCodesChanged = (oldCodes: string[], newCodes: string[]) => {
  return JSON.stringify(oldCodes.sort()) !== JSON.stringify(newCodes.sort())
}
