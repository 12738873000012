import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: ${Colors.LIGHT_1};
    padding: 36px 40px;
    max-width: 1110px;
    margin: 0 auto;

    ${Breakpoints.SM} {
      padding: 25px;
    }
  `,

  header: {
    container: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      flex-direction: column;

      ${Breakpoints.SM} {
        gap: 5px;
      }
    `,

    title: css`
      font-family: Calibre;
      font-weight: 500;
      font-size: 32px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-bottom: 10px;
    `,

    reference: css`
      font-family: Calibre;
      font-weight: 400;
      font-size: 20px;
      color: ${Colors.NEUTRAL_GREY};
    `,
  },

  dates: {
    container: css`
      grid-column-gap: 0;
    `,

    icon: css`
      margin: 0 32px;

      ${Breakpoints.SM} {
        margin: 0 16px;
      }
    `,
  },

  link: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.PRIMARY_GREEN};
  `,

  image: css`
    width: auto;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
    margin-top: 40px;
    margin-bottom: 24px;
  `,

  details: {
    title: css`
      font-size: 24px;
      font-family: Calibre;
    `,

    subtitle: css`
      font-size: 20px;
      font-family: Calibre;
      margin-top: 4px;
      color: #8e8e8e;
    `,
  },

  divider: css`
    background-color: #eaeaea;
    width: 100%;
    height: 1px;
    margin: 23px 0;
  `,

  accentColor: css`
    color: #009f9d;
  `,

  summary: css`
    font-size: 24px;
    font-family: Calibre;
  `,

  flightsSummary: css`
    font-size: 22px;
    color: tomato;
    margin-top: 8px;
  `,

  flights: {
    wrapper: css`
      display: flex;
      align-items: center;
    `,
    type: css`
      font-size: 15px;
      background-color: ${Colors.NEUTRAL_LIGHT};
      border-radius: 4px;
      padding: 2px 4px;
      margin-left: 8px;
    `,
  },
}
