import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,

  fields: {
    container: css`
      display: grid;
      grid-template-columns: 1fr 2.5fr 2.5fr;
      gap: 12px;

      @media (max-width: 700px) {
        grid-template-columns: 1fr;
      }
    `,

    documentInputs: css`
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;

      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    `,

    phone: {
      wrapper: css`
        max-width: 250px;
      `,

      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    email: {
      wrapper: css`
        max-width: 250px;
      `,

      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    firstName: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    secondName: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    issuingCountryCode: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    dateOfBirth: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },
    identifier: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },

    countryOfResidence: {
      container: css`
        width: 250px;
      `,
    },

    expiresOn: {
      input: css`
        font-size: 16px;
        border-color: #d5d4dc;
      `,
    },
  },

  type: css`
    width: max-content;

    margin-bottom: 16px;
    padding: 2px 4px;
    border-radius: 6px;
    background-color: ${Colors.NEUTRAL_LIGHT};

    color: ${Colors.NEUTRAL_DARK};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: 19px;
    text-transform: capitalize;
  `,

  title: css`
    margin-top: 16px;

    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.36px;
  `,
}
