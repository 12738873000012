import axios, { AxiosResponse } from 'axios'
import { TripIdea } from '~/entities/trip'

interface Response {
  total: number
  destinations: TripIdea[]
}

//keep request separated as request url might be different from main endpoint structure
export const getDestinations = async ({
  limit,
  offset,
  code,
  name,
}: {
  limit: number
  offset: number
  code?: string
  name?: string
}): Promise<Response> => {
  try {
    const response: AxiosResponse<Response> = await axios.get(
      `${process.env.AA_API_URL}/api/v1/hotels/destinations`,
      {
        params: {
          limit,
          offset,
          code,
          name,
        },
      },
    )

    return response.data
  } catch (error) {
    // Handle error
    throw new Error('Failed to retrieve destinations.')
  }
}
