import { useStore } from 'effector-react'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IMenuItem, ACCOUNT_MENU_ITEMS, BOOKINGS_MENU_ITEMS } from '../config'
import { $activeTab, activeTabChanged } from '../model'
import { MenuItem } from './menu-item'
import { styles } from './styles'

export const Sidebar: FC = () => {
  const { pathname } = useLocation()

  const activeMenuItem = useStore($activeTab)

  useEffect(() => {
    activeTabChanged(pathname)
  }, [pathname])

  const renderMenuItems = (items: IMenuItem[], activeItem: string) =>
    items.map(({ icon, name, path, action, disabled }) => (
      <MenuItem
        key={name}
        icon={icon}
        name={name}
        path={path}
        action={action}
        active={!!path && activeItem.startsWith(path)}
        disabled={disabled}
      />
    ))

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <h1 css={styles.title}>Your account</h1>
        {renderMenuItems(ACCOUNT_MENU_ITEMS, activeMenuItem)}
      </div>

      <div css={styles.container}>
        <h1 css={styles.title}>Trips</h1>
        {renderMenuItems(BOOKINGS_MENU_ITEMS, activeMenuItem)}
      </div>
    </div>
  )
}
