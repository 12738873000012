export const isImgUrlValid = (url: string) => {
  const img = new Image()
  img.src = url
  return new Promise<boolean>((resolve) => {
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}

export const getNextImage = (currentImage: string, images: string[]) => {
  let currentImageIndex = images?.findIndex((value) => currentImage.includes(value))
  if (currentImageIndex !== undefined && images?.[currentImageIndex++]) {
    return images?.[currentImageIndex++]
  }
  return ''
}
