import { createFactory } from '@withease/factories'
import { sample } from 'effector'
import { destinationPageModel } from '~/pages/destination'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { ManageLocalStorageForSearchFlights } from './manage-local-storage-with-search-flights'
import { SearchFlightsFactory } from './search-flights'
import { SearchFlightsOptionFactory } from './search-flights-option'

export const flightsAnalyticsFactory = createFactory(
  ({
    searchFlightsFactory,
    manageLocalStorageForSearchFlights,
    searchFlightsOptionFactory,
  }: {
    searchFlightsFactory: SearchFlightsFactory
    manageLocalStorageForSearchFlights: ManageLocalStorageForSearchFlights
    searchFlightsOptionFactory: SearchFlightsOptionFactory
  }) => {
    sample({
      clock: searchFlightsFactory.$fromAirport,
      fn: (fields) => ({
        name: eventNames.userLocationSelected,
        properties: {
          name: fields?.name,
          city_name: fields?.city_name,
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: manageLocalStorageForSearchFlights.$flightType,
      fn: (fields) => ({
        name: eventNames.flightsTypeSelected,
        properties: {
          type: fields,
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: searchFlightsOptionFactory.flightsOptionChanged,
      fn: (fields) => ({
        name: eventNames.flightsOptionSelected,
        properties: {
          option: fields,
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: manageLocalStorageForSearchFlights.flightsIncludeChanged,
      source: {
        isFlightsIncluded: manageLocalStorageForSearchFlights.$isFlightsIncluded,
      },
      fn: ({ isFlightsIncluded }) => ({
        name: eventNames.flightsIncluded,
        properties: {
          flights: isFlightsIncluded ? 'included' : 'excluded',
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: manageLocalStorageForSearchFlights.$selectedOffer,
      filter: (offer) => !!offer,
      fn: (offer) => ({
        name: eventNames.flightOfferSelected,
        properties: {
          offer_id: offer?.id,
          offer_type: offer?.type,
          offer_origin: offer?.directions?.[0].segments?.[0].origin?.cityName,
          offer_destination:
            offer?.directions?.[0].segments?.slice(-1)[0].destination?.cityName,
          offer_price: offer?.amount,
          offer_owner_name: offer?.owner?.name,
          offer_cabin_class: offer?.cabinClass,
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: destinationPageModel.bookingInitiated,
      source: manageLocalStorageForSearchFlights.$selectedOffer,
      filter: (offer) => !!offer,
      fn: (offer) => ({
        name: eventNames.flightOfferForBookingSelected,
        properties: {
          offer_id: offer?.id,
          offer_type: offer?.type,
          offer_origin: offer?.directions?.[0].segments?.[0].origin?.cityName,
          offer_destination:
            offer?.directions?.[0].segments?.slice(-1)[0].destination?.cityName,
          offer_price: offer?.amount,
          offer_owner_name: offer?.owner?.name,
          offer_cabin_class: offer?.cabinClass,
        },
      }),
      target: analyticsModel.track,
    })

    sample({
      clock: searchFlightsFactory.searchFlightsFx.done,
      filter: ({ result }) => result?.data?.offers?.length === 0,
      fn: ({ params }) => ({
        name: eventNames.emptyFlightsReceived,
        properties: {
          from: params.journeys[0].origin,
          to: params.journeys[0].destination,
          departure_date: params.journeys[0].departureDate,
          type: params.type,
        },
      }),
      target: analyticsModel.track,
    })
    sample({
      clock: searchFlightsFactory.searchFlightsFx.fail,
      fn: ({ params, error }) => ({
        name: eventNames.flightsRequestFailed,
        properties: {
          from: params.journeys[0].origin,
          to: params.journeys[0].destination,
          departure_date: params.journeys[0].departureDate,
          type: params.type,
          error: error.message,
        },
      }),
      target: analyticsModel.track,
    })
  },
)
