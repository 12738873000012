import { BookedTripResponse } from '~/entities/booking'
import { apiService, errorHandler } from '~/shared/api'

export const getBooking = async (bookingId: number): Promise<BookedTripResponse> => {
  try {
    return await apiService().getTripBooking({ id: bookingId })
  } catch (e) {
    return errorHandler(e)
  }
}
