import { CreateTripRequestBody, TripBooking } from '@softcery/awayaway-apiclient'
import { createEffect, createEvent, createStore, sample, split } from 'effector'

import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { ApiError, apiErrorHandler, apiService } from '~/shared/api'
import { LocalStorageKeys } from '~/shared/config'
import { generateFlightPassengersList } from './lib'
import { CreateBookingData } from './types'

export const createTripBookingFx = createEffect<
  CreateTripRequestBody,
  TripBooking,
  ApiError
>(async (fields) => {
  try {
    const { trip } = await apiService().createTrip({ fields })
    return trip!
  } catch (res: any) {
    return apiErrorHandler(res)
  }
})

export const $tripBooking = createStore<TripBooking | null>(null)
export const $tripPaymentClientToken = $tripBooking.map(
  (trip) => trip?.paymentClientToken ?? '',
)

export const $isCreating = createTripBookingFx.pending
export const $isBookingCreated = createStore(false)

export const stateReset = createEvent()
export const initiated = createEvent<CreateBookingData>()

export const bookingSuccessfullyCreated = createEvent<TripBooking>()
export const errorChanged = createEvent<string>()
export const validationErrorChanged = createEvent<Map<any, any> | null>()
export const errorsReset = createEvent()

export const $error = createStore<string | null>(null).on(
  errorChanged,
  (_, error) => error,
)

sample({
  clock: [initiated, createTripBookingFx.doneData],
  target: errorsReset,
})

reset({
  clock: errorsReset,
  target: $error,
})
sample({
  clock: initiated,
  fn: ({
    hotelCode,
    tripId,
    selectedRooms,
    additionalDetails,
    checkIn,
    checkOut,
    flightOffer,
    flightServices,
    contactDetails,
    guestGroups,
  }) => {
    const allGuests = Object.values(guestGroups).flat()

    const rooms = selectedRooms.map((room) => {
      const roomGuests = guestGroups[room.roomId].map((guest) => ({
        age: guest.age,
        name: guest.firstName,
        surname: guest.lastName,
        type: guest.type,
      }))

      return {
        guests: roomGuests,
        id: room.roomId,
        packageId: room.packageId,
        sessionId: room.sessionId,
      }
    })

    return {
      tripIdeaId: tripId,
      flight: flightOffer
        ? {
            selectedOfferId: flightOffer.flightId || '',
            selectedOfferResourceId: flightOffer.id || '',
            services: flightServices,
            passengers: generateFlightPassengersList(
              allGuests,
              flightOffer,
              contactDetails,
            ),
          }
        : undefined,

      hotel: {
        checkIn,
        checkOut,
        rooms,
        selectedCode: hotelCode || 0,
        leadName: contactDetails.firstName,
        leadSurname: contactDetails.lastName,
        leadEmail: contactDetails.leadEmail,
        leadPhone: contactDetails.leadPhone,
        userRequestMessage: additionalDetails ?? undefined,
      },
    } as CreateTripRequestBody
  },
  target: createTripBookingFx,
})

sample({
  clock: createTripBookingFx.doneData,
  target: $tripBooking,
})

sample({
  clock: createTripBookingFx.done,
  fn: () => true,
  target: $isBookingCreated,
})

sample({
  clock: createTripBookingFx.doneData,
  target: bookingSuccessfullyCreated,
})

sample({
  clock: createTripBookingFx.failData,
  fn: (error) => error.message,
  target: errorChanged,
})

reset({
  clock: stateReset,
  target: [$isBookingCreated, $error],
})

persist({ store: $tripBooking, key: LocalStorageKeys.TripBooking })
