import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { logoutModel } from '~/features/user/logout'
import {
  userIcon,
  lockIcon,
  bellIcon,
  logOutIcon,
  mapPinIcon,
} from '~/shared/assets/icons/primary'
import { AppRoute } from '~/shared/config'

export interface IMenuItem {
  icon: any
  name: string
  path: string
  action?: () => void
  disabled?: boolean
}

export const ACCOUNT_MENU_ITEMS = [
  { icon: userIcon, name: 'Personal Information', path: AppRoute.AccountPersonalInfo },
  { icon: lockIcon, name: 'Login & Password', path: AppRoute.AccountSecurity },
  { icon: bellIcon, name: 'Notifications', path: '#', disabled: true },
  {
    icon: logOutIcon,
    name: 'Log out',
    path: AppRoute.Destinations,
    action: () => {
      logoutModel.logOutInitialized()
      onboardingLayoutModel.stateReset()
    },
  },
]

export const BOOKINGS_MENU_ITEMS = [
  { icon: mapPinIcon, name: 'Manage Trips', path: AppRoute.AccountBookings },
]
