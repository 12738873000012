import { Rule } from 'effector-forms'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as yup from 'yup'

export const namePattern = /^[^\d\s]{2,50}$/gms
export const fullNamePattern = /^[^\d\s]{2,50}\s[^\d\s]{2,50}$/gms
export const passwordPattern = /^.*(?=.{6,})/

export function createRule<V, T = any>({
  schema,
  name,
}: {
  schema: yup.AnySchema<T>
  name: string
}): Rule<V> {
  return {
    name,
    validator: (v: V) => {
      try {
        schema.validateSync(v)
        return {
          isValid: true,
          value: v,
        }
      } catch (err: any) {
        return {
          isValid: false,
          value: v,
          errorText: err.message,
        }
      }
    },
  }
}

declare module 'yup' {
  interface StringSchema {
    isValidPhoneNumber(message: string): this
  }
}

const isValidDate = (dateString?: string): boolean => {
  if (!dateString) return false
  const [, , year] = dateString.split(/[\/.-]/).map(Number)

  const todayYear = new Date().getFullYear()

  return year < todayYear && year > todayYear - 120
}
const calculateAge = (dateString?: string): number => {
  if (!dateString) return 0
  const [day, month, year] = dateString.split(/[\/.-]/).map(Number)
  const birthDate = new Date(year, month - 1, day)
  const ageDifMs = Date.now() - birthDate.getTime()
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const adultAgeValidation = yup
  .string()
  .matches(
    /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
    'Please enter valid date of birth.',
  )
  .test('is-valid', 'Please enter valid date of birth.', function (value) {
    return isValidDate(value)
  })
  .test('is-adult', 'Adult age should be at least 12 years.', function (value) {
    return calculateAge(value) >= 12
  })

const childAgeValidation = yup
  .string()
  .matches(
    /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
    'Please enter valid date of birth.',
  )
  .test('is-valid', 'Please enter valid date of birth.', function (value) {
    return isValidDate(value)
  })
  .test('is-child', 'Child age should be less than 12 years.', function (value) {
    return calculateAge(value) < 12
  })

yup.addMethod(yup.string, 'isValidPhoneNumber', function (message) {
  return this.test('isValidPhoneNumber', message, function (value) {
    const { path, createError } = this
    const phoneNumber = parsePhoneNumberFromString(value ?? '')
    return (
      (phoneNumber && phoneNumber.isValid()) ||
      createError({ path, message: message || 'Invalid phone number' })
    )
  })
})

export const rules = {
  required: (errorMessage = 'Please enter a value') =>
    createRule({
      name: 'required',
      schema: yup.string().required(errorMessage),
    }),
  firstName: (errorMessage = 'Please enter your name') =>
    createRule({
      name: 'firstName',
      schema: yup.string().trim().matches(namePattern, errorMessage),
    }),
  lastName: (errorMessage = 'Please enter your last name') =>
    createRule({
      name: 'lastName',
      schema: yup.string().trim().matches(namePattern, errorMessage),
    }),
  fullName: () =>
    createRule({
      name: 'fullName',
      schema: yup.string().trim().matches(fullNamePattern, 'Please enter your full name'),
    }),
  leadEmail: () =>
    createRule({
      name: 'leadEmail',
      schema: yup
        .string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
    }),
  minLength: (length: number, errorText = `Must be at least ${length} characters`) =>
    createRule({
      name: 'minLength',
      schema: yup.string().trim().min(length, errorText),
    }),
  maxLength: (length: number, errorText = `Field requires maximum ${length} symbol`) =>
    createRule({
      name: 'maxLength',
      schema: yup.string().max(length, errorText),
    }),
  leadPhone: () =>
    createRule({
      name: 'leadPhone',
      schema: yup
        .string()
        .isValidPhoneNumber('Please enter a valid international phone number'),
    }),
  validDate: (errorMessage = 'Please enter valid date') => {
    return createRule({
      name: 'validDate',
      schema: yup
        .string()
        .matches(
          /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
          errorMessage,
        ),
    })
  },
  dateOfBirth: (isAdult: boolean) =>
    isAdult
      ? createRule({
          name: 'dateOfBirth',
          schema: adultAgeValidation,
        })
      : createRule({
          name: 'dateOfBirth',
          schema: childAgeValidation,
        }),
}
