import { FC } from 'react'
import { styles } from './styles'

interface Props {
  icon: string
  children: Element | string
}

export const KeyPoint: FC<Props> = ({ icon, children }) => {
  return (
    <div css={styles.container}>
      {icon && <img src={icon} />}
      {children}
    </div>
  )
}
