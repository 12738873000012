import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    max-width: 919px;
    width: 100%;
    background-color: ${Colors.NEUTRAL_WHITE};
    padding: 40px;
    border-radius: 8px;
    width: 998px;

    ${Breakpoints.LG} {
      width: 100%;
      min-width: 600px;
    }

    ${Breakpoints.MD} {
      width: 100%;
      min-width: unset;
      padding: 20px;
    }
  `,

  wrapper: css`
    border: 1px solid ${Colors.NEUTRAL_SILVER};
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
  `,

  heading: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,

  icon: css`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `,

  text: css`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${Colors.NEUTRAL_DARK};

    span {
      font-size: 14px;
      background-color: ${Colors.NEUTRAL_LIGHT};
      padding: 4px 6px;
      color: ${Colors.NEUTRAL_BLACK};
      margin-left: 5px;
      border-radius: 4px;
    }
  `,

  title: {
    main: css`
      font-family: ${Fonts.Joane};
      font-weight: 400;
      font-size: 32px;
      line-height: 38.4px;
      color: ${Colors.NEUTRAL_BLACK};

      margin-bottom: 10px;
    `,

    section: css`
      margin-top: 40px;

      font-weight: 500;
      font-size: 32px;
      line-height: 38.4px;
      color: ${Colors.NEUTRAL_BLACK};
    `,
  },

  section: css`
    padding: 16px 0;
    border-bottom: 1px solid ${Colors.NEUTRAL_LIGHT};
  `,

  borderNone: css`
    border: none;
  `,

  tile: {
    container: css`
      display: inline-block;

      :first-of-type {
        margin-right: 44px;
      }
    `,

    title: css`
      font-weight: 500;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      color: ${Colors.NEUTRAL_GREY};
      margin-bottom: 8px;
    `,
  },

  image: css`
    border-radius: 7px;
    margin-bottom: 15px;

    max-height: 136px;
    width: 100%;
    object-fit: cover;
  `,

  hotelName: css`
    font-weight: 400;
    font-size: 24px;
    line-height: 28.8px;
    color: ${Colors.NEUTRAL_BLACK};
  `,

  link: css`
    font-size: 20px;
    line-height: 27px;
    color: ${Colors.PRIMARY_GREEN};
    float: right;
  `,
  item: css`
    font-family: ${Fonts.Calibre};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2%;
    line-height: 27px;
    color: ${Colors.NEUTRAL_BLACK};

    :first-child {
      margin-top: 10px;
    }

    :not(:last-child) {
      margin-bottom: 10px;
    }
  `,

  room: {
    title: css`
      font-size: 18px;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.28px;
      display: flex;
      margin-top: 8px;

      ${Breakpoints.MD} {
        font-size: 16px;
      }
    `,

    board: css`
      display: flex;
      padding: 2px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: ${Colors.LIGHT_2};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.1;
      letter-spacing: 0.23px;
      margin-left: 8px;
      text-align: center;
    `,

    policy: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-top: 5px;
      svg {
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    `,
  },

  colorGrey: css`
    color: ${Colors.NEUTRAL_GREY};
  `,
}
