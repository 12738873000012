import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fafafa;
    min-height: 100vh;
  `,

  headerContainer: css`
    z-index: 4;
  `,

  title: css`
    margin-bottom: 64px;
    font-size: 32px;
    font-weight: normal;
  `,

  hotels: css`
    padding: 120px 0 93px 165px;
    background-color: #fafafa;

    ${Breakpoints.XL} {
      padding-left: 20px;
    }
  `,

  hotelsContainer: css`
    display: flex;
  `,

  hotelsCarouselArrows: css`
    top: 139px;
  `,

  bookingModalWrapper: css`
    max-width: 1140px;
  `,

  text: css`
    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    max-width: 650px;
    text-align: center;
    margin-top: 16px;
    margin-inline: auto;
  `,
}
