import { SerializedStyles } from '@emotion/react'
import { forwardRef } from 'react'
import MaskedInput, { Mask, MaskedInputProps } from 'react-text-mask'
import { Error, Label } from '../../atoms'
import { styles } from './styles'

export interface InputWithMaskProps extends MaskedInputProps {
  mask: Mask

  error?: string
  label?: string
  required?: boolean
  extendStyle?: {
    wrapper?: SerializedStyles
    input?: SerializedStyles
    label?: SerializedStyles
  }
}

export const InputWithMask = forwardRef<any, InputWithMaskProps>(
  (
    {
      mask,

      error,
      label,
      extendStyle,
      required,

      ...props
    },
    ref,
  ) => {
    return (
      <div css={[styles.wrapper, extendStyle?.wrapper]}>
        {label && (
          <Label required={required} extendStyles={styles.label}>
            {label}
          </Label>
        )}
        <MaskedInput
          ref={ref}
          mask={mask}
          css={[styles.input, error && styles.invalid, extendStyle?.input]}
          {...props}
        />
        {!!error && <Error>{error}</Error>}
      </div>
    )
  },
)
