import { css, keyframes } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

const loadingAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`

export const styles = {
  card: css`
    width: 100%;
    min-width: 255px;
    height: 100%;
    min-height: 270px;
    cursor: pointer;
    border-radius: 8px;
    aspect-ratio: 67 / 76;
    position: relative;
    background-color: ${Colors.LIGHT_GREY_2};
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 2s ${loadingAnimation} ease-in-out infinite;

    ${Breakpoints.SM} {
      min-width: 140px;
    }
  `,
}
