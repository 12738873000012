import { TripBooking } from '@softcery/awayaway-apiclient'
import { useGate, useStore } from 'effector-react'
import { BOOKING_STATUS } from '~/entities/booking'
import { formatDate, getRoomsLabelForCancelBookingSidebar } from '~/entities/booking/lib'
import { searchBookingModel } from '~/entities/booking/model'
import { AppRoute } from '~/shared/config'
import { Loader, Error } from '~/shared/ui'
import { ListItemCard } from '~/shared/ui/organisms'
import { bookingListPageModel } from '../'
import { getBookedDates } from '../lib'
import { styles } from './styles'

export const BookingsListPage = () => {
  useGate(bookingListPageModel.BookingsGate)

  const bookedTrips = useStore(searchBookingModel.$$getBookingsFactory.$bookedTrips)
  const error = useStore(searchBookingModel.$$getBookingsFactory.$error)
  const loading = useStore(searchBookingModel.$$getBookingsFactory.$loading)

  const getBookingRooms = (booking: TripBooking): { name: string }[] => {
    if (booking?.hotel?.packages) {
      return booking?.hotel?.packages?.map((p): { name: string } => ({
        name: p.name || '',
      }))
    }

    return []
  }

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Manage Trips</h1>

      {loading && (
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <Loader extendStyle={styles.states.loader} />
        </div>
      )}

      {error && <Error>{error}</Error>}

      {bookedTrips?.map((booking) => (
        <ListItemCard
          key={booking.id}
          image={booking.hotel?.hotelImages?.[0]}
          title={booking.name || ''}
          items={[
            `${formatDate('MMM D', booking?.hotel?.checkIn) || '-'} - ${
              formatDate('MMM D', booking?.hotel?.checkOut) || '-'
            }`,
            getRoomsLabelForCancelBookingSidebar(getBookingRooms(booking), true) ||
              'Unknown rooms',
            booking.hotel?.hotelDestinationName || 'Unknown location',
          ]}
          link={
            booking.hotel?.status === BOOKING_STATUS.BOOKED
              ? {
                  title: 'Manage Booking',
                  path: `${AppRoute.AccountBookings}/${booking.id}`,
                }
              : undefined
          }
          status={
            booking.hotel?.status !== BOOKING_STATUS.BOOKED
              ? booking.hotel?.status
              : undefined
          }
        />
      ))}
    </div>
  )
}
