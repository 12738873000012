import { css } from '@emotion/react'
import 'react-multi-carousel/lib/styles.css'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  arrow: {
    base: css`
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      cursor: pointer;
      transform: translateY(-50%);
      border-radius: 42px;
      background-color: ${Colors.LIGHT_1};
      box-shadow: 0 3px 40px 0 rgb(0 0 0 / 16%);
      & img {
        width: 20px;
        height: 20px;
      }
    `,

    prev: css`
      left: 10px;
    `,

    next: css`
      right: 10px;
      ${Breakpoints.MD} {
        right: 20px;
      }
    `,
  },
}
