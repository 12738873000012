import { SerializedStyles } from '@emotion/react'
import ReactSelect from 'react-select'
import { selectStyles, styles } from './styles'
import { SelectOption } from './types'

interface Props {
  label?: string
  selected?: SelectOption
  options: SelectOption[]
  onSelect: (value: string | number) => void
  isDisabled?: boolean
  extendStyles?: {
    container: SerializedStyles
  }
}

export const SelectWithLabel = ({
  label,
  selected,
  options,
  onSelect,
  extendStyles,
  isDisabled,
  ...props
}: Props) => {
  return (
    <div css={[styles.container, extendStyles?.container]}>
      {label && <label css={styles.label}>{label}</label>}

      <ReactSelect
        styles={selectStyles}
        options={options}
        onChange={(option) => onSelect(option?.value || 1)}
        defaultValue={selected || options[0]}
        isSearchable={false}
        isDisabled={isDisabled}
        {...props}
      />
    </div>
  )
}
