import { ListImageRecommendationsRequest } from '@softcery/awayaway-apiclient'
import { apiErrorHandler, apiService } from '~/shared/api'

export const getHolidaysRecommendations = async (
  body: ListImageRecommendationsRequest,
) => {
  try {
    return await apiService().listImageRecommendations(body)
  } catch (error) {
    return apiErrorHandler(error)
  }
}
