import { SerializedStyles } from '@emotion/utils'
import { useStore } from 'effector-react'
import { FC } from 'react'

import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { navigationModel } from '~/entities/navigation'
import { popupModel, PopupType } from '~/entities/popup'
import { viewerModel } from '~/entities/viewer'
import { accountIcon } from '~/shared/assets/icons'
import { AppRoute } from '~/shared/config'
import { Logo } from '~/shared/ui'

import { styles } from './styles'

interface Props {
  type: 'light' | 'dark'

  onLogoClick?: () => void
  extendStyles?: {
    container: SerializedStyles
  }
}

export const Header: FC<Props> = ({ onLogoClick, type, extendStyles }) => {
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  const logoClickHandler = () => {
    onLogoClick && onLogoClick()
    onboardingLayoutModel.logoClickedTracked()
  }

  const getAction = (route: AppRoute) => () =>
    isAuthorized
      ? navigationModel.pathChanged(route)
      : popupModel.visiblePopupChanged(PopupType.Login)

  return (
    <div css={[styles.container, extendStyles?.container]}>
      <div css={styles.wrapper}>
        <Logo onClick={() => logoClickHandler()} type={type} />

        <nav css={[styles.menu.container, type === 'light' && styles.menu.light]}>
          {/* <a css={styles.menu.item} href="#">
            <img src={heartIcon} alt="Saved trips" />
            Saved Trips
          </a> */}

          {/* <a css={styles.menu.item} href="#">
            <img src={messageIcon} alt="Messages" />
            Messages
          </a> */}

          {isAuthorized && (
            <a onClick={getAction(AppRoute.AccountBookings)} css={styles.menu.item}>
              <img src={accountIcon} alt="Account" />
              Account
            </a>
          )}
        </nav>
      </div>
    </div>
  )
}
