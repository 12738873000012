import { createEvent, createStore, sample } from 'effector'
import { viewerModel } from '~/entities/viewer'

export const logOutInitialized = createEvent()

export const $isLogouted = createStore(false)

sample({
  clock: logOutInitialized,
  target: viewerModel.reset,
})

sample({
  clock: logOutInitialized,
  fn: () => true,
  target: $isLogouted,
})
