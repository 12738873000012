import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'
import { NotificationVariant } from './index'

const notificationStyles = {
  default: css`
    background-color: #f8f8f8;

    &::before {
      background-color: ${Colors.PRIMARY_GREEN};
    }
  `,

  warning: css`
    background-color: #fdf6ed;

    &::before {
      background-color: #efa75b;
    }
  `,
}

export const styles = {
  wrapper: (variant: NotificationVariant) => css`
    margin-bottom: 24px;
    border-radius: 4px;
    overflow: hidden;
    padding: 8px 13px;
    position: relative;
    ${notificationStyles[variant]}

    ${Breakpoints.SM} {
      padding: 8px 10px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 5px;
      left: 0;
      top: 0;
      bottom: 0;
    }
  `,

  container: css`
    display: flex;
  `,

  content: css`
    margin-left: 8px;
  `,

  icon: css`
    min-width: 18px;
  `,

  title: css`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.36px;
    margin-bottom: 4px;
    color: ${Colors.NEUTRAL_DARK};
  `,

  text: css`
    p {
      font-size: 16px;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_DARK};
      span {
        font-weight: 500;
      }
    }
  `,
}
