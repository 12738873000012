import { createFactory } from '@withease/factories'
import { createEvent, createStore, restore, sample } from 'effector'
import { reset } from 'patronum'
import { MutatedTripIdea } from '~/entities/hotel/types'

export const manageHotelDetailsFactory = createFactory(() => {
  const resetState = createEvent()
  const closeHotelDetails = createEvent()

  const $isHotelDetailsViewed = createStore(false)

  const updateHotelDetails = createEvent<MutatedTripIdea>()
  const $hotelDetails = restore(updateHotelDetails, null)

  sample({
    clock: updateHotelDetails,
    fn: () => true,
    target: $isHotelDetailsViewed,
  })

  reset({
    clock: [resetState, closeHotelDetails],
    target: [$isHotelDetailsViewed],
  })

  return {
    $hotelDetails,
    $isHotelDetailsViewed,

    updateHotelDetails,
    resetState,
    closeHotelDetails,
  }
})
