import { SerializedStyles } from '@emotion/react'
import { FC, Fragment } from 'react'
import { Subtitle, Title } from '~/shared/ui'
import { styles } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: string
  description?: string
  extendStyles?: {
    title?: SerializedStyles
  }
}

export const Heading: FC<Props> = ({
  title,
  subtitle,
  description,
  extendStyles,
  ...rest
}) => (
  <div {...rest}>
    <Title extendStyles={extendStyles?.title ? extendStyles.title : styles.title}>
      {title}
    </Title>

    {subtitle && (
      <Fragment>
        <div css={styles.divider} />

        <Subtitle>{subtitle}</Subtitle>
      </Fragment>
    )}

    {description && <span css={styles.description}>{description}</span>}
  </div>
)
