import { SerializedStyles } from '@emotion/react'
import { TripIdeaHotelResponseDto } from '@softcery/awayaway-nodejs-api-client'
import { FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MutatedTripIdea } from '~/entities/hotel/types'
import { crossIcon } from '~/shared/assets/icons'
import { AddressIcon, FacilitiesIcon } from '~/shared/assets/icons/components'

import { chevronLeftIcon, chevronRightIcon } from '~/shared/assets/icons/grey'
import { normalizeStringCase } from '~/shared/lib/string'
import { Dimensions } from '~/shared/styles'
import { Button } from '~/shared/ui'
import { styles } from './styles'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/pagination'

import './styles.css'

interface Props {
  hotel: MutatedTripIdea | null
  className?: string
  selected?: boolean
  loading: boolean
  onSelect?: (hotel: MutatedTripIdea) => void
  onClose?: () => void
  onDetailsClick?: (hotel: TripIdeaHotelResponseDto) => void
  extendStyles?: {
    container?: SerializedStyles
  }
}

export const HotelDetails: FC<Props> = ({
  hotel,
  onSelect,
  selected,
  onClose,
  loading,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any | null>(null)
  const [images, setImages] = useState(hotel?.images || [])
  const isDesktop = useMediaQuery({ query: `(min-width: ${Dimensions.Lg}px)` })
  const [showAll, setShowAll] = useState(false)

  const city = normalizeStringCase(hotel?.city)

  const facilities = hotel?.facilities || []

  const maxFacilitiesItemsToShow = isDesktop ? 15 : 10

  const renderFacilities = showAll
    ? facilities
    : facilities.slice(0, maxFacilitiesItemsToShow)

  const cancelHandler = () => {
    onClose && onClose()
  }

  const handleImageError = (image: string) => {
    setImages((prevImages) => prevImages.filter((img) => img !== image))
  }
  if (!hotel) return null

  return (
    <div css={styles.wrapper}>
      <div css={styles.top}>
        <h3 css={styles.name}>{hotel.name}</h3>

        <button css={styles.closeBtn} onClick={onClose}>
          <img src={crossIcon} />
        </button>
      </div>

      <div css={styles.slider.wrapper}>
        {isDesktop && (
          <div>
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={{
                nextEl: '.custom-next',
                prevEl: '.custom-prev',
              }}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mainSlider"
            >
              {images!.map((image) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={image}
                >
                  <div className="blurred-background"></div>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}

              <div css={styles.slider.nav}>
                <div
                  className="custom-prev"
                  onClick={() => thumbsSwiper.current?.slidePrev()}
                >
                  <img className="custom-icon" src={chevronLeftIcon} />
                </div>
                <div
                  className="custom-next"
                  onClick={() => thumbsSwiper.current?.slideNext()}
                >
                  <img className="custom-icon" src={chevronRightIcon} />
                </div>
              </div>
            </Swiper>

            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={4}
              slidesPerView={8}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="thumbsSwiper"
            >
              {images!.map((image) => (
                <SwiperSlide key={image}>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!isDesktop && (
          <div>
            <Swiper
              loop={true}
              spaceBetween={10}
              navigation={{
                nextEl: '.custom-next',
                prevEl: '.custom-prev',
              }}
              modules={[FreeMode, Navigation, Thumbs, Pagination]}
              className="mainSlider"
              pagination={{
                dynamicBullets: true,
                dynamicMainBullets: 4,
              }}
            >
              {images!.map((image) => (
                <SwiperSlide
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={image}
                >
                  <div className="blurred-background"></div>
                  <img src={image} onError={() => handleImageError(image)} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div css={styles.slider.nav}>
              <div className="custom-prev">
                <img className="custom-icon" src={chevronLeftIcon} />
              </div>
              <div className="custom-next">
                <img className="custom-icon" src={chevronRightIcon} />
              </div>
            </div>
          </div>
        )}
      </div>

      <div css={styles.about.wrapper}>
        <div css={styles.about.title}>About</div>
        <div css={styles.about.content}>
          <div css={styles.about.tags}>
            <div css={styles.about.tagsItem}>
              <AddressIcon />
              {hotel.address}, {city}, {hotel.countryName}
            </div>
          </div>
        </div>
        <p css={styles.about.description}>{hotel.description}</p>
      </div>

      {!!hotel.facilities && (
        <div>
          <div css={styles.details.wrapper}>
            <div css={styles.details.title}>Amenities</div>
            <div css={styles.about.tags}>
              {!!renderFacilities.length &&
                renderFacilities.map((facility) => (
                  <div key={facility.facilityId} css={styles.about.tagsItem}>
                    <FacilitiesIcon />
                    {facility.name}
                  </div>
                ))}

              {facilities.length > maxFacilitiesItemsToShow && (
                <div css={styles.seeMoreButton} onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'View less' : 'View more'}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div css={styles.bottom.wrapper}>
        <Button onClick={cancelHandler} css={styles.bottom.cancelButton}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          css={styles.bottom.selectButton(selected)}
          onClick={() => {
            onSelect && onSelect(hotel)
            onClose && onClose()
          }}
        >
          Select hotel
        </Button>
      </div>
    </div>
  )
}
