import {
  FlightOffer,
  CreateTripRequestBodyFlightPassengersInner,
} from '@softcery/awayaway-apiclient'
import { formatDateStringToCustom } from '~/shared/lib/date'
import { ContactDetails } from '../add-contact-info'
import { Guest, GuestGender, GuestType } from '../add-guests-info'

export const generateFlightPassengersList = (
  guests: Guest[],
  flightOffer: FlightOffer,
  contactDetails: ContactDetails,
): CreateTripRequestBodyFlightPassengersInner[] => {
  const adultPassengers = flightOffer.passengers?.filter(
    (passenger) => passenger.type === 'adult',
  )
  const childrenPassengers = flightOffer.passengers?.filter(
    (passenger) => passenger.type === 'child',
  )

  let adultIndex = 0
  let childIndex = 0

  return guests.map((guest) => {
    let passengerId
    if (guest.type === GuestType.Adult) {
      passengerId = adultPassengers?.[adultIndex]?.id
      adultIndex += 1
    } else {
      passengerId = childrenPassengers?.[childIndex]?.id
      childIndex += 1
    }

    const formattedDateOfBirth = guest.dateOfBirth
      ? formatDateStringToCustom(guest.dateOfBirth, 'DD/MM/YYYY', 'YYYY-MM-DD')
      : ''
    const formattedExpiresOn = guest.expiresOn
      ? formatDateStringToCustom(guest.expiresOn, 'DD/MM/YYYY', 'YYYY-MM-DD')
      : ''

    // todo here is not correct type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const title =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof guest.title === 'string' ? guest.title : (guest?.title?.value as any)

    const gender =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof guest.gender === 'string' ? guest.gender : (guest?.gender?.value as any)

    return {
      id: passengerId || '',
      dob: formattedDateOfBirth,
      email: contactDetails.leadEmail,
      firstName: guest.firstName,
      lastName: guest.lastName,
      phoneNumber: contactDetails.leadPhone,
      title,
      gender: gender === GuestGender.Male ? 'm' : 'f',
      identityDocument: guest?.documentType
        ? {
            identifier: guest.identifier || '',
            issuingCountryCode: guest.issuingCountryCode || '',
            expiresOn: formattedExpiresOn,
            type: guest.documentType || '',
          }
        : undefined,
    }
  })
}
