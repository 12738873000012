import { createEvent, createStore, sample } from 'effector'
import { atom, bridge } from '~/shared/lib/factory'

export const fileUploadFactory = () =>
  atom(() => {
    const filesSelected = createEvent<File[]>()
    const fileRemoved = createEvent<File>()

    const $files = createStore<File[]>([])

    // files select logic
    bridge(() => {
      sample({
        clock: filesSelected,
        target: $files,
      })
      sample({
        clock: fileRemoved,
        source: $files,
        fn: (files, fileToRemove) =>
          files.filter((file) => file.name !== fileToRemove.name),
        target: $files,
      })
    })

    return {
      filesSelected,
      fileRemoved,
      $files,
    }
  })
