import { FC, Fragment } from 'react'
import { styles } from './styles'

interface Props {
  activeTab: number

  checkInDate: string
  checkOutDate: string

  dateRange: string
}

export const ScheduledDates: FC<Props> = ({
  activeTab,
  checkInDate,
  checkOutDate,
  dateRange,
}) => {
  return (
    <div css={styles.container}>
      {activeTab === 1 ? (
        <Fragment>
          <label css={styles.label}>Depart</label>
          <label css={styles.label}>Return</label>
        </Fragment>
      ) : (
        <label css={styles.label}>Date Range</label>
      )}

      <div css={styles.datesWrapper}>
        <div css={styles.mapMarker} />

        {activeTab === 1 ? (
          <Fragment>
            <div css={styles.checkInDate}>{checkInDate}</div>
            <div css={styles.checkOutDate}>{checkOutDate}</div>
          </Fragment>
        ) : (
          <div css={styles.dateRange}>{dateRange}</div>
        )}
      </div>
    </div>
  )
}
