import { sample } from 'effector'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { $$contactForm, contactDetailsReceived } from '.'

sample({
  clock: contactDetailsReceived,
  source: $$contactForm.$values,
  fn: ({ leadEmail, leadPhone, firstName, lastName }) => ({
    name: eventNames.completedContactsStep,
    properties: {
      email: leadEmail,
      phone: leadPhone,
      firstName: firstName,
      lastName: lastName,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: contactDetailsReceived,
  fn: () => ({
    name: eventNames.bookingContactsCompleted,
  }),
  target: analyticsModel.track,
})
