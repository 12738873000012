import { css } from '@emotion/react'

export const styles = {
  container: css`
    position: relative;
  `,

  label: css`
    margin-bottom: 8px;
  `,

  status: css`
    position: absolute;
    top: 6px;
    right: 0;
    bottom: 0;
    margin: auto 20px auto 0;
  `,
}
