import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { tripInfoModel } from '~/entities/trip-info'

import { LocalStorageKeys } from '~/shared/config'
import { Budget } from './types'

export const budgetReset = createEvent()
export const budgetUpdated = createEvent<Budget | null>()

export const $selectedBudget = createStore<Budget | null>(null)
persist({ store: $selectedBudget, key: LocalStorageKeys.SelectedBudget })

//Deselect on click if category is selected
sample({
  clock: budgetUpdated,
  source: $selectedBudget,
  fn: (selectedBudget, newBudget) => (selectedBudget === newBudget ? null : newBudget),
  target: $selectedBudget,
})

sample({
  clock: $selectedBudget,
  fn: (newBudget) => (newBudget ? newBudget.category : 'any'),
  target: tripInfoModel.priceCategoryChoosed,
})

reset({
  clock: budgetReset,
  target: $selectedBudget,
})
