import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  text: css`
    color: ${Colors.ERROR};
    font-size: 18px;

    &:first-letter {
      text-transform: capitalize;
    }
  `,
}
