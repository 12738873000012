import { Route, Routes, useLocation } from 'react-router-dom'

import { Header } from '~/widgets/header'
import { navigationModel } from '~/entities/navigation'
import { AppRoute } from '~/shared/config'
import { NavigationBack, WithHeader } from '~/shared/ui'
import { WithSidebar } from '~/shared/ui/layout/with-sidebar'

import { BookingCancelPage } from './bookings/booking-cancel'
import { BookingDetailsPage } from './bookings/booking-details'
import { BookingsListPage } from './bookings/bookings-list'
import { PersonalInfoPage } from './personal-info'
import { SecurityPage } from './security'
import { Sidebar } from './sidebar'
import { styles } from './styles'
import { BookingCancellationDetailsPage } from './bookings/booking-cancellation-details'

export const AccountPage = () => {
  const { pathname } = useLocation()

  const showBackToBookings =
    pathname.startsWith(AppRoute.AccountBookings) && pathname !== AppRoute.AccountBookings

  return (
    <WithHeader
      header={
        <Header
          onLogoClick={() => navigationModel.pathChanged(AppRoute.Destinations)}
          type="dark"
        />
      }
      extendStyles={styles.container}
    >
      {showBackToBookings && (
        <div css={styles.backToBookings.wrapper}>
          <NavigationBack
            to={AppRoute.AccountBookings}
            extendStyles={styles.backToBookings.link}
          >
            Back to Trips
          </NavigationBack>
        </div>
      )}

      <Routes>
        <Route path="bookings">
          <Route
            index
            element={
              <WithSidebar
                sidebarContent={<Sidebar />}
                position="left"
                main={<BookingsListPage />}
                extendsStyles={{ main: styles.fullWidth }}
              />
            }
          />

          <Route path=":bookingId" element={<BookingDetailsPage />} />

          <Route path=":bookingId/cancel" element={<BookingCancelPage />} />

          <Route
            path=":bookingId/cancellation-details"
            element={<BookingCancellationDetailsPage />}
          />
        </Route>

        <Route
          path="security"
          element={
            <WithSidebar
              sidebarContent={<Sidebar />}
              position="left"
              main={<SecurityPage />}
              extendsStyles={{ main: styles.fullWidth }}
            />
          }
        />

        <Route
          path="personal-info"
          element={
            <WithSidebar
              sidebarContent={<Sidebar />}
              position="left"
              main={<PersonalInfoPage />}
              extendsStyles={{ main: styles.fullWidth }}
            />
          }
        />
      </Routes>
    </WithHeader>
  )
}
