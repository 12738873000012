import { sample } from 'effector'
import { createGate } from 'effector-react'
import { cancelBookingModel, searchBookingModel } from '~/entities/booking'

import { navigationModel } from '~/entities/navigation'
import { AppRoute } from '~/shared/config'
import { atom, bridge } from '~/shared/lib/factory'

export const bookingCancelPageModel = atom(() => {
  const BookingCancelGate = createGate<number>()

  bridge(() => {
    sample({
      clock: BookingCancelGate.open,
      source: searchBookingModel.$$getBookingFactory.$bookedTripDetails,
      filter: (booking, bookingId) => booking?.id !== bookingId,
      fn: (_, bookingId) => bookingId,
      target: searchBookingModel.$$getBookingFactory.initiatedGetBooking,
    })
  })

  bridge(() => {
    sample({
      clock: cancelBookingModel.bookingCancelInitiated,
      source: searchBookingModel.$$getBookingFactory.$bookedTripDetails,
      fn: (booking) => ({ id: booking?.id || 0 }),
      target: cancelBookingModel.initiatedCancelBooking,
    })

    sample({
      clock: cancelBookingModel.updateCancelledBooking,
      filter: (booking) => !!booking,
      fn: (booking) => `${AppRoute.AccountBookings}/${booking?.id}/cancellation-details`,
      target: navigationModel.pathReplace,
    })
  })

  return {
    BookingCancelGate,
  }
})
