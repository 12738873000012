import { SerializedStyles } from '@emotion/react'
import * as ReactSlider from '@radix-ui/react-slider'
import { styles } from './styles'

interface Props extends ReactSlider.SliderProps {
  extendStyles?: {
    root?: SerializedStyles
    track?: SerializedStyles
    range?: SerializedStyles
    thumb?: SerializedStyles
  }
}

export const Slider = ({ extendStyles, ...props }: Props) => (
  <ReactSlider.Root css={[styles.root, extendStyles?.root]} {...props}>
    <ReactSlider.Track css={[styles.track, extendStyles?.track]}>
      <ReactSlider.Range css={extendStyles?.range} />
    </ReactSlider.Track>
    <ReactSlider.Thumb css={[styles.thumb, extendStyles?.thumb]} />
  </ReactSlider.Root>
)
