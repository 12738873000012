import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    height: 59px;

    display: flex;
    justify-content: center;
    gap: 100px;

    border-bottom: 1px solid ${Colors.NEUTRAL_SILVER};

    ${Breakpoints.SM} {
      gap: 50px;
    }
  `,
}
