import { Form } from 'effector-forms'

export type { SearchOfferPassenger as Passenger } from '@softcery/awayaway-apiclient'

export enum GuestTitle {
  Mr = 'mr',
  Mrs = 'mrs',
  Ms = 'ms',
}

export enum GuestType {
  Adult = 'AD',
  Child = 'CH',
}

export enum GuestGender {
  Male = 'Male',
  Female = 'Female',
}

export enum DocumentType {
  Passport = 'passport',
  TaxID = 'tax_id',
  KnownTravelerNumber = 'known_traveler_number',
  RedressNumber = 'redress_number',
}

export interface Guest {
  type: GuestType
  title: GuestTitle
  firstName: string
  lastName: string
  gender: GuestGender
  dateOfBirth: string
  age: number

  issuingCountryCode?: string
  expiresOn?: string
  documentType?: DocumentType
  identifier?: string
}

export type GuestGroups = Record<string, Guest[]>

export type GuestGroupForms = Record<string, Form<Guest>[]>
