import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const TagIcon: FC<Props> = ({ width = 16, height = 17, color = '#009F9D' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="tag">
        <path
          id="Vector"
          d="M13.7268 9.44001L8.94683 14.22C8.823 14.344 8.67595 14.4423 8.51408 14.5094C8.35222 14.5765 8.17872 14.6111 8.0035 14.6111C7.82828 14.6111 7.65477 14.5765 7.49291 14.5094C7.33105 14.4423 7.18399 14.344 7.06016 14.22L1.3335 8.50001V1.83334H8.00016L13.7268 7.56001C13.9752 7.80983 14.1146 8.14776 14.1146 8.50001C14.1146 8.85226 13.9752 9.19019 13.7268 9.44001V9.44001Z"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M4.6665 5.16666H4.67317"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
