import { TripBooking } from '@softcery/awayaway-apiclient'
import { createEvent, sample, split, createEffect, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { cancelBooking } from '~/entities/booking/model/cancel-booking/api'
import { ApiError } from '~/shared/api'
import { LocalStorageKeys } from '~/shared/config'
import { atom, bridge } from '~/shared/lib/factory'

export const cancelBookingModel = atom(() => {
  const resetState = createEvent()

  const initiatedCancelBooking = createEvent<{ id: number }>()
  const cancelBookingFx = createEffect<{ id: number }, TripBooking | undefined, ApiError>(
    cancelBooking,
  )

  const $loading = cancelBookingFx.pending
  const updateError = createEvent<string | null>()
  const $error = restore(updateError, null)

  const updateCancelledBooking = createEvent<TripBooking | null>()
  const $cancelledBooking = restore<TripBooking | null>(updateCancelledBooking, null)

  const bookingCancelInitiated = createEvent()

  bridge(() => {
    sample({
      clock: initiatedCancelBooking,
      target: cancelBookingFx,
    })
  })

  // handle success
  bridge(() => {
    sample({
      clock: cancelBookingFx.doneData,
      fn: (booking) => booking ?? null,
      target: updateCancelledBooking,
    })
  })

  // handle error
  bridge(() => {
    split({
      source: cancelBookingFx.failData,
      match: (error: ApiError) => error.status,
      cases: {
        500: updateError.prepend<ApiError>(
          () => 'Something went wrong. One of our team will look into this right away.',
        ),
        __: updateError.prepend<ApiError>(
          () =>
            'Automatic cancellation was not available. One of our team will look into this right away and be in touch. In the mean time you can contact us using the live chat feature or email us at development@awayaway.com.',
        ),
      },
    })
  })

  reset({
    clock: resetState,
    target: [$error, $cancelledBooking],
  })

  persist({ store: $cancelledBooking, key: LocalStorageKeys.CancelledBooking })
  return {
    $error,
    $loading,
    $cancelledBooking,

    resetState,
    bookingCancelInitiated,
    initiatedCancelBooking,
    updateCancelledBooking,
  }
})
