import { Global, SerializedStyles } from '@emotion/react'
import { FC, Fragment, useLayoutEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { crossErrorIcon } from '~/shared/assets/icons'
import { CrossIcon } from '~/shared/assets/icons/components'
import { useScrollLock } from '~/shared/lib/use-scroll-lock'
import { globalStyles, styles } from './styles'

interface Props {
  isOpen: boolean
  extendStyles?: {
    modal?: SerializedStyles
    wrapper?: SerializedStyles
  }
  centred?: boolean
  onClose?: () => void
}

export const NotificationPopup: FC<Props> = ({
  isOpen,
  extendStyles,
  onClose,
  centred,
  children,
}) => {
  const nodeRef = useRef(null)

  const [lockScroll, unlockScroll] = useScrollLock()

  useLayoutEffect(() => {
    if (isOpen) {
      lockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [isOpen])

  return (
    <Fragment>
      <Global styles={globalStyles} />
      <CSSTransition
        in={isOpen}
        timeout={500}
        nodeRef={nodeRef}
        classNames="modal"
        unmountOnExit
      >
        <section
          ref={nodeRef}
          onClick={onClose}
          css={[styles.modal(!!centred), extendStyles?.modal]}
          data-testid="modal"
        >
          <div
            css={[styles.wrapper, extendStyles?.wrapper]}
            onClick={(evt) => evt.stopPropagation()}
          >
            <div data-testid="modal-content">
              <div css={styles.content.wrapper}>
                {children}
                <div css={styles.content.closeButton} onClick={onClose}>
                  <CrossIcon width={18} height={18} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </CSSTransition>
    </Fragment>
  )
}
