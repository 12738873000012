import { FC } from 'react'

const elementHeight = 14

export const ButtonLoader: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.9210526315789473s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(45 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.7894736842105262s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(90 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.6578947368421052s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(135 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.5263157894736842s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.3947368421052631s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(225 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.2631578947368421s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(270 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="-0.13157894736842105s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(315 50 50)">
      <rect
        x="47.5"
        y="27"
        rx="2.5"
        ry="2.64"
        width="5"
        height={elementHeight}
        fill="#8e8e8e"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0526315789473684s"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
  </svg>
)
