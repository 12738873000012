import { createEffect, createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { selectBudgetModel } from '~/features/onboarding/select-budget'

export const Gate = createGate<void>()
export const stateReset = createEvent()
export const nextStepNavigated = createEvent()

const scrollToTopFx = createEffect(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

export const $isStepSkippable = createStore(true)

sample({
  clock: [selectBudgetModel.budgetUpdated, Gate.open],
  source: selectBudgetModel.$selectedBudget,
  fn: (selectedBudget) => !Boolean(selectedBudget),
  target: $isStepSkippable,
})

reset({
  clock: stateReset,
  target: [selectBudgetModel.$selectedBudget, $isStepSkippable],
})

sample({
  clock: [stateReset, Gate.open],
  target: scrollToTopFx,
})
