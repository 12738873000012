import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import ReactSelect from 'react-select'
import { SelectOption } from '../../types'
import { selectStyles, styles } from './styles'

interface Props {
  label?: string
  options: SelectOption[]
  onSelect: (duration: number) => void
  value: number
  extendStyles?: {
    container: SerializedStyles
  }
}

export const Select: FC<Props> = ({ label, options, onSelect, extendStyles, value }) => {
  return (
    <div css={[styles.container, extendStyles?.container]}>
      {label && <label css={styles.label}>{label}</label>}

      <ReactSelect
        styles={selectStyles}
        options={options}
        onChange={(option) => onSelect(option?.value || options[0].value)}
        value={options.find((option) => option.value === value)}
        isSearchable={false}
      />
    </div>
  )
}
