import { FC } from 'react'
import { styles } from './styles'

interface Props {
  title: string
}

export const Column: FC<Props> = ({ children, title }) => {
  return (
    <div css={styles.container}>
      <h6 css={styles.title}>{title}</h6>
      <p css={styles.value}>{children}</p>
    </div>
  )
}
