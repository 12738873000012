import { css } from '@emotion/react'
import { Breakpoints, Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    background-color: ${Colors.LIGHT_1};
    padding: 24px;
    width: 100%;
    border-radius: 8px;
    max-width: 800px;
    min-width: 800px;
    position: relative;

    ${Breakpoints.LG} {
      padding: 24px;
      max-width: 600px;
      min-width: unset;
    }

    ${Breakpoints.MD} {
      padding: 16px;
      max-width: 450px;
    }

    ${Breakpoints.SM} {
      padding: 16px 12px;
      max-width: 354px;
    }
  `,

  top: {
    wrapper: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    `,

    title: css`
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
    `,
  },

  cross: css`
    padding: 5px;
    cursor: pointer;
  `,

  title: css`
    font-family: ${Fonts.Joane};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 24px;
  `,

  highlights: {
    wrapper: css`
      padding: 12px;
      border-radius: 8px;
      background-color: ${Colors.LIGHT_2};

      margin-top: 24px;
    `,

    title: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      margin-bottom: 16px;
    `,

    list: css`
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
    `,

    item: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_DARK};
      svg {
        margin-right: 8px;
      }
    `,
  },

  amenities: {
    wrapper: css`
      padding: 32px 0 16px;
    `,

    heading: css`
      font-size: 20px;
      font-weight: 400;
      line-height: 27px; /* 135% */
    `,

    title: css`
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_BLACK};
      svg {
        margin-right: 12px;
      }
    `,

    row: css`
      display: flex;
      margin-top: 24px;
      width: 100%;

      ${Breakpoints.MD} {
        flex-direction: column;
        gap: 20px;
      }
    `,

    col: css`
      width: 50%;

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,
  },

  slider: {
    wrapper: css`
      position: relative;
    `,

    nav: css`
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 16px;

      ${Breakpoints.MD} {
        padding: 0 12px;
      }
    `,
  },

  tabs: {
    container: css`
      display: flex;
      justify-content: space-between;
      user-select: none;
      cursor: pointer;
    `,

    item: css`
      display: block;
      width: 100%;
      text-align: center;
      padding: 12px;
      font-size: 18px;
      line-height: 22px;
      color: ${Colors.NEUTRAL_BLACK};
      letter-spacing: 0.02em;
      border-bottom: 1px solid #d1d1d1;
    `,

    active: css`
      font-weight: 500;
      color: ${Colors.PRIMARY_GREEN};
      border-bottom: 2px solid #009f9d;
    `,
  },

  tooltip: {
    container: css`
      z-index: 2;
      padding: 12px 16px;
      margin-top: 5px;
      background-color: ${Colors.DARK_1};
      opacity: 0.8;
      border-radius: 8px;
    `,

    title: css`
      margin-bottom: 12px;
      font-weight: 500;
    `,

    text: css`
      font-size: 16px;
      font-weight: 400;
      color: ${Colors.LIGHT_1};
    `,

    boldText: css`
      font-weight: 500;
    `,

    helper: css`
      margin-top: 16px;
      opacity: 0.75;
    `,
  },

  content: {
    list: css`
      margin: 16px 32px 12px;
      list-style: disc !important;
    `,

    item: css`
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: ${Colors.NEUTRAL_DARK};
    `,

    shortList: css`
      overflow: hidden;
      list-style: disc;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    `,
  },

  bottom: {
    wrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      margin-top: 24px;

      ${Breakpoints.MD} {
        margin-top: 16px;
        justify-content: center;
      }
    `,

    row: css`
      display: flex;
      align-items: center;
      gap: 13px;

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,

    selectButton: (selected?: boolean) => css`
      background-color: ${selected ? Colors.LIGHT_GREY_2 : Colors.PRIMARY_GREEN};
      width: 160px;

      &:hover,
      &:focus {
        background-color: ${selected && Colors.LIGHT_GREY_2};
      }

      ${Breakpoints.MD} {
        font-size: 18px;
        width: auto;
      }

      ${Breakpoints.SM} {
        width: 50%;
      }
    `,

    cancelButton: css`
      background-color: ${Colors.LIGHT_1};
      border: 1px solid ${Colors.NEUTRAL_GREY};
      color: ${Colors.NEUTRAL_GREY};
      width: 160px;

      &:hover,
      &:focus {
        background-color: ${Colors.LIGHT_1};
        opacity: 0.5;
      }

      ${Breakpoints.MD} {
        width: auto;
        font-size: 18px;
      }

      ${Breakpoints.SM} {
        width: 50%;
      }
    `,
  },

  price: {
    perNight: css`
      font-size: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};
        margin-left: 2px;
      }
    `,

    total: css`
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 135.714% */
      letter-spacing: 0.28px;
      color: ${Colors.NEUTRAL_DARK};
    `,

    desktop: css`
      ${Breakpoints.MD} {
        display: none;
      }
    `,

    mobile: css`
      display: none;

      ${Breakpoints.MD} {
        display: block;
        margin-top: 24px;
      }
    `,
  },

  rates: {
    row: css`
      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
      padding-top: 24px;
      display: flex;
      gap: 20px;
      flex-direction: column;
    `,

    icon: css`
      margin-left: 8px;
    `,

    title: css`
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${Colors.NEUTRAL_DARK};
      margin-bottom: 12px;
    `,

    col: css`
      width: 100%;
    `,

    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    item: css`
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
    `,

    withoutChoice: {
      wrapper: css`
        border-top: 1px solid ${Colors.NEUTRAL_SILVER};
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        ${Breakpoints.MD} {
          margin-left: 0;
        }
      `,

      board: css`
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};
      `,

      policy: css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        color: ${Colors.NEUTRAL_DARK};

        span {
          color: ${Colors.PRIMARY_GREEN};
          margin-right: 6px;
        }
      `,
    },
    refundCost: css`
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.32px;
      color: ${Colors.NEUTRAL_BLACK};
      margin: 0 5px;
    `,

    mobileItem: css`
      display: flex;
    `,

    mobileTooltip: {
      wrapper: css`
        display: none;

        ${Breakpoints.MD} {
          display: block;
        }
      `,

      button: (isPolicyDetailsVisible: boolean) => css`
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: ${Colors.NEUTRAL_DARK};

        span {
          display: flex;
          align-items: center;
          margin-left: 4px;
          svg {
            transform: rotate(${isPolicyDetailsVisible ? '180deg' : '0deg'});
            transition: transform 0.3s;
          }
        }
      `,

      content: (isPolicyDetailsVisible: boolean) => css`
        padding-left: 28px;
        margin-top: 4px;
        font-size: 12px;
        color: ${Colors.NEUTRAL_GREY};
        display: ${isPolicyDetailsVisible ? 'block' : 'none'};
      `,
    },

    desktopTooltip: {
      wrapper: css`
        display: flex;
        ${Breakpoints.MD} {
          display: none;
        }
      `,
    },
  },

  rateComment: {
    container: css`
      border-top: 1px solid ${Colors.NEUTRAL_SILVER};
      margin-top: 32px;
      padding-top: 32px;
    `,

    wrapper: css``,

    title: css`
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-bottom: 24px;
      font-weight: 400;
      line-height: 27px;

      color: ${Colors.NEUTRAL_BLACK};

      svg {
        margin-right: 12px;
      }
    `,

    item: css`
      padding-left: 10px;
      overflow-wrap: break-word;
      &:before {
        content: '·';
        font-size: 1em;
        font-weight: 900;
        position: relative;
        margin-right: 5px;
        margin-left: -8px;
        color: ${Colors.NEUTRAL_GREY};
      }
    `,

    shortList: css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    `,

    button: css`
      position: relative;
      z-index: 3;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 2%;
      color: ${Colors.NEUTRAL_GREY};
      text-decoration: underline;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    `,
  },
}
