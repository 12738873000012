import { Button, ButtonProps, Loader } from '../..'
import { styles } from './styles'

interface Props extends ButtonProps {
  loading: boolean
}

export const ButtonWithLoader = ({ children, loading, ...rest }: Props) => (
  <Button css={styles.button(loading)} disabled={loading || rest.disabled} {...rest}>
    {children}
    {loading && <Loader extendStyle={styles.loader} />}
  </Button>
)
