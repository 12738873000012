import { RoomsResponseDto } from '@softcery/awayaway-nodejs-api-client'
import { createEvent, createStore, restore, sample } from 'effector'
import { navigationModel } from '~/entities/navigation'

export const stateReset = createEvent()
export const previewRoomChanged = createEvent<RoomsResponseDto>()
export const roomDetailsOpened = createEvent<RoomsResponseDto>()

export const $previewRoom = restore(previewRoomChanged, null)
export const $roomDetails = createStore<RoomsResponseDto>({} as RoomsResponseDto)

sample({
  clock: previewRoomChanged,
  fn: (room) => String(room?.targetRoomKey).replaceAll('.', '-'),
  target: navigationModel.pathPushed,
})

sample({
  clock: roomDetailsOpened,
  target: $roomDetails,
})
