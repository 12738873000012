import compose from 'compose-function'

import { withGlobalStyles } from './with-global-styles'
import { withNotifications } from './with-notifications'
import { withRouter } from './with-router'
import './with-token-refresh'
import './with-analytics'
import './with-dayjs'

export const withHocs = compose(withRouter, withGlobalStyles, withNotifications)
