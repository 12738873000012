import { css } from '@emotion/react'
import 'react-multi-carousel/lib/styles.css'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    margin-top: 32px;
    width: 100%;
    max-width: 1130px;
  `,
  root: css`
    position: relative;
    width: 100%;
  `,
  thumb: (thumbWidth: number, sliderWidth: number) => css`
    transition: 250ms all ease-out;
    height: 1.5px;
    width: ${((sliderWidth - 40 < 1130 ? sliderWidth - 40 : 1130) / 100) * thumbWidth}px;
    background: ${Colors.PRIMARY_GREEN};
    border-radius: 0%;
  `,
  track: css`
    height: 1.5px;
  `,
}
