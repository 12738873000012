import { SerializedStyles } from '@emotion/react'
import { FC, forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { formatToPrettyDate } from '~/shared/lib/date'
import { DropdownIndicator } from '~/shared/ui'

import { styles } from './styles'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  extendStyles?: SerializedStyles
}

interface SelectDateProps {
  onChange: (date: Date) => void
  currentDate: Date
  minDate: Date

  extendStyles?: {
    customInput?: SerializedStyles
  }
}

const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ value, onClick, extendStyles }, ref) => (
    <div css={[styles.container, extendStyles]} onClick={onClick} ref={ref}>
      <div css={styles.text}>{value}</div>
      <DropdownIndicator />
    </div>
  ),
)

export const SelectDate: FC<SelectDateProps> = ({
  onChange,
  currentDate,
  minDate,
  extendStyles,
}) => (
  <ReactDatePicker
    customInput={<CustomInput extendStyles={extendStyles?.customInput} />}
    minDate={minDate}
    value={formatToPrettyDate(currentDate)}
    selected={currentDate ? new Date(currentDate) : new Date()}
    onChange={onChange}
  />
)
