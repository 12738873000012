import { css, keyframes } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

const loadingAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,

  item: css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #dbdbdb33;
    border-radius: 10px;
    padding: 20px 24px;

    ${Breakpoints.MD} {
      padding: 16px;
    }
  `,

  block: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,

  title: css`
    width: 134px;
    height: 24px;
    background-color: #ececec99;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 2s ${loadingAnimation} ease-in-out infinite;
    border-radius: 10px;
  `,

  subtitle: css`
    width: 207px;
    height: 40px;
    background-color: #ececec99;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 2s ${loadingAnimation} ease-in-out infinite;
    border-radius: 10px;
  `,

  content: css`
    border-radius: 10px;
    width: 100%;
    height: 72px;

    background-color: #ececec99;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 3s ${loadingAnimation} ease-in-out infinite;

    ${Breakpoints.MD} {
      animation: 2s ${loadingAnimation} ease-in-out infinite;
    }
  `,
}
