import { SerializedStyles } from '@emotion/react'
import React, { FC, ImgHTMLAttributes, SyntheticEvent } from 'react'
import { placeholderImage } from '~/shared/assets/images/other'
import { getNextImage } from '~/shared/lib/images'
import { Title } from '~/shared/ui'
import { hotelBedsUrlProvider } from '../../atoms/image/hotelbeds-provider'
import { ImageUrlProvider } from '../../atoms/image/provider'
import { styles } from './styles'
interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  className?: string
  hotelName?: string
  images: string[]
  imageUrlProvider?: ImageUrlProvider
  placeholder?: string
  extendStyles?: {
    container?: SerializedStyles
    image?: SerializedStyles
    gradient?: SerializedStyles
    hotelName?: SerializedStyles
  }
  handleClick?: () => void
  isSingleHotel?: boolean
}
export const HotelImage: FC<Props> = ({
  hotelName,
  images,
  className,
  extendStyles,
  handleClick,
  imageUrlProvider = hotelBedsUrlProvider,
  placeholder = placeholderImage,
  isSingleHotel,
}) => {
  const handleError = ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
    currentTarget.onerror = null // prevents looping
    const nextImage = getNextImage(currentTarget.src, images || [])
    currentTarget.src = nextImage ? nextImage : (placeholder as string)
  }

  return (
    <div
      css={[styles.container(isSingleHotel), extendStyles?.container]}
      className={className}
      onClick={handleClick}
    >
      <img
        src={images?.[0]}
        css={[styles.image, extendStyles?.image]}
        onError={handleError}
      />

      {/* leave until redesign questions are clear */}
      {hotelName && (
        <>
          <div css={[styles.gradient, extendStyles?.gradient]} />
          <Title css={[styles.hotelName, extendStyles?.hotelName]}>{hotelName}</Title>
        </>
      )}
    </div>
  )
}
