import { css, keyframes } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

const loadingAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding-bottom: 60px;
  `,

  container: css`
    display: flex;
    width: 100%;
    gap: 24px;

    border: 1.5px solid ${Colors.NEUTRAL_SILVER};
    padding: 20px;
    border-radius: 10px;

    ${Breakpoints.MD} {
      display: flex;
      flex-direction: column;
    }
  `,

  content: css`
    width: 100%;
  `,

  image: css`
    border-radius: 8px;
    width: 240px;
    min-width: 240px;
    height: 160px;

    ${Breakpoints.MD} {
      width: 100%;
      min-width: unset;
      height: auto;
      min-height: 240px;
    }

    ${Breakpoints.SM} {
      min-height: 200px;
    }
  `,

  info: {
    wrapper: css`
      width: 100%;
      display: flex;

      border-bottom: 1px solid ${Colors.LIGHT_GREY_2};
      padding-bottom: 24px;

      ${Breakpoints.MD} {
        flex-direction: column;
      }
    `,

    col: css`
      width: 50%;

      &:nth-child(2) {
        padding-left: 230px;

        ${Breakpoints.MD} {
          padding-left: 0;
        }
      }

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,

    title: css`
      width: 100%;
      height: 30px;
      border-radius: 25px;
      margin-bottom: 14px;
    `,

    facilities: css`
      width: 100%;
      height: 18px;
      border-radius: 25px;
      margin-bottom: 14px;
    `,

    details: css`
      width: 15%;
      border-radius: 25px;
      height: 18px;
      margin-top: 24px;

      ${Breakpoints.MD} {
        width: 35%;
      }
    `,

    price: {
      button: css`
        border-radius: 25px;
        height: 18px;
        width: 100%;
        margin-bottom: 6px;

        ${Breakpoints.MD} {
          display: none;
        }
      `,

      value: css`
        border-radius: 25px;
        height: 18px;
        width: 50%;

        ${Breakpoints.MD} {
          display: none;
        }
      `,
    },
  },

  board: {
    row: css`
      display: flex;
      padding-top: 16px;
      width: 70%;
      gap: 100px;

      ${Breakpoints.MD} {
        width: 100%;
        gap: 25px;
        flex-direction: column;
      }
    `,

    col: css`
      width: 50%;

      ${Breakpoints.MD} {
        width: 100%;
      }
    `,

    title: css`
      width: 25%;
      height: 18px;
      border-radius: 25px;
      margin-bottom: 16px;

      ${Breakpoints.MD} {
        width: 35%;
      }
    `,

    item: css`
      width: 100%;
      height: 18px;
      border-radius: 25px;
      margin-bottom: 10px;
    `,
  },

  animation: css`
    background-color: ${Colors.LIGHT_GREY_2};
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      ${Colors.LIGHT_GREY_2};
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 2s ${loadingAnimation} ease-in-out infinite;
  `,
}
