import { GroupedRateDto } from '@softcery/awayaway-nodejs-api-client'
import { useStore } from 'effector-react'
import { FC } from 'react'
import { hotelRoomModel } from '~/entities/room'
import { getPriceWithCurrency } from '~/shared/lib/currency'
import { normalizeStringEnding } from '~/shared/lib/string'
import { Rate } from '../rate'
import { styles } from './styles'

interface Props {
  rates: GroupedRateDto[]
  selectedRate: GroupedRateDto | null
  onSelect: (rate: GroupedRateDto) => void
}

export const BoardsList: FC<Props> = ({ rates, selectedRate, onSelect }) => {
  const hotelCurrency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)

  const isSelected = (rate: GroupedRateDto) => {
    return selectedRate && selectedRate.baseBoardName === rate.baseBoardName
  }

  return (
    <div css={styles.wrapper}>
      {rates.map((rate) => {
        return (
          <Rate
            key={rate.baseBoardName}
            rate={rate}
            title={normalizeStringEnding(rate.baseBoardName)}
            content={
              <p css={styles.rateContent}>
                {getPriceWithCurrency(rate.baseAmount || 0, hotelCurrency || '')}
              </p>
            }
            isSelected={!!isSelected(rate)}
            onSelect={() => onSelect(rate)}
          />
        )
      })}
    </div>
  )
}
