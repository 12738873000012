import { CarouselInternalState, ButtonGroupProps } from 'react-multi-carousel'
import { useWindowSize } from 'react-use'
import { Slider } from '~/shared/ui/atoms/slider'
import { styles } from './styles'

interface CustomSlideProps extends ButtonGroupProps {
  updateCarousel: (args: Partial<CarouselInternalState>) => void
}

export const CustomSlider = ({ carouselState, updateCarousel }: CustomSlideProps) => {
  const { width } = useWindowSize()

  if (!carouselState) return null

  const { transform, itemWidth, totalItems, slidesToShow } = carouselState

  if (slidesToShow === totalItems) return null

  const maxTranslateX = Math.round(itemWidth * (totalItems - slidesToShow))
  const value = maxTranslateX / 100 // calculate the unit of transform for the slider
  const visiblePercent = (slidesToShow / totalItems) * 100

  const handleInputChange = (changedValue: number[]) => {
    const nextTransform = changedValue[0] * value
    const nextSlide = Math.round(nextTransform / itemWidth)

    updateCarousel({
      transform: -nextTransform,
      currentSlide: nextSlide,
    })
  }

  return (
    <div css={styles.container}>
      <Slider
        value={[Math.round(Math.abs(transform) / value)]}
        max={(itemWidth * (totalItems - slidesToShow)) / value}
        onValueChange={handleInputChange}
        extendStyles={{ thumb: styles.thumb(visiblePercent, width), track: styles.track }}
      />
    </div>
  )
}
