import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,

  title: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.NEUTRAL_GREY};
    margin-bottom: 8px;
  `,

  value: css`
    font-family: Calibre;
    font-weight: 400;
    font-size: 20px;
    color: ${Colors.NEUTRAL_BLACK};
  `,
}
