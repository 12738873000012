import { normalizeStringCase } from '../lib/string'

export const errorHandler = async (
  res: any,
  errorMessage?: string,
  setFieldError?: (message: string) => void,
): Promise<never> => {
  if (res.response instanceof Response) {
    const data = await res.response.json()
    const parsedErrorMessage = data?.message || data.error.message || 'Unknown error'

    const fullErrorMessage = errorMessage
      ? `${errorMessage}: ${parsedErrorMessage}`
      : normalizeStringCase(parsedErrorMessage)

    if (res.status === 500) {
      console.error(fullErrorMessage, data?.audit || '')
    } else {
      console.debug(fullErrorMessage, data?.audit || '')
    }

    setFieldError?.(fullErrorMessage)

    if (data.message === 'validation failed') {
      throw data
    }

    throw new Error(normalizeStringCase(fullErrorMessage))
  }

  if (res instanceof Error) {
    const fullErrorMessage = errorMessage
      ? `${errorMessage}: ${res.message || 'Unknown error'}`
      : res.message || 'Unknown error'

    console.error(fullErrorMessage)

    throw new Error(normalizeStringCase(fullErrorMessage))
  }
  throw new Error('Unknown error')
}

//refactor throwing custom exceptions
export class ApiError extends Error {
  status: number
  validationFields?: Map<any, any>

  constructor(message: string, status: number, validationFields?: Map<any, any>) {
    super(message)
    this.status = status
    this.validationFields = validationFields
    Object.setPrototypeOf(this, ApiError.prototype)
  }
}

export const apiErrorHandler = async (res: any) => {
  const data = await res.response.json()

  const message = data.message || data.error.message || 'Unknown error'
  const status = res.response.status

  if (status === 500) {
    console.error(message, data?.audit || '')
  } else {
    console.debug(message, data?.audit || '')
  }

  // handling validation errors
  if (message === 'invalid request body' && data.error.validationErrors) {
    const apiValidationErrors: any = Object.entries(data.error.validationErrors)
    const validationFields = new Map(apiValidationErrors)
    throw new ApiError(message, status, validationFields)
  }

  throw new ApiError(message, status)
}
