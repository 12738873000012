import { SearchFlightOfferOptionsRequest } from '@softcery/awayaway-apiclient'
import { ListOfferOptionsRes } from '~/entities/flights/types'
import { apiService, errorHandler } from '~/shared/api'

export const searchFlightsOption = async (
  fields: SearchFlightOfferOptionsRequest,
): Promise<ListOfferOptionsRes> => {
  try {
    return await apiService().searchFlightOfferOptions(fields)
  } catch (e) {
    return errorHandler(e)
  }
}
