import { DependencyList, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Dimensions } from '~/shared/styles'

export const useActiveDay = (deps?: DependencyList) => {
  const [offsetY, setOffsetY] = useState(650)
  const [activeDay, setActiveDay] = useState(0)

  const itineraryDaysRef = useRef<HTMLDivElement>(null)

  const isMobile = useMediaQuery({ query: `(max-width: ${Dimensions.Md}px)` })
  const isDesktop = useMediaQuery({ query: `(max-width: 1440px)` })

  useEffect(() => {
    if (isMobile) {
      setOffsetY(200)
    }
    if (isDesktop) {
      setOffsetY(550)
    } else {
      setOffsetY(650)
    }
  }, [isMobile])

  useEffect(() => {
    if (itineraryDaysRef.current) {
      // Get all itinerary days
      const daysHTMLCollection = itineraryDaysRef.current?.children || []

      const days = [...daysHTMLCollection]

      // for vertical (Y) scroll
      const scrollYDayHighlighter = () => {
        // Get current scroll position
        const scrollY = window.scrollY

        days.forEach((current, index) => {
          // Day container parameters
          const dayHeight = current.clientHeight
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dayTop = current.offsetTop - offsetY
          const day = index + 1

          // Define and set current active day
          if (scrollY > dayTop && scrollY <= dayTop + dayHeight) {
            setActiveDay(day)
          }
        })

        // Itinerary container parameters
        const containerHeight = itineraryDaysRef.current?.clientHeight || 0
        const containerTop = (itineraryDaysRef.current?.offsetTop || 0) - offsetY

        // Reset active day if scroll is outside itinerary
        if (scrollY < containerTop && scrollY <= containerTop + containerHeight) {
          setActiveDay(0)
        }
      }

      // Apply highlighting first time
      scrollYDayHighlighter()
      // Add an event listener listening for scroll
      window.addEventListener('scroll', scrollYDayHighlighter)

      return () => window.removeEventListener('scroll', scrollYDayHighlighter)
    }
  }, [itineraryDaysRef.current, ...(deps || [])])

  return { itineraryDaysRef, activeDay }
}
