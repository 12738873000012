import { css } from '@emotion/react'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
  `,

  base: css`
    color: ${Colors.NEUTRAL_GREY};
    font-size: 18px;
    padding: 0px;
  `,

  next: css`
    margin-left: 24px;
    color: ${Colors.LIGHT_1};
    background-color: ${Colors.PRIMARY_GREEN};
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border-radius: 4px;
    padding: 11.5px 20px;
  `,

  prevButton: css`
    ${Breakpoints.SM} {
      display: none;
    }
  `,
}
