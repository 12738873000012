import { FC } from 'react'

export interface Props {
  width?: number
  height?: number
  color?: string
}

export const ZapIcon: FC<Props> = ({ width = 20, height = 21, color = '#333333' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10.8333 2.16669L2.5 12.1667H10L9.16667 18.8334L17.5 8.83335H10L10.8333 2.16669Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
