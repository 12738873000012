import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'
import { ButtonVariant } from './index'

const buttonStyles = {
  default: css`
    padding: 14px 32px;
    color: ${Colors.LIGHT_1};
    border: 0;
    background-color: ${Colors.PRIMARY_GREEN};

    &:hover,
    &:focus {
      background-color: ${Colors.ACCENT_3};
    }

    &:disabled {
      cursor: default;
      background-color: ${Colors.LIGHT_GREY_1};
    }
  `,

  link: css`
    color: ${Colors.NEUTRAL_BLACK};
    border: 0;
    background-color: transparent;

    &:hover,
    &:focus {
      color: ${Colors.DARK_GREY_2};
    }

    &:disabled {
      cursor: default;
      color: ${Colors.LIGHT_GREY_1};
    }
  `,

  outlined: css`
    padding: 14px 32px;
    color: ${Colors.NEUTRAL_BLACK};
    border: 1px solid ${Colors.PRIMARY_GREEN};
    background-color: transparent;

    &:hover,
    &:focus {
      color: ${Colors.ACCENT_3};
      border: 1px solid ${Colors.ACCENT_3};
    }

    &:disabled {
      cursor: default;
      color: ${Colors.LIGHT_GREY_1};
      border: 1px solid ${Colors.LIGHT_GREY_1};
    }
  `,
}

export const styles = {
  button: (variant: ButtonVariant) => css`
    cursor: pointer;
    user-select: none;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.2px;
    ${buttonStyles[variant]}

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
  `,
}
