import { FC } from 'react'
import { InfoIcon } from '~/shared/assets/icons/components'
import { styles } from './styles'

export type NotificationVariant = 'default' | 'warning'

interface Props {
  variant?: NotificationVariant
  title?: string
}

export const Notification: FC<Props> = ({ children, title, variant = 'default' }) => {
  return (
    <div css={styles.wrapper(variant)}>
      <div css={styles.container}>
        <div css={styles.icon}>
          <InfoIcon />
        </div>
        <div css={styles.content}>
          {title && <span css={styles.title}>{title}</span>}
          <div css={styles.text}>{children}</div>
        </div>
      </div>
    </div>
  )
}
