export enum STEPS {
  TRIP_INFO = 'trip-info',
  FLIGHTS = 'flights',
  CONTACTS = 'contacts',
  PAYMENT = 'payment',
  SUMMARY = 'summary',
}


export const STEP_NUMBERS = {
  [STEPS.TRIP_INFO]: 1,
  [STEPS.CONTACTS]: 2,
  [STEPS.FLIGHTS]: 3,
  [STEPS.PAYMENT]: 4,
  [STEPS.SUMMARY]: 5,
}