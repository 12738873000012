import { SerializedStyles } from '@emotion/react'

import { FC } from 'react'
import { FlexiblePolicyDetails } from './flexible-policy-details'
import { StrictPolicyDetails } from './strict-policy-details'

interface Props {
  policies: any[]
  currency: string
  isStrict?: boolean
  extendStyles?: {
    focusedText?: SerializedStyles
    tooltip?: SerializedStyles
  }
}

export const CancellationPolicy: FC<Props> = ({ isStrict, ...props }) => {
  if (isStrict) return <StrictPolicyDetails {...props} />
  return <FlexiblePolicyDetails {...props} />
}
