import { useGate, useStore } from 'effector-react'
import { useEffect, useState } from 'react'
import Drawer from 'react-modern-drawer'
import { useLocation, useParams } from 'react-router-dom'

import { Header } from '~/widgets/header'
import { TripFeedback, TripFeedbackModal, tripFeedbackModel } from '~/features/feedback'
import {
  RegenerateItineraryPopup,
  regenerateItineraryModel,
} from '~/features/regenerate-itinerary'
import { Offers } from '~/features/search-flights'
import { searchFlightsModel } from '~/features/search-flights/model'
import { chooseTripDatesModel } from '~/features/trip-booking/choose-trip-dates'
import { SelectDates } from '~/features/trip-booking/choose-trip-dates/ui/select-dates'
import { SelectRoom } from '~/features/trip-booking/select-room'
import { HotelsList, hotelsForTripModel } from '~/entities/hotel'
import { MutatedTripIdea } from '~/entities/hotel/types'
import { HotelDetails } from '~/entities/hotel/ui'
import { navigationModel } from '~/entities/navigation'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { Itineraries } from '~/entities/trip/ui/itineraries'
import { DestinationDetails } from '~/entities/trip/ui/trip-details'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ReturnIcon,
} from '~/shared/assets/icons/components'
import { getFormattedPrice } from '~/shared/lib/currency'
import { formatToCustom, formatToDate, isDatesSame } from '~/shared/lib/date'
import { normalizeStringCase } from '~/shared/lib/string'
import { ButtonWithLoader, Modal, Title, WithHeader } from '~/shared/ui'

import {
  bookingInitiated,
  changeForbidDoReqForItineraries,
  DestinationDetailsGate,
} from '../model'
import { styles } from './styles'
import { TripDetailsNavigation } from './trip-details-navigation'

//import styles
import 'react-modern-drawer/dist/index.css'

const formatDate = (stringDate: string) => {
  const date = formatToDate(stringDate, 'YYYY-MM-DD')
  return formatToCustom(date, 'ddd, DD MMM')
}

export const DestinationPage = () => {
  const { pathname } = useLocation()
  const { destinationCode } = useParams()

  useGate(DestinationDetailsGate, destinationCode)

  const destination = useStore(destinationModel.$selectedDestination)
  const hotels = useStore(hotelsForTripModel.$$manageHotelFactory.$hotelsForTrip)
  const hotelCard = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )
  const hotelDetails = useStore(
    hotelsForTripModel.$$manageHotelDetailsFactory.$hotelDetails,
  )

  const isModalOpen = useStore(
    hotelsForTripModel.$$manageHotelDetailsFactory.$isHotelDetailsViewed,
  )
  const isFeedbackModalOpen = useStore(tripFeedbackModel.$feedbackModalOpened)
  const datesRange = useStore(chooseTripDatesModel.$range)
  const hotelsFromSelectedTripWithPrices = useStore(
    hotelsForTripModel.$$manageHotelFactory.$hotelsFromSelectedTripWithPrices,
  )
  const { error } = useStore(hotelRoomModel.$$manageRoomsDataFactory.$getRoomsStatus)

  const loading = useStore(hotelRoomModel.$$getRoomsFactory.$loading)
  const isRoomsListEmpty = useStore(
    hotelRoomModel.$$manageRoomsDataFactory.$isRoomsListEmpty,
  )
  const hotelCurrency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)
  const lowestTotalRate = useStore(
    hotelRoomModel.$$manageRoomsDataFactory.$lowestTotalRateAmount,
  )
  const lowestDailyRates = useStore(chooseTripDatesModel.$availableDatesWithRates)
  const unavailableDates = useStore(chooseTripDatesModel.$unavailableDates)
  const checkOutOnlyDates = useStore(chooseTripDatesModel.$checkOutOnlyDates)
  const isCalendarVisible = useStore(chooseTripDatesModel.$isCalendarVisible)
  const lastCheckOutDate = useStore(chooseTripDatesModel.$lastCheckOutDate)
  const isGetLastCheckOutDatePending = useStore(
    chooseTripDatesModel.$isGetLastCheckoutPending,
  )

  const isDateRangeInvalid = useStore(chooseTripDatesModel.$isDateRangeInvalid)
  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)
  const selectedFlightOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )
  const flightsLoading = useStore(searchFlightsModel.$loading)
  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsIncluded,
  )
  const flightsType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )
  const flightDirections = selectedFlightOffer?.directions
  const flightOrigin = flightDirections?.[0].segments?.[0].origin?.cityName
  const flightDestination =
    flightDirections?.[0].segments?.slice(-1)[0].destination?.cityName

  const hotelCode = hotelCard?.code
  const resultsRoute = pathname.split('/')[1]
  const totalRoomsPrice = selectedRooms.reduce((acc, room) => acc + room.price!, 0)
  const totalPrice =
    totalRoomsPrice + (isFlightsIncluded ? selectedFlightOffer?.amount || 0 : 0)
  const totalRooms = selectedRooms.length

  const isRoomLoading = useStore(hotelRoomModel.$$getRoomsFactory.$loading)
  const isProceedToBookingDisabled = selectedRooms.length === 0 || isDateRangeInvalid

  const [isOpen, setIsOpen] = useState(false)

  const selectedDates = useStore(chooseTripDatesModel.$selectedDateForDestinations)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    if (!destination) {
      setTimeout(() => navigationModel.pathReplace(resultsRoute), 100)
    }
  }, [destination])

  const onClose = () => {
    chooseTripDatesModel.calendarClosed()
    changeForbidDoReqForItineraries(false)
    destinationModel.regenerateTripItinerariesWhenDateChanged()
  }
  if (!datesRange) {
    return null
  }
  const { startDate, endDate } = datesRange

  const selectedDate = selectedDates?.find(
    (dest) => dest.destinationCode === destinationCode,
  )

  const onSelect = (hotel: MutatedTripIdea) => {
    hotelsForTripModel.$$manageHotelFactory.updateSelectedHotelForTrip(hotel)
  }

  return (
    <WithHeader
      header={
        <Header
          onLogoClick={() => navigationModel.pathChanged(resultsRoute)}
          type="dark"
          extendStyles={{ container: styles.headerContainer }}
        />
      }
      extendStyles={styles.container}
    >
      <TripDetailsNavigation />

      <div css={styles.trip.container} id="overview">
        {destination && (
          <DestinationDetails
            destination={destination}
            onReturnClick={() => navigationModel.pathChanged(resultsRoute)}
          />
        )}

        <div css={styles.trip.wrapper} id="hotels">
          {hotels && (
            <div css={styles.hotels(hotels?.length === 1)}>
              <Title css={styles.title}>
                {hotels?.length === 1 ? 'Hotel' : 'Hotels'} chosen for you
              </Title>
              <HotelsList
                hotels={hotels}
                hotelCode={hotelCode}
                onSelect={onSelect}
                onDetailsClick={
                  hotelsForTripModel.$$manageHotelDetailsFactory.updateHotelDetails
                }
                selectedDate={selectedDate}
                loading={loading}
                isRoomLoading={isRoomLoading}
                hotelsFromSelectedTripWithPrices={
                  hotelsFromSelectedTripWithPrices ?? undefined
                }
              />
            </div>
          )}

          <div id="rooms">
            <SelectRoom />
          </div>
        </div>

        <div css={styles.flights.wrapper} id="flights">
          <div css={styles.toggle.container}>
            <input
              checked={isFlightsIncluded}
              type="checkbox"
              id="flights-included"
              css={styles.toggle.checkbox}
              onChange={(value) =>
                searchFlightsModel.$$manageLocalStorageForSearchFlights.flightsIncludeChanged(
                  value.target.checked,
                )
              }
            />
            <label htmlFor="flights-included">Include flights</label>
          </div>

          {isFlightsIncluded && (
            <div>
              <Title css={styles.title}>Flights chosen for you</Title>
              <p>
                Flights displayed are appropriate for your trip – sorted by lowest price.
              </p>

              <Offers />
            </div>
          )}
        </div>

        <div css={styles.trip.itineraries} id="itinerary">
          <Itineraries onRegenerate={regenerateItineraryModel.openPopup} />

          <RegenerateItineraryPopup />
        </div>
      </div>

      <div css={styles.floating.wrapper}>
        <div css={styles.floating.container}>
          <div css={styles.floating.row}>
            <div css={styles.floating.feedback.wrapper}>
              <div css={styles.floating.feedback.title}>Rate your recommendations</div>
              <TripFeedback />
            </div>

            <div css={styles.hotelInfo.wrapper}>
              <div css={[styles.hotelInfo.item, styles.hotelInfo.name]}>
                <span css={styles.hotelInfo.title}>Hotel</span>
                <p css={[styles.hotelInfo.value, styles.hotelInfo.nameHover]}>
                  {hotelCard?.name}
                  <span css={styles.hotelInfo.tooltip}>{hotelCard?.name}</span>
                </p>
              </div>

              <div css={[styles.hotelInfo.item, styles.hotelInfo.dates]}>
                <span css={styles.hotelInfo.title}>Dates </span>
                <div css={styles.hotelInfo.row}>
                  <p css={styles.hotelInfo.value}>
                    {startDate && endDate
                      ? formatToCustom(startDate, 'ddd, DD MMM') +
                        ' - ' +
                        (isDatesSame(startDate, endDate)
                          ? ''
                          : formatToCustom(endDate, 'ddd, DD MMM'))
                      : 'Select dates'}
                  </p>

                  <span
                    css={styles.floatingMobile.item.edit}
                    onClick={() => {
                      changeForbidDoReqForItineraries(true)
                      chooseTripDatesModel.calendarOpened()
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>

              {isFlightsIncluded && !!selectedFlightOffer && (
                <div
                  css={[
                    styles.hotelInfo.item,
                    styles.hotelInfo.flighs,
                    styles.hotelInfo.nameHover,
                  ]}
                >
                  <span css={styles.hotelInfo.title}>Flights</span>
                  <p css={styles.hotelInfo.type}>{normalizeStringCase(flightsType)}</p>

                  {isFlightsIncluded && !!selectedFlightOffer && (
                    <span css={styles.hotelInfo.flightTooltip}>
                      {`${flightOrigin}  `}
                      <ReturnIcon />
                      {`${flightDestination}`}
                    </span>
                  )}
                </div>
              )}

              <div css={[styles.hotelInfo.item, styles.hotelInfo.price]}>
                <span css={styles.hotelInfo.title}>Total</span>
                <p css={styles.hotelInfo.value}>
                  {loading ? 'Loading...' : `£${getFormattedPrice(totalPrice)}`}
                </p>
              </div>
            </div>

            <ButtonWithLoader
              disabled={isProceedToBookingDisabled || flightsLoading}
              onClick={() => bookingInitiated()}
              css={styles.floating.button}
              loading={flightsLoading}
            >
              Proceed to booking
            </ButtonWithLoader>
          </div>

          <div css={styles.floatingMobile.wrapper}>
            <div css={styles.floatingMobile.container}>
              <div css={styles.floatingMobile.bottom.wrapper}>
                <div css={styles.floatingMobile.bottom.container}>
                  <div css={styles.floatingMobile.item.wrapper}>
                    <div css={styles.floatingMobile.item.title}>Price</div>
                    <div css={styles.floatingMobile.item.row}>
                      {loading ? 'Loading...' : `£${getFormattedPrice(totalPrice)}`}
                    </div>
                  </div>

                  <ButtonWithLoader
                    disabled={isProceedToBookingDisabled || flightsLoading}
                    onClick={() => bookingInitiated()}
                    css={styles.floating.button}
                    loading={flightsLoading}
                  >
                    Proceed to booking
                  </ButtonWithLoader>
                </div>
              </div>

              <div css={styles.floatingMobile.viewMore.wrapper}>
                <button
                  onClick={toggleDrawer}
                  css={styles.floatingMobile.viewMore.button}
                >
                  View more <ChevronUpIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        enableOverlay={false}
        css={styles.drawer}
      >
        <div css={styles.floatingMobile.wrapper}>
          <div css={styles.floatingMobile.container}>
            <div css={styles.floatingMobile.info}>
              <div css={styles.floatingMobile.item.wrapper}>
                <div css={styles.floatingMobile.item.title}>Hotel</div>
                <div css={styles.floatingMobile.item.row}>{hotelCard?.name}</div>
              </div>

              <div
                css={[
                  styles.floatingMobile.item.wrapper,
                  styles.floatingMobile.item.border,
                ]}
              >
                <div css={styles.floatingMobile.item.title}>Dates</div>
                <div css={styles.floatingMobile.item.row}>
                  {startDate && endDate
                    ? formatToCustom(startDate, 'ddd, DD MMM') +
                      ' - ' +
                      (isDatesSame(startDate, endDate)
                        ? ''
                        : formatToCustom(endDate, 'ddd, DD MMM'))
                    : 'Select dates'}

                  <span
                    css={styles.floatingMobile.item.edit}
                    onClick={() => {
                      changeForbidDoReqForItineraries(true)
                      chooseTripDatesModel.calendarOpened()
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>

              <div
                css={[
                  styles.floatingMobile.item.wrapper,
                  styles.floatingMobile.item.border,
                ]}
              >
                <div css={styles.floatingMobile.item.title}>Rooms</div>
                <div css={styles.floatingMobile.item.row}>
                  {totalRooms} {totalRooms === 1 ? 'room' : 'rooms'}
                </div>
              </div>

              <div
                css={[
                  styles.floatingMobile.item.wrapper,
                  styles.floatingMobile.item.border,
                ]}
              >
                <div css={styles.floatingMobile.item.title}>Flights</div>
                <div css={styles.floatingMobile.item.row}>
                  <p css={styles.hotelInfo.type}>{normalizeStringCase(flightsType)}</p>
                </div>
              </div>
            </div>

            <div css={styles.floatingMobile.bottom.wrapper}>
              <div css={styles.floatingMobile.bottom.container}>
                <div css={styles.floatingMobile.item.wrapper}>
                  <div css={styles.floatingMobile.item.title}>Price</div>
                  <div css={styles.floatingMobile.item.row}>
                    {loading ? 'Loading...' : `£${getFormattedPrice(totalPrice)}`}
                  </div>
                </div>

                <ButtonWithLoader
                  disabled={isProceedToBookingDisabled || flightsLoading}
                  onClick={() => bookingInitiated()}
                  css={styles.floating.button}
                  loading={flightsLoading}
                >
                  Proceed to booking
                </ButtonWithLoader>
              </div>
            </div>

            <div css={styles.floatingMobile.viewMore.wrapper}>
              <button onClick={toggleDrawer} css={styles.floatingMobile.viewMore.button}>
                View less <ChevronDownIcon />
              </button>
            </div>
          </div>
        </div>
      </Drawer>

      <Modal
        isOpen={isModalOpen}
        onClose={() => hotelsForTripModel.$$manageHotelDetailsFactory.closeHotelDetails()}
      >
        <HotelDetails
          hotel={hotelDetails}
          onClose={() =>
            hotelsForTripModel.$$manageHotelDetailsFactory.closeHotelDetails()
          }
          onSelect={hotelsForTripModel.$$manageHotelFactory.updateSelectedHotelForTrip}
          loading={loading}
          selected={hotelDetails?.code === hotelCode}
        />
      </Modal>

      <Modal isOpen={isCalendarVisible} onClose={onClose}>
        <SelectDates
          range={datesRange}
          checkInDate={datesRange.startDate}
          checkOutDate={datesRange.endDate}
          totalPrice={isRoomsListEmpty || loading ? 0 : lowestTotalRate}
          currency={hotelCurrency}
          lowestDailyRates={lowestDailyRates}
          unavailableDates={unavailableDates?.map((date) => formatToDate(date))}
          checkOutOnlyStringDates={checkOutOnlyDates}
          isConfirmDatesDisabled={isDateRangeInvalid || !!error}
          onClose={onClose}
          error={error}
          lastCheckOutDate={lastCheckOutDate}
          isGetLastCheckoutLoading={isGetLastCheckOutDatePending}
          isRoomsListEmpty={isRoomsListEmpty}
          loading={loading}
        />
      </Modal>

      <Modal
        isOpen={isFeedbackModalOpen}
        onClose={() => tripFeedbackModel.closeFeedbackModal()}
      >
        <TripFeedbackModal />
      </Modal>
    </WithHeader>
  )
}
