import { useGate, useStore } from 'effector-react'
import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { cancelBookingModel } from '~/entities/booking'
import { normalizeStringCase } from '~/shared/lib/string'
import { Loader } from '~/shared/ui'
import { bookingCancellationDetailsPageModel } from '../model'
import { styles } from './styles'

export const BookingCancellationDetailsPage: FC = () => {
  const { bookingId } = useParams()

  useGate(
    bookingCancellationDetailsPageModel.BookingCancellationDetailsGate,
    Number(bookingId),
  )

  const booking = useStore(cancelBookingModel.$cancelledBooking)

  const totalRefundAmount =
    booking?.paymentTransactions?.slice(-1)[0]?.refunds?.slice(-1)[0].amount || 0

  const origin = booking?.flight?.segments?.[0]?.origin?.cityName || ''
  const destination = booking?.flight?.segments?.[0]?.destination?.cityName || ''
  const flightType = booking?.flight?.offerType || ''

  if (!booking) {
    return (
      <Fragment>
        <Loader />
      </Fragment>
    )
  }

  return (
    <div css={styles.container}>
      <header css={styles.header.container}>
        <h1 css={styles.header.title}>Your booking has been cancelled.</h1>
        <p css={styles.header.reference}>
          We have emailed you a breakdown of your cancellation, which is also listed
          below.
        </p>
      </header>

      <img src={booking.hotel?.hotelImages?.[0]} css={styles.image} alt={'Trip Image'} />

      <h1 css={styles.details.title}>{booking?.hotel?.hotelName}</h1>
      <p css={styles.details.subtitle}>
        {booking?.hotel?.hotelDestinationName || 'Destination'}
      </p>

      <div css={styles.divider}></div>

      {booking?.flight && (
        <>
          <h1 css={styles.details.title}>Flight</h1>
          <div css={styles.flights.wrapper}>
            <p css={styles.details.subtitle}>{`${origin} - ${destination}`}</p>
            <span css={styles.flights.type}>{normalizeStringCase(flightType)}</span>
          </div>
          <div css={styles.divider}></div>
        </>
      )}

      {!!booking?.flight && !booking?.flight?.isCancellable ? (
        <>
          <p css={styles.summary}>
            We’ve issued a refund for hotel booking of{' '}
            <span css={styles.accentColor}>£{totalRefundAmount}</span> to your payment
            method.
          </p>

          <p css={styles.flightsSummary}>
            Flight offer will be manually canceled by our team.
          </p>
        </>
      ) : (
        <p css={styles.summary}>
          We’ve issued a refund of{' '}
          <span css={styles.accentColor}>£{totalRefundAmount}</span> to your payment
          method.
        </p>
      )}
    </div>
  )
}
