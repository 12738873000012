import { css } from '@emotion/react'
import { StylesConfig } from 'react-select'
import { GuestsOption } from '~/entities/trip-info'
import { Breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    ${Breakpoints.SM} {
      width: 100%;
    }
  `,

  title: css`
    display: inline-block;

    font-size: 18px;
    line-height: 21.6px;
    letter-spacing: 2%;
    color: #000000;

    margin-right: 12px;
  `,

  options: css`
    display: flex;
    gap: 25px;
    margin-top: 12px;

    ${Breakpoints.SM} {
      flex-direction: column;
      width: 100%;
    }
  `,

  button: css`
    border: 0;
    background: transparent;

    font-size: 18px;
    line-height: 21.37px;
    color: ${Colors.PRIMARY_GREEN};

    cursor: pointer;

    margin-top: 40px;
    &:disabled {
      cursor: default;
      color: ${Colors.LIGHT_GREY_1};
    }
  `,

  ages: {
    container: css`
      margin-top: 16px;
    `,

    title: css`
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: ${Colors.NEUTRAL_BLACK};
    `,

    dropdowns: css`
      margin-top: 8px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    `,
  },
}

export const innerStyles: StylesConfig<GuestsOption, false> = {
  container: (provided) => ({
    ...provided,
    width: '53px',
    fontSize: '18px',
  }),

  menu: (provided) => ({
    ...provided,
    margin: 0,
    zIndex: 100,
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 220,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? Colors.PRIMARY_GREEN : 'white',
    cursor: 'pointer',
  }),

  control: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    width: '53px',
    height: '36px',

    border: `1px solid #D5D4DC`,
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '9px 8px 9px 8px',

    cursor: 'pointer',
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),

  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: '10px',
  }),
}
