import { useGate } from 'effector-react'
import { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { AvailabilityPage } from '~/pages/onboarding/availability'

import { animationDataRequested } from '~/entities/loader/model'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { AppRoute } from '~/shared/config'

import { BudgetPage } from './budget'
import { GuestsPage } from './guests'
import { HolidaysPage } from './holidays'
import { LocationsPage } from './locations'
import { SummaryPage } from './summary'

//TODO: think about handling routes in separate file

export const Onboarding: FC = () => {
  useGate(onboardingSessionModel.Gate)
  const { pathname } = useLocation()

  useEffect(() => {
    onboardingSessionModel.stepChanged(location.pathname)
  }, [pathname])

  useEffect(() => {
    animationDataRequested()

    //test scroll event for webflow
    let hasScrolled = false
    function handleScroll() {
      if (!hasScrolled) {
        window.parent.postMessage({ scrollEvent: 'IFRAME_SCROLL_STARTED' }, '*')

        hasScrolled = true
        window.removeEventListener('scroll', handleScroll)
      }
    }

    window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <Routes>
      <Route path={AppRoute.Inspiration} element={<HolidaysPage />} />
      <Route path={AppRoute.Destination} element={<LocationsPage />} />
      <Route path={AppRoute.Guests} element={<GuestsPage />} />
      <Route path={AppRoute.Budget} element={<BudgetPage />} />
      <Route path={AppRoute.Dates} element={<AvailabilityPage />} />
      <Route path={AppRoute.Summary} element={<SummaryPage />} />
      <Route path="*" element={<Navigate to={AppRoute.Destination} />} />
    </Routes>
  )
}
