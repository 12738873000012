import { OccupancyOptions } from '@softcery/awayaway-nodejs-api-client'
import { useStore } from 'effector-react'
import { FC, useState } from 'react'

import {
  CustomSelectedHotelRoom,
  hotelRoomModel,
  SelectedHotelRoom,
} from '~/entities/room'
import { ChevronDownIcon, UserIcon } from '~/shared/assets/icons/components'
import { Room } from '../room'
import { styles } from './styles'

interface Props {
  rooms?: CustomSelectedHotelRoom[]
  occupancy?: OccupancyOptions
  roomAdded?: any
  listIndex: number
}

export const RoomsList: FC<Props> = ({ rooms, occupancy, listIndex, roomAdded }) => {
  const [viewMore, setViewMore] = useState(false)
  const [customRooms, setCustomRooms] = useState<CustomSelectedHotelRoom[]>(rooms || [])
  const hotelCurrency = useStore(hotelRoomModel.$$manageRoomsDataFactory.$hotelCurrency)

  const sortRoomsList = (isPreselect = false) => {
    let room: CustomSelectedHotelRoom = null!

    if (isPreselect) {
      room =
        customRooms?.find((room) =>
          room.groupedRates?.find((rate) => {
            return rate.baseBoardCode === room.preselectedRate?.boardCode
          }),
        ) || null!
    } else {
      room = customRooms?.find((room) => room.isSelected) || null!
    }

    const roomIndex = customRooms?.indexOf(room)

    if (roomIndex && roomIndex !== -1) {
      setCustomRooms([
        room,
        ...customRooms!.slice(0, roomIndex),
        ...customRooms!.slice(roomIndex + 1),
      ])
    }
  }

  const viewMoreHandler = () => {
    const newValue = !viewMore
    setViewMore(newValue)
    if (!newValue) {
      sortRoomsList()
    }
  }

  const onSelectRoom = (
    room: SelectedHotelRoom,
    listIndex: number,
    roomIndex: number,
  ) => {
    const rooms = customRooms?.map((room) => ({
      ...room,
      isSelected: false,
    }))

    rooms![roomIndex].isSelected = true

    setCustomRooms(rooms)
    roomAdded({ room, listIndex })
  }

  return (
    <div>
      <div css={styles.rooms.heading.wrapper}>
        <span css={styles.rooms.heading.num}>Room {listIndex + 1}</span>

        <span css={styles.rooms.heading.quests}>
          <UserIcon /> {occupancy?.adults} adults
        </span>

        {!!occupancy?.children && (
          <span css={styles.rooms.heading.quests}>
            <UserIcon /> {occupancy?.children} children
          </span>
        )}
      </div>

      <div css={styles.rooms.wrapper}>
        {(customRooms.length > 1 && viewMore ? customRooms : [customRooms[0]]).map(
          (room, roomIndex) => (
            <Room
              key={`${roomIndex}-${listIndex}`}
              room={room}
              occupancy={occupancy!}
              roomIndex={roomIndex}
              listIndex={listIndex}
              currency={hotelCurrency!}
              onSelect={(room) => onSelectRoom(room, listIndex, roomIndex)}
            />
          ),
        )}

        {customRooms!.length > 1 && (
          <button css={styles.viewButton(viewMore)} onClick={() => viewMoreHandler()}>
            {viewMore ? 'Show less' : 'More options'}

            <ChevronDownIcon color={'#333333'} />
          </button>
        )}
      </div>
    </div>
  )
}
