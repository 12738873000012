import { createEffect, createEvent, createStore, sample } from 'effector'
import { or, reset, spread } from 'patronum'

import { viewerModel } from '~/entities/viewer'
import { apiService, errorHandler, updateApiServiceToken } from '~/shared/api'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { atom } from '~/shared/lib/factory'

import { notification } from '~/shared/lib/notification'
import { logOutInitialized } from '../logout/model'
import { closeWindow, redirectToOauth } from './lib'

interface OAuthType {
  accessToken: string
  refreshToken: string
  userId: string
}

export const oauthModel = atom(() => {
  const initiated = createEvent<string>()
  const oAuthLoginInitiated = createEvent<OAuthType>()
  const oAuthFailed = createEvent<string>()
  const userDataUpdated = createEvent()

  const requestOauthUrlFx = createEffect(async (fields: { type: string }) => {
    try {
      return await apiService().redirectUser({ provider: fields.type })
    } catch (res: any) {
      return errorHandler(res)
    }
  })

  const redirectToOauthFx = createEffect(redirectToOauth)

  const updateApiServiceTokenFx = createEffect(updateApiServiceToken)

  const closeWindowFx = createEffect(closeWindow)

  const getLoggedInUserFx = createEffect(async () => {
    try {
      const { user } = await apiService().getUser()
      return user!
    } catch (res: any) {
      return errorHandler(res)
    }
  })

  const $isPending = or(requestOauthUrlFx.pending, redirectToOauthFx.pending)
  const $isLoggedInByOAuth = createStore(false)

  sample({
    clock: initiated,
    fn: (type) => ({ type }),
    target: requestOauthUrlFx,
  })

  sample({
    clock: requestOauthUrlFx.doneData,
    fn: () => ({
      name: eventNames.quizAuthCompleted,
      properties: {
        type: 'google',
      },
    }),
    target: analyticsModel.track,
  })

  sample({
    clock: requestOauthUrlFx.doneData,
    fn: ({ redirectURL }) => redirectURL!,
    target: redirectToOauthFx,
  })

  sample({
    clock: oAuthLoginInitiated,
    fn: ({ accessToken }) => accessToken,
    target: updateApiServiceTokenFx,
  })

  spread({
    source: oAuthLoginInitiated,
    targets: {
      accessToken: viewerModel.accessTokenUpdated,
      refreshToken: viewerModel.refreshTokenUpdated,
    },
  })

  sample({
    clock: oAuthLoginInitiated,
    fn: () => true,
    target: $isLoggedInByOAuth,
  })

  sample({
    clock: oAuthLoginInitiated,
    target: getLoggedInUserFx,
  })

  sample({
    clock: getLoggedInUserFx.doneData,
    target: viewerModel.updated,
  })

  sample({
    clock: getLoggedInUserFx.doneData,
    target: userDataUpdated,
  })

  sample({
    clock: getLoggedInUserFx.doneData,
    fn: ({ email, fullName }) => ({
      properties: {
        email: email,
        full_name: fullName,
      },
    }),
    target: analyticsModel.trackConversion,
  })

  notification({
    clock: oAuthFailed,
    title: 'Google authorization failed',
    message: (message) => `${message}`,
    type: 'error',
  })

  reset({
    clock: logOutInitialized,
    target: $isLoggedInByOAuth,
  })

  return {
    initiated,
    oAuthLoginInitiated,
    redirectToOauthFx,
    getLoggedInUserFx,
    $isPending,
    $isLoggedInByOAuth,
    oAuthFailed,
    requestOauthUrlFx,
    userDataUpdated,
  }
})
