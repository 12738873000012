import { useEffect, useState } from 'react'

export const usePartialVisibilityGutter = ({
  width,
  slideWidth,
  itemsToShow,
}: {
  width: number
  slideWidth: number
  itemsToShow: number[]
}) => {
  const [partialVisibilityGutter, setPartialVisibilityGutter] = useState([0, 0, 0])

  useEffect(() => {
    //calculate gutter based in fully visible items number
    const partialVisibilityGutterToShow = itemsToShow.map((item) => {
      const gutterWidth = (width - slideWidth * item) / item
      return gutterWidth > 0 ? gutterWidth : 0
    })
    setPartialVisibilityGutter(partialVisibilityGutterToShow)
  }, [width])
  return { partialVisibilityGutter }
}
