import { errorHandler } from '~/shared/api'

// we will use this api when on the backend side will overwrite logic
export const getHotelsForTrip = async (ids: number[]): Promise<any> => {
  try {
    return await []
  } catch (e) {
    return errorHandler(e)
  }
}
