import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  wrapper: css`
    position: relative;
  `,

  label: css`
    margin-bottom: 8px;
  `,

  input: css`
    padding: 12px;
    width: 100%;
    border: 1px solid #eaeaea;

    font-size: 18px;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: ${Colors.NEUTRAL_BLACK};

    &:active,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${Colors.NEUTRAL_GREY};
    }
  `,

  invalid: css`
    color: ${Colors.ERROR};
    border: 1px solid ${Colors.ERROR};
  `,
}
