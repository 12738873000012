import { useStore } from 'effector-react'
import { FC } from 'react'

import { searchFlightsModel } from '~/features/search-flights/model'
import { addGuestsInfoModel } from '~/features/trip-booking/add-guests-info'
import { createBookingModel } from '~/features/trip-booking/create-booking'
import { formatBookingDates } from '~/entities/booking/lib'
import { SidebarInfoTile } from '~/entities/booking/ui/sidebar-info-tile'
import { hotelsForTripModel } from '~/entities/hotel'

import { hotelRoomModel } from '~/entities/room'
import { destinationModel } from '~/entities/trip'
import { tripInfoModel } from '~/entities/trip-info'
import { CheckedBagIcon } from '~/shared/assets/icons/components'
import { normalizeStringCase, normalizeStringEnding } from '~/shared/lib/string'

import { getAllBags, hasServiceType } from '../lib'
import { styles } from './styles'

export const Overview: FC = () => {
  // useGate(bookingModel.CheckoutStepGate)

  const checkIn = useStore(tripInfoModel.$checkIn)
  const checkOut = useStore(tripInfoModel.$checkOut)
  const selectedHotel = useStore(
    hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  )
  const selectedDestination = useStore(destinationModel.$selectedDestination)
  const guestGroups = useStore(addGuestsInfoModel.$guestGroups)
  const selectedRooms = useStore(hotelRoomModel.$$manageRoomsDataFactory.$selectedRooms)
  const tripBooking = useStore(createBookingModel.$tripBooking)
  const selectedOffer = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$selectedOffer,
  )

  const flightDirections = selectedOffer?.directions
  const flightOrigin = flightDirections?.[0].segments?.[0].origin?.cityName
  const flightDestination =
    flightDirections?.[0].segments?.slice(-1)[0].destination?.cityName

  const selectedFlightType = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$flightType,
  )

  const isFlightsIncluded = useStore(
    searchFlightsModel.$$manageLocalStorageForSearchFlights.$isFlightsSelected,
  )

  const hotelImage = selectedHotel?.images?.[0]
  const hotelCode = selectedHotel?.code

  if (!guestGroups) {
    return null
  }

  const guests = Object.values(guestGroups).flat()
  const flightsPassengers = tripBooking?.flight?.passengers

  const allBags = getAllBags(tripBooking!)
  const hasBags = hasServiceType(tripBooking!, 'baggage')
  const hasSeats = hasServiceType(tripBooking!, 'seat')

  return (
    <div css={styles.overview.container}>
      <div css={styles.overview.wrapper}>
        {/* Header */}
        <div css={styles.overview.header.wrapper}>
          <img css={styles.overview.header.img} src={hotelImage} alt="trip-img" />
          <div css={styles.overview.header.content}>
            <h1 css={styles.overview.header.title}>{selectedDestination?.name}</h1>
            <p css={styles.overview.header.subtitle}>
              {formatBookingDates(checkIn, checkOut)}
            </p>
          </div>
        </div>

        {/* Guests */}
        <div>
          <SidebarInfoTile title={'Guests'} />

          <div css={styles.guests.wrapper}>
            {guests.map((guest, index) => (
              <div key={index} css={styles.guests.item}>
                <div css={styles.guests.name}>
                  {guest.firstName} {guest.lastName}
                </div>
                <span></span>
                <div css={styles.guests.date}>{guest.dateOfBirth}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Hotel */}
        <div css={styles.infoTile}>
          <SidebarInfoTile editable title={'Hotel'} value={`${selectedHotel?.name} `} />
        </div>

        {/* Rooms */}
        {selectedRooms && (
          <div css={styles.infoTile}>
            <div>
              <SidebarInfoTile editable title={'Rooms'} />
              {selectedRooms.map((room, index) => (
                <div key={index} css={styles.item}>
                  <p css={styles.room.title}>
                    {normalizeStringCase(room.name)}{' '}
                    <span css={styles.room.board}>
                      {normalizeStringEnding(room.rate?.boardName)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Flights */}
        {isFlightsIncluded && (
          <>
            <div css={styles.infoTile}>
              <div>
                <SidebarInfoTile title={'Flights'} />
                <div css={styles.flights.wrapper}>
                  <div
                    css={styles.flights.title}
                  >{`${flightOrigin} - ${flightDestination}`}</div>
                  <div css={styles.flights.type}>
                    {normalizeStringCase(selectedFlightType)}
                  </div>
                </div>
                <div css={styles.flights.dates}>
                  {formatBookingDates(checkIn, checkOut)}
                </div>
              </div>
            </div>

            {hasBags && (
              <div css={styles.infoTile}>
                <div>
                  <SidebarInfoTile
                    path={`${hotelCode}/flights`}
                    editable
                    title={'Baggage'}
                  />

                  <div css={styles.baggage.wrapper}>
                    {allBags.map((bag, index) => (
                      <div key={index} css={styles.baggage.item}>
                        <CheckedBagIcon /> {bag.quantity} Checked Bag
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {hasSeats && (
              <div css={styles.infoTile}>
                <div>
                  <SidebarInfoTile
                    path={`${hotelCode}/flights`}
                    editable
                    title={'Seating'}
                  />

                  <div css={styles.seating.row}>
                    <div css={styles.seating.col}>
                      <div css={styles.flights.wrapper}>
                        <div
                          css={styles.seating.title}
                        >{`${flightOrigin} - ${flightDestination}`}</div>
                        <div css={styles.flights.type}>
                          {normalizeStringCase(selectedFlightType)}
                        </div>
                      </div>
                      <div css={styles.seating.list}>
                        {flightsPassengers!.map((guest, index) => (
                          <div key={index} css={styles.seating.list}>
                            {guest.firstName} {guest.lastName} -{' '}
                            {guest.services
                              ?.filter((service) => service.type === 'seat')
                              .map((service) => service.metadata?.designator)
                              .join('/')}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
