import { css } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'

import { Colors, Fonts } from '~/shared/styles'

import { fonts } from './fonts'
import { reset } from './reset'

export const globalStyles = css`
  ${fonts}
  ${emotionNormalize}
  ${reset}
  body {
    width: 100%;
    height: 100%;

    font-family: ${Fonts.Calibre};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::after {
      position: fixed;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      content: '';

      transition: opacity 500ms ease;
      pointer-events: none;

      opacity: 0;
      z-index: 100;

      background: rgb(0 0 0 / 30%);

      /*  don't work in Firefox */
      backdrop-filter: blur(5px);
    }

    &.lock {
      overflow: hidden;

      padding-right: var(--scrollbar-compensation);
      touch-action: none;

      &::after {
        opacity: 1;
      }
    }
    /* stylelint-disable */
    .react-datepicker__day--selected {
      outline: 1px solid ${Colors.PRIMARY_GREEN} !important;
      background-color: ${Colors.PRIMARY_GREEN} !important;
    }
  }

  .visually-hidden {
    position: absolute;

    overflow: hidden;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    white-space: nowrap;

    border: 0;
    clip-path: inset(100%);
  }

  //Intercom styles

  .intercom-lightweight-app-launcher {
    bottom: 105px !important;

    background-color: ${Colors.LIGHT_1} !important;
  }

  .intercom-lightweight-app-launcher-icon-open {
    svg {
      path {
        fill: ${Colors.PRIMARY_GREEN} !important;
      }
    }
  }

  /*localhost*/
  .intercom-dfosxs {
    bottom: 92px !important;
  }

  iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
  }

  @media (max-width: 768px) {
    .intercom-lightweight-app-launcher {
      bottom: 20px !important;
      right: 20px !important;
    }

    /*localhost*/
    .intercom-dfosxs {
      bottom: 92px !important;
    }

    iframe[name='intercom-notifications-frame'] {
      bottom: 124px !important;
    }
  }
`
