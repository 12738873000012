import { createEffect, createStore, sample, type Event, type Store } from 'effector'
import { toast, type ToastOptions, type TypeOptions } from 'react-toastify'
import { getNotificationIcon } from './lib'
import { styles } from './styles'

export interface Options extends ToastOptions {
  title?: string
  message: string
}

const showNotificationFx = createEffect((options: Options) => {
  const { type, title, message } = options

  return toast(
    <div css={styles.wrapper}>
      <div css={styles.icon}>{getNotificationIcon(type)}</div>

      <div css={styles.container}>
        {title && <h1 css={styles.title}>{title}</h1>}
        <p css={styles.message}>{message}</p>
      </div>
    </div>,
  )
})

const $defaultOptions = createStore<ToastOptions>({
  hideProgressBar: true,
})

export function notification<T, K = void>(config: {
  clock: Event<T>
  source?: Store<K>
  filter?: Store<boolean>
  type:
    | TypeOptions
    | (K extends void ? (clock: T) => TypeOptions : (clock: T, source: K) => TypeOptions)
  title?:
    | string
    | (K extends void ? (clock: T) => string : (clock: T, source: K) => string)
  message:
    | string
    | (K extends void ? (clock: T) => string : (clock: T, source: K) => string)
  options?: Options
}) {
  return sample({
    clock: config.clock,
    source: {
      configSource: config.source ?? createStore<K>(null as unknown as K),
      defaultOptions: $defaultOptions,
    },
    filter: config.filter,
    fn: ({ configSource, defaultOptions }, clock) => {
      return {
        type:
          typeof config.type === 'function'
            ? config.type(clock, configSource)
            : config.type,
        title:
          typeof config.title === 'function'
            ? config.title(clock, configSource)
            : config.title,
        message:
          typeof config.message === 'function'
            ? config.message(clock, configSource)
            : config.message,
        ...defaultOptions,
      }
    },
    target: showNotificationFx,
  })
}
